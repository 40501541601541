import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule, ClrDatagrid, ClrForm, ClrModal } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription, firstValueFrom } from "rxjs";
import { first } from "rxjs/operators";

import { InTableValidatorDirective } from "src/app/directives/validators/in-table-validator.directive";
import { ArrayMethodsCallbackPipe } from "src/app/pipes/array-methods-callback.pipe";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { LinieDrehungPipe } from "src/app/pipes/linie-drehung.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { LinieDrehungService } from "src/app/services/Shared/linie-drehung.service";
import { WebSocketService } from "src/app/services/Websocket/websocket.service";
import { db } from "src/app/services/dexieDB";
import { LinieValidationManager } from "src/app/shared/classes/linie-validation-manager";
import { BasedatamodalComponent } from "../../../_components/_modals/basedatamodal/basedatamodal.component";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { DynamicStyleDirective } from "src/app/directives/dynamic-style.directive";

/** same as Linie + value*/
export type SpezPointType = SpezOptType & { bezeichnung: any; };

type SpezColumnType = {
  id: string; // name
  bezeichnung: string; // orgName
  pflichtFeld: boolean | 0 | 1;
  wListe: string[] | null;
  optionalData: {
    isOptSpez: boolean;
    fieldType: string;
    sField: string;
  };
};

type SpezRowType = {
  [key: string]: {
    objekt: string;
    value: any;
    orgName: string;
  };
};

export type SpezOptType = {
  objekt: string;
  orgName: string;
  pflichtFeld?: boolean | 0 | 1;
  wListe?: string[];
  sField?: string;
  fieldType: string;
  isHeader?: boolean;
};

@Component({
  selector: "app-spezifikationsdialog",
  templateUrl: "./spezifikationsdialog.component.html",
  styleUrls: ["./spezifikationsdialog.component.scss"],
  imports: [CommonModule, ClarityModule, FormsModule, ReactiveFormsModule, BasedatamodalComponent, InTableValidatorDirective, MrTranslatePipe, ObjTypePipe,ArrayMethodsCallbackPipe,
    LinieDrehungPipe, HideIDColumnsPipe, TablePrettyPrintPipe,BackendLocaleDatePipe, DynamicStyleDirective],
  providers: [BackendLocaleDatePipe, LinieDrehungPipe],
  standalone: true
})
export class SpezifikationsDialogComponent extends LinieValidationManager implements OnInit, OnDestroy {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private dataRefresh: EventEmitter<any>;

  @ViewChild(ClrForm, { static: false })
  private clrForm: ClrForm
    ;
  @ViewChild(NgForm, { static: false })
  protected ngForm: NgForm
    ;
  @ViewChild('templateTableGrid', { read: ClrDatagrid, static: false })
  private templateTableGrid: ClrDatagrid
    ;
  @ViewChild('templateTableGrid', { read: ElementRef, static: false })
  private scrollContainer: ElementRef<HTMLElement>
    ;
  @ViewChild('spezTableGrid', { read: ClrDatagrid, static: false })
  private spezTableGrid: ClrDatagrid
    ;
  protected isBasedataModalOpen: boolean = false;
  @ViewChild(BasedatamodalComponent, { static: false })
  private basedatamodal: BasedatamodalComponent
    ;
  @ViewChild('notCompleteModal', { static: true })
  private notCompleteModal: ClrModal
    ;


  protected typ_Line_Point: number = 1;
  @Input() set settypeid(typeid: number) {
    this.typ_Line_Point = typeid;
  }

  private OTYPID: number;
  @Input() set setotypid(otypid: number) {
    this.OTYPID = otypid;
  }

  @Input() set setstammData(stammData: any) {
    this.startmeter = stammData.startmeter;
    this.endmeter = stammData.endmeter;
  }

  private ostammid: number;
  @Input() set setostammid(ostammid: number) {
    this.isInspektion = true;
    this.ostammid = ostammid;
  }

  public set data(dataIn: any) {
    this.table = dataIn.tableSpez;
    this.spezData = dataIn.spezData;
    this.ostammid = dataIn.ostamm;
    this.typ_Line_Point = dataIn.type;
    this.OTYPID = dataIn.otypid;

    this.startmeter = dataIn.stammData.startmeter;
    this.endmeter = dataIn.stammData.endmeter;

    this.websocket._connection.on('inspLocked', data => {
      this.toastr.info(
        this.mrTranslate.transform("Spezifikation für '" + data[0] + "' wird erstellt von " + data[1] + ". Stammdokument ist für diesen Zeitraum gesperrt."),
        this.mrTranslate.transform("Inspektion")
      );
    });

    this.websocket._connection.on('inspClosed', data => {
      this.toastr.info(
        this.mrTranslate.transform("Spezifikation für '" + data[0] + "' wurde geschlossen von " + data[1] + ". Stammdokument ist wieder bearbeitbar."),
        this.mrTranslate.transform("Inspektion")
      );
    });

    this.dataRefresh = dataIn.dataRefresh;

    this.apiService
      .sendAnlagenMessage(this.ostammid, "Start")
      .pipe(first())
      .subscribe((val: any) => {
      });
  }

  protected titel: string = 'Spezifikation verwalten';
  protected isInspektion: boolean = false;

  //* nur für Punktobjekte
  private OSPEZID: number;
  protected spezData: SpezPointType[];

  //* nur für Linienobjekte
  protected table: { columns: SpezColumnType[]; rows: SpezRowType[]; };
  protected spezOpt: SpezOptType[] = [];
  protected setInsert: boolean = false;
  protected isSmartInsert: boolean = true;
  protected isComplete: boolean = true;
  protected direction: number = 1;
  protected exceptStartEnd =
    (col: SpezColumnType) => !['Startmeter', 'Endmeter'].includes(col.id)
    ;
  protected startmeter: number = 0;
  protected endmeter: number = 0;

  protected formSpezTop = new UntypedFormGroup({
    Startmeter: new UntypedFormControl(''),
    Endmeter: new UntypedFormControl(''),
    Code: new UntypedFormControl('', [Validators.required]),
  });

  private _selectedSpez: SpezRowType;
  protected get selectedSpez(): SpezRowType {
    return this._selectedSpez;
  }
  protected set selectedSpez(row: SpezRowType) {
    this._selectedSpez = row;
    this.formSpezTop.reset();
    if (!row) return;
    this.titel = 'Spezifikation verwalten';
    this.setInsert = false;
    this.formSpezTop.enable();
    Object.entries(this.formSpezTop.controls).forEach(([key, control]) => {
      control.setValue(row[key]?.value);
    });
    this.clrForm.markAsTouched();
    if (this.typ_Line_Point == 4 && row.Code?.value) {
      this.templateTableGrid.singleSelected = this.tableTmpl?.rows?.find(
        (tmpl) => tmpl.Bezeichnung == row.Code.value
      );
      setTimeout(() => {
        this.scrollContainer.nativeElement
          .querySelector("#t-" + row.Code.value)
          ?.scrollIntoView({
            behavior: 'auto',
            block: 'center'
          });
      });
    }
  }

  //* nur für Gleisobjekte
  protected tableTmpl: { columns: { id: string }[]; rows: any[] };
  protected set selectedTmpl(value: any) {
    this._selectedTmpl = value;
    this.formSpezTop?.controls.Code?.setValue(value?.Bezeichnung);
  }
  protected get selectedTmpl(): any {
    return this._selectedTmpl;
  }
  private _selectedTmpl: any;


  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    protected drehung: LinieDrehungService,
    protected drehungPipe: LinieDrehungPipe,
    protected websocket: WebSocketService,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    super();
    this.form = this.formSpezTop;
  }
  @HostListener('window:beforeunload')
  ngOnDestroy() {
    firstValueFrom(this.apiService.sendAnlagenMessage(this.ostammid, "Close"));
  }

  async ngOnInit() {
    if (
      !isNaN(this.startmeter) &&
      !isNaN(this.endmeter) &&
      !!(this.startmeter - this.endmeter)
    ) {
      this.direction = this.initLinieValidators(this.startmeter, this.endmeter);
    }

    if (this.typ_Line_Point == 4)
      this.tableTmpl = this.apiService.isRep
        ? await db.getSpezTemplate()
        : await firstValueFrom(this.apiService.getSpezTemplates());

    const data = this.isInspektion
      ? this.apiService.isRep ? await db.getSpez(this.ostammid, this.OTYPID, this.typ_Line_Point)
        : await firstValueFrom(
          this.apiService.getSpez(
            this.ostammid,
            this.OTYPID,
            this.typ_Line_Point
          )
        )
      : undefined;
    this.initializeTables(data);
  }

  private initializeTables(data: any) {
    switch (this.typ_Line_Point) {
      case 1: case 3:
        this.spezData ||= data;
        this.OSPEZID =
          this.spezData?.find((row) => row.objekt == 'OSPEZID')?.bezeichnung ??
          -1;
        break;
      case 2:
        const skipArr = ['OSPEZID', 'Startmeter', 'Endmeter'];
        this.formSpezTop.removeControl('Code');
      case 4:
        this.table ||= data;
        this.table.columns.forEach((col) => {
          if (
            col.optionalData?.isOptSpez ||
            (this.typ_Line_Point == 2 && !skipArr.includes(col.id))
          ) {
            this.formSpezTop.addControl(col.id, new UntypedFormControl());
            this.spezOpt.push({
              objekt: col.id,
              orgName: col.bezeichnung,
              pflichtFeld: col.pflichtFeld,
              wListe: col.wListe,
              sField: col.optionalData?.sField,
              fieldType: col.optionalData?.fieldType,
            });
          }
        });
        this.formSpezTop.disable();
        this.checkComplete();
        break;
    }
  }

  protected openTabelle(spez: SpezPointType | SpezOptType) {
    this.isBasedataModalOpen = true;
    setTimeout(() => {
      const inData = {
        titel: spez.objekt,
        tabelle: spez.sField,
        OTYPID: this.OTYPID,
        tname: spez.orgName,
      };
      this.basedatamodal.open(inData);

      const sub: Subscription = this.basedatamodal.onOK.subscribe((res) => {
        if (res.isChanged) {
          spez.wListe = res.stammdaten.rows.map(
            (row: any) => row.Bezeichnung ?? row.Name
          );
          setTimeout(() => {
            // ! in Timeout, weil wListe Zeit braucht, um zu updaten
            const control =
              (this.typ_Line_Point == 4 || this.typ_Line_Point == 2
                ? this.formSpezTop
                : this.ngForm
              )?.controls[spez.objekt];
            control.updateValueAndValidity();
          });
        }
        sub?.unsubscribe();
        this.isBasedataModalOpen = false;
      });
    });
  }

  private async saveSpezPoint(): Promise<boolean> {
    if (this.ngForm.pristine) {
      this.toastr.success(
        this.mrTranslate.transform('Nichts wurde verändert'),
        this.mrTranslate.transform('Spezifikationen')
      );
      return true;
    }
    const sendObj = {
      table: this.spezData,
      OSPEZID: this.OSPEZID,
      otypid: this.OTYPID,
      typ: this.typ_Line_Point,
    };

    const { success, error, spezid } = this.apiService.isRep ?
      await db.SetSpezifikationen(sendObj, this.ostammid)
      : await firstValueFrom(
        this.apiService.setSpez(this.ostammid, sendObj)
      );
    if (success) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert'),
        this.mrTranslate.transform('Spezifikationen')
      );
      this.OSPEZID = spezid;
      this.dataRefresh?.emit(true);
      return true;
    } else {
      console.log(error);
      this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Spezifikationen')
      );
      return false;
    }
  }

  private async saveSpezLine(): Promise<boolean> {
    const formValue = this.formSpezTop.getRawValue();
    const spezData = this.table.columns.map(
      (col): SpezPointType => ({
        objekt: col.id,
        bezeichnung:
          formValue[col.id] ??
          this.selectedTmpl?.[col.id],
        orgName: col.bezeichnung,
        fieldType: col.optionalData?.fieldType,
      })
    );

    const sendObj = {
      table: spezData,
      OSPEZID: this.selectedSpez?.OSPEZID.value,
      otypid: this.OTYPID,
      typ: this.typ_Line_Point,
      smartInsert: this.setInsert && this.isSmartInsert,
      upwards: this.direction == 1,
      von: formValue['Startmeter'],
      bis: formValue['Endmeter'],
    };

    const { success, spezid, table, error } = this.apiService.isRep ?
      await db.SetSpezifikationen(sendObj, this.ostammid)
      : await firstValueFrom(
        this.apiService.setSpez(this.ostammid, sendObj)
      );

    if (success) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert'),
        this.mrTranslate.transform('Spezifikationen')
      );
      spezData.find(
        (spez) => spez.objekt == 'OSPEZID'
      ).bezeichnung = spezid;
      if (this.setInsert) {
        if (this.isSmartInsert && table) this.table.rows = table.rows;
        let neuRow: SpezRowType = {};
        spezData.forEach((spez) => {
          neuRow[spez.objekt] = {
            objekt: spez.objekt,
            value: spez.bezeichnung,
            orgName: spez.orgName,
          };
        });
        this.table.rows.push(neuRow);
        this.table.rows.sort(
          (a, b) => a.Startmeter.value - b.Startmeter.value
        );
        this.spezTableGrid.singleSelected = neuRow;
      } else if (this.selectedSpez) {
        spezData.forEach(
          (spez) =>
            (this.selectedSpez[spez.objekt].value = spez.bezeichnung)
        );
      }
      this.checkComplete();
      this.dataRefresh?.emit(true);
      return true;
    } else {
      console.log(error);
      this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Spezifikationen')
      );
      return false;
    }
  }

  public saveIfValid(): Promise<boolean> {
    this.clrForm.markAsTouched();
    switch (this.typ_Line_Point) {
      case 1: case 3:
        return this.ngForm.valid && this.saveSpezPoint();
      case 2: case 4:
        return this.formSpezTop.valid && this.saveSpezLine();
    }
  }

  public isVollständig(startMeter: number, endMeter: number) {
    this.startmeter = startMeter;
    this.endmeter = endMeter;
    this.direction = this.initLinieValidators(this.startmeter, this.endmeter);
    this.checkComplete();
    if (!this.isComplete) this.toastr.error(
      this.mrTranslate.transform('Berücksichtigen Sie die Vollständigkeit'),
      this.mrTranslate.transform('Spezifikationen')
    );
    return this.isComplete;
  }

  protected addSpez() {
    this.setInsert = true;
    this.isSmartInsert = true;
    this.spezTableGrid?.selection.clearSelection();
    this.templateTableGrid?.selection.clearSelection();
    this.titel = 'Neue Spezifikation anlegen';
    this.formSpezTop.enable();
  }

  protected deleteSpezLine() {
    const final = (success: any, error: string) => {
      if (success) {
        this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
        this.table.rows.splice(
          this.table.rows.indexOf(this.selectedSpez),
          1
        );
        this.checkComplete();
        this.formSpezTop.disable();
        this.spezTableGrid?.selection.clearSelection();
        this.templateTableGrid?.selection.clearSelection();
        this.dataRefresh?.emit(true);
      } else {
        this.toastr.error(
          error,
          this.mrTranslate.transform('Fehler beim Löschen')
        );
      }
    }
    const text = this.mrTranslate.transform('Spezifikation wirklich löschen?');
    if (confirm(text) && this.selectedSpez) {
      this.apiService.isRep ?
        db.DeleteObjectWhere(this.selectedSpez.OSPEZID.value, "OSPEZID", "OSPEZ").then((v: any) => {
          final(v.success, v.error)
        })
        :
        this.apiService
          .deleteSpez(this.selectedSpez.OSPEZID.value)
          .pipe(first())
          .subscribe(({ success, error }: any) => {
            final(success, error);
          });
    }
  }

  private checkComplete() {
    const { laenge, min, max } = this.table.rows.reduce(
      (vals, row) => {
        vals.laenge += (row.Endmeter.value * 100 - row.Startmeter.value * 100) / 100;
        vals.min = Math.min(vals.min, row.Endmeter.value, row.Startmeter.value);
        vals.max = Math.max(vals.max, row.Endmeter.value, row.Startmeter.value);
        return vals;
      },
      { laenge: 0, min: Number.MAX_VALUE, max: Number.MIN_VALUE }
    );
    this.isComplete =
      laenge == (this.endmeter * 100 - this.startmeter * 100) / 100 &&
      min == Math.min(this.startmeter, this.endmeter) &&
      max == Math.max(this.startmeter, this.endmeter);
  }


  protected close() {
    if (!this.isComplete)
      this.notCompleteModal.open();
    else this.closedialog.next(true);
  }
}
