import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { MrUnitTextPipe } from 'src/app/pipes/mrUnitText.pipe';
import { APIService } from '../../../../services/APIService/api.service';
import { PlotlyPlotComponent } from '../../plotly-plot/plotly-plot.component';


@Component({
  selector: 'app-trassierungseditordialog',
  templateUrl: './trassierungseditordialog.component.html',
  styleUrls: ['./trassierungseditordialog.component.scss'],
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, PlotlyPlotComponent,MrUnitTextPipe, HideIDColumnsPipe],
  standalone: true
})

export class Trassierungseditordialog implements OnInit {
  @ViewChild('von') von: ElementRef;
  @ViewChild('bis') bis: ElementRef;
  @ViewChild('wert1') wert1: ElementRef;
  @ViewChild('wert2') wert2: ElementRef;
  @ViewChild('wert3') wert3: ElementRef;
  @ViewChild('wert4') wert4: ElementRef;
  @ViewChild('awert') awert: ElementRef;
  @ViewChild('steigung') steigung: ElementRef;

  ostammid: number;
  radius: any;
  ueberhoehung: any;
  geschwindigkeit: any;
  bahnsteighoehe: any;
  bahnsteigentfernung: any;
  neigung: any;
  typen: any;
  selecteddata: any;
  public seltyp: number = 0;
  selectedtyp: any;
  selid: number = 0;
  startmeter: number = 0;
  endmeter: number = 0;
  richtung: number = 0;

  graphdata: any = [];
  layout: any;
  graphconfig: any;
  selecteditem: any;
  detailState: any;
  seltypid: any;

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  set data(dataIn: any) {
    this.ostammid = dataIn.id;

    let trass = {
      status: "gettyp",
      ostammid: this.ostammid,
      typ: 0,
    }

    this.apiservice.sendOSTEDT(trass).pipe(first()).subscribe((res: any) => {

      this.typen = res.typen.rows;
      this.startmeter = res.startmeter;
      this.endmeter = res.endmeter;

      if (this.startmeter > this.endmeter)
        this.richtung = 1;
    });
  }

  newitem() {

    if (this.seltyp != 6) {
      let newitems = {
        ID: -1,
        OSTAMMID: this.ostammid,
        KSTEDTID: this.seltyp,
        Startposition: '',
        Endposition: '',
        Anfangswert: '',
      }


      this.selecteddata.rows.push(newitems);
      this.selecteditem = newitems;
      this.detailState = newitems;
    }
    else {
      let newitems = {
        ID: -1,
        OSTAMMID: this.ostammid,
        KSTEDTID: this.seltyp,
        Startposition: '',
        Endposition: '',
        Anfangswert: '',
        Steigung: '',
        Radius: '',
      }


      this.selecteddata.rows.push(newitems);
      this.selecteditem = newitems;
      this.detailState = newitems;
    }


  }
  saveItem() {


    if (this.von.nativeElement.value == '') {
      this.toastr.warning(this.mrTranslate.transform("Startposition fehlt"));
      return;
    }

    if (this.bis.nativeElement.value == '' && this.awert.nativeElement.value == '') {
      this.toastr.warning(this.mrTranslate.transform("Endposition fehlt"));
      return;
    }

    if (this.wert1.nativeElement.value == '') {
      this.toastr.warning(this.mrTranslate.transform("Wert fehlt"));
      return;
    }

    let bis = 0;

    if (this.seltyp == 6) {
      if (this.wert2.nativeElement.value == '' && this.wert3.nativeElement.value == '') {
        this.toastr.warning(this.mrTranslate.transform("Wert fehlt"));
        return;
      }
    }

    if (this.bis.nativeElement.value != '')
      bis = parseFloat(this.bis.nativeElement.value);


    if (this.richtung == 0) {
      if (this.bis.nativeElement.value == '' && this.awert.nativeElement.value != '') {
        this.bis.nativeElement.value = parseFloat(this.von.nativeElement.value) + parseFloat(this.awert.nativeElement.value);
      }
    }
    else {
      if (this.bis.nativeElement.value == '' && this.awert.nativeElement.value != '') {
        this.bis.nativeElement.value = parseFloat(this.von.nativeElement.value) - parseFloat(this.awert.nativeElement.value);
      }
    }

    let von = parseFloat(this.von.nativeElement.value)

    if (this.richtung == 0) {
      if (von < this.startmeter) {
        this.toastr.warning(this.mrTranslate.transform("von < Startposition"));
        return;
      }
      if (bis > this.endmeter) {
        this.toastr.warning(this.mrTranslate.transform("bis > Endmeter"));
        return;
      }
    }
    else {
      if (von > this.startmeter) {
        this.toastr.warning(this.mrTranslate.transform("von > Startposition"));
        return;
      }
      if (bis < this.endmeter) {
        this.toastr.warning(this.mrTranslate.transform("bis < Endmeter"));
        return;
      }
    }

    if (von == bis) {
      this.toastr.warning(this.mrTranslate.transform("von =  bis"));
      return;
    }


    let val1 = parseFloat(this.wert1.nativeElement.value);
    let val2 = 0;

    if (this.wert2.nativeElement.value != '')
      val2 = parseFloat(this.wert2.nativeElement.value);
    else
      val2 = parseFloat(this.wert1.nativeElement.value);

    let val3 = 0;
    let val4 = 0;

    if (this.seltyp == 6) {
      let a = Math.abs(bis - von);
      if (this.wert2.nativeElement.value == '') {
        val2 = val1 + parseFloat(this.wert3.nativeElement.value) / 1000 * a
      }
      if (this.wert3.nativeElement.value == '') {
        let h = Math.abs(val2 - val1);
        val3 = (h / a) * 1000;

        if (val2 - val1 < 0) {
          val3 *= -1;
        }
      }

      if (this.wert4.nativeElement.value != '')
        val4 = parseFloat(this.wert4.nativeElement.value);
    }

    if (this.seltyp == 3) {
      val1 = Math.abs(val1);
      val2 = val1;
    }

    this.selecteddata.rows = [];

    let trass = {
      status: "update",
      id: this.selid,
      ostammid: this.ostammid,
      typ: this.seltyp,
      von: von,
      bis: bis,
      val1: val1,
      val2: val2,
      val3: val3,
      val4: val4,
    }

    this.apiservice.sendOSTEDT(trass).pipe(first()).subscribe((res: any) => {
      this.selecteddata.rows = res.rows;
      this.selecteTypChanged(this.seltypid);
    });


  }
  deletItem() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?"))) {
      let trass = {
        status: 'delete',
        id: this.selid,
        typ: this.seltyp,
      }

      this.selecteditem = undefined;
      this.detailState = undefined;
      this.selecteddata.rows = [];

      this.apiservice
        .sendOSTEDT(trass)
        .pipe(first())
        .subscribe((val: any) => {
          if (val) {
            this.selecteddata.rows = val.rows;
            this.selecteTypChanged(this.seltypid);
          }
        });
    }
  }

  selectionItemChanged(value) {
    this.selid = value.ID;
  }

  selecteTypChanged(value) {
    this.seltypid = value;

    if (this.selecteddata != undefined) {
      if (this.selecteddata.rows != undefined) {
        this.selecteddata.rows = [];
      }
    }

    this.graphdata = [];
    let tmpstart = this.startmeter;
    let tmpende = this.endmeter;

    if (this.richtung == 1) {
      tmpstart = this.endmeter;
      tmpende = this.startmeter;
    }


    this.seltyp = parseInt(value);
    let trass = {
      status: "get",
      ostammid: this.ostammid,
      typ: this.seltyp,
    }

    this.apiservice.sendOSTEDT(trass).pipe(first()).subscribe((res: any) => {

      if (this.selecteddata == undefined) {
        this.selecteddata = res;
      }
      else {
        this.selecteddata.rows = res.rows;
      }

      switch (value) {
        case '1':
        case '2':
        case '3':
        case '6': {
          this.name = this.mrTranslate.transform("Überhöhung");

          let x = [];
          let y = [];
          let lastX;
          let lastY;

          for (var i = 0; i < this.selecteddata.rows.length; i++) {
            let row = this.selecteddata.rows[i];
            let s = row['Startposition'];
            let e = row['Endposition'];
            let aw = row['Anfangswert'];
            let ew = row['Endwert'];

            if (i == 0) {
              if (s > tmpstart) {
                x.push(tmpstart);
                y.push(0)
              }
              lastX = s;
            }

            if (lastX < s) {
              x.push(lastX);
              y.push(0);

              x.push(s);
              y.push(0);

            }

            if (this.richtung == 1) {
              x.push(e);
              y.push(aw);
              x.push(s);
              y.push(ew);
            }
            else {
              x.push(s);
              y.push(aw);
              x.push(e);
              y.push(ew);
            }


            if (i == this.selecteddata.rows.length - 1) {
              if (e < tmpende) {
                x.push(e);
                y.push(0);
                x.push(tmpende);
                y.push(0);
              }
            }

            lastX = e;
            lastY = ew;
          }

          for (var i = 1; i < x.length; i++) {
            let tx0 = x[i - 1];
            let tx1 = x[i];
            let ty0 = y[i - 1];
            let ty1 = y[i];

            if (tx0 == tx1 && ty0 == ty1) {
              x.splice(i, 1);
              y.splice(i, 1);
            }
          }

          if (value == '6') {
            let xm = [];
            let ym = [];
            let tx = [];

            for (var i = 0; i < this.selecteddata.rows.length; i++) {
              let row = this.selecteddata.rows[i];
              let s = row['Startposition'];
              let e = row['Endposition'];
              let aw = row['Anfangswert'];
              let ew = row['Endwert'];

              if (this.richtung == 1) {
                s = row['Endposition'];
                e = row['Startposition'];
              }

              let _xm = (e + s) / 2;
              let _ym = (aw + ew) / 2;

              xm.push(_xm);
              ym.push(_ym);
              tx.push(row['Steigung'] + " ‰");
            }

            let xt = [];
            let yt = [];
            let tt = [];

            for (var i = 1; i < this.selecteddata.rows.length; i++) {
              let row0 = this.selecteddata.rows[i - 1];
              let row1 = this.selecteddata.rows[i];

              let aw = row1['Anfangswert'];
              let s = row1['Startposition'];
              let e = row1['Endposition'];
              if (this.richtung == 1) {
                s = row1['Endposition'];
                e = row1['Startposition'];
              }
              let stg0 = row0['Steigung'];
              let stg1 = row1['Steigung'];
              let ar = row1['Radius'];

              let stg = (stg0 / 10) - (stg1 / 10);
              let t = Math.abs(ar / 2 * stg / 100);
              let f = (t * t) / (2 * ar);
              let h = Math.abs(row0['Endwert'] - row0['Anfangswert']);
              let l = Math.abs(row0['Endposition'] - row0['Startposition']);

              let alpha1 = Math.atan(h / l);
              let _ba = Math.tan(alpha1) * t;

              h = Math.abs(row1['Endwert'] - row1['Anfangswert']);
              l = Math.abs(row1['Endposition'] - row1['Startposition']);

              let alpha2 = Math.atan(h / l);
              let _be = Math.tan(alpha2) * t;

              let BaX = s - t;
              let BeX = s + t;
              let BaY = aw - _ba;
              let BeY = aw - _be;
              let stHW = "HW";

              if (stg0 > 0 && stg1 < 0) {
                BaY = aw - _ba;
                BeY = aw - _be;
                stHW = "HK";
              }

              if (stg0 <= 0 && stg1 > 0) {
                BaY = aw + _ba;
                BeY = aw + _be;
                stHW = "HW";
              }

              if (stg0 < 0 && stg1 < 0) {
                BaY = aw + _ba;
                BeY = aw - _be;
                stHW = "HW";

                if (stg0 > stg1)
                  stHW = "HK";
              }

              if (stg0 < 0 && stg1 == 0) {
                BaY = aw + _ba;
                BeY = aw - _be;
                stHW = "HW";
              }

              if (stg0 > 0 && stg1 > 0) {
                BaY = aw - _ba;
                BeY = aw + _be;
                stHW = "HW";

                if (stg0 > stg1)
                  stHW = "HK";
              }

              xt.push(BaX);
              yt.push(BaY);

              xt.push(BeX);
              yt.push(BeY);

              let strLbl = BeX.toFixed(3) + "<br>"
              strLbl += BeY.toFixed(3) + "<br>"
              strLbl += stHW + " = " + ar.toFixed(0) + "<br>"
              strLbl += "T = " + t.toFixed(3) + "<br>"
              strLbl += "f = " + f.toFixed(3) + "<br>"
              tt.push(strLbl);

            }

            let newtt = []
            if (this.selecteddata.rows.length > 1) {
              let row0 = this.selecteddata.rows[0];
              let aw = row0['Anfangswert'];

              newtt.push(aw.toString() + "<br>");
              for (var i = 0; i < tt.length; i++) {
                newtt.push(tt[i]);
              }
              row0 = this.selecteddata.rows[this.selecteddata.rows.length - 1];
              aw = row0['Endwert'];
              newtt.push(aw.toString() + "<br>");
            }


            var trace1 = {
              x: x,
              y: y,
              mode: 'lines+markers+text',
              name: this.name,
              line: {
                color: 'rgb(128, 0, 128)',
              },
              text: newtt,
              textposition: 'top',
              marker: {
                color: 'rgb(0, 0, 0)',
                size: 14
              },
            };


            var traceT = {
              x: xt,
              y: yt,
              mode: 'markers',
              name: this.name + "mittett",
              marker: {
                color: 'rgb(0, 0, 0)',
                symbol: 'diamond',
                size: 7
              },
            };


            var traceM = {
              x: xm,
              y: ym,
              mode: 'text',
              name: this.name + "mitte",
              text: tx,
              textposition: 'top',
              marker: {
                color: 'rgb(128, 0, 128)',
                size: 8
              },
            };

            if (x.length > 0)
              this.graphdata = [trace1, traceM, traceT];

          }
          else {
            var trace = {
              x: x,
              y: y,
              mode: 'lines+markers',
              name: this.name,
              line: {
                color: 'rgb(60, 133, 0)',
              },
            };

            if (x.length > 0)
              this.graphdata = [trace];
          }

        }
          break;
      }


    });

  }

  name: string = '';

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe
  ) {

  }

  public close() {
    this.closedialog.next(true);
  }

  ngOnInit() {
    this.layout = {
      autosize: true,
      hovermode: 'closest',
      showlegend: false,
      legend: { "orientation": "h" },
      height: 400,
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0
      },
      plot_bgcolor: 'transparent',
      paper_bgcolor: 'transparent'
    }


    this.graphconfig = {
      responsive: true,
      displaylogo: false,
      locale: 'de',
      modeBarButtonsToRemove: ['sendDataToCloud', 'lasso2d', 'zoomOut2d', 'zoomIn2d', 'autoScale2d', 'toggleSpikelines', 'hoverCompareCartesian', 'hoverClosestCartesian']
    }
  }

}
