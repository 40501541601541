import { Component, Input, EventEmitter, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../../../pipes/mr-translate.pipe";
import { PinchZoomComponent  } from "@meddv/ngx-pinch-zoom";
import { NgTemplateOutlet, NgIf, NgFor, NgStyle } from "@angular/common";
import { ClrModalModule, ClrSpinnerModule, ClrIconModule } from "@clr/angular";

@Component({
    selector: "app-mediaviewmodal",
    templateUrl: "./mediaviewmodal.component.html",
    styleUrls: ["./mediaviewmodal.component.scss"],
    imports: [
        ClrModalModule,
        NgTemplateOutlet,
        PinchZoomComponent,
        NgIf,
        ClrSpinnerModule,
        ClrIconModule,
        NgFor,
        NgStyle,
        MrTranslatePipe_1,
    ],
    standalone: true
})
export class MediaViewModalComponent {
  /**
   * @alias getBigFileFnc
   * @function base64 beim Server anfordern (mit Dateigröße ab 9,5 MB)
   * @async
   * @returns Promise - string
   */
  @Input('getBigFileFnc')
    fileBase64FromServer: (idOrName: number | string) => Promise<string>
  ;
  /**
   * @function base64[] beim Server anfordern (mit Dateigröße bis 9,5 MB)
   * @async
   * @returns Promise - {base64, name, id?}[]
   */
  @Input() getMediaFromServerAll: (fileNames: string[]) => Promise<Array<object>>;

  @Input() set media( files: TitleAndFileNames []) {
    this.previewThumbnails = [];
    this.currentThumb = undefined;
    if (files?.length && !this.loading) {
      this.loading = true;
      setTimeout(() => {

        if (files[0]["title"] == this.mrTranslate.transform("Messpunktskizze")){
          this.isSkizze = true;
        }

        this.unpackFiles(files[0]["title"],files[0]["fileNames"]);

        if (this.previewThumbnails.length) {
          this.changeIndexTo(0);
          this.modalOpen = true;
        }
        this.loading = false;
      });
    }
  }

  @Input() set srcs( files: TitleAndFileNames []) {
    this.previewThumbnails = [];
    this.currentThumb = undefined;
    if (files?.length && !this.loading) {
      this.loading = true;
      setTimeout(() => {
        this.getFiles(files);
      });
    }
  }
  private loading: boolean;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  protected set modalOpen(isOpen) {
    this._modalOpen = isOpen;
    if (!isOpen) this.onClose.emit();
  }
  protected get modalOpen() {
    return this._modalOpen;
  }
  private _modalOpen = false;

  protected isSkizze: boolean = false;
  protected currentIndex: number = 0;
  protected currentThumb: any;
  protected previewThumbnails: any[] = [];
  protected loadingFile = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
  ) {}

  private async getFiles(fileNames: TitleAndFileNames []) {
    const promisesArray = fileNames.map(({ title = "Gallerie", fileNames }) =>
      new Promise<{ title: string, files: any[] }>( async (resolve, reject) => {
        try {
          const files = await this.getMediaFromServerAll(fileNames);
          resolve({
            title: title,
            files: files,
          });
        } catch (er) {
          reject(title + ": " + er);
        }
      })
    );

    (await Promise.allSettled(promisesArray)).forEach(({ status, value : { title, files } = { title: "", files: []}, reason }: any) => {
      if (status == "fulfilled") this.unpackFiles(title, files);
      else this.toastr.error(reason);
    });

    if (this.previewThumbnails.length) {
      this.changeIndexTo(0);
      this.modalOpen = true;
    }
    this.loading = false;
  }

  private unpackFiles(title: string, files: any) {
    files.forEach(({ name, base64, id = undefined }: any) => {
      const extension = name.split(".").pop();
      let plays = false;
      let src = "";
      switch (extension.toLowerCase()) {
        case "svg":
          src = "data:image/" + extension + "+xml;base64," + base64;
          base64 = null;
          break;
        case "mp4":
        case "mov":
          src = "/assets/icons/videoicon.png";
          plays = true;
          break;
        default:
          src = "data:image/" + extension + ";base64," + base64;
          base64 = null;
          break;
      }
      this.previewThumbnails.push({
        src: this.domSanitizer.bypassSecurityTrustUrl(src),
        title: title,
        name: name,
        id: id,
        base64: base64,
        plays: plays,
      });
    });
  }

  private async openVideo(index: number = undefined) {
    const fileObj = index ? this.previewThumbnails[index] : this.currentThumb;
    if (!fileObj.url) {
      this.loadingFile = true;
      try {
        //! base64
        //   - fileObj
        //   - beim Server anfordern, wenn Size mehr als 9,5 MB
        const base64 = fileObj.base64 || await this.fileBase64FromServer(fileObj.id || fileObj.name);
        const fileUrl = "data:video/mp4;base64," + base64;
        fileObj.url = fileUrl;
      } catch (er) {
        this.toastr.error((<ErrorEvent>er).message);
      }
      this.loadingFile = false;
    }
  }

  protected moveGallery(mode: string) {
    let nextIndex = this.currentIndex;
    if (mode == "right") nextIndex++;
    else if (mode == "left") nextIndex--;

    if (nextIndex >= this.previewThumbnails.length) nextIndex = 0;
    if (nextIndex < 0) nextIndex = this.previewThumbnails.length - 1;

    this.changeIndexTo(nextIndex);
  }

  protected changeIndexTo(index: number) {
    this.currentIndex = index;
    this.currentThumb = this.previewThumbnails[index];

    if (this.currentThumb.plays) this.openVideo();
  }
}

export interface TitleAndFileNames {
  title?: string;
  fileNames: string[];
}
