<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
        <span class="title">{{'Prüfungen verwalten' | mrTranslate}}</span>
    </div>
    <div class="header-actions">
      <a href="javascript://" class="nav-link nav-icon">
        <clr-icon  shape="times" (click)="close()"></clr-icon >
      </a>
    </div>
  </header>
  <div id="objekttpyWrapper">
    <div class="clr-row" style="margin-top: -20px;">
      <div class="clr-col-3">
        <h3  style="margin-left: 30px;">{{ "Objekte" | mrTranslate }}</h3>
        <div style="height: 10px;"></div>

        <clr-tree>
          <clr-tree-node *ngFor="let item of sotyp" [(clrSelected)]="item.selected" [clrDisabled]="item.disabled">
            <button class="clr-treenode-link" routerLinkActive="active">
              <clr-icon class="customIcon" [attr.shape]="'objekttyp' + item.symbol" size="28"></clr-icon>
              {{ item.bezeichnung }}
            </button>
          </clr-tree-node>
        </clr-tree>
      </div>

      <div class="clr-col-9">
        <h3  class="margin-11-tb">{{ "Prüfungsarten" | mrTranslate }}</h3>

        <div class="clr-row nxm" style="align-items: center;">
          <div class="clr-col-auto margin-12-tb">
            <div class="btn-group btn-primary btn-icon" style="margin-left: -10px;">
              <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                (click)="newitem()">
                <clr-icon shape="file"></clr-icon>
              </button>
              <button class="btn btn-icon" [title]="'Speichern der Objektzuordnung' | mrTranslate"
                (click)="saveitem()" [disabled]="selPruefID == 0 || selPruefID == 1">
                <clr-icon shape="floppy"></clr-icon>
              </button>
              <button class="btn btn-icon" [title]="'Bearbeiten' | mrTranslate"
                (click)="showedititem()" [disabled]="selPruefID == 0">
                <clr-icon shape="note"></clr-icon>
              </button>
              <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
                (click)="deletitem()" [disabled]="deletdisabled">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="limit-height">
          <clr-datagrid #pruefungDg *ngIf="pruefungen?.columns?.length"
            style="padding-top: 0px;" class="datagrid-compact" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selpruefung" (clrDgSingleSelectedChange)="selectionPruefungChanged($event)">
            <clr-dg-column *ngFor=" let column of pruefungen['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns:['otypid']">{{(column.id | titlecase) | mrTranslate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let row of pruefungen['rows']" [clrDgItem]="row">
              <clr-dg-cell *ngFor="let cell of pruefungen['columns']" [hidden]="cell.id | hideIDColumns:['otypid']"
                [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination>{{pruefungen?.rows?.length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </div>
  </div>
</div>


<clr-modal [(clrModalOpen)]="showPruefModal" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{showPruefModalTitel | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm ngForm [formGroup]="formGroupKP" #f="ngForm" clrLayout="horizontal" clrLabelSize="8">

      <clr-input-container>
        <label class="clr-col-md-3 clr-required-mark"> {{ "Prüfungsart" | mrTranslate }} </label>
        <input clrInput formControlName="titel" name="name" required style="width: 300px;"/>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-md-3 clr-required-mark"  for="intervall">{{ "Standard Intervall" | mrTranslate }}</label>
        <input clrInput type="number"
          min="1" name="intervall" formControlName="intervallpr" style="width: 80px;"/>
        <clr-control-error *clrIfError="'required'">
          {{ "Erforderlich" | mrTranslate }}
        </clr-control-error>
        <clr-control-error *clrIfError="'min'">
          {{ "Mindestens 1" | mrTranslate }}
        </clr-control-error>
        <clr-control-error *clrIfError="'pattern'">
          {{ "nur ganze Zahl" | mrTranslate }}
        </clr-control-error>
      </clr-input-container>

      <clr-input-container>
        <label class="clr-col-md-3 clr-required-mark"  for="intervall">{{ "Intervall Messung" | mrTranslate }}</label>
        <input clrInput type="number"
          min="1" name="intervall" formControlName="intervallme" style="width: 80px;"/>
        <clr-control-error *clrIfError="'required'">
          {{ "Erforderlich" | mrTranslate }}
        </clr-control-error>
        <clr-control-error *clrIfError="'min'">
          {{ "Mindestens 1" | mrTranslate }}
        </clr-control-error>
        <clr-control-error *clrIfError="'pattern'">
          {{ "nur ganze Zahl" | mrTranslate }}
        </clr-control-error>
      </clr-input-container>

    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="savePR()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="showPruefModal = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
