<div class="grid w-100-p h-100" id="grid-tab-container" *ngIf="zaehler_name$ | async as name">
  <div class="clr-row nxm clr-align-items-start">
    <ng-container *ngIf="!store.isReadOnly(); else readonlyRow">
      <div class="btn-group btn-icon btn-primary margin-12-t">
        <button class="btn btn-success"
          type="submit" form="pruefung-form"
          [title]="'Speichern' | mrTranslate"
          [disabled]="store.isNew() || !objekt().OPRUEFID">
          <clr-icon shape="floppy"></clr-icon>
        </button>
        <!-- <button class="btn btn-danger" [title]="'Löschen' | mrTranslate"
          (click)="resetMesswerteToDefault()">
          <clr-icon shape="times"></clr-icon>
        </button> -->
        <button class="btn btn-icon btn-outline"
          [title]="'Beschreibung' | mrTranslate"
          (click)="openHelper(name)">
          <clr-icon shape="help"></clr-icon>
        </button>
  
      </div>
      <form *ngIf="edit_messung()?.pruefung as pruefung" id="pruefung-form"
        #pruefungForm clrForm (ngSubmit)="saveIfValid(objekt().OPRUEFID)"
        clrLayout="vertical" class="width100 clr-col">
        <div class="clr-row nxm clr-align-items-baseline" style="gap: 25px;">
          <clr-date-container class="mt-0">
            <label class="clr-required-mark" for="date">
              {{'Datum' | mrTranslate}}
            </label>
            <input type="date" clrDate name="P_ZDatum" required
              [ngModel]="pruefung.P_ZDatum | localeDate"
              (ngModelChange)="pruefung.P_ZDatum = localeDate.tosql($event)"
              [ngModelOptions]="{ updateOn: 'blur' }" />
            <clr-control-error *clrIfError="'required'">
              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
            </clr-control-error>
          </clr-date-container>
          <clr-datalist-container class="mt-0">
            <label class="clr-required-mark clr-col-md-12">
              {{'Messender' | mrTranslate}}
            </label>
            <input clrDatalistInput type="search" name="P_ZUser"
              [(ngModel)]="pruefung.P_ZUser" required
              class="clr-col-md-12" autocomplete="off" />
            <datalist>
              <option *ngFor="let option of messenderList()" [value]="option"></option>
            </datalist>
            <clr-control-error *clrIfError="'required'">
              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
            </clr-control-error>
          </clr-datalist-container>
          <clr-datalist-container class="mt-0">
            <label class="clr-col-md-12">
              {{'Messgerät' | mrTranslate}}
            </label>
            <input clrDatalistInput type="search" name="M_Device"
              [(ngModel)]="pruefung.M_Device"
              class="clr-col-md-12" autocomplete="off" />
            <datalist>
              <option *ngFor="let option of messgeraeteList()" [value]="option"></option>
            </datalist>
          </clr-datalist-container>
          <clr-textarea-container class="mt-0 clr-col nxp" style="min-width: 200px;">
            <label>{{ "Bemerkung" | mrTranslate }}</label>
            <textarea rows="2" clrTextarea name="P_Bemerkung"
              [(ngModel)]="pruefung.P_Bemerkung">
            </textarea>
          </clr-textarea-container>
        </div>
      </form>
    </ng-container>
    <ng-template #readonlyRow>
      <button class="btn btn-icon btn-outline margin-12-t"
        [title]="'Beschreibung' | mrTranslate"
        (click)="openHelper(name)">
        <clr-icon shape="help"></clr-icon>
      </button>
      <!-- <div class="btn-group btn-icon btn-outline margin-12-t">
      </div> -->
      <form *ngIf="edit_messung()?.pruefung as pruefung" clrLayout="vertical" class="width100">
        <div class="clr-row nxm clr-align-items-baseline" style="gap: 5px;">
          <clr-input-container class="mt-0">
            <label>{{'Datum' | mrTranslate}}</label>
            <input clrInput type="text" readonly [value]="pruefung.P_ZDatum" />
          </clr-input-container>
          <clr-input-container class="mt-0">
            <label>{{'Messender' | mrTranslate}}</label>
            <input clrInput type="text" readonly [value]="pruefung.P_ZUser" />
          </clr-input-container>
          <clr-input-container class="mt-0">
            <label>{{'Messgerät' | mrTranslate}}</label>
            <input clrInput type="text" readonly [value]="pruefung.M_Device" />
          </clr-input-container>
          <clr-input-container class="mt-0">
            <label>{{'Bemerkung' | mrTranslate}}</label>
            <input clrInput type="text" readonly [value]="pruefung.P_Bemerkung" />
          </clr-input-container>
        </div>
      </form>
    </ng-template>
  </div>
  <ng-container *ngIf="werte_checklist$ | async as checklist">
    <ng-container *ngIf="messungen$ | async as messungen">
      <ng-container *ngIf="tol$ | async as tolOption">
        <ng-template #loading>
          <clr-spinner class="centred"></clr-spinner>
        </ng-template>
        <!-- [clrDgRowSelection]="false && !store.isReadOnly()" -->
        <clr-datagrid #wertGrid *ngIf="checklist.columns | filterTolByOptions: name as columns; else loading"
          class="datagrid-compact h-100 w-100-p no-min-width-grid sticky-action-column">
          <ng-container *ngIf="!store.isReadOnly()">
            <clr-dg-column class="sticky-action" style="width: 64px;">
              <button type="button" class="btn btn-primary btn-icon btn-sm nm"
              (click)="editNextRow(index());">
                <clr-icon shape="step-forward" solid="true"
                  [title]="(index() == 0 ? 'Starten' : 'Weiter') | mrTranslate">
                </clr-icon>
              </button>
            </clr-dg-column>
          </ng-container>
          <clr-dg-column *ngFor="let column of columns">
            <ng-container *clrDgHideableColumn="{ hidden: column.id | hideIDColumns: 
                ['szaehnid', 'i_sort', 'zeinheit', 'username', 'status', 'zart', 'ztype', 'sgenau', 'tgenau', 'aktivsrlimm', 'aktivsr100', 'aktivsrg', 'lastid']
              }">
              {{ column.id | transformColNames: tolOption | mrTranslate }}
            </ng-container>
          </clr-dg-column>
          <ng-container *ngIf="edit_messung()?.unsaved">
            <clr-dg-column>
              {{ 'Ist' | mrTranslate }}
              <clr-icon shape="note" class="margin-3-lr"></clr-icon>
            </clr-dg-column>
            <clr-dg-column>{{ 'Tol. Verl.' | mrTranslate }}</clr-dg-column>
            <clr-dg-column>{{ 'Bemerkung' | mrTranslate }}</clr-dg-column>
          </ng-container>
          <ng-container *ngFor="let group of messungen">
            <clr-dg-column>
              {{ 'Ist' | mrTranslate }} - {{ group.pruefung.Column_Datum }}
              <clr-icon
                *ngIf="group.pruefung.OPRUEFID == edit_messung()?.pruefung.OPRUEFID"
                shape="note" class="margin-3-lr">
              </clr-icon>
            </clr-dg-column>
            <clr-dg-column>{{ 'Tol. Verl.' | mrTranslate }} - {{ group.pruefung.Column_Datum }}</clr-dg-column>
            <clr-dg-column>{{ 'Bemerkung' | mrTranslate }} - {{ group.pruefung.Column_Datum }}</clr-dg-column>
          </ng-container>
          <clr-dg-row
            *clrDgItems="let row of checklist.rows; let i = index; trackBy: trackByID"
            [clrDgItem]="row"
            id="i-{{ row.ID }}"
            [class.headerRow]="row.ZType == ZType_Header"
          >
            <clr-dg-cell *ngIf="!store.isReadOnly()"
              class="sticky-action" style="width: 64px;">
              <button *ngIf="row.ZType != ZType_Header" 
                type="button" class="btn btn-icon btn-sm nm h-auto"
                (click)="editNextRow(i)">
                <clr-icon shape="pencil" [title]="'Bearbeiten' | mrTranslate"></clr-icon>
              </button>
            </clr-dg-cell>
            <ng-container *ngFor="let cell of columns">
              <clr-dg-cell class="name-cell"
                [class.sticky-action]="row.ZType == ZType_Header && cell.id == 'Code'"
                [innerHTML]="row | werteRowValues: cell.id | tablePrettyPrint">
              </clr-dg-cell>
            </ng-container>
            <ng-container *ngIf="edit_messung()?.unsaved">
              <clr-dg-cell class="name-cell">{{ edit_messung()?.messwerte[i]?.Ist | mrTranslate }}</clr-dg-cell>
              <clr-dg-cell class="noover">
                <span *ngIf="edit_messung()?.messwerte[i]?.Ausfall as ausfall"
                  class="label ausfall"
                  [style.--ausfall-color]="ausfall | getAusfallColor: tolOption">
                  {{ ausfall }}
                </span>
              </clr-dg-cell>
              <clr-dg-cell class="name-cell">{{ edit_messung()?.messwerte[i]?.Bemerkung }}</clr-dg-cell>
            </ng-container>
            <ng-container *ngFor="let group of messungen">
              <clr-dg-cell class="name-cell">{{ group.messwerte[i]?.Ist }}</clr-dg-cell>
              <clr-dg-cell class="noover">
                <span *ngIf="group.messwerte[i]?.Ausfall as ausfall"
                  class="label ausfall"
                  [style.--ausfall-color]="ausfall | getAusfallColor: tolOption">
                  {{ ausfall }}
                </span>
              </clr-dg-cell>
              <clr-dg-cell class="name-cell">{{ group.messwerte[i]?.Bemerkung }}</clr-dg-cell>
            </ng-container>
          </clr-dg-row>
          <clr-dg-footer>{{checklist.rows.length}} {{"Zeilen" | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
        <clr-modal #modal
          [clrModalOpen]="isOpenModal()" (clrModalOpenChange)="isOpenModal.set($event)"
          [clrModalClosable]="true"
          [clrModalSize]="'xl'"
        >
          <div class="modal-title">
            <h3>{{ "Zählererfassung" | mrTranslate }}</h3>
          </div>
          <div class="modal-body grid" id="grid-form-container">
            <div id="preview">
              <ng-container *ngTemplateOutlet="preview; context: { name }"></ng-container>
            </div>
            <form *ngIf="erfassungModel() && checklist.rows[index()] as selected"
              id="erfassung-form" #erfassungForm clrForm
              (ngSubmit)="saveRow()" [clrLabelSize]="4"
              class="width100 grid no-margin text-center" clrLayout="vertical">
              <h3 [style.grid-area]="'code'">{{ selected.Code }}</h3>          
              <ng-container *ngIf="ZArt_Options.get(+selected.ZArt) as mode">
                <ng-container *ngIf="mode.soll.input != 'Select'; else ja_nein">
                  <clr-input-container [style.grid-area]="'ist'">
                    <label class="clr-align-self-end">
                      <span hidden>{{ "Ist" | mrTranslate }}</span>
                      <button class="btn btn-sm btn-icon btn-link clr-col-auto nm"
                        style="text-transform: lowercase;"
                        tabindex="-1"
                        type="button" [title]="'Toggle n.g.' | mrTranslate"
                        (click)="erfassungModel().Ist = 'n.g.'">
                        {{ 'n.g.' | mrTranslate }}
                      </button>
                    </label>
                    <input clrInput type="number" name="Ist"
                      [(ngModel)]="erfassungModel().Ist"
                      [placeholder]="'n.g.' | mrTranslate"/>
                    <!-- <clr-control-error *clrIfError="'required'">
                      {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                    </clr-control-error> -->
                  </clr-input-container>
                </ng-container>
                <ng-template #ja_nein>
                  <clr-select-container [style.grid-area]="'ist'">
                    <label hidden>{{ "Ist" | mrTranslate }}</label>
                    <select clrSelect name="Ist" [(ngModel)]="erfassungModel().Ist">
                      <option value="n.g.">{{ "n.g." | mrTranslate }}</option>
                      <option value="Ja">{{ "Ja" | mrTranslate }}</option>
                      <option value="Nein">{{ "Nein" | mrTranslate }}</option>
                    </select>
                  </clr-select-container>
                </ng-template>
                <span *ngIf="erfassungModel().Ausfall as ausfall"
                  class="label ausfall" [style.grid-area]="'ausfall'"
                  [style.--ausfall-color]="ausfall | getAusfallColor: tolOption">
                  {{ ausfall }}
                </span>
                <!-- <input type="text" name="Ausfall" hidden [ngModel]="erfassungModel().Ausfall" readonly /> -->
                <ng-container *ngTemplateOutlet="readonlyInput; context:
                  { label: 'Soll-Wert',
                    control: 'Soll',
                    area: 'soll',
                    mode: mode.soll }">
                </ng-container>
                <ng-container *ngIf="mode.minus">
                  <ng-container *ngIf="name.SR100 && selected.aktivSR100">
                    <ng-container *ngTemplateOutlet="readonlyInput; context: 
                      {
                        label: 'SR100minus' | transformColNames: tolOption,
                        control: 'SR100minus',
                        area: 'sr100-minus',
                        mode: mode.minus
                      }">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="selected.aktivSRlimm">
                    <ng-container *ngTemplateOutlet="readonlyInput; context: 
                      {
                        label: 'SRlimminus' | transformColNames: tolOption,
                        control: 'SRlimminus',
                        area: 'srlim-minus',
                        mode: mode.minus
                      }">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="name.SRG && selected.aktivSRG">
                    <ng-container *ngTemplateOutlet="readonlyInput; context: 
                      {
                        label: 'SRGminus' | transformColNames: tolOption,
                        control: 'SRGminus',
                        area: 'srg-minus',
                        mode: mode.minus
                      }">
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="mode.plus">
                  <ng-container *ngIf="name.SR100 && selected.aktivSR100">
                    <ng-container *ngTemplateOutlet="readonlyInput; context: 
                      {
                        label: 'SR100plus' | transformColNames: tolOption,
                        control: 'SR100plus',
                        area: 'sr100-plus',
                        mode: mode.plus
                      }">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="selected.aktivSRlimm">
                    <ng-container *ngTemplateOutlet="readonlyInput; context: 
                      {
                        label: 'SRlimplus' | transformColNames: tolOption,
                        control: 'SRlimplus',
                        area: 'srlim-plus',
                        mode: mode.plus
                      }">
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="name.SRG && selected.aktivSRG">
                    <ng-container *ngTemplateOutlet="readonlyInput; context: 
                      {
                        label: 'SRGplus' | transformColNames: tolOption,
                        control: 'SRGplus',
                        area: 'srg-plus',
                        mode: mode.plus
                      }">
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #readonlyInput let-label="label" let-control="control" let-mode="mode" let-area="area">
                  <clr-input-container [style.grid-area]="area" style="justify-self: center;">
                    <label>{{ label | mrTranslate }}</label>
                    <input clrInput type="text" readonly
                      [value]="mode.text + ' ' + ((control | addTol: selected) ?? '')"
                    />
                  </clr-input-container>
                </ng-template>
              </ng-container>
    
              <clr-textarea-container [style.grid-area]="'bemerkung'" class="fill-space">
                <label>{{ "Bemerkung" | mrTranslate }}</label>
                <textarea clrTextarea name="Bemerkung"
                  [(ngModel)]="erfassungModel().Bemerkung">
                </textarea>
              </clr-textarea-container>
    
              <clr-input-container [style.grid-area]="'bezeichnung'">
                <label>{{ "Bezeichnung" | mrTranslate }}</label>
                <input clrInput type="text" [value]="selected.Bezeichnung" readonly />
              </clr-input-container>
              
              <clr-input-container [style.grid-area]="'zart'">
                <label>{{ "Zählerart" | mrTranslate }}</label>
                <input clrInput type="text" [value]="ZArt_Map.get(+selected.ZArt) ?? ''" readonly />
              </clr-input-container>
            </form>
          </div>
          <div class="modal-footer clr-align-items-baseline">
            <div class="clr-col">
              <div id="nav-row" class="grid">
                <button *ngIf="index() != 0"
                  type="button"
                  class="btn btn-link np nm text-right"
                  style="justify-self: end;"
                  [style.grid-area]="'prev'"
                  (click)="editPrevRow()"
                  [title]="'vorherige Wert öffnen' | mrTranslate">
                  {{ (checklist.rows | at: (index() - 1))?.Code }}
                </button>
                <button
                  type="button"
                  class="btn np nm btn-link btn-state btn-state-custom-size" [style.grid-area]="'prev-btn'"
                  [disabled]="index() == 0"
                  (click)="editPrevRow()"
                  [title]="'vorherige Wert öffnen' | mrTranslate">
                  <clr-icon shape="circle-arrow left"></clr-icon>
                </button>
                <button
                  type="button"
                  class="btn np nm btn-link btn-state btn-state-custom-size" [style.grid-area]="'next-btn'"
                  [disabled]="index() == checklist.rows.length - 1"
                  (click)="editNextRow()"
                  [title]="'nächste Wert öffnen' | mrTranslate">
                  <clr-icon shape="circle-arrow right"></clr-icon>
                </button>
                <button *ngIf="index() != checklist.rows.length - 1"
                  type="button"
                  class="btn btn-link np nm text-left"
                  [style.grid-area]="'next'"
                  style="justify-self: start;"
                  (click)="editNextRow()"
                  [title]="'nächste Wert öffnen' | mrTranslate">
                  {{ (checklist.rows | at: (index() + 1))?.Code }}
                </button>
              </div>
            </div>
            <button type="submit" form="erfassung-form" class="btn btn-primary">
              <clr-icon shape="step-forward" solid="true"></clr-icon>
              {{'Speichern & weiter' | mrTranslate}}
            </button>
          </div>
        </clr-modal>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngTemplateOutlet="preview; context: { name }"></ng-container>
</div> 
<app-hilfe-beschreibung-modal [readonly]="true"></app-hilfe-beschreibung-modal>
<ng-template #preview let-name="name">
  <app-preview-thumbnails *ngIf="name.Bild64"
    [data]="[{ base64: name.Bild64, name: '.png' }]"
    [readonly]="true"
    [isSkizze]="true">
  </app-preview-thumbnails>
</ng-template>