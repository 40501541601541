import { Pipe, PipeTransform } from '@angular/core';
import { ToleranzenBezeichnungen, ZählerName, ZählerWert } from './zaehler.types';
import { defaultTolBez, matchTolOption } from './zaehler.enums';

@Pipe({
  name: 'transformColNames',
  standalone: true
})
export class TransformColNamesPipe implements PipeTransform {

  transform(colName: keyof ZählerWert | keyof ZählerName, tol: ToleranzenBezeichnungen): string {
    tol ??= defaultTolBez;
    if (colName == 'ZType') 
      return 'Header';
    const optionName: keyof ToleranzenBezeichnungen = matchTolOption[colName];
    if (optionName)
      return tol[optionName] ?? defaultTolBez[optionName];
    return colName;
  }
}