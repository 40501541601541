#detailviewwrapper {
  padding: 10px;
  height: 100%;

  .zaehlerAccordion .clr-accordion-inner-content {
    padding: 0.4rem !important;
  }

  .kavbar {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.3em 0.5em;
    box-shadow: none;
  }

  .detailViewToolbar {
    .btn {
      min-width: 3.5em !important;
      padding: 0px !important;
    }
  }

  .wrap-button.set-width {
    &.dokumenteControls {
      button.btn {
        width: 130px;
        min-width: 90px !important;
        max-width: 100%;
      }
    }
    &.detailViewToolbar {
      div.btn-group-overflow, button.btn {
        width: 42px;
        min-width: 30px !important;
        max-width: 100%;
      }
    }

  }

  clr-tabs {
    height: calc(100% - 36px) !important;
    &.short {
      height: calc(100% - 60px) !important;
    }
    section.tab-content {
      overflow: auto;
    }
  }
}

#detailviewSwitcherTriggerWrapper {
  position: absolute;
  inset-block: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .side-panel-body & {
    display: none;
  }

  #detailviewSwitcherTrigger {
    height: 64px;
    width: 32px;
    background-color: #002538;

    a {
      color: #fff;
    }
  
    clr-icon {
      margin-top: 16px;
      transition: rotate .3s ease-out;
    }
    ::ng-deep .dark-theme & {
      background: #202a30 !important;
    }
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-left: .4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    cursor: pointer;
}