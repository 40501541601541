//#region import
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule, ClrDatagrid, ClrForm } from "@clr/angular";
import { Position, lineString, nearestPointOnLine } from "@turf/turf";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subject, Subscription, firstValueFrom } from "rxjs";
import { filter, pairwise, startWith } from "rxjs/operators";

import OlGeolocation from 'ol/Geolocation.js';
import { Table } from "src/app/models/ui/table";
import { ArrayMethodsCallbackPipe } from "src/app/pipes/array-methods-callback.pipe";
import { CheckByCallbackFunctionPipe } from "src/app/pipes/check-by-callback.pipe";
import { CompareDatePipe } from "src/app/pipes/compare-date.pipe";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { LinieDrehungPipe } from "src/app/pipes/linie-drehung.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ObjTypePipe, ObjTypePipe as objtype } from "src/app/pipes/objtype.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { LinieDrehungService } from "src/app/services/Shared/linie-drehung.service";
import { MangelMediaApiFuncService } from "src/app/services/_abstract/mangel-media-api-func.service";
import { db } from "src/app/services/dexieDB";
import { LinieValidationManager } from "src/app/shared/classes/linie-validation-manager";
import { BasedatamodalComponent } from "../../_modals/basedatamodal/basedatamodal.component";
import { HilfeBeschreibung, HilfeBeschreibungModalComponent } from "../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { DynamicStyleDirective } from "src/app/directives/dynamic-style.directive";

//#endregion
//#region types
type BaumMode = 'Gattung' | 'Stamm' | 'Pruefart' | 'Lage' | 'Mangel' | 'Instandsetzung' | 'FK';

type MangelRowType = {
  [key: string]: any;
};

type StammTable = {
  coluns: any[];
  rows: {
    GattungID?: number; // nur für Stammitems
    ID: number;
    Bezeichnung: string;
  }[];
};

type SpezRow = {
  von: number;
  bis: number;
  strValue02: string;
  strValue03: string;
  strValue04: string;
  strValue05: string;
};

type SelectType = Map<string, number>;

type StammValues = {
  schiene: StammTable;
  befestigung: StammTable;
  schwellenart: StammTable;
  bettung: StammTable;
  eindeckung: StammTable;
  bauwerke: StammTable;
  regellichtraumprofil: StammTable;
  schienenverbindung: StammTable;
  grundinstandsetzung: StammTable;
  messwerte: StammTable;
  stammitems: StammTable;
};

type MangelBaumVerknüpfung = {
  ID: number; // szumzid
  GattungID: number;
  Gattung?: string;
  StammID: number;
  Stamm?: string;
  PruefartID: number;
  Pruefart: string;
  LageID?: number;
  Lage?: string;
  MangelID: number;
  Mangel: string;
  InstandsetzungID: number;
  Instandsetzung: string;
  MengeneinheitID: number;
  Mengeneinheit: string;
  Fehlerklasse: number;
}

type MangelBaumRow = {
  zuAbt: string;
  Bedingung: string;
  Min: number;
  UND: string;
  Bedingung2: string;
  Max: number;
  Bedingungseinheit: string;
} & MangelBaumVerknüpfung;
//#endregion

@Component({
  selector: "app-mangel-dialog",
  templateUrl: "./mangeldialog.component.html",
  styleUrls: ["./mangeldialog.component.scss"],
  imports: [
    CommonModule, ClarityModule,
    PreviewThumbnailsComponent, CheckByCallbackFunctionPipe, TablePrettyPrintPipe, 
    HilfeBeschreibungModalComponent,ArrayMethodsCallbackPipe, CompareDatePipe, LinieDrehungPipe,
    BasedatamodalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, ObjTypePipe,HideIDColumnsPipe,
    DynamicStyleDirective
  ],
  providers: [BackendLocaleDatePipe, CompareDatePipe, LinieDrehungPipe],
  standalone: true
})
export class MangelDialogComponent extends LinieValidationManager implements OnInit, OnDestroy {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private dataRefresh: EventEmitter<any> | Subject<void>;
  private sub = new Subscription();
  //#region ViewChild
  @ViewChild(PreviewThumbnailsComponent, { static: true })
  private preview: PreviewThumbnailsComponent
    ;
  @ViewChild(HilfeBeschreibungModalComponent, { static: true })
  protected hilfeModal: HilfeBeschreibungModalComponent
    ;
  @ViewChild(BasedatamodalComponent, { static: true })
  private basedatamodal: BasedatamodalComponent
    ;
  @ViewChild('mangelForm', { static: false, read: ClrForm })
  private clrForm: ClrForm
    ;
  @ViewChild('mangelTableGrid', { read: ClrDatagrid, static: false })
  private mangelTableGrid: ClrDatagrid<MangelRowType>
    ;
  //#endregion
  //#region @Input
  //#region Inspektion
  protected typ_Line_Point: number = 1;
  @Input() set settypeid(typeid: number) {
    this.typ_Line_Point = typeid;
  }

  private OTYPID: number;
  @Input() set setotypid(otypid: number) {
    this.OTYPID = otypid;
  }

  @Input() set setstammData(stammData: any) {
    this.startmeter = stammData.startmeter;
    this.endmeter = stammData.endmeter;
  }

  private ostammid: number;
  @Input() set setostammid(ostammid: number) {
    this.isInspektion = true;
    this.ostammid = ostammid;
  }
  @Input() set setCloseDialog(dialog:any) {
    this.closedialog = dialog;
  }

  private pruefid: number = 0;
  @Output() setpruefidChange = new EventEmitter<number>();
  @Input() set setpruefid(value: number) {
    this.pruefid = value;
    this.setpruefidChange.emit(value);
  }
  //#endregion
  public set data({ id: dataIn, dataRefresh }: any) {
    this.table = dataIn.mangel;
    this.ostammid = dataIn.ostammid;
    this.pruefid = dataIn.opruefid;
    this.typ_Line_Point = dataIn.typ_Line_Point ?? 1;
    this.OTYPID = dataIn.otypid;

    this.startmeter = dataIn.startmeter;
    this.endmeter = dataIn.endmeter;

    this.dataRefresh = dataRefresh;
  }
  //#endregion
  //#region properties
  protected isInspektion: boolean = false;
  protected opt_Abteilung = false;
  /**
   * @property verfolgt, ob FK nach unter änderbar und ob die Meldung angezeigt sein soll
   */
  protected sperrungFK: boolean;

  /**
   * @property speichert Kette von den nächsten autoselect MangelBaumNodes; Gleis / andere
   */
  private baumKette: Map<BaumMode, BaumMode>;
  /**
   * @property speichert gefilterte MangelBaumVerknüpfungen zum letzten Schritt im Kette
   * @description die Suche wird schneller, weil nächste Schritte im Kette werden
   * im schon gefiltertem Array durchsucht, der wesentlich kleiner ist
   */
  private mangelBaum = new Map<BaumMode, MangelBaumRow[]>();
  private stammBaum: StammValues;
  private spezTable: SpezRow[];
  private bauartgruppe: { [key: string]: number };
  private geocoordinates: Position[];
  /**
   * @property speichert Werte zum Auswahl im Dropdown; verknüpft mit Schritt im Kette,
   * um dynamisch beim Überweg zum nächsten Schritt zu erstellen
   */
  protected values = new Map<BaumMode | 'Mengeneinheit', SelectType>();
  protected valuesFK: string[];
  protected valuesAbteilung: string[];

  protected isMangelValidStatus: boolean = false;
  protected direction: number = 1;
  private startmeter: number = 0;
  private endmeter: number = 0;
  private curMode: "von" | "bis";

  private helper: { [mangel: string]: HilfeBeschreibung } = {};
  private bilder: { [omazsid: number]: any[] } = {};
  protected deletedFiles: string[] = [];

  protected table: Table<MangelRowType>;
  protected exceptStartEnd = (col: any) => !['von', 'bis'].includes(col.id);
  protected notProzent = (umfang: number) => !umfang || umfang < 1 || umfang > 100;

  gattid: number = 0;
  stammid: number = 0;
  protected setInsert: boolean = false;
  private isLoading = false;
  protected isnewmangeltree: boolean = false;
  private _selectedMangel: MangelRowType;
  protected get selectedMangel(): MangelRowType {
    return this._selectedMangel;
  }
  //#endregion
  protected set selectedMangel(row: MangelRowType) {
    if (this._selectedMangel?.OMAZSID === row?.OMAZSID) return;
    this._selectedMangel = row;
    this.formTopGroup.reset(undefined, { emitEvent: false });
    this.sperrungFK = false;
    if (!row) return;

    this.setInsert = false;
    this.isLoading = true;
    this.formTopGroup.enable({ emitEvent: false });

    const {
      gattung, stamm, pruefart, von, bis, nIO,
      szumzid, faktor, lfNr,
      lage, mangel, instandsetzungshinweis, fehlerklasse,
      umfang, mengeneinheit, abteilung, betriebsgefahr, bemerkung
    } = row;

    this.formTopGroup.patchValue({
      pruefart,
      nIO,
      mangelGr: nIO ?? true ? {
        lage, mangel, instandsetzungshinweis, fehlerklasse,
        umfang, mengeneinheit, abteilung, betriebsgefahr, bemerkung
      } : null,
      linieGr: { von, bis },
      gleisGr: { gattung, stamm },
      hiddenGr: { szumzid, faktor, gattung, lfNr },
    }, { emitEvent: false });

    const meh = this.values.get('Mengeneinheit');
    if (mengeneinheit && !meh?.has(mengeneinheit))
      this._selectedMangel.addMeh = true;

    this.clrForm.markAsTouched();
    this.isnewmangeltree = false;
    this.resetBaumKette();
    this.isLoading = false;
    this.getBilder(row.bild, row.OMAZSID);
    this.getBaumKetteIDs();

  }
  //#region Form
  /**
   * @formGroup die meiste Mangel Werte sammelt, wird separat für Punkte in Inspektion mit nIO gesteuert
   */
  protected mangelGr = new UntypedFormGroup({
    lage: new UntypedFormControl(null, Validators.required),
    mangel: new UntypedFormControl(null, Validators.required),
    umfang: new UntypedFormControl(null, { updateOn: 'blur' }),
    mengeneinheit: new UntypedFormControl(null),
    instandsetzungshinweis: new UntypedFormControl(null, Validators.required),
    fehlerklasse: new UntypedFormControl(null),
    betriebsgefahr: new UntypedFormControl(false, {
      validators: Validators.required,
      nonNullable: true
    }),
    abteilung: new UntypedFormControl(null),
    bemerkung: new UntypedFormControl(null),
  });
  /**
   * @formGroup die nicht für User angezeigt ist
   */
  private hiddenGr = new UntypedFormGroup({
    szumzid: new UntypedFormControl(0),
    autofk: new UntypedFormControl(null),
    gattung: new UntypedFormControl(null), // * für Punkte
    lfNr: new UntypedFormControl(null),
    faktor: new UntypedFormControl(1),
  });
  /**
   * @formGroup Hauptform, andere werden mit ihr verbunden
   */
  protected formTopGroup = new UntypedFormGroup({
    pruefart: new UntypedFormControl(null, Validators.required),
    mangelGr: this.mangelGr,
    hiddenGr: this.hiddenGr,
  });
  /**
   * @formGroup für Start- & Endmeter, Validierung durch LinieValidators class hinzugefügt
   */
  protected linieGr = new UntypedFormGroup({
    von: new UntypedFormControl(null),
    bis: new UntypedFormControl(null),
  });

  private gleisGr = new UntypedFormGroup({
    gattung: new UntypedFormControl(null, Validators.required),
    stamm: new UntypedFormControl(null, Validators.required),
  });

  private nIO = new UntypedFormControl(false, {
    validators: Validators.required,
    nonNullable: true
  });

  private geolocation = new OlGeolocation({
    trackingOptions: {
      enableHighAccuracy: true,
    },
  });

  protected get formValue() {
    const formValue = this.formTopGroup.getRawValue();
    return {
      ...formValue.hiddenGr,
      ...formValue.mangelGr,
      ...formValue.gleisGr,
      ...formValue.linieGr,
      pruefart: formValue.pruefart,
      nio: formValue.nIO,
    };
  }
  //#endregion


  //#region load()
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private compareDate: CompareDatePipe,
    private media: MangelMediaApiFuncService,
    protected drehung: LinieDrehungService,
    protected drehungPipe: LinieDrehungPipe,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    super({ start: 'von', end: 'bis' }, true);
    this.form = this.linieGr;

    this.geolocation.setProjection("EPSG:4326");
    this.geolocation.setTracking(false);

    this.geolocation.on('change', pos => {
      if(pos.target.getPosition()) {
        const snapped = nearestPointOnLine(
          lineString(this.geocoordinates),
          pos.target.getPosition(),
          { units: 'meters' }
        );
        console.log("snapped distance:" + snapped.properties.dist) + " m";
        if (snapped.properties.dist > 50) {
          this.toastr.warning(
            this.mrTranslate.transform(
              "Sie sind mehr als 50m vom ausgewählten Objekt entfernt"
            ),
            this.mrTranslate.transform(
              "Geodaten konnten nicht übernommen werden"
            )
          );
          return;
        }
  
        const length = snapped.properties.location;
        const value = length > (this.endmeter - this.startmeter) * this.direction
          ? this.endmeter
          : this.startmeter + length * this.direction;
        this.linieGr.get(this.curMode).reset(Math.round(value * 100) / 100);
        this.geolocation.setTracking(false);
      }
      
    });
      // Callback Error
      this.geolocation.on('error', error => this.toastr.warning(error.message, this.mrTranslate.transform(
        "Geodaten konnten nicht übernommen werden"
      ))
    );
  }


  addMangelTree() {
    this.isnewmangeltree = false;
    const sendObj = {
      status: 'newfrominspektion',
      otypid: this.OTYPID,
      form: this.formValue,
      typ: this.typ_Line_Point,
      gattid: this.gattid,
      stammid: this.stammid,
      omazsid: this._selectedMangel?.OMAZSID
    };

    this.apiService
      .setMangelbaum(sendObj)
      .subscribe((res: any) => {
        if (res) {
          if (res.status == "OK") {

            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
            this.initMangelTree();
          }
          else {
            this.toastr.error(this.mrTranslate.transform("Eintrag existiert bereits !!"));
          }
        }
      });

  }


  async ngOnInit() {
    if (
      !isNaN(this.startmeter) &&
      !isNaN(this.endmeter) &&
      !!(this.startmeter - this.endmeter)
    ) {
      this.direction = this.initLinieValidators(
        this.startmeter, this.endmeter, !this.isInspektion
      );
    }

    this.initKette();
    this.initializeForm();
    this.initMangelTree();
    this.catchChanges();

    if (this.isInspektion)
      this.setNewPruefung();
  }
  /**
   * @method initialisiert formGroups, abhängig von typ_Line_Point und Inspektion Modus
   */
  private initializeForm() {
    switch (this.typ_Line_Point) {
      case 1: case 3:
        if (!this.isInspektion) return;
        this.formTopGroup.addControl('nIO', this.nIO);
        this.sub.add(this.nIO.valueChanges.subscribe(nIO => nIO
          ? this.mangelGr.enable({ emitEvent: false })
          : this.mangelGr.disable({ emitEvent: false })
        ));
        break;
      case 4:
        this.formTopGroup.addControl('gleisGr', this.gleisGr);
      case 2:
        this.formTopGroup.addControl('linieGr', this.linieGr);
        const lage = this.mangelGr.get('lage');
        lage.clearValidators();
        lage.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        break;
    }
    if (this.isInspektion) this.formTopGroup.disable();
  }

  private async setNewPruefung() {
    const sendObj = {
      ostammid: this.ostammid,
      oldpruefid: this.pruefid,
      otypid: this.OTYPID,
      typeid: this.typ_Line_Point,
    }

    const { newpruefid: id, mangel } = await firstValueFrom(
      this.apiService.setNewPruefung(sendObj));

    if(id == 0) {
      this.toastr.warning(
        this.mrTranslate.transform("Inspektion kann nicht erstellt werden - es ist ein Fehler aufgetreten"));
      this.closedialog.next(true);
      return;
    }
    this.setpruefid = id;
    this.table = mangel;

    await this.resetLfNr(); // * Prüft, ob Update der lfNr nötig ist

    this.checkMangelValid();
  }
  /**
   * @method erstellt Kette von den nächsten autoselect MangelBaumNodes; unterscheidet Gleis und andere
   */
  private initKette() {
    const kette: [BaumMode, BaumMode][] = this.typ_Line_Point == 4
      ? [
        ['Gattung', 'Stamm'],
        ['Stamm', 'Pruefart'],
        ['Pruefart', 'Mangel'],
        ['Mangel', 'Instandsetzung'],
        ['Instandsetzung', 'FK'],
      ]
      : [
        ['Pruefart', 'Lage'],
        ['Lage', 'Mangel'],
        ['Mangel', 'Instandsetzung'],
        ['Instandsetzung', 'FK'],
      ]
    this.baumKette = new Map<BaumMode, BaumMode>(kette);
  }

  // TODO: DataTableFE weg
  /**
   * @method initialisiert
   * - erste Werte für Gattung / Prüfart;
   * - erste Schritte im MangelBaum Kette mit grid.rows
   * - Mengeneinheiten, Fehlerklassen, Stammdaten, Spez;
   * - Optionen:
   * -- FK erforderlich;
   * -- FK == minFK => Betriebsgefahr;
   * -- FK nach unter änderbar nach autoSelectFK;
   * -- Abteilung angezeigt;
   */
  private async initMangelTree() {
    const {
      gattung, pruefart, stamm, grid,
      fehlerklassen, mengeneinheit, zuabt, optzuabt,
      useFK, gefahrFK, sperrFK, minfk,
      stammSpez, bauartgruppe, geo

    } =
      this.apiService.isRep ?
        await db.initMangelbaum(this.ostammid, this.OTYPID, this.typ_Line_Point)
        :
        await firstValueFrom(this.apiService.initMangelbaum(
          this.OTYPID.toString() + ";" + this.ostammid.toString() + ";0"
        ));

    // * parse geo: bei coordinates jede paar wird gedreht zu (longitude, latitude)
    // vom ganzen Objekt nur coordinates speichern
    this.geocoordinates = geo ? JSON.parse(geo,
      (key, value) => key === '' ? value.coordinates
        : key === 'coordinates'
          ? value.map((geo: number[]) => geo.reverse())
          : value
    ) : null;
    //#region options
    if (sperrFK)
      this.catch_FK_Sperr_Changes();
    if (gefahrFK)
      this.catch_FK_BG_Changes(minfk);
    if (optzuabt) {
      this.opt_Abteilung = optzuabt;
      const abt = this.mangelGr.get('abteilung');
      abt.setValidators(Validators.required);
      abt.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
    if (useFK) {
      const fk = this.mangelGr.get('fehlerklasse');
      fk.setValidators(Validators.required);
      fk.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
    //#endregion
    //#region values

    this.valuesFK = fehlerklassen;
    this.valuesAbteilung = zuabt?.rows?.map((r: any) => r.Bezeichnung);
    this.values.set('Mengeneinheit', this.initMap(mengeneinheit.rows));

    if (this.typ_Line_Point == 4) {
      this.mangelBaum.set('Gattung', grid.rows);
      this.values.set('Gattung', this.initMap(gattung.rows));
      this.values.set('Lage', new Map([
        [this.mrTranslate.transform('links'), 0],
        [this.mrTranslate.transform('rechts'), 0],
        [this.mrTranslate.transform('links') + '/' + this.mrTranslate.transform('rechts'), 0]
      ]));
      this.stammBaum = stamm;
      this.spezTable = stammSpez;
    }
    else {
      this.mangelBaum.set('Pruefart', grid.rows);
      this.values.set('Pruefart', this.initMap(pruefart.rows));
      this.bauartgruppe = bauartgruppe;
    }
    //#endregion
  }
  /**
   * @method initialisiert values für Select, mit schnellem Zugriff auf ID
   * @returns Map von Bezeichnung zu ID (intID für Gattung)
   */
  private initMap(rows: any[]): SelectType {
    return new Map<string, number>(rows.map(r => [r.Bezeichnung, r.IntID ?? r.ID]));
  }
  //#endregion
  //#region subscription
  /**
   * @method subscribes zu valueChanges vom HauptFormGroup
   * @pipe `pairwise` für Vergleich mit letztem valueChanges Emit
   * @description sucht höchste Schritt im Kette, der geändert wurde,
   * und ruft filterKette() auf
   */
  private catchChanges() {
    this.sub.add(
      this.formTopGroup.valueChanges.pipe(
        startWith(this.formTopGroup.value), pairwise(), filter(_ => !this.isLoading)
      ).subscribe(([old, neu]) => {

        for (const mode of this.baumKette.keys()) {

          const path = Mode_Control[mode]?.split('.');
          const neuValue = path.reduce((group, path) => group?.[path], neu);
          const oldValue = path.reduce((group, path) => group?.[path], old);

          if (neuValue != oldValue) {
            this.filterKette(mode, neuValue);
            break;
          }
        }

        if (neu.linieGr?.von !== old.linieGr?.von) {
          // Stamm basierend auf Spezifikation ist von Startmeter abhängig
          const id = this.values.get('Gattung')?.get(neu.gleisGr.gattung);
          if (!id) return;
          const nextValue = this.getValueFromSpez(id);
          if (nextValue) this.gleisGr.get('stamm').reset(nextValue);
        }

        if (
          neu.mangelGr?.umfang !== old.mangelGr?.umfang
          || neu.linieGr?.von !== old.linieGr?.von
          || neu.linieGr?.bis !== old.linieGr?.bis
        ) {
          // FK rechnen, falls es Bedingungen gibt

          if (this.mangelBaum.get('FK')?.length > 1) {
            const nextValue = this.autoSelectFK(
              this.findFK_beiBedingung(neu.mangelGr.umfang)
            );
            if (nextValue) this.mangelGr.get('fehlerklasse').reset(nextValue);
          }

        }
      })
    );
  }
  /**
   * @description FK == minFK => Betriebsgefahr; Betriebsgefahr => FK = minFK
   * { emitEvent: false }, um valueChanges nicht aufzurufen
   * @param minfk kommt vom initMangelBaum <- Back-End
   */
  private catch_FK_BG_Changes(minfk: number) {
    this.sub.add(
      this.mangelGr.get('fehlerklasse').valueChanges
        .pipe(filter(_ => !this.isLoading)).subscribe(
          value => value === minfk ? this.mangelGr.get('betriebsgefahr')
            .setValue(true, { emitEvent: false }) : null
        )
    );
    this.sub.add(
      this.mangelGr.get('betriebsgefahr').valueChanges
        .pipe(filter(_ => !this.isLoading)).subscribe(
          value => value ? this.mangelGr.get('fehlerklasse')
            .setValue(minfk, { emitEvent: false }) : null
        )
    );
  }
  /**
   * @description FK < autofk ? => Meldung && Zurücksetzen
   * @info
   * - { emitEvent: false }, um valueChanges nicht aufzurufen;
   * - sperrungFK verfolgt status der Meldung
   */
  private catch_FK_Sperr_Changes() {
    this.sub.add(
      this.mangelGr.get('fehlerklasse').valueChanges
        .pipe(filter(_ => !this.isLoading)).subscribe(fk => {
          const autofk = this.hiddenGr.get('autofk').value;
          if (autofk && fk > autofk) {
            this.sperrungFK = true;
            this.mangelGr.get('fehlerklasse').reset(
              autofk, { emitEvent: false }
            );
          }
          else this.sperrungFK = false;
        })
    );
  }

  private getBaumKetteIDs() {

    if (this.typ_Line_Point == 1 || this.typ_Line_Point == 3 && this.nIO.value == false) {
      return;
    }

    for (const mode of this.baumKette.keys()) {
      const value = this.formTopGroup.get(Mode_Control[mode]).value;
      const id = this.values.get(mode)?.get(value);

      if (mode.toString() == "Gattung") {
        this.gattid = id;
      }
      if (mode.toString() == "Stamm") {
        this.stammid = id;
      }

      if (!id) {
        this.isnewmangeltree = true;
        return;
      }
    }
  }

  //#endregion
  //#region MangelBaum Logik()
  /**
   * @method handelt Situationen, wenn Werte gleichzeitig geändert wurden
   * (selected, reset auf null)
   */
  private resetBaumKette() {
    for (const mode of this.baumKette.keys()) {
      const value = this.formTopGroup.get(Mode_Control[mode]).value;
      this.filterBaum(mode, value);
    }
  }
  /**
   * @method filtert angerufene Schritt im Kette und die nächsten,
   * falls neuer Wert in der Liste ist
   * erstellt values, autoselect Value, falls nur eine möglich
   */
  private filterKette(mode: BaumMode, value: string) {
    const id = this.values.get(mode)?.get(value);
    if (!id) return; // not in values -> nicht filtern und ersetzen
    const resetAll = this.typ_Line_Point == 4
      ? mode == 'Gattung'
      : mode == 'Pruefart';

    while (mode) {
      const { nextMode, nextValue } = this.filterForNext(mode, value);
      mode = nextMode;
      value = nextValue;
      if (mode && (value || resetAll))
        this.formTopGroup.get(Mode_Control[mode]).reset(value, { emitEvent: false });
    }
  }
  /**
   * @method
   * `gets` ID von Map<Bezeichnung, ID>, die im values dieses Schrittes gespeichert ist.
   * `filtert` mangelBaum dieses Schrittes nach ID und
   * `erstell` mangelBaum für nächsten Schritt, der hat nur rows mit diesem ID
   * @clears values und mangelBaum, wenn value null ist oder ID nicht gefunden wurde
   * @param mode Schritt in der Kette zum Filtern von
   * @param value Bezeichnung von Gattung | Stamm | Prüfart usw.
   */
  private filterBaum(mode: BaumMode, value: string) {
    const id = this.values.get(mode)?.get(value);
    /**
     * @description enthält array mit alle mangelBaum rows mit dem Wert dieses Schrittes
     * @zB alle rows LageID = 4 haben; mangelBaum('Lage').filter(LageId == 4)
     */
    const filteredArray = id ? this.mangelBaum.get(mode)
      ?.filter(row => row[mode + 'ID'] == id) ?? [] : [];

    const nextMode = this.baumKette.get(mode);
    this.mangelBaum.set(nextMode, filteredArray);

    const values = id ? this.getValues(nextMode, id) : null;
    this.values.set(nextMode, values);

    return { id, values, nextMode, filteredArray };
  }
  /**
   * @method aufruft filterBaum() + gibt nächster Wert zurück
   * @param mode Schritt in der Kette zum Filtern von
   * @param value Bezeichnung von Gattung | Stamm | Prüfart usw.
   * @returns nächster Schritt und Wert des nächsten Schrittes im Kette,
   * (für FK - sucht nach Bedingungen,
   * für Stamm - sucht nach Spezifikationen)
   * falls nur ein, oder null
   */
  private filterForNext(mode: BaumMode, value: string): {
    nextMode: BaumMode,
    nextValue: string | null
  } {
    const { id, values, nextMode, filteredArray } = this.filterBaum(mode, value);
    let nextValue: string | null;
    if (nextMode == 'Stamm')
      nextValue = this.getValueFromSpez(id) || this.autoSelect(values);

    else if (nextMode == 'FK')
      nextValue = this.autoSelectFK(
        values?.size > 1
          ? this.findFK_beiBedingung(this.mangelGr.value.umfang)
          : filteredArray[0] // der einzige Wert
      );

    else
      nextValue = this.autoSelect(values);

    return { nextMode, nextValue };
  }

  private getValues(mode: BaumMode, id: number): SelectType {
    const array = this.mangelBaum.get(mode);
    switch (mode) {
      case "Stamm":
        const stammMode = <keyof StammValues>Stamm_Gattung[id] ?? 'stammitems';
        let rows = this.stammBaum[stammMode].rows;
        if (stammMode == 'stammitems')
          rows = rows.filter(row => row.GattungID == id);
        return this.initMap(rows);
      case "FK":
        const fkArr: [string, number][] = array.map(
          row => ['' + row.Fehlerklasse, row.Fehlerklasse]
        );
        return new Map<string, number>(fkArr);

      default:
        const mapArr = array.map(row => ({
          ID: row[mode + 'ID'],
          Bezeichnung: row[mode]
        }));
        return this.initMap(mapArr);
    }
  }

  private autoSelect(values: SelectType | null): string | null {
    return values?.size == 1 ? values.keys().next().value : null;
  }
  /**
   * @method sets
   * - szumzid, autofk,
   * - Gattung für Punktobjekte,
   * - Abteilung, falls eingeschaltet
   * - Mengeneinheit, falls nicht vom User ausgewählt wurde
   * @param row - der einzige Row oder nach Bedingungen gefunden oder null
   * @returns FK fürs Scheriben in Fehlerklasse Input Control
   */
  private autoSelectFK(row: MangelBaumRow): string | null {
    this.hiddenGr.get('autofk').setValue(row?.Fehlerklasse, { emitEvent: false });
    if (objtype.is(this.typ_Line_Point, 'point'))
      this.hiddenGr.get('gattung').setValue(row?.Gattung, { emitEvent: false });

    const abt = this.mangelGr.get('abteilung');
    if (abt?.pristine) abt.reset(
      row?.zuAbt, { emitEvent: false }
    );

    this.mangelGr.get('mengeneinheit').reset(
      row?.Mengeneinheit, { emitEvent: false }
    );
    this.hiddenGr.get('szumzid').setValue(row?.ID ?? 0, { emitEvent: false });

    const fk = row?.Fehlerklasse.toString();
    if (fk && !this.valuesFK.includes(fk)) return null;
    else return fk;
  }
  /**
   * @method durchsucht Spezifikationen nach Abschnitt, der Startmeter der Mangel enthält
   * @param id von Gattung (intID hier)
   * @returns Wert der Stammdaten, basierend auf Gattung (strValue02 | strValue03 | strValue04 | strValue05)
   */
  private getValueFromSpez(id: number): string | null {
    const von = this.linieGr.value.von;
    const colName = <keyof typeof Spez_Gattung>Spez_Gattung[id];
    if (!(von || von === 0) || !colName) return null;
    return this.spezTable?.find(row =>
      this.direction == 1
        ? row.von <= von && von < row.bis
        : row.von >= von && von > row.bis
    )?.[colName] || null;
  }
  /**
   * @method durchsucht mangelBaum mit allen möglichen FK:
   * - für Linieobjekte nach prozent von Länge der Mangel
   * - für Weiche nach prozent von gesamten Anzahl dieser Prüfart in Bauartgruppe
   * - für andere Punkobjekte - immer erster Row
   * @param umfang - aus der mangelFormGroup
   */
  private findFK_beiBedingung(umfang: number): MangelBaumRow {
    if (!umfang) return null;
    const rows = this.mangelBaum.get('FK');
    const { mengeneinheit: meh } = this.mangelGr.value;

    let prozent: number;

    switch (this.typ_Line_Point) {
      case 1: case 3:
        const anzahl = this.bauartgruppe[this.formTopGroup.value.pruefart];
        prozent = anzahl
          ? umfang / anzahl * 100
          : umfang;
        break;
      case 2: case 4:
        const { von, bis } = this.linieGr.value;
        const { faktor } = this.hiddenGr.value;
        const länge = (Math.abs(bis - von) || 1) * faktor * 1.6667;
        prozent = this.linieGr.valid
          ? umfang / länge * 100
          : umfang;
        break;
    }

    return rows?.find(row => {
      let passt = false;
      const value = row.Bedingungseinheit == "%" && meh != "%"
        ? prozent > 100 ? 100 : prozent
        : umfang;
      switch (row.Bedingung) {
        case '>':
          passt = value > row.Min;
          break;
        case '>=':
          passt = value >= row.Min;
          break;
        case '<':
          passt = value < row.Min;
          break;
        case '<=':
          passt = value <= row.Min;
          break;
      }
      if (row.UND) switch (row.Bedingung2) {
        case '<':
          passt &&= value < row.Max;
          break;
        case '<=':
          passt &&= value <= row.Max;
          break;
      }
      return passt;
    });
  }
  //#endregion
  //#region input buttons helpers()
  protected copyLinieValue(copyFrom: 'von' | 'bis', copyTo: 'von' | 'bis') {
    const value = this.linieGr.get(copyFrom).value;
    this.linieGr.get(copyTo).reset(value);
  }

  protected findPositionAndAutofill(mode: 'von' | 'bis') {
    if (!this.geocoordinates) { 
      this.toastr.warning(
        this.mrTranslate.transform("Geodaten konnten nicht gefunden werden"));
      return;
    }
    this.curMode = mode;
    this.geolocation.setTracking(true);
    this.geolocation.changed();
  }

  protected setFaktor(faktor: number) {
    faktor ||= 1;
    this.hiddenGr.get('faktor').setValue(faktor, { emitEvent: false });
    const { von, bis } = this.linieGr.value;
    const umfang = this.mangelGr.get('umfang');
    // * linieGr immer valid hier u. 1<umfang<=100; weil button sonst disabled
    const anzahl = (Math.abs(bis - von) || 1) * faktor * 1.6667;
    const value = Math.round(umfang.value * anzahl / 100);
    umfang.reset(value);
  }

  protected async openHelper() {
    const { mangel } = this.mangelGr.value;
    if (this.helper[mangel]) {
      this.hilfeModal.item = this.helper[mangel];
      return;
    }
    const id = this.values.get('Mangel')?.get(mangel);
    if (id) {
      const { success, helpObj, error } = await firstValueFrom(
        this.apiService.getMangelHilfe(id)
      );
      if (!success) {
        this.toastr.error(this.mrTranslate.transform('Etwas ist schief gelaufen'));
        console.log(error);
        return;
      }
      else if (helpObj.HelpBeschreibung || helpObj.HelpBildBase64) {
        this.hilfeModal.item = helpObj;
        this.helper[mangel] = helpObj;
        return;
      }
    }
    this.toastr.warning(this.mrTranslate.transform('Keine Hilfebeschreibung vorhanden'));
  }

  protected openTabelle() {
    const inData = {
      titel: 'Mengeneinheiten',
      tabelle: 'smeh',
      OTYPID: this.OTYPID,
      tname: '',
    };
    this.basedatamodal.open(inData);

    const sub: Subscription = this.basedatamodal.onOK.subscribe((res) => {
      if (res.isChanged) {
        const mehArr: [string, number][] = res.stammdaten.rows.map(
          (row: any) => [row.Bezeichnung, row.ID]
        );
        this.values.set('Mengeneinheit', new Map([['', 0], ...mehArr]));
        const meh = this.mangelGr.get('mengeneinheit');
        if (meh.value && !this.values.get('Mengeneinheit').has(meh.value))
          meh.reset(null, { emitEvent: false });
      }
      sub?.unsubscribe();
      this.basedatamodal.close();
    });
  }
  //#endregion
  //#region API()
  private async saveMangel(): Promise<boolean> {
    const sendObj = {
      mangelmedien: await this.preview.packUnsavedFiles(),
      ostammid: this.ostammid,
      form: this.formValue
    };

    const row: MangelRowType = this.apiService.isRep ?
      await db.SetMangel(this.ostammid, this.pruefid, this.OTYPID, sendObj)
      : await firstValueFrom(
        this.apiService.sendNewMangel(this.OTYPID, this.typ_Line_Point, sendObj)
      );
    if (row.OMAZSID) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert'),
        this.mrTranslate.transform('Mangel')
      );
      this.table ??= {
        columns: Object.keys(row).map(key => ({ id: key })),
        rows: [],
      };
      this.table.rows.push(row);

      let tempsave = { ostammid: this.ostammid, now: BackendLocaleDatePipe.now, mangelrows: this.table.rows };
      localStorage.setItem('tempsaveinsp', JSON.stringify(tempsave));

      this.formTopGroup.reset();
      this.preview?.clearPreview();
      this.resetBaumKette();
      if ('emit' in this.dataRefresh)
        this.dataRefresh?.emit(true);
      else this.dataRefresh?.next();
      return true;
    } else {
      console.log(row);
      this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Mangel')
      );
      return false;
    }
  }

  protected saveIfValid(): Promise<boolean> {
    this.clrForm.markAsTouched();
    return this.formTopGroup.valid && (this.isInspektion
      ? this.editMangel()
      : this.saveMangel());
  }

  protected addMangel() {
    const { pruefart } = this.formTopGroup.value;
    const { lfNr } = this.hiddenGr.value;
    this.preview?.clearPreview();
    this.setInsert = true;
    this.mangelTableGrid?.selection.clearSelection();
    this.formTopGroup.enable();

    if (objtype.is(this.typ_Line_Point, 'point')) {
      this.hiddenGr.get('lfNr').setValue(lfNr + 1, { emitEvent: false });
      this.formTopGroup.get('pruefart').setValue(pruefart, { emitEvent: false });
    }
    this.resetBaumKette();
    this.linieGr.markAsUntouched({ onlySelf: true });
  }

  private async editMangel(): Promise<boolean> {
    if (this.preview.disabled) this.preview.clearPreview();

    const sendObj = {
      status: 'update',
      omazsid: this.selectedMangel?.OMAZSID ?? -1,
      vals: this.formValue,
      mangelmedien: await this.preview.packUnsavedFiles(),
      savedFiles: this.preview.saved.map((file) => file.name).join("|"),
      ostammid: this.ostammid,
      pruefid: this.pruefid,
      otypid: this.OTYPID,
      typeid: this.typ_Line_Point,
    };


    const { omazsid, mangel, error } =
      await firstValueFrom(
        this.apiService.updateOMAZS(sendObj, this.selectedMangel?.OMAZSID ?? -1)
      );
    if (omazsid) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert'),
        this.mrTranslate.transform('Mangel')
      );
      this.table.rows = mangel.rows;

      let tempsave = { ostammid: this.ostammid, now: BackendLocaleDatePipe.now, mangelrows: mangel.rows };
      localStorage.setItem('tempsaveinsp', JSON.stringify(tempsave));

      this.resetPreview(omazsid);
      this.mangelTableGrid.singleSelected = this.table.rows.find(
        row => row.OMAZSID == omazsid
      );
      if (!this.setInsert) this.checkMangelValid();
      if(this.dataRefresh)
        if ('emit' in this.dataRefresh)
          this.dataRefresh?.emit(true);
        else this.dataRefresh?.next();
      return true;
    } else {
      console.log(error);
      this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Mangel')
      );
      return false;
    }
  }

  protected async deleteMangel() {
    if (!confirm(
      this.mrTranslate.transform('Mangel wirklich löschen?')
    )) return;


    await firstValueFrom(this.apiService.updateOMAZS({
      status: 'deleteitems',
      deleteIDs: [this.selectedMangel.OMAZSID]
    }, -1));


    this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));

    this.table.rows.splice(
      this.table.rows.indexOf(this.selectedMangel),
      1
    );

    let tempsave = { ostammid: this.ostammid, now: BackendLocaleDatePipe.now, mangelrows: this.table.rows };
    localStorage.setItem('tempsaveinsp', JSON.stringify(tempsave));


    await this.resetLfNr();
    this.formTopGroup.disable();
    this.mangelTableGrid?.selection.clearSelection();
    if ('emit' in this.dataRefresh)
      this.dataRefresh?.emit(true);
    else this.dataRefresh?.next();
  }
  /**
 * @method
 * - resets lfNr für Punkte: 1, 2, 3, ...
 * - erstellt Array of IDs und lfNr zum Update
 * - sendet Abfrage zu Back-End, falls falsche gibt es
 * @returns Promise of delete API
 */
  private resetLfNr() {
    if (!objtype.is(this.typ_Line_Point, 'point')) return;

    const items = this.table.rows.flatMap((row, index) => {
      const lfNr = index + 1;
      if (row.lfNr == lfNr) return []; // * Keine Update nötig
      row.lfNr = lfNr; // für Anzeige
      return { lfNr, OMAZSID: row.OMAZSID };
    });


    if (items.length) return firstValueFrom(
      this.apiService.updateOMAZS({ status: 'updatelfNr', items }, -1) // für Datenbank
    );
  }
  //#endregion
  //#region public() für Inspektion
  public checkLinieValid(startMeter: number, endMeter: number) {
    this.startmeter = startMeter;
    this.endmeter = endMeter;
    this.direction = this.initLinieValidators(this.startmeter, this.endmeter, false);
    const valid = this.table.rows.every(row =>
      (row.von || row.von === 0)
      && (row.bis || row.bis === 0)
      && (this.direction == 1
        ? row.von >= startMeter && row.bis <= endMeter
        : row.von <= startMeter && row.bis >= endMeter
      )
    );
    if (!valid) this.toastr.error(
      this.mrTranslate.transform('Berücksichtigen Sie die Start- & Endmeter'),
      this.mrTranslate.transform('Mangel')
    );
    return valid;
  }

  public async checkValid() {
    if (objtype.is(this.typ_Line_Point, 'point')) await this.findDuplicates();
    if (!this.isMangelValidStatus) this.toastr.error(
      this.mrTranslate.transform(
        'Es gibt noch offene Mängel aus der letzten Inspektion...!'
      ),
      this.mrTranslate.transform('Mangel')
    );
    return this.isMangelValidStatus;
  }
  //#region Checkliste
  public async openChecklist(name: string) {
    if (!name) return;
    if (this.table?.rows.length && !confirm(this.mrTranslate.transform(
      'Die vorhandenen Prüfeinträge werden durch die neue Checkliste ersetzt. Möchten Sie fortsetzen?'
    ))) return;

    const sendObj = {
      status: 'addchecklist',
      typeid: this.typ_Line_Point,
      otypid: this.OTYPID,
      pruefid: this.pruefid,
      ostammid: this.ostammid,
      checklistname: name,
    };

    const { mangel } =
      await firstValueFrom(
        this.apiService.updateOMAZS(sendObj, -1)
      );
    if (mangel?.rows) {
      if (this.table?.columns?.length)
        this.table.rows = mangel.rows;
      else this.table = mangel;

      let tempsave = { ostammid: this.ostammid, now: BackendLocaleDatePipe.now, mangelrows: mangel.rows };
      localStorage.setItem('tempsaveinsp', JSON.stringify(tempsave));

      this.toastr.success(
        this.mrTranslate.transform("Checkliste wurde geöffnet"),
        this.mrTranslate.transform("Checkliste")
      );
      this.isMangelValidStatus = true;
      this.mangelTableGrid?.selection.clearSelection();
      this.preview?.clearPreview();
      this.bilder = {};
    }
    else
      this.toastr.error(
        this.mrTranslate.transform("Checkliste konnte nicht geöffnet werden"),
        this.mrTranslate.transform("Checkliste")
      );
  }

  public get pruefarte() {
    return [...new Set(this.table.rows.map(row => row.pruefart))];
  }
  public get mangel(): MangelRowType[] {
    return this.table.rows;
  }
  public set mangel(rows: MangelRowType[]) {
    this.table.rows = rows;
    this.resetLfNr();
  }
  //#endregion
  //#endregion
  //#region helpers()
  private checkMangelValid() {
    this.isMangelValidStatus = this.table.rows.every(
      row => this.compareDate.check(row.status)
    );
  }

  private async getBilder(bildnames: string, id: number) {
    if (!this.bilder[id]) {
      try {
        this.preview.clearPreview();

        const [
          { fileNames: mangelBilderNames = [] } = {}
        ] = this.media.openMangelMedia(bildnames);
        const files = await this.media.getApiFunctionMedia(mangelBilderNames);
        this.preview.unpackFiles(files);
        this.bilder[id] = this.preview.rawPreviewThumbnails;
      } catch (error) {
        this.toastr.error(this.mrTranslate.transform(error));
        console.log(error);
      }
    }
    else setTimeout(() => {
      this.preview.rawPreviewThumbnails = this.bilder[id];
    });
  }

  private async resetPreview(id: number) {
    if (this.preview.unsaved.length)
      this.bilder[id] = null;
    else if (this.deletedFiles.length) {
      this.bilder[id] = this.preview.rawPreviewThumbnails;
      this.deletedFiles = [];
    }
  }

  /**
   * @method sucht gleiche Prüfarte und behaltet:
   * - bei 2 und mehr n.I.O. - `alle`
   * - bei 1 i.O. und 1 n.I.O. - `mit Mangel`
   * - bei 2 und mehr i.O - `erste`
   * @returns Promise of Löschen und Update von lfNr
   */
  private findDuplicates() {
    const keepMap = new Map<string, number | number[]>();
    // * Durchgeht rows und erstellt Map mit Prüfart und
    // - mehrere ID im Array (n.I.O.)
    // - einem ID (i.O.)
    this.table.rows.forEach(({ pruefart, nIO, OMAZSID }) => {
      const id = keepMap.get(pruefart);
      if (!id)
        // * erstmal alle gespeichert, i.O. und n.I.O.
        // - bei n.I.O. ID in Array, da es mehrere möglich sind
        keepMap.set(pruefart, nIO ? [OMAZSID] : OMAZSID);
      else if (nIO) {
        // * bei zweitem Mal nur speichern, wenn n.I.O.
        // - im Array hinzufügen, wenn letzte war auch n.I.O., sonst ersetzen
        if (Array.isArray(id)) id.push(OMAZSID);
        else keepMap.set(pruefart, [OMAZSID]);
      }
    });

    // * get alle ID in Array, [[1, 2], 3] flat() zu [1, 2, 3]
    const keepIDs = [...keepMap.values()].flat();
    const deleteIDs: number[] = [];
    if (keepIDs.length == this.table.rows.length) return;

    // * ausfiltern rows, die bleiben, gleichzeitig erstellen IDs von rows zu löschen
    this.table.rows = this.table.rows.filter(
      // - return true to keep, falls im keepIDs Array, sonst push zu deleteIDs und return false
      ({ OMAZSID }) => keepIDs.includes(OMAZSID) || deleteIDs.push(OMAZSID) && false
    );
    return Promise.all([
      firstValueFrom(
        this.apiService.updateOMAZS({ status: 'deleteitems', deleteIDs }, -1)
      ),
      this.resetLfNr()
    ]);
  }

  protected close() {
    this.closedialog.next(true);
  }

  ngOnDestroy(): void {
    this.geolocation = undefined;
    this.sub.unsubscribe();
    localStorage.removeItem('tempsaveinsp');
  }
  //#endregion
}

enum Stamm_Gattung {
  schiene = 1,
  befestigung,
  schwellenart,
  bettung,
  eindeckung,
  regellichtraumprofil,
  bauwerke,
  schienenverbindung,
  grundinstandsetzung,
  messwerte,
}

enum Spez_Gattung {
  'strValue02' = 1,
  'strValue03' = 3,
  'strValue04' = 2,
  'strValue05' = 5
}

enum Mode_Control {
  'Gattung' = 'gleisGr.gattung',
  'Stamm' = 'gleisGr.stamm',
  'Pruefart' = 'pruefart',
  'Lage' = 'mangelGr.lage',
  'Mangel' = 'mangelGr.mangel',
  'Instandsetzung' = 'mangelGr.instandsetzungshinweis',
  'FK' = 'mangelGr.fehlerklasse'
}
