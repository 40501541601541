<header class="header-2">
  <div class="branding">
      <span class="title">{{'Checklisten verwalten' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
    <clr-dropdown>
      <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
        <clr-icon shape="cog"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a (click)="addChecklist()" clrDropdownItem>{{'Neue Checkliste erstellen' | mrTranslate}}</a>

        <div *ngIf="selchecklist">
          <a (click)="saveAsChecklist()" clrDropdownItem>{{'Checkliste speichern unter...' | mrTranslate}}</a>
          <a (click)="deleteChecklist()" clrDropdownItem>{{'Checkliste löschen' | mrTranslate}}</a>
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon  shape="times" (click)="close()"></clr-icon >
    </a>
  </div>
</header>


<div class="overlay-inner-wrapper" style="height: calc(100% + 60px);">
  <div id="objekttpyWrapper">
    <div class="clr-row" id="groupSelectionRow">

      <div class="clr-col-12 text-center">
        <clr-select-container style="display: inline-block">
            <select clrSelect #sel [(ngModel)]="selectedOption" (change)="selectedNamechanged(sel.value)">
              <option *ngFor="let item of checklistennamen" [value]="item.Titel">
                {{ item.Titel }}
              </option>
            </select>
          </clr-select-container>

          <div class="inline-block float-right" style="margin-top: 15px;">
            <clr-icon *ngIf="cansortup" size="24" shape="arrow" (click)="sortUp()"
              class="cursor-pointer"></clr-icon>
            <clr-icon *ngIf="cansortdown" size="24" shape="arrow" (click)="sortDown()" class="cursor-pointer"
              style="transform: rotate(180deg);"></clr-icon>
          </div>
       </div>



      <div  id="objectSelectionWrapper" class="clr-row">
        <div class="clr-col" style="max-width: calc(calc(100% - 8.3333333333%) / 2)">
          <div>{{'Alle möglichen Prüfarten' | mrTranslate}}</div>

          <div class="limit-height">
            <clr-datagrid class="datagrid-compact" *ngIf="stammobjekte" [(clrDgSelected)]="selectedStammList" [clrDgRowSelection]="true">
              <clr-dg-column *ngFor="let column of stammobjekte['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns">
                {{ column.id | titlecase | mrTranslate }} </clr-dg-column>

              <clr-dg-row *clrDgItems="let row of stammobjekte['rows']" [clrDgItem]="row">
                <ng-container *ngFor="let cell of stammobjekte['columns']" >
                  <clr-dg-cell [hidden]="cell.id | hideIDColumns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint"
                    [title]="row[cell.id] | tablePrettyPrint | dotDotDot : 30">
                  </clr-dg-cell>
                </ng-container>
              </clr-dg-row>

              <clr-dg-footer>
                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="500">{{stammobjekte['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
              </clr-dg-footer>
            </clr-datagrid>
          </div>
        </div>

        <div id="objectControl" class="clr-col-1">
          <a href="javascript:void(0);" [ngClass]="{ disabled: selectedStammList.length == 0 }" (click)="executeToAdd()" >
            <clr-icon shape="circle-arrow right" size="72" style="color: green" ></clr-icon>
          </a>
          <a href="javascript:void(0);" [ngClass]="{ disabled: selectedPOList.length == 0 }" (click)="executeToRemove()" >
            <clr-icon shape="circle-arrow left" size="72" style="color: red" class="is-solid"></clr-icon>
          </a>
        </div>
        <div class="clr-col" style="max-width: calc(calc(100% - 8.3333333333%) / 2)">
          <div>{{'Prüfarten in Checkliste' | mrTranslate}}</div>
          <div class="limit-height">
            <clr-datagrid class="datagrid-compact" *ngIf=" groupObjects" [(clrDgSelected)]="selectedPOList" [clrDgRowSelection]="true" (clrDgSelectedChange)="selectionPOChanged($event)">
              <clr-dg-column
                *ngFor="let column of groupObjects['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns:['sprueid', 'titel', 'sortid']">
                {{ column.id | titlecase | mrTranslate }}
              </clr-dg-column>
              <clr-dg-row *clrDgItems="let row of groupObjects['rows']" [clrDgItem]="row">
                <clr-dg-cell class="name-cell" *ngFor="let cell of groupObjects['columns']"
                  [hidden]="cell.id | hideIDColumns:['sprueid', 'titel', 'sortid']" [innerHTML]="row[cell.id] | tablePrettyPrint"
                ></clr-dg-cell>
              </clr-dg-row>
              <clr-dg-footer>
                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="500">{{groupObjects['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
              </clr-dg-footer>
            </clr-datagrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="isChecklisteAddVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{modaltitel}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <div class="clr-row">
        <div class="clr-col-12">
          <div class="comboboxcontainer">
            <div class="clr-control-container" style="width: 300px">
              <input id="_aufgroup_name_new" class="clr-input combobox" placeholder="{{'Name' | mrTranslate}}" #_checklistename />
            </div>
          </div>
        </div>
      </div>
    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="editCheckliste()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isChecklisteAddVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>



