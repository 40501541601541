import { booleanAttribute, Directive, Input, OnChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/classes/custom-validators';

/**
 * @see CustomValidators.InTableValidator
 *
 * @example
 // Beispiel: Ein `<select>`-Element mit der Direktive, die sicherstellt, dass der ausgewählte Wert in der Liste `listToCheck` enthalten ist.
 * <select #control="ngModel" [inTableValidator]="listToCheck">
 *   <div *ngIf="control.errors?.['not-in-table']">
 *     <!-- Der Wert muss in der Liste eingetragen sein. -->
 *   </div>
 * </select>
 *
 * @example
 // Beispiel: Ein `<input>`-Element, das prüft, dass der Wert nicht bereits in der Liste `listToCheck` enthalten ist und das Initialwert berücksichtigt.
 * <input type="text" [inTableValidator]="listToCheck" [doesNotInclude]="true"
 *   [initValue]="name" [(ngModel)]="name">
 *   <clr-control-error *clrIfError="'in-Table'; error as err">
 *    <!-- Der Wert ist bereits verwendet. -->
 *   </clr-control-error>
 * </input>
 */
@Directive({
  selector: '[ngModel][inTableValidator], [formControlName][inTableValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: InTableValidatorDirective, multi: true }],
  standalone: true
})
export class InTableValidatorDirective implements Validator, OnChanges {
  @Input({ alias: 'inTableValidator', required: true }) list: string[] = [];
  @Input({ transform: booleanAttribute }) doesNotInclude: boolean = false;
  @Input() initValue: string = '';

  validate(control: AbstractControl): ValidationErrors {
    return this.validator(control);
  }

  private validator: ValidatorFn = () => null;

  ngOnChanges() {
    this.validator = CustomValidators.InTableValidator(this.list, this.doesNotInclude, this.initValue);
  }
}