
<div class="limit-height">
  <div class="card">
    <div class="card-block">
      <div class="card-title">{{ titel | mrTranslate}}</div>
      <form clrForm class="clr-form" [formGroup]="tabelleNameGroup">
        <input type="text" id="basic" #bezpo class="clr-input" formControlName="txt0s"/>
      </form>

      <div class="limit-height">
        <clr-datagrid #dgStammdaten class="datagrid-compact w-100-p wrapColumn"
          *ngIf="stammdaten?.rows?.length; else elseTemplate"
          [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selecteditem"
          (clrDgSingleSelectedChange)="selectionItemsChanged($event)">
          <clr-dg-column [clrDgField]="'Bezeichnung'">{{ "Bezeichnung" | mrTranslate }}
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let row of stammdaten['rows']" [clrDgItem]="row">
            <clr-dg-cell class="ellipsis" [innerHTML]="row['Bezeichnung'] | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>{{stammdaten['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
        <ng-template #elseTemplate> <div style="height: calc((100vh - 220px) / 4 );"></div> </ng-template>
      </div>
    </div>
    <div class="card-footer">
      <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-success" (click)="newitem()">
          <clr-icon shape="file"></clr-icon>
          <span class="clr-icon-title">Add</span>
        </button>
        <button class="btn" (click)="saveitem()">
          <clr-icon shape="floppy"></clr-icon>
          <span class="clr-icon-title">Save</span>
        </button>
        <button class="btn btn-danger" [disabled]="selid == 0" (click)="deletitem()">
          <clr-icon shape="times"></clr-icon>
          <span class="clr-icon-title">Delete</span>
        </button>
      </div>
      <button *ngIf="tablename == 'smang'" class="btn btn-icon" style="margin-left: auto;" [title]="'Beschreibung' | mrTranslate"
        (click)="openHelper()" [disabled]="selid == 0">
        <clr-icon shape="help"></clr-icon>
      </button>

    </div>
  </div>
</div>

<app-hilfe-beschreibung-modal *ngIf="tablename == 'smang'"
  (onSave)="saveHelper($event)">
</app-hilfe-beschreibung-modal>
