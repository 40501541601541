<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
      <span class="title">{{'Liste Sonderinspektion' | mrTranslate}} {{'Mangel' | mrTranslate}}: {{mangel}}</span>
    </div>
    <div class="header-actions">
      <a href="javascript://" class="nav-link nav-icon">
        <clr-icon  shape="times" (click)="close()"></clr-icon >
      </a>
    </div>
  </header>
  <div class="clr-row nxm" style="align-items: center;">
    <div class="clr-col-auto margin-12-tb">
      <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-icon" [title]="'Neu' | mrTranslate"
          (click)="showNewSi()"[disabled]="cusSiID == 0">
          <clr-icon shape="file"></clr-icon>
        </button>
        <button class="btn btn-icon btn-success" [title]="'Sonderinspektion beenden' | mrTranslate"
          (click)="exitSi()" [disabled]="cusSiID == 0">
          <clr-icon shape="success-standard"></clr-icon>
        </button>
      </div>
    </div>
  </div>


  <clr-datagrid *ngIf="sonderPruefunglTable?.columns?.length" style="padding-top: 0px; padding-left: 10px; padding-right: 10px;" class="datagrid-compact" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionTabelleTitelChanged($event)">
    <clr-dg-column *ngFor=" let column of sonderPruefunglTable['columns']" [clrDgField]="column.id"
      [hidden]="column.id | hideIDColumns:['id','typ_icon','bilder']">{{column.id | mrTranslate}}</clr-dg-column>

      <ng-container *ngIf="sonderPruefunglTable?.rows">
        <clr-dg-row *clrDgItems="let row of sonderPruefunglTable['rows']; trackBy: trackById" [clrDgItem]="row">
          <clr-dg-cell class="name-cell" *ngFor="let cell of sonderPruefunglTable['columns']"
            [hidden]="cell.id | hideIDColumns:['id','typ_icon','bilder']" [innerHTML]="row[cell.id] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
      </ng-container>

    <clr-dg-footer>
      <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{sonderPruefunglTable?.rows?.length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>

  <br/>
  <div class="clr-col-12">
    <app-preview-thumbnails #preview1 [readonly]="true"></app-preview-thumbnails>
  </div>
</div>



<clr-modal [(clrModalOpen)]="objekteSonderModal" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'Sonderinspektion dokumentieren' | mrTranslate}}</h3>
  <div class="modal-body">

    <h4>{{"Mangel" | mrTranslate}}: {{mangel}}</h4>
    <hr/>
    <br/>
    <form clrForm ngForm [formGroup]="formGroupSI" #f="ngForm" clrLayout="horizontal" clrLabelSize="8">

      <clr-date-container>
        <label class="clr-col-md-2"> {{ "Datum" | mrTranslate }} </label>
        <input clrDate name="Datum" autocomplete="off" type="date"
          [ngModel]="formGroupSI.controls['datum'].value | localeDate"
          (ngModelChange)="formGroupSI.controls['datum'].setValue(localeDate.tosql($event))"
          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
          required />
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-date-container>

      <clr-datalist-container>
        <label class="clr-col-md-2">{{"Zustand" | mrTranslate}}</label>
        <input clrDatalistInput formControlName="zustand" autocomplete="off" style="min-width: 650px;"/>
        <datalist>
          <option *ngFor="let item of items" [value]="item" required></option>
        </datalist>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-datalist-container>

      <clr-textarea-container>
        <label class="clr-col-md-2">{{"Bemerkung" | mrTranslate}}</label>
        <textarea id="aa" class="clr-col-md-10"
          clrTextarea formControlName="bemerkung"
          style=" width:100%;height:150px"></textarea>
      </clr-textarea-container>

      <br/>
      <div class="clr-col-12">
        <app-preview-thumbnails [acceptTypes]="'image/*'" [maxAmount]="6"
          ></app-preview-thumbnails>
      </div>
      <br/>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="newSi()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="objekteSonderModal = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
