import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, NgForm, ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrForm } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, firstValueFrom, forkJoin, Subscription } from "rxjs";
import { first, map } from "rxjs/operators";

import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { APIService } from "../../../../services/APIService/api.service";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { DotDotDotPipe } from 'src/app/pipes/dot-dot-dot.pipe';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { BasedatamodalComponent } from '../../_modals/basedatamodal/basedatamodal.component';
import { SpezOptType, SpezPointType } from '../spezifikationsdialog/spezifikationsdialog.component';
import { InTableValidatorDirective } from 'src/app/directives/validators/in-table-validator.directive';

interface TreeDataNode {
  id: number,
  typ: number,
  name: string,
  selected: boolean,
  expanded: boolean,
  rootpath: string | null,
  parent: TreeDataNode | null,
  children: TreeDataNode[]
}

@Component({
  selector: 'app-komponenteneditdialog',
  imports: [ CommonModule, ClarityModule, PreviewThumbnailsComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, BasedatamodalComponent,
    HideIDColumnsPipe,TablePrettyPrintPipe,DotDotDotPipe,BackendLocaleDatePipe, InTableValidatorDirective ],
  providers: [ BackendLocaleDatePipe ],
  templateUrl: './komponenteneditdialog.component.html',
  styleUrls: ['./komponenteneditdialog.component.scss'],
  standalone: true
})
export class KomponenteneditdialogComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();
  protected isBasedataModalOpen: boolean = false;
  @ViewChild(BasedatamodalComponent, { static: false })
  private basedatamodal: BasedatamodalComponent
    ;

  @ViewChild(ClrForm, { static: false })
    private clrForm: ClrForm

  @ViewChild(NgForm, { static: false })
  protected ngForm: NgForm

  @ViewChild(PreviewThumbnailsComponent, {static: false})
  private preview: PreviewThumbnailsComponent

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private basicStore: BasicStoreService,
    protected localeDate: BackendLocaleDatePipe
  ) {
    this.initdate = this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS')
  }

  initdate: any;
  ostammid: number = 0;
  otypid: number = 0;
  selOKOMPID: number = 0;
  selKTYPID: number = 0;
  stammObjekte: any;
  namesotyp: any;
  bezeichnung: any;
  komponentenObjekt: any;
  komponenten: any;
  komponentenObjektImport: any;
  komponentenObjektSpez: any;
  komponentenObjektBilder: any;
  spezData: any = [];
  rootObjekt: TreeDataNode;
  rootObjektImport: TreeDataNode;
  selTreeNoteObjekt: any;
  selTreeNoteObjektImport: any;

  root: TreeDataNode;
  selTreeNote: any;
  allSubNodes: any = [];
  selnode: any;
  selnodename: string = '';
  selnodetyp: number = 0;
  isnewAdd: boolean = false;
  typ_Line_Point: number;
  private OSPEZID: number;
  objekte: any;
  bilder: any = [];
  isDarkThemeAktiv = localStorage.getItem("theme") == "dark";

  protected objekteModal: boolean = false;
  protected iconColumns: string[] = ["typ", "sperrstatus", "lfstatus", "rep", "icon"];
  selectedObjekt: any;

  set data(dataIn: any) {

    this.ostammid  = dataIn.id;
    this.stammObjekte = dataIn.stamm;
    this.otypid = dataIn.otypid,
    this.typ_Line_Point = dataIn.type;

    let nsotyp = this.stammObjekte.rows.find(
      (row: any) => row.DBField == "sotyp.Bezeichnung"
    );

    this.namesotyp = nsotyp?.Bezeichnung;

    let bez = this.stammObjekte.rows.find(
      (row: any) => row.DBField == "Bezeichnung"
    );

    this.bezeichnung = bez?.Bezeichnung;

    this.loadKomponentenObjekt();
  }

  loadKomponentenObjekt(){
    this.apiservice
      .getObjectKomponenten(this.ostammid.toString())
      .pipe(first())
      .subscribe((val: any) => {
        this.komponentenObjekt = val.objektkomponenten;
        this.komponentenObjektSpez = val.spez;
        this.komponentenObjektBilder = val.bilder;

        this.initTree();

    });
  }

  fillChildrenObjekt(parentNode:TreeDataNode, iid:number){
    const filtered = this.komponentenObjekt.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.OKOMPID,
        name: komp.TypBezeichnung,
        typ: komp.KTYPID,
        selected: false,
        expanded: false,
        rootpath: null,
        parent: parentNode,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildrenObjekt(node,komp.OKOMPID);
    }

  }

  loadKomponenten(){
    this.apiservice
      .getKomponenten(this.otypid)
      .pipe(first())
      .subscribe((val: any) => {
        this.komponenten = val;

        setTimeout(() =>{
          for (let i = 0; i < this.komponenten.rows.length; i++) {
            let komp = this.komponenten.rows[i];
            if (komp.ParentID == 0){

              this.root = {
                id: komp.ID,
                typ: komp.SysIcon,
                name: komp.Bezeichnung,
                selected: true,
                expanded: true,
                parent: null,
                rootpath: null,
                children: []
              }
              this.fillChildren(this.root, komp.ID);
            }
          }

          this.isnewAdd = true;

        },150);

    });
  }

  fillChildren(parentNode:TreeDataNode, iid:number){
    const filtered = this.komponenten.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.ID,
        typ: komp.SysIcon,
        name: komp.Bezeichnung,
        selected: false,
        expanded: false,
        parent: parentNode,
        rootpath: null,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildren(node,komp.ID);
    }

  }

  public getChildren = (node) => node.children;

  treeClick(value){

    try{
      if (this.preview != undefined){
        this.preview?.clearPreview();
      }
    }catch(e){}

    this.selOKOMPID = value.id;
    this.selnode = value;
    this.selnodename = value.name;
    this.selnodetyp = value.typ;
    this.spezData = [];

    Object.keys(this.komponentenObjektSpez).forEach((typid) => {
      if(typid == this.selnodetyp.toString()){
        this.spezData = this.komponentenObjektSpez[typid];
        this.OSPEZID = this.spezData?.find((row) => row.objekt == 'OSPEZID')?.bezeichnung ?? -1;
      }
    });


    Object.keys(this.komponentenObjektBilder).forEach((typid) => {
      if(typid == this.selnodetyp.toString()){
        this.bilder = this.komponentenObjektBilder[typid];

        setTimeout(() => {
          this.preview.clearPreview();
          if (this.bilder.length>0) {
            this.preview.unpackFiles(this.bilder);
          }

        },200);

      }
    });

  }

  newitem(){
    this.loadKomponenten();

  }

  initTree(){
    let root = {
      id: 0,
      name: this.bezeichnung,
      typ: null,
      selected: true,
      expanded: true,
      parent: null,
      rootpath: null,
      children: []
    }

    setTimeout(() => {
      for (let i = 0; i < this.komponentenObjekt.rows.length; i++) {
        let komp = this.komponentenObjekt.rows[i];

        if (komp.ParentID == 0){

          let node: TreeDataNode = {
            id: komp.OKOMPID,
            name: komp.TypBezeichnung,
            typ: komp.KTYPID,
            selected: false,
            expanded: false,
            rootpath: null,
            parent: this.rootObjekt,
            children: []
          };

          root.children.push(node);

          this.fillChildrenObjekt(node, komp.OKOMPID);
        }
      }

      this.rootObjekt = root;
      this.selTreeNoteObjekt = root;

    },200);
  }



  deletitem(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Komponente löschen wollen?"))) {

      let komp = {
        ostammid: this.ostammid,
        otypid: this.otypid,
        okompid: this.selOKOMPID,
        ospezid: this.OSPEZID,
        status: 'deleteokomp'
      }

      this.apiservice
      .setObjectKomponenten(komp)
      .pipe(first())
      .subscribe((val: any) => {
      this.komponentenObjekt = val.objektkomponenten;
      this.komponentenObjektSpez = val.spez;
      this.selOKOMPID = 0;

      this.initTree();

      });
    }

  }


  importitem(){
    forkJoin({
      anlagen: this.basicStore.getComponentStoreAsync("anlagen").pipe(
        first((val) => val && JSON.parse(val).anlagen.rows.length),
        map((res: any) => JSON.parse(res)?.anlagen)
      ),
    }).subscribe((res: any) => {

      this.objekte = res.anlagen;
      let rows:[] = res.anlagen.rows.filter((cells) => cells.OTYPID == this.otypid && cells.OSTAMMID != this.ostammid);
      this.objekte.rows = rows;

      this.objekteModal = true;


    });
  }

  selectionGridChanged(value){
    if (value != undefined){
      this.rootObjektImport = undefined;
      this.loadKomponentenObjektImport(value.OSTAMMID);
    }
  }

  importKomponent(){
    this.allSubNodes = []
    this.rootObjektImport.children.forEach(k => {
      if (k.selected) {
        k.rootpath = `${this.rootObjektImport.name}\\${k.name}`;
        let node = {
          ParentID: 0,
          KTYPID: k.id,
          RootPath: k.rootpath,
          children: k.children.length
        }
        this.allSubNodes.push(node);
      }
        this.RunRecursive(k);
    });

    if(this.allSubNodes.length > 0){

      this.objekteModal = false

      let komp = {
        selOKOMPID: this.selOKOMPID,
        selKTYPID: this.selKTYPID,
        otypid: this.otypid,
        ostammid: this.ostammid,
        oldostammid: this.selectedObjekt.OSTAMMID,
        allSubNodes: this.allSubNodes,
        status: 'move',
      }

      this.apiservice
      .setObjectKomponenten(komp)
      .pipe(first())
      .subscribe((val: any) => {
      this.komponentenObjekt = val.objektkomponenten;
      this.komponentenObjektSpez = val.spez;
      this.komponentenObjektBilder = val.bilder;

      this.initTree();

      });
    }
  }

  loadKomponentenObjektImport(ostmm: number){
    this.apiservice
      .getObjectKomponenten(ostmm.toString())
      .pipe(first())
      .subscribe((val: any) => {
        this.komponentenObjektImport = val.objektkomponenten;

        let root = {
          id: 0,
          name: this.selectedObjekt.bezeichnung,
          typ: null,
          selected: true,
          expanded: true,
          parent: null,
          rootpath: null,
          children: []
        }

        setTimeout(() => {
          for (let i = 0; i < this.komponentenObjektImport.rows.length; i++) {
            let komp = this.komponentenObjektImport.rows[i];

            if (komp.ParentID == 0){

              let node: TreeDataNode = {
                id: komp.OKOMPID,
                name: komp.TypBezeichnung,
                typ: komp.KTYPID,
                selected: false,
                expanded: false,
                rootpath: null,
                parent: this.root,
                children: []
              };

              root.children.push(node);

              this.fillChildrenObjektImport(node, komp.OKOMPID);
            }
          }

          this.rootObjektImport = root;
          this.selTreeNoteObjekt = root;

        },200);

    });
  }

  fillChildrenObjektImport(parentNode:TreeDataNode, iid:number){
    const filtered = this.komponentenObjektImport.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.OKOMPID,
        name: komp.TypBezeichnung,
        typ: komp.KTYPID,
        selected: false,
        expanded: false,
        rootpath: null,
        parent: parentNode,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildrenObjektImport(node,komp.OKOMPID);
    }

  }

  public saveIfValid(): Promise<boolean> {
    this.clrForm.markAsTouched();
    return this.ngForm.valid && this.saveSpezPoint();

  }

  private async saveSpezPoint(): Promise<boolean> {

    let images;
    try {
      images = await this.preview?.packUnsavedFiles();
    } catch (_) {
      this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"));
    }

    if (images == undefined){
      if (this.ngForm.pristine) {
        this.toastr.success(
          this.mrTranslate.transform('Nichts wurde verändert'),
          this.mrTranslate.transform('Spezifikationen')
        );
        return true;
      }
    }

    const sendObj = {
      table: this.spezData,
      OSPEZID: this.OSPEZID,
      otypid: this.otypid,
      okompid: this.selOKOMPID,
      typ: this.typ_Line_Point,
      images: images,
      bezeichnung: this.bezeichnung,
      status: 'komponenten'
    };

    const { success, error, spezid, spez, bilder } = await firstValueFrom(
      this.apiservice.setSpez(this.ostammid, sendObj)
    );
    if (success) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert'),
        this.mrTranslate.transform('Komponenten')
      );
      this.komponentenObjektSpez = spez;
      this.komponentenObjektBilder = bilder;

      Object.keys(this.komponentenObjektSpez).forEach((typid) => {
        if(typid == this.selnodetyp.toString()){
          this.spezData = this.komponentenObjektSpez[typid];
          this.OSPEZID = this.spezData?.find((row) => row.objekt == 'OSPEZID')?.bezeichnung ?? -1;
        }
      });


      this.dataRefresh?.emit(true);
      return true;
    } else {
      console.log(error);
      this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Komponenten')
      );
      return false;
    }
  }

  RunRecursive(k){
    k.children.forEach(child =>{
      if (child.selected)
        {
          child.rootpath = `${k.rootpath}\\${child.name}`;

          let node = {
            ParentID: k.id,
            KTYPID: child.id,
            RootPath: child.rootpath,
            children: child.children.length
          }
          this.allSubNodes.push(node);
        }
      this.RunRecursive(child);
    });
  }

  addKompItems(){

    this.allSubNodes = []
    this.root.children.forEach(k => {
      if (k.selected) {
        k.rootpath = `${this.root.name}\\${k.name}`;
        let node = {
          ParentID: 0,
          KTYPID: k.id,
          RootPath: k.rootpath,
          children: k.children.length
        }
        this.allSubNodes.push(node);
      }
        this.RunRecursive(k);
    });


    if(this.allSubNodes.length > 0){

      let komp = {
        otypid: this.otypid,
        ostammid: this.ostammid,
        allSubNodes: this.allSubNodes,
      }

      this.apiservice
      .setObjectKomponenten(komp)
      .pipe(first())
      .subscribe((val: any) => {
      this.komponentenObjekt = val.objektkomponenten;
      this.komponentenObjektSpez = val.spez;
      this.komponentenObjektBilder = val.bilder;

        this.initTree()
      });

      this.isnewAdd = false;
    }
  }
  
  protected openTabelle(spez: SpezPointType | SpezOptType) {
    this.isBasedataModalOpen = true;
    setTimeout(() => {
      const inData = {
        titel: spez.objekt,
        tabelle: spez.sField,
        OTYPID: this.otypid,
        tname: spez.orgName,
      };
      this.basedatamodal.open(inData);

      const sub: Subscription = this.basedatamodal.onOK.subscribe((res) => {
        if (res.isChanged) {
          spez.wListe = res.stammdaten.rows.map(
            (row: any) => row.Bezeichnung ?? row.Name
          );
          setTimeout(() => {
            // ! in Timeout, weil wListe Zeit braucht, um zu updaten
            const control = this.ngForm?.controls[spez.objekt];
            control?.updateValueAndValidity();
          });
        }
        sub?.unsubscribe();
        this.isBasedataModalOpen = false;
      });
    });
  }

  public close() {
    this.closedialog.next(true);
  }

}
