import { Component, Input, OnInit } from '@angular/core';
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { Layer } from 'konva/lib/Layer';
import { Line } from 'konva/lib/shapes/Line';
import { Rect } from 'konva/lib/shapes/Rect';
import { Circle } from 'konva/lib/shapes/Circle';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { APIService } from 'src/app/services/APIService/api.service';
import { ToastrService } from "ngx-toastr";
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { first } from 'rxjs';
import { OverlayService } from 'src/app/services/Overlay/overlay.service';
import { HttpClient } from '@angular/common/http';
import { Base64Service } from 'src/app/services/base64/base64.service';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { PaintFilterLayerPipe } from 'src/app/pipes/paintfilterlayer.pipe';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-painting',
  templateUrl: './painting.component.html',
  styleUrls: ['./painting.component.scss'],
  imports: [ CommonModule, ClarityModule, MrTranslatePipe,PaintFilterLayerPipe, FormsModule ],
  standalone: true
})

export class PaintingComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private overlayService: OverlayService,
    private http: HttpClient,
    private base64service: Base64Service,
  ) {
    this.httpClient = http;
  }

  private ostammid: number = -1;
  @Input() set setostammid(ostammid: number) {
    this.ostammid = ostammid;
  }

  private typ: number;
  @Input() set setinittyp(typid: string) {
    this.typ = parseInt(typid);
  }

  @Input() set setUserStatus(status: string) {
    let userStatus = parseInt(status);
    if (parseInt(status) > 2){
      this.userCanEdit = true;
    }
  }

  private paintid: number = -1;
  userCanEdit: Boolean = false;
  httpClient: any;
  base64String: string = ';'
  sideBarIcons: any = [];
  historyAllgemein: any = [];       //INTTYP = 1
  historyAuftrag: any = [];         //INTTYP = 2
  historyRueckmeldung: any = [];    //INTTYP = 3
  shape: any;
  isrectangle: boolean = false;
  iscircle: boolean = false;
  isline: boolean = false;
  isfreeline: boolean = false;
  isdrawing: boolean = false;
  ispoint: boolean = false;
  isarc: boolean = false;
  color: any = "white";
  delete: boolean = false;
  x1: any = 0;
  y1: any = 0;
  x2: any = 0;
  y2: any = 0;
  redo_flag: boolean = false;
  undo_flag: boolean = false;
  back: any;
  stage!: Stage;
  layer!: Layer;
  templayer!: Layer;
  tr = new Konva.Transformer();
  collapsed = true;
  newPaint: any;
  isPortrait: boolean = false;
  sidebarShow: boolean = false;
  historieShow: boolean = false;
  curcolor = '#0000ff';
  curstrokeWidth: number = 5;
  hasTemplate: boolean = false;
  countLine: number = 0;
  countrect: number = 0;
  countcircle: number = 0;
  countpoint: number = 0;
  countarc: number = 0;
  editLayer: any;
  doEdit: boolean = false;
  selsharp: any;
  selsharporg: any;
  editsharporg: any;
  editsharporgpoints: any;
  pile = [];
  storageCapacity = 30;
  pilePointer = 1;
  redopile: any;
  arcpoints: any = [];
  dorestore: boolean = false;

  sellayer!: Layer;


  ngOnInit(): void {

    if(!this.delete){

      this.sideBarIcons = [
          {
            name: 'EH_L',
            source: 'assets/icons/paint/EH_L.png',
          },
          {
            name: 'EH_R',
            source: 'assets/icons/paint/EH_R.png',
          },
          {
            name: 'EW_L',
            source: 'assets/icons/paint/EW_L.png',
          },
          {
            name: 'EW_R',
            source: 'assets/icons/paint/EW_L.png',
          },
          {
            name: 'ZDW_L',
            source: 'assets/icons/paint/ZDW_L.png',
          },
        ];

        this.sideBarIcons.forEach((element: any) => {

          this.httpClient.get(element.source, { responseType: 'blob' })
          .subscribe((blob: any) => {

            this.blobToBase64(blob)
            .then((dataUrl: any) => {
                element.base64String = dataUrl.split(',')[1];
              }
            )
          });
        });

        this.apiService
          .getVorlagenFiles(this.ostammid)
          .pipe(first())
          .subscribe((val: any) => {
            if (val)
            {
              let v = val.vorlagen;

              Object.keys(v).forEach((index) => {
                let elem = v[index];
                let item = {
                  name: index,
                  source: '',
                  base64String: elem,
                }
                this.sideBarIcons.push(item);

              });

              this.historyAllgemein = [];
              this.historyAuftrag = [];
              this.historyRueckmeldung= [];

              if (val.history.rows){
                val.history.rows.forEach((row) => {
                  row.active = false;
                  switch (row["INTTYP"]) {
                    case 1:
                      this.historyAllgemein.push(row);
                      break;
                    case 2:
                      this.historyAuftrag.push(row);
                      break;

                    case 3:
                      this.historyRueckmeldung.push(row);
                      break;
                  }
                });
              }

            }
        });
    }

    this.collapsed = true;
    let pixelW = (96 * 29.7 ) / 2.54;
    let pixelh = (96 * 21) / 2.54;
    document.getElementById("drawing-field").style.width = pixelW.toString()+"px";
    document.getElementById("drawing-field").style.height = pixelh.toString()+"px";

    this.stage = new Stage({
      container: 'drawing-field',
      width: pixelW,
      height: pixelh,
    });

    this.setStageEvents();
  }

  setStageEvents(){
    var scaleBy = 1.01;
    this.stage.on('wheel', e => {
      e.evt.preventDefault();
      var oldScale = this.stage.scaleX();

      var mousePointTo = {
        x: this.stage.getPointerPosition().x / oldScale - this.stage.x() / oldScale,
        y: this.stage.getPointerPosition().y / oldScale - this.stage.y() / oldScale
      };

      var newScale =
        e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        this.stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x:
          -(mousePointTo.x - this.stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - this.stage.getPointerPosition().y / newScale) *
          newScale
      };
      this.stage.position(newPos);
      this.stage.batchDraw();
    });

    this.stage.on('mousedown', () => {
      const pos = this.stage.getPointerPosition();
      this.x1 = pos.x;
      this.y1 = pos.y;

      if (this.isline){
        this.countLine += 1;
        this.storingState();
        let name: string = "#line" + this.countLine.toString();

        this.layer = new Layer({name: name, isTemplate: false, typ: 'line', edit: false});
        this.stage.add(this.layer);
        this.newPaint = new Konva.Line({
          id: this.getID(),
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          // remove line from hit graph, so we can check intersections
          listening: false,
          points: [pos.x, pos.y, pos.x, pos.y]
        });
        this.layer.add(this.newPaint);
      }
      if (this.isfreeline){
        this.countLine += 1;
        this.storingState();
        let name: string = "#freehandline" + this.countLine.toString();
        this.layer = new Layer({name: name, isTemplate: false, typ: 'freehandline', edit: false});
        this.stage.add(this.layer);

        this.newPaint = new Konva.Line({
          id: this.getID(),
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          // remove line from hit graph, so we can check intersections
          listening: false,
          points: [pos.x, pos.y, pos.x, pos.y]
        });
        this.layer.add(this.newPaint);
      }
      if (this.isrectangle){
        this.storingState();
        this.countrect += 1;
        let name: string = "#rectangle" + this.countrect.toString();
        this.layer = new Layer({name: name, isTemplate: false, typ: 'rectangle', edit: false});
        this.stage.add(this.layer);

        this.x1 = pos.x;
        this.y1 = pos.y;

        this.newPaint = new Konva.Rect({
          id: this.getID(),
          x: this.x1,
          y: this.y1,
          width: 0,
          height: 0,
          fill: 'transparent',
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          draggable: true,
          name: 'rectangle',
        });

        this.layer.add(this.newPaint);
      }
      if(this.iscircle)
      {
        this.storingState();
        let p1 = {
          x: this.x1,
          y: this.y1,
        }

        this.arcpoints.push(p1);

        let square1: Rect;
        square1 = new Konva.Rect({
          x: this.x1-5,
          y: this.y1-5,
          width: 10,
          height: 10,
          fill: 'white',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle1',
        });


        this.templayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
        this.stage.add(this.templayer);
        this.templayer.add(square1).batchDraw();


        this.countcircle += 1;
        let name: string = "#circle" + this.countcircle.toString();
        this.layer = new Layer({name: name, isTemplate: false, typ: 'circle', edit: false});
        this.stage.add(this.layer);
        this.x1 = pos.x;
        this.y1 = pos.y;

        this.newPaint = new Konva.Circle({
          id: this.getID(),
          x: this.x1,
          y: this.y1,
          radius: 0,
          fill: 'transparent',
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          draggable: true,
          name: 'circle',
        });
        this.layer.add(this.newPaint);
      }
      if(this.ispoint)
      {

        this.countpoint += 1;
        this.storingState();
        let name: string = "#point" + this.countpoint.toString();
        this.layer = new Layer({name: name, isTemplate: false, typ: 'point', edit: false});
        this.stage.add(this.layer);

        let circle: Circle;
        circle = new Konva.Circle({
          id: this.getID(),
          x: this.x1,
          y: this.y1,
          radius: 5,
          fill: this.curcolor,
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          draggable: false,
          name: 'point',
        });
        this.ispoint = false;
        this.layer.add(circle).batchDraw();

        this.isdrawing = false;
        this.layer.draw();
      }
      if(this.isarc){

        if (this.arcpoints.length == 0){
          this.storingState();
          let p1 = {
            x: this.x1,
            y: this.y1,
          }

          this.arcpoints.push(p1);

          let square1: Rect;
          square1 = new Konva.Rect({
            x: this.x1-5,
            y: this.y1-5,
            width: 10,
            height: 10,
            fill: 'white',
            stroke: '#44BAFF',
            strokeWidth: 2,
            draggable: true,
            name: 'rectangle1',
          });


          this.templayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
          this.stage.add(this.templayer);
          this.templayer.add(square1).batchDraw();

          square1.on('mouseover', function() {
              document.body.style.cursor = 'move';
          });

          square1.on('mouseout', function() {
              document.body.style.cursor = 'default';
          });

          return;
        }

        if (this.arcpoints.length == 1){
          let p2 = {
            x: this.x1,
            y: this.y1,
          }

          this.arcpoints.push(p2);

          let square1: Rect;
          square1 = new Konva.Rect({
            x: this.x1-5,
            y: this.y1-5,
            width: 10,
            height: 10,
            fill: 'white',
            stroke: '#44BAFF',
            strokeWidth: 2,
            draggable: true,
            name: 'rectangle1',
          });

          this.templayer.add(square1).batchDraw();

          square1.on('mouseover', function() {
              document.body.style.cursor = 'move';
          });

          square1.on('mouseout', function() {
              document.body.style.cursor = 'default';
          });

          return;
        }

        if (this.arcpoints.length == 2){

          let p3 = {
            x: this.x1,
            y: this.y1,
          }

          this.arcpoints.push(p3);

          let L  = GetPointsOfArc(this.arcpoints);

          this.newPaint = new Konva.Line({
            id: this.getID(),
            stroke: this.curcolor,
            strokeWidth: this.curstrokeWidth,
            // remove line from hit graph, so we can check intersections
            listening: false,
            points: L
          });

          this.templayer.add(this.newPaint).batchDraw();

        }
    }

    });

    this.stage.on('mouseover', (e) => {
      if (this.layer != undefined){
        this.layer.draw();
      }
    });

    this.stage.on('mouseout', (e) => {
      if (this.layer != undefined){
        this.layer.draw();
      }
    });

    this.stage.on('mousemove', (e) => {
      if (this.isdrawing){
        document.body.style.cursor = 'crosshair';
      }
      if (!this.newPaint) {
        return;
      }
      if (this.isline)
      {
        const pos = this.stage.getPointerPosition();
        const points = this.newPaint.points().slice();
        points[2] = pos.x;
        points[3] = pos.y;
        this.newPaint.points(points);
        this.layer.batchDraw();
      }
      if (this.isfreeline){
        e.evt.preventDefault();
        const pos = this.stage.getPointerPosition();
        var newPoints = this.newPaint.points().concat([pos.x, pos.y]);
        this.newPaint.points(newPoints);
      }
      if (this.isrectangle){
        const pos = this.stage.getPointerPosition();
        let width =  pos.x - this.x1;
        let height = pos.y - this.y1;
        this.newPaint.attrs.width = width;
        this.newPaint.attrs.height = height;
        this.layer.batchDraw();
      }
      if(this.iscircle){
        const pos = this.stage.getPointerPosition();
        let a =  this.x1 - pos.x;
        let b = this.y1 - pos.y;
        let radius = Math.sqrt( a*a + b*b );
        this.newPaint.attrs.radius = radius;
        this.layer.batchDraw();
      }
      if(this.isarc){

        if (this.arcpoints.length == 3){
          const pos = this.stage.getPointerPosition();
          let p3 = {
            x: pos.x,
            y: pos.y,
          }
          this.arcpoints[2] = p3;
          let L  = GetPointsOfArc(this.arcpoints);
          this.newPaint.points(L);

        }
      }

    });

    this.stage.on('mouseup', (e) => {
      document.body.style.cursor = 'default';
      if (!this.newPaint) {
        return;
      }
      const pos = this.stage.getPointerPosition();
      this.x2 = pos.x;
      this.y2 = pos.y;

      if (this.isline){
        let line: Line;
        line = new Konva.Line({
          id: this.getID(),
          points: [this.x1, this.y1, this.x2, this.y2],
          strokeWidth: this.curstrokeWidth,
          stroke: this.curcolor,
          width: this.x2,
          height: this.y2,
          draggable: false,
          name: 'line',
        });
        this.layer.add(line).batchDraw();
        this.storingState();
        this.isdrawing = false;
        (this.isline = false), (this.redo_flag = false);

        this.newPaint.destroy();
        this.layer.draw();
        this.newPaint = null;
        this.isline = false;
      }
      if (this.isfreeline){
        let line: Line;
        line = new Konva.Line({
          id: this.getID(),
          points: this.newPaint.attrs.points,
          strokeWidth: this.curstrokeWidth,
          stroke: this.curcolor,
          draggable: false,
          name: 'freeline',
        });
        this.layer.add(line).batchDraw();
        this.storingState();
        this.isdrawing = false;
        (this.isline = false), (this.redo_flag = false);

        this.newPaint.destroy();
        this.layer.draw();
        this.newPaint = null;
        this.isfreeline = false;;
      }
      if (this.isrectangle){
        let width =  this.x2 - this.x1;
        let height = this.y2 - this.y1;

        let rect = new Konva.Rect({
          id: this.getID(),
          x: this.x1,
          y: this.y1,
          width: width,
          height: height,
          fill: 'transparent',
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          draggable: false,
          name: 'rectangle',
        });
        this.layer.add(rect).batchDraw();
        this.storingState();
        this.isdrawing = false;

        this.newPaint.destroy();
        this.layer.draw();
        this.newPaint = null;
        this.isrectangle = false;
      }
      if(this.iscircle){
        let a =  this.x1 - pos.x;
        let b = this.y1 - pos.y;
        let radius = Math.sqrt( a*a + b*b );
        this.newPaint.attrs.radius = radius;
        let circle: Circle;
        circle = new Konva.Circle({
          id: this.getID(),
          x: this.x1,
          y: this.y1,
          radius: radius,
          fill: 'transparent',
          stroke: this.curcolor,
          strokeWidth: this.curstrokeWidth,
          draggable: false,
          name: 'circle',
        });

        this.layer.add(circle).batchDraw();
        this.storingState();
        this.isdrawing = false;
        this.templayer.destroy();
        this.newPaint.destroy();
        this.layer.draw();
        this.newPaint = null;
        this.iscircle = false;

      }
      if(this.isarc){

        if (this.arcpoints.length == 3){
          const pos = this.stage.getPointerPosition();
          let p3 = {
            x: pos.x,
            y: pos.y,
          }
          this.arcpoints[2] = p3;

          let L  = GetPointsOfArc(this.arcpoints);
          this.newPaint.points(L);


          let line: Line;
          line = new Konva.Line({
            id: this.getID(),
            points: this.newPaint.attrs.points,
            strokeWidth: this.curstrokeWidth,
            stroke: this.curcolor,
            draggable: false,
            name: 'arc',
            p1: this.arcpoints[0],
            p2: this.arcpoints[1],
            p3: this.arcpoints[2],
          });

          this.countarc += 1;
          let name: string = "#arc" + this.countarc.toString();
          this.layer = new Layer({name: name, isTemplate: false, typ: 'arc', edit: false});
          this.stage.add(this.layer);

          this.layer.add(line).batchDraw();
          this.storingState();
          this.isdrawing = false;

          this.templayer.destroy();
          this.newPaint.destroy();
          this.layer.draw();
          this.newPaint = null;
          this.isarc = false;;

        }
      }
    });

    if (this.layer != undefined){
      this.layer.draw();
    }
  }

  save() {

    let layers = this.stage.getLayers();
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].attrs.edit){
        this.doEndEdit(layers[i]);
        break;
      }
    }

    var json = this.stage.toJSON();

    let send = {
      ostammid: this.ostammid,
      typid: this.typ,
      paintid: this.paintid,
      base64: btoa(json),
      option: '',
      base64string: this.base64String,
    }

    this.apiService
    .setPaintItems(send, '')
    .pipe(first())
    .subscribe((val: any) => {
      if (val.success){
        if (val.id){
          this.paintid = val.id;
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert'));
        }
      }
    });

  }

  saveAsImage(){

    let all = this.stage.getLayers();
    let texte: any = [];
    let nummer: number = 0;
    let max: number = 0;

    for (var i = 0; i < all.length; i++) {
      let layer: Layer = all[i];
      let typ = '';

      if(!layer.attrs.isTemplate){
        let layertyp = layer.attrs.typ;
        let layername = layer.attrs.name;
        if(layertyp != 'temp' && !layername.startsWith('#')){

          nummer++;

          let txt = new Konva.Text({
            x: 0,
            y: 0,
            text: nummer.toString(),
            fontSize: 12,
            fontFamily: 'Calibri',
            fill: '#000000',
          });

          let txt1 = new Konva.Text({
            x: 0,
            y: 0,
            text: nummer.toString() + " " + layername,
            fontSize: 12,
            fontFamily: 'Calibri',
            fill: '#000000',
          });

          if (txt1.width() > max){
            max = txt1.width();
          }


          let child = layer.children[0].attrs;
          switch (layertyp) {
            case 'point':

              let ptxt = {
                id: nummer,
                text: layername,
                x: child.x - txt.width() / 2,
                y: child.Y - txt.height() / 2,
                stroke: child.stroke,
                w: txt.width(),
                h: txt.height(),
                fill: 'transparent',
              }
              texte.push(ptxt);
              break;
            case 'line':
              let x1 = child.points[0];
              let y1 = child.points[1];
              let x2 = child.points[2];
              let y2 = child.points[3];
              let ltxt = {
                id: nummer,
                text: layername,
                x: (x1 + x2) / 2 - txt.width() / 2,
                y:(y1 + y2) / 2 - txt.height() / 2,
                stroke: child.stroke,
                w: txt.width(),
                h: txt.height(),
                fill: 'transparent',
              }
              texte.push(ltxt);
              break;

            case "arc":
            case "freehandline":
              let fx1 = child.points[0];
              let fy1 = child.points[1];
              let ftxt = {
                id: nummer,
                text: layername,
                x: fx1 - txt.width() / 2,
                y: fy1 - txt.height() / 2,
                stroke: child.stroke,
                w: txt.width(),
                h: txt.height(),
                fill: 'transparent',
              }
              texte.push(ftxt);
              break;
            case "rectangle":
              let rtxt = {
                id: nummer,
                text: layername,
                x: child.x - txt.width() / 2,
                y: child.Y - txt.height() / 2,
                stroke: child.stroke,
                w: txt.width(),
                h: txt.height(),
                fill: 'transparent',
              }
              texte.push(rtxt);
              break;
            case "circle":
              let ctxt = {
                id: nummer,
                text: layername,
                x: child.x - txt.width() / 2,
                y: child.Y - child.radius - txt.height() / 2,
                stroke: child.stroke,
                w: txt.width(),
                h: txt.height(),
                fill: 'transparent',
              }
              texte.push(ctxt);
              break;

          }
        }
      }
    }

    let inText: any = [];
    let top: number = 15;

    let legText = this.mrTranslate.transform("Legende")
    let txtmess = new Konva.Text({
      x: 0,
      y: 0,
      text: legText,
      fontSize: 16,
      fontFamily: 'Calibri',
      fill: '#000000',
    });

    if (txtmess.width() > max){
      max = txtmess.width();
    }

    let txtleg = new Konva.Text({
      x: this.stage.width() - max - 10,
      y: top,
      text: legText,
      fontSize: 16,
      fontFamily: 'Calibri',
      fill: '#000000',
    });
    inText.push(txtleg);

    top += txtmess.height();

    for (var i = 0; i < texte.length; i++) {
      let curtext = texte[i];

      let simpleLabel = new Konva.Label({
        x: curtext.x,
        y: curtext.y,
        opacity: 0.75,
      });

      simpleLabel.add(
        new Konva.Tag({
          fill: 'yellow',
        })
      );

      simpleLabel.add(
        new Konva.Text({
          text: curtext.id.toString(),
          fontFamily: 'Calibri',
          fontSize: 12,
          padding: 3,
          fill: 'black',
        })
      );

      inText.push(simpleLabel);

      let txt = new Konva.Text({
        x: this.stage.width() - max - 10,
        y: top,
        text: curtext.id.toString() + " " + curtext.text,
        fontSize: 12,
        fontFamily: 'Calibri',
        fill: curtext.stroke,
      });
      inText.push(txt);
      top += curtext.h;
    }

    this.templayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
    this.stage.add(this.templayer);
    this.templayer.add(...inText).batchDraw();

    setTimeout(() => {
      var dataURL = this.stage.toDataURL({pixelRatio: 3});
      this.downloadURI(dataURL, 'stage.png');

      this.templayer.destroy();
    });

  }

  downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  saveAsPDF(){

    let all = this.stage.getLayers();

    let width = document.getElementById("drawing-field").style.width;
    let height = document.getElementById("drawing-field").style.height;

    let tosend: any = [];

    let stage ={
      row: 'stage',
      width: width,
      height: height
    }

    tosend.push(stage);

    for (var i = 0; i < all.length; i++) {
      let layer: Layer = all[i];
      let typ = '';

      let rotation: any;
      if (layer.children.length > 1){
        let trans = layer.children[1].attrs;
        rotation = {
          transX: trans.x,
          transY: trans.y,
        }
      }

      if (layer.attrs.typ){
        typ = layer.attrs.typ;
      }

      let slayer = {
        row: 'layer',
        name: layer.attrs.name,
        typ: typ,
        child: layer.children[0],
        rotation: rotation,
      }
      tosend.push(slayer);
    }

    let s = JSON.stringify(tosend);

    let send = {
      ostammid: this.ostammid,
      typid: this.typ,
      paintid: this.paintid,
      base64: btoa(s),
      option: 'pdf',
      base64string: this.base64String,
    }

    this.apiService
    .setPaintItems(send, 'pdf')
    .pipe(first())
    .subscribe((val: any) => {
      if (val.type){
        this.overlayService.setOverlay({
          overlay: "viewAuftrag",
          titel: this.mrTranslate.transform('Zeichnen'),
          data: val,
          zIndex: 5,
        });
      }
    });
  }

  storingState() {
    if (this.pile.length > this.storageCapacity -1) {
      this.pile.shift();
    }
    var json = this.stage.toJSON();
    this.pile.push(json);
    this.undo_flag = true;
  }

  undo() {
    this.redopile =  this.stage.toJSON();
    var json = this.pile[this.pile.length - 1];
    this.restoringState(json);
    this.pile =  this.pile.slice(0, -1)
    this.undo_flag = this.pile.length > 0;
    this.redo_flag = true;
  }

  redo() {
    let json = JSON.parse(this.redopile);
    this.pile.push(JSON.parse(this.redopile));
    this.restoringState(json);
    this.redopile = '';
    this.redo_flag = false;
    this.undo_flag = true;
  }

  restoringState(json){
    this.dorestore = true;
    this.deleteAll();
    this.stage.destroy();
    this.tr = new Konva.Transformer();
    this.stage = Konva.Node.create(json, 'drawing-field');

    this.setStageEvents();

    this.stage.find('Image').forEach((imageNode) => {
      let vorlage = imageNode.getAttr('name');
      let icon = this.sideBarIcons.find(x => x.name == vorlage);

      let pixelW = (96 * 29.7) / 2.54;
      let pixelh = (96 * 21) / 2.54;
      let imlayer = imageNode.parent;

      var imageObj = new Image();
      imageObj.onload = function () {
        var yoda = new Konva.Image({
          x: 0,
          y: 0,
          image: imageObj,
          width: pixelW,
          height: pixelh,
          name: vorlage,
        });
        imlayer.add(yoda);
        imlayer.listening(false);
      };
      imageObj.src = 'data:image/png;base64,'+ icon.base64String;
      this.base64String = icon.base64String;

    });

    this.dorestore = false;
  }

  getID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r: number = Math.random() * 16 | 0;
      const v: number = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getImageSaveUrl(image) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'+ image);
  }

  onColorChange(v: any){
    if (this.selsharp != undefined){
      this.selsharp.stroke(v);
      this.layer.draw();
    }
  }

  onSizeChange(v: any){
    if (this.selsharp != undefined){
      this.selsharp.strokeWidth(parseInt(v));
      this.layer.draw();
    }
  }

  focusIn(v: any){
    const tr = new Konva.Transformer({
      nodes: [v.children[0]],
      resizeEnabled: false,
      rotateEnabled: false,
      borderStroke: '#FE5D00',
      borderStrokeWidth: 4,
      borderDash: [20, 10],
    });

    this.sellayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
    this.sellayer.add(tr);
    this.stage.add(this.sellayer);
    this.sellayer.draw();

  }

  focusOut(v: any){
    this.sellayer.destroy();
  }


  strokeChanged(v: any){
    if (this.selsharp != undefined){
      this.storingState();
    }
  }

  colorChanged(v: any){
    if (this.selsharp != undefined){
      this.storingState();
    }
  }

  findNonTempLayer(layer: any): any {
    return layer.attrs.typ != 'temp';
  }

  switch(){
    return;
    if (this.isPortrait){
      this.isPortrait = false;
      let pixelW = (96 * 29.7) / 2.54;
      let pixelh = (96 * 21) / 2.54;
      document.getElementById("drawing-field").style.width = pixelW.toString() + "px";
      document.getElementById("drawing-field").style.height = pixelh.toString() + "px";
    }
    else{
      this.isPortrait = true;
      let pixelW = (96 * 21) / 2.54;
      let pixelh = (96 * 29.7) / 2.54;
      document.getElementById("drawing-field").style.width = pixelW.toString() + "px";
      document.getElementById("drawing-field").style.height = pixelh.toString() + "px";
    }
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  openHistory(row){

    for (const itemrow of this.historyAllgemein) {
      itemrow.active = false;
    }

    for (const itemrow of this.historyAuftrag) {
      itemrow.active = false;
    }

    for (const itemrow of this.historyRueckmeldung) {
      itemrow.active = false;
    }

    if (row.INTTYP == 1)
    {
      for (const itemrow of this.historyAllgemein) {
        itemrow.active = itemrow.ID === row.ID;
      }
    }

    if (row.INTTYP == 2)
    {
      for (const itemrow of this.historyAuftrag) {
        itemrow.active = itemrow.ID === row.ID;
      }
    }

    if (row.INTTYP == 3)
    {
      for (const itemrow of this.historyRueckmeldung) {
        itemrow.active = itemrow.ID === row.ID;
      }
    }


    this.deleteAll();

    //this.stage.destroy();
    this.tr = new Konva.Transformer();
    this.paintid = row.ID;

    let json = atob(row.DRAWING);
    this.stage = Konva.Node.create(json, 'drawing-field');

    this.setStageEvents();

    this.stage.find('Image').forEach((imageNode) => {
      let vorlage = imageNode.getAttr('name');
      let icon = this.sideBarIcons.find(x => x.name == vorlage);

      let pixelW = (96 * 29.7) / 2.54;
      let pixelh = (96 * 21) / 2.54;
      let imlayer = imageNode.parent;

      var imageObj = new Image();
      imageObj.onload = function () {
        var yoda = new Konva.Image({
          x: 0,
          y: 0,
          image: imageObj,
          width: pixelW,
          height: pixelh,
          name: vorlage,
        });
        imlayer.add(yoda);
        imlayer.listening(false);
      };
      imageObj.src = 'data:image/png;base64,'+ icon.base64String;
      this.base64String = icon.base64String;

    });

  }

  async onFileChange(files: File[]) {
    if (!files.length) return;
    for (const file of files) {
      if (!file) continue;

      let filename = file.name.split('.')[0];
      let fileexists: boolean = false;
      this.sideBarIcons.forEach((element: any) => {
        if (element.name.toLowerCase() == filename.toLowerCase()){
          fileexists = true;
        }
      });

      if (fileexists){
        this.toastr.error(this.mrTranslate.transform('Vorlage ') + filename + this.mrTranslate.transform(" existiert bereits!"));
        continue;
      }

      const src = await this.base64service.getUrlStringFromFile(file);
      let base64 = src.split(",").pop();

      let item = {
        name: filename,
        source: '',
        base64String: base64,
      }
      this.sideBarIcons.push(item);


      let send = {
        status: 'savevorlagendatei',
        filename: file.name,
        base64string: base64,
      }

      this.apiService
      .setPaintItems(send, '')
      .pipe(first())
      .subscribe((val: any) => {

      });




    }
  }

  showHistory(){
    this.sidebarShow = false;
    this.historieShow = !this.historieShow;
  }

  showTemplates(){
    this.historieShow = false;
    this.sidebarShow = !this.sidebarShow;
  }

  addTemplate(name: string, image: string){
    if (this.isPortrait){
      this.switch();
    }

    this.pile = [];
    this.storingState();

    this.sidebarShow = false;
    this.hasTemplate = true;

    let pixelW = (96 * 29.7) / 2.54;
    let pixelh = (96 * 21) / 2.54;

    let imlayer = new Konva.Layer({name:"#Vorlage " + name, isTemplate: true});
    this.stage.add(imlayer);

    var imageObj = new Image();
    imageObj.onload = function () {
      var yoda = new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj,
        width: pixelW,
        height: pixelh,
        name: name,
      });
      imlayer.add(yoda);
      imlayer.listening(false);
    };
    imageObj.src = 'data:image/png;base64,'+ image;
    this.base64String = image;
  }

  doSelect(object){

    let layers = this.stage.getLayers();
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].attrs.edit){
        this.doEndEdit(layers[i]);
        break;
      }
    }

    this.editLayer = object;

    object.attrs.edit = true;

    if(object.children[0].attrs.points){
      this.editsharporgpoints = JSON.stringify(object.children[0].attrs.points);
    }

    this.editsharporg = JSON.stringify(object.children[0]);
    this.selsharp = object.children[0];
    this.m_select(object.children[0], object.attrs.typ);
    this.doEdit = true;
  }

  doDelete(object){

    if (object.attrs.edit){
      this.doEndEdit(object);
    }

    this.storingState();
    object.destroyChildren();
    object.destroy();
    this.layer.draw();

  }

  doEndEdit(object){

    if (this.dorestore){
      return;
    }

    try{
      this.selsharp.draggable(false);
    }catch(e){}


    this.doEdit = false;
    this.selsharp = undefined;

    if (object.attrs.typ == 'rectangle' || object.attrs.typ == 'freehandline' || object.attrs.typ == 'circle'){
      object.attrs.edit = false;
      this.tr.nodes([]);
      return;
    }

    object.add(this.templayer.children[0]).batchDraw();
    object.attrs.edit = false;

    this.templayer.destroy();
    this.editLayer = undefined;

  }

  doCopy(object){
    this.doEndEdit(object);

    let o = JSON.parse(JSON.parse(this.selsharporg));
    this.storingState();
    if (o.className == "Line") {

      if (o.attrs.name == 'arc'){
        this.countarc += 1;
        let name: string = "#arc" + this.countarc.toString();
        this.layer = new Layer({name: name, isTemplate: false, typ: 'arc', edit: false});

        let p1 = o.attrs.p1;
        let p2 = o.attrs.p2;
        let p3 = o.attrs.p3;

        p1.y += 40;
        p2.y += 40;
        p3.y += 40;

        let L  = GetPointsOfArc([p1, p2, p3]);

        let line: Line;
        line = new Konva.Line({
          id: this.getID(),
          points: L,
          strokeWidth: o.attrs.strokeWidth,
          stroke: o.attrs.stroke,
          draggable: false,
          name: 'arc',
          p1: p1,
          p2: p2,
          p3: p3,
        });


        this.layer.add(line).batchDraw();
        this.storingState();
        this.stage.add(this.layer);
        this.doSelect(this.layer)

        return;
      }
      if (o.attrs.name == 'freeline'){
        this.countLine += 1;
        let layername: string = "#freeline" + this.countLine.toString();
        this.layer = new Layer({name: layername, isTemplate: false, typ: 'freeline', edit: false});

        let line: Line;
        line = new Konva.Line({
          id: this.getID(),
          points: o.attrs.points,
          strokeWidth: o.attrs.strokeWidth,
          stroke: o.attrs.stroke,
          draggable: false,
          name: 'freeline',
        });

        line.offsetY(-40);
        this.layer.add(line).batchDraw();
        this.storingState();
        this.stage.add(this.layer);
        this.layer.draw();
        this.doSelect(this.layer)
      }
      else
      {
        this.countLine += 1;
        let layername: string = "#line" + this.countLine.toString();

        this.layer = new Layer({name: layername, isTemplate: false, typ: 'line', edit: false});

        let line: Line;
        line = new Konva.Line({
          id: this.getID(),
          points: [o.attrs.points[0], o.attrs.points[1] + 25, o.attrs.points[2], o.attrs.points[3] + 25],
          strokeWidth: o.attrs.strokeWidth,
          stroke: o.attrs.stroke,
          width: o.attrs.width,
          height: o.attrs.height,
          draggable: false,
          name: 'line',
        });
        this.layer.add(line).batchDraw();
        this.storingState();
        this.stage.add(this.layer);
        this.layer.draw();
        this.doSelect(this.layer);
      }
    }

    if (o.className == "Circle") {
      if (o.attrs.name == 'point'){
        this.countpoint += 1;

        let name: string = "#point" + this.countpoint.toString();

        this.layer = new Layer({name: name, isTemplate: false, typ: 'point', edit: false});
        this.stage.add(this.layer);

        let circle: Circle;
        circle = new Konva.Circle({
          id: this.getID(),
          x: o.attrs.x,
          y: o.attrs.y + 25,
          radius: 5,
          fill: this.curcolor,
          stroke: o.attrs.stroke,
          strokeWidth:o.attrs.strokeWidth,
          draggable: false,
          name: 'point',
        });
        this.layer.add(circle).batchDraw();
        this.storingState();
        this.doSelect(this.layer);
      }
      else
      {
        this.countcircle += 1;
        let name: string = "#circle" + this.countcircle.toString();
        this.layer = new Layer({name: name, isTemplate: false, typ: 'circle', edit: false});
        this.stage.add(this.layer);
        let circle = new Konva.Circle({
          id: this.getID(),
          x: o.attrs.x,
          y: o.attrs.y + 25,
          radius: 0,
          fill: 'transparent',
          stroke: o.attrs.stroke,
          strokeWidth:o.attrs.strokeWidth,
          draggable: true,
          name: 'circle',
        });
        this.layer.add(circle).batchDraw();
        this.storingState();
        this.doSelect(this.layer);
      }
    }

    if (o.className == "Rect") {

      this.countrect += 1;
      let layername: string = "#rectangle" + this.countrect.toString();
      this.layer = new Layer({name: layername, isTemplate: false, typ: 'rectangle', edit: false});

      let rect = new Konva.Rect({
        id: this.getID(),
        x: o.attrs.x,
        y: o.attrs.y + 25,
        width: o.attrs.width,
        height: o.attrs.height,
        fill: 'transparent',
        stroke: o.attrs.stroke,
        strokeWidth:o.attrs.strokeWidth,
        draggable: false,
        name: 'rectangle',
      });
      this.layer.add(rect).batchDraw();
      this.storingState();
      this.layer.draw();
      this.stage.add(this.layer);
      this.doSelect(this.layer);
    }
  }

  m_select(shape: any, typ:string) {
    let self = this;
    switch (typ) {
      case 'point':
        let px = shape.attrs.x;
        let py = shape.attrs.y;

        let point1: Rect;
        point1 = new Konva.Rect({
          x: px - 9,
          y: py - 9,
          width: 18,
          height: 18,
          fill: 'transparent',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle1',
        });

        this.templayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
        this.stage.add(this.templayer);
        this.templayer.add(shape).batchDraw();
        this.templayer.add(point1).batchDraw();

        point1.on('mouseover', function() {
            document.body.style.cursor = 'move';
        });

        point1.on('mouseout', function() {
            document.body.style.cursor = 'default';
        });

        point1.on('dragstart', function(event){
          self.storingState();
        })

        point1.on('dragmove', function(event){
          const Pos = point1.getPosition();

          Pos.x = Pos.x + 9;
          Pos.y = Pos.y + 9;

          shape.x(Pos.x);
          shape.y(Pos.y);
        })

        break;
      case 'line':
        let x = shape.attrs.points[0];
        let y = shape.attrs.points[1];
        let x1 = shape.attrs.points[2];
        let y1 = shape.attrs.points[3];

        let square1: Rect;
        square1 = new Konva.Rect({
          x: x - 6,
          y: y - 6,
          width: 12,
          height: 12,
          fill: 'white',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle1',
        });

        let square2: Rect;
        square2 = new Konva.Rect({
          x: x1 - 6,
          y: y1 - 6,
          width: 12,
          height: 12,
          fill: 'white',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle2',
        });


        this.templayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
        this.stage.add(this.templayer);
        this.templayer.add(shape).batchDraw();
        this.templayer.add(square1).batchDraw();
        this.templayer.add(square2).batchDraw();

        square1.on('mouseover', function() {
            document.body.style.cursor = 'move';
        });

        square1.on('mouseout', function() {
            document.body.style.cursor = 'default';
        });

        square1.on('dragstart', function(event){
          self.storingState();
        })

        square2.on('mouseover', function() {
            document.body.style.cursor = 'move';
        });

        square2.on('mouseout', function() {
            document.body.style.cursor = 'default';
        });

        square2.on('dragstart', function(event){
          self.storingState();
        })

        square1.on('dragmove', function(event){
          const circle1Pos = square1.getPosition(),
          circle2Pos = square2.getPosition();

          circle1Pos.x = circle1Pos.x + 6;
          circle1Pos.y = circle1Pos.y + 6;

          circle2Pos.x = circle2Pos.x + 6;
          circle2Pos.y = circle2Pos.y + 6;

          shape.points([circle1Pos.x, circle1Pos.y, circle2Pos.x , circle2Pos.y])
        })

        square2.on('dragmove', function(event){
          const circle1Pos = square1.getPosition(),
          circle2Pos = square2.getPosition();

          circle1Pos.x = circle1Pos.x+8;
          circle1Pos.y = circle1Pos.y+8;

          circle2Pos.x = circle2Pos.x+8;
          circle2Pos.y = circle2Pos.y+8;

          shape.points([circle1Pos.x, circle1Pos.y, circle2Pos.x , circle2Pos.y])
        })
        break;
      case 'arc':

        let p1 = shape.attrs.p1;
        let p2 = shape.attrs.p2;
        let p3 = shape.attrs.p3;

        let squareP1: Rect;
        squareP1 = new Konva.Rect({
          x: p1.x-5,
          y: p1.y-5,
          width: 10,
          height: 10,
          fill: 'white',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle1',
        });

        let squareP2: Rect;
        squareP2 = new Konva.Rect({
          x: p2.x-5,
          y: p2.y-5,
          width: 10,
          height: 10,
          fill: 'white',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle2',
        });

        let squareP3: Rect;
        squareP3 = new Konva.Rect({
          x: p3.x-5,
          y: p3.y-5,
          width: 10,
          height: 10,
          fill: 'white',
          stroke: '#44BAFF',
          strokeWidth: 2,
          draggable: true,
          name: 'rectangle2'
        });

        this.templayer = new Layer({name: 'temp', isTemplate: false, typ: 'temp'});
        this.stage.add(this.templayer);
        this.templayer.add(shape).batchDraw();
        this.templayer.add(squareP1).batchDraw();
        this.templayer.add(squareP2).batchDraw();
        this.templayer.add(squareP3).batchDraw();

        squareP1.on('mouseover', function() {
          document.body.style.cursor = 'move';
        });

        squareP1.on('mouseout', function() {
            document.body.style.cursor = 'default';
        });

        squareP2.on('mouseover', function() {
          document.body.style.cursor = 'move';
        });

        squareP2.on('mouseout', function() {
            document.body.style.cursor = 'default';
        });

        squareP3.on('mouseover', function() {
            document.body.style.cursor = 'move';
        });

        squareP3.on('mouseout', function() {
            document.body.style.cursor = 'default';
        });

        squareP3.on('dragstart', function(event){
          self.storingState();
        })

        squareP2.on('dragstart', function(event){
          self.storingState();
        })

        squareP1.on('dragstart', function(event){
          self.storingState();
        })

        squareP1.on('dragmove', function(event){
          const Pos1 = squareP1.getPosition(),
          Pos2 = squareP2.getPosition(),
          Pos3 = squareP3.getPosition();

          Pos1.x = Pos1.x+5;
          Pos1.y = Pos1.y+5;

          Pos2.x = Pos2.x+5;
          Pos2.y = Pos2.y+5;

          Pos3.x = Pos3.x+5;
          Pos3.y = Pos3.y+5;

          let L  = GetPointsOfArc([Pos1,Pos2,Pos3]);
          shape.attrs.p1 = Pos1;
          shape.attrs.p2 = Pos2;
          shape.attrs.p3 = Pos3;
          shape.points(L);
        })

        squareP2.on('dragmove', function(event){
          const Pos1 = squareP1.getPosition(),
          Pos2 = squareP2.getPosition(),
          Pos3 = squareP3.getPosition();

          Pos1.x = Pos1.x+5;
          Pos1.y = Pos1.y+5;

          Pos2.x = Pos2.x+5;
          Pos2.y = Pos2.y+5;

          Pos3.x = Pos3.x+5;
          Pos3.y = Pos3.y+5;

          let L  = GetPointsOfArc([Pos1,Pos2,Pos3]);
          shape.attrs.p1 = Pos1;
          shape.attrs.p2 = Pos2;
          shape.attrs.p3 = Pos3;
          shape.points(L);

        })

        squareP3.on('dragmove', function(event){
          const Pos1 = squareP1.getPosition(),
          Pos2 = squareP2.getPosition(),
          Pos3 = squareP3.getPosition();

          Pos1.x = Pos1.x+5;
          Pos1.y = Pos1.y+5;

          Pos2.x = Pos2.x+5;
          Pos2.y = Pos2.y+5;

          Pos3.x = Pos3.x+5;
          Pos3.y = Pos3.y+5;

          let L  = GetPointsOfArc([Pos1,Pos2,Pos3]);
          shape.attrs.p1 = Pos1;
          shape.attrs.p2 = Pos2;
          shape.attrs.p3 = Pos3;
          shape.points(L);
        })

        break;
      default:
        shape.draggable(true);


        shape.on('transformstart', () => {
          self.storingState();
        });

        shape.on('transformend', function () {
          console.log('transform end');
          //self.storingKObject(shape, 'transform');
        });

        // by default select all shapes
        shape.on('transform', () => {
          shape.setAttrs({
            width: Math.max(shape.width() * shape.scaleX(), 5),
            height: Math.max(shape.height() * shape.scaleY(), 5),
            scaleX: 1,
            scaleY: 1,
          });
        });

        this.tr.nodes([shape]);
        if (typ == 'freehandline'){

          this.tr = new Konva.Transformer({
            nodes: [shape],
            resizeEnabled: false
          });
        }

        this.layer.add(this.tr);

        break;
    }

    this.selsharporg = JSON.stringify(shape);
    this.selsharp = shape;

  }

  resetEdit(){
    if (this.doEdit){
      let layers = this.stage.getLayers();
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].attrs.edit){
          this.doEndEdit(layers[i]);
          break;
        }
      }
    }
  }

  rectangle() {
    this.resetBool();
    this.resetEdit();
    this.isdrawing = true;
    this.isrectangle = true;
  }

  freeline() {
    this.resetBool();
    this.resetEdit();
    this.isdrawing = true;
    this.isfreeline = true;
  }

  line() {
    this.resetBool();
    this.resetEdit();
    this.isdrawing = true;
    this.isline = true;
  }

  circle() {
    this.resetBool();
    this.resetEdit();
    this.isdrawing = true;
    this.iscircle = true;
  }

  point(){
    this.resetBool();
    this.resetEdit();
    this.isdrawing = true;
    this.ispoint = true;
  }

  arc(){
    this.resetBool();
    this.resetEdit();
    this.isdrawing = true;
    this.arcpoints = [];
    this.isarc = true;
  }

  resetBool(){
    this.isrectangle = false,  this.iscircle = false,
    this.isline = false, this.redo_flag = false,
    this.delete = false,
    this.ispoint = false, this.isarc = false;
  }

  neu(){
    this.pile = [];
    this.redopile = '';
    this.undo_flag = false;
    this.redo_flag = false;
    this.deleteAll();
  }

  deleteAll(){
    this.resetBool();

    if (!this.dorestore){
      this.paintid = -1;
    }

    this.delete = true;
    this.hasTemplate = false;

    if (this.layer != undefined){
      this.layer.destroyChildren();
      this.layer.destroy();
    }

    this.stage.destroy();
    this.ngOnInit();
    this.tr = new Konva.Transformer();
    this.delete = false;
  }

  draw_shape(){
    this.historieShow = false;
    this.sidebarShow = false;
  }

}

  class Vector
{
    x : number;
    y: number ;

    constructor(xValue: number, yValue: number)
    {
        this.x = xValue;
        this.y = yValue;
    }
  }

  function GetPointsOfArc(points : Vector[])
{
    var a = points[0];
    var b = points[2];
    var c = points[1];
    let d = 2 * (a.x - c.x) * (c.y - b.y) + 2 * (b.x - c.x) * (a.y - c.y);
    let m1 = Math.pow(a.x, 2) - Math.pow(c.x, 2) + Math.pow(a.y, 2) - Math.pow(c.y, 2);
    let m2 = Math.pow(c.x, 2) - Math.pow(b.x, 2) + Math.pow(c.y, 2) - Math.pow(b.y, 2);
    let nx = m1 * (c.y - b.y) + m2 * (c.y - a.y);
    let ny = m1 * (b.x - c.x) + m2 * (a.x - c.x);
    let cx = nx / d;
    let cy = ny / d;
    let dx = cx - a.x;
    let dy = cy - a.y;
    let distance = Math.sqrt(dx * dx + dy * dy);
    var va1 = new Vector(a.x - cx, a.y - cy);
    var vb1 = new Vector(b.x - cx, b.y - cy);
    var vc1 = new Vector(c.x - cx, c.y - cy);
    var xaxis1 = new Vector(1, 0);
    let startAngle = AngleBetween(xaxis1, va1);
    let sweepAngle = AngleBetween(va1, vb1) + AngleBetween(vb1, vc1);

    let NumersOfSegmets = GetNumberOfSegments(cx, cy, distance, startAngle, sweepAngle);
    let PointsLocal = PointsOnCircleSector(cx, cy, distance, startAngle, sweepAngle, NumersOfSegmets);

    var L = [];

    for (let i = 0; i <= PointsLocal.length - 1; i++) {

      L = L.concat([PointsLocal[i].x, PointsLocal[i].y]);
    }

    return L;
  }

  function AngleBetween(v1: Vector, v2: Vector): number {
    let sin = v1.x * v2.y - v2.x * v1.y;
    let cos = v1.x * v2.x + v1.y * v2.y;
    return Math.atan2(sin, cos) * (180 / Math.PI);
  }

  function GetNumberOfSegments(cx, cy, radius, StartAngle, SweepAngle) {
    let alpha = 180 + StartAngle;
    let LenghtOfSegments = 100000;
    let InternNumberOfSegments = 9;

    try {
      while (LenghtOfSegments > 15) {
        let p = CalcPoint(cx, cy, alpha, radius);
        let p1 = CalcPoint(cx, cy, alpha + SweepAngle / InternNumberOfSegments, radius);

        LenghtOfSegments = getDictancOfPoint(p,p1);

        InternNumberOfSegments += 1;
      }
    }
    catch(e){
        console.log(e.Message);
    }

    return InternNumberOfSegments - 1;

  }

  function getDictancOfPoint(p1 ,p2){
    let a = p1.x - p2.x;
    let b = p1.y - p2.y;
    return Math.sqrt( a*a + b*b );
  }

  function PointsOnCircleSector(cx: number, cy: number, radius: number, StartAngle: number, SweepAngle: number, NumberOfsegments: number) {
    var Points = [];
    let alpha = 180 + StartAngle;

    for (let i = 0; i <= NumberOfsegments; i++) {
      Points.push(CalcPoint(cx, cy, alpha, radius));
      alpha = alpha + SweepAngle / NumberOfsegments;
    }

    return Points;

  }

  function CalcPoint(cx: number, cy: number, alpha: number, radius: number): Vector {
    let x = 0;
    let y = 0;
    try {
      let cos = Math.cos((alpha * 2) * Math.PI / 360);
      let sin: number = Math.sin((alpha * 2) * Math.PI / 360);
      x = cos * radius;
      y = sin * radius;
    }
    catch(e){
        console.log(e.Message);
    }
    return new Vector(cx - x, cy - y);
  }
