import { Pipe, type PipeTransform } from '@angular/core';

const ArrayMethod = ['filter', 'map'] as const;
type ArrayMethod = (typeof ArrayMethod)[number];

const ArrayCallback = ['getProperty', 'getElement'] as const;
type ArrayCallback = (typeof ArrayCallback)[number];

type CallbackFn = (value: any, index?: number, array?: any[]) => any;


@Pipe({
  name: 'array',
})
export class ArrayMethodsCallbackPipe implements PipeTransform {
  transform<T>(array: T[], method: 'filter', cb: CallbackFn): T[];
  transform<T, K>(array: T[], method: 'map', cb: CallbackFn): K[];
  transform<T, K>(array: T[], method: 'map', cb: 'getProperty', property?: string): K[];

  /**
   * @example
   * *ngFor="let col of table.columns | array:'filter':exceptID"
   * protected exceptID = (col) => col.id != 'ID' || !col.id.toLower().includes('id');
   * @param array zu filtern
   * @param method name der Aktion
   * @param cb eine von existierten callbacks names or callback function
   * @param property
   * @returns gefilterten Array
   */
  transform<T, K>(
    array: T[],
    method: ArrayMethod,
    cb: CallbackFn | ArrayCallback,
    property?: string
  ): T[] | K[] {
    if (!Array.isArray(array)) return array;
    if (!method || !ArrayMethod.includes(method))
      method = 'map';
    if (typeof cb !== 'function') {
      if (!cb || !ArrayCallback.includes(cb))
        cb = 'getElement';
      cb = this[cb](property);
    }
    switch (method) {
      case 'map':
        return array.map(cb);
      case 'filter':
        return array.filter(cb);
    }
  }


  private getProperty(property: string): CallbackFn {
    return (value) => value?.[property];
  }

  private getElement(_: string): CallbackFn {
    return (value) => value;
  }
}

