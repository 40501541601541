<div class="overlay-inner-wrapper">
  <ng-container *ngIf="!isInspektion">
    <header class="header-2">
      <div class="branding">
        <span class="title">{{'Neuen Mangel anlegen' | mrTranslate}}</span>
      </div>
      <div class="header-actions">
        <a href="javascript:void(0);" class="nav-link nav-icon">
          <clr-icon shape="times" (click)="close()"></clr-icon>
        </a>
      </div>
    </header>
  </ng-container>

  <div id="mangelerfassungsWrapper" class="w-100-p padding-10-lr padding-12-b scroll-y"
    [ngClass]="isInspektion ? 'h-100' : 'h-60'">
    <div class="clr-row nxm" [ngStyle]="isInspektion ? {'flex-wrap': 'wrap-reverse'} : {}"
      [class.auto-height-md]="isInspektion">
      <div class="clr-col-12"
        [class]="isInspektion ? 'auto-height-md clr-col-md-9' : 'clr-col-lg-6'">
        <!-- Top-Sektion - Felder zum eintragen -->
        <form #heightRef #mangelForm [formGroup]="formTopGroup" #f='ngForm' (ngSubmit)="saveIfValid()"
          clrForm [clrLayout]="isInspektion ? 'vertical' : 'horizontal'" class="width100" [clrLabelSize]="isInspektion ? 12 : 4">
          <div class="clr-row nxm">
            <!-- von + bis -->
            <ng-container *ngIf="typ_Line_Point | objtype:'line'" formGroupName="linieGr">
              <ng-container *ngIf="linieGr.errors?.['mindestens-ein-wert'] && linieGr.touched">
                <div class="clr-row nxm clr-col-12 clr-col-sm-10 clr-col-md-8 clr-col-lg-6">
                  <clr-alert class="w-100-p" clrAlertType="danger" [clrAlertClosable]="false">
                    <clr-alert-item>
                      <span class="alert-text">
                        {{ "Der Startmeter oder Endmeter muss eingetragen sein" | mrTranslate }}
                      </span>
                    </clr-alert-item>
                  </clr-alert>
                </div>
                <div class="clr-break-row"></div>
              </ng-container>
              <ng-container *ngIf="linieGr.errors?.['not-in-Direction'] as err">
                <div class="clr-row nxm clr-col-12 clr-col-sm-10 clr-col-md-8 clr-col-lg-6 clr-break-row">
                  <clr-alert class="w-100-p" clrAlertType="danger" [clrAlertClosable]="false">
                    <clr-alert-item>
                      <span class="alert-text">
                        {{ "Der Startmeter muss " + (err.upwards ? "kleiner" : "größer") + " als der Endmeter sein" | mrTranslate }}
                      </span>
                    </clr-alert-item>
                  </clr-alert>
                </div>
                <div class="clr-break-row"></div>
              </ng-container>
              <div [class]="isInspektion ? 'clr-col-auto' : 'clr-col-12'">
                <ng-container *ngTemplateOutlet="!drehung.isOn ? start : end"></ng-container>
              </div>
              <div [class]="isInspektion ? 'clr-col-auto' : 'clr-col-12'">
                <ng-container *ngTemplateOutlet="!drehung.isOn ? end : start">
                  <span style="background-color: red; width: 10px; height: 5px;"></span>
                </ng-container>
              </div>
              <ng-template #start>
                <clr-input-container>
                  <label for="von" class="w-100-p">
                    {{ (!drehung.isOn ? "von" : "bis") | mrTranslate }}
                    <div class="btn-group btn-sm btn-icon btn-outline"
                      style="margin: -7px 0; float: inline-end;">
                      <button *ngIf="!drehung.isOn" class="btn" type="button"
                        [title]="'Werte einsetzen' | mrTranslate" tabindex="-1"
                        (click)="copyLinieValue('von', 'bis')" [disabled]="formTopGroup.disabled || !(formValue.von || formValue.von === 0)">
                        <clr-icon shape="paste"></clr-icon>
                      </button>
                      <button class="btn" type="button"
                        [title]="'Geoposition orten' | mrTranslate" tabindex="-1"
                        (click)="findPositionAndAutofill('von')" [disabled]="formTopGroup.disabled">
                        <clr-icon shape="map-marker"></clr-icon>
                      </button>
                    </div>  
                  </label>
                  <input clrInput formControlName="von"
                    [value]="formTopGroup.get('von')?.value | drehung: drehung.summe"
                    (change)="formTopGroup.get('von')?.setValue(
                      drehungPipe.back($event.target.value, drehung.summe)
                    )" name="von" type="number" [step]="0.1"/>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'min'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'kleiner' : 'größer') + " als" | mrTranslate }} {{err.min | drehung: drehung.summe}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'max'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'größer' : 'kleiner') + " als" | mrTranslate }} {{err.max | drehung: drehung.summe}}
                  </clr-control-error>
                </clr-input-container>
              </ng-template>
              <ng-template #end>
                <clr-input-container>
                  <label for="bis" class="w-100-p">
                    {{ (!drehung.isOn ? "bis" : "von") | mrTranslate }}
                    <div class="btn-group btn-sm btn-icon btn-outline"
                      style="margin: -7px 0; float: inline-end;">
                      <button *ngIf="drehung.isOn" class="btn" type="button"
                        [title]="'Werte einsetzen' | mrTranslate" tabindex="-1"
                        (click)="copyLinieValue('bis', 'von')" [disabled]="formTopGroup.disabled || !(formValue.bis || formValue.bis === 0)">
                        <clr-icon shape="paste"></clr-icon>
                      </button>
                      <button class="btn" type="button"
                        [title]="'Geoposition orten' | mrTranslate" tabindex="-1"
                        (click)="findPositionAndAutofill('bis')" [disabled]="formTopGroup.disabled">
                        <clr-icon shape="map-marker"></clr-icon>
                      </button>
                    </div>  
                  </label>
                  <input clrInput formControlName="bis"
                    [value]="formTopGroup.get('bis')?.value | drehung: drehung.summe"
                    (change)="formTopGroup.get('bis')?.setValue(
                      drehungPipe.back($event.target.value, drehung.summe)
                    )" name="bis" type="number" [step]="0.1"/>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'min'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'kleiner' : 'größer') + " als" | mrTranslate }} {{err.min | drehung: drehung.summe}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'max'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'größer' : 'kleiner') + " als" | mrTranslate }} {{err.max | drehung: drehung.summe}}
                  </clr-control-error>
                </clr-input-container>
              </ng-template>
              <div class="clr-break-row"></div>
            </ng-container>
            <!-- Gattung + Stamm -->
            <ng-container *ngIf="typ_Line_Point == 4" formGroupName="gleisGr">
              <div [class]="isInspektion ? 'clr-col' : 'clr-col-12'">
                <clr-select-container>
                  <label for="gattung">{{ "gattung" | titlecase | mrTranslate }}</label>
                  <select clrSelect formControlName="gattung" name="gattung">
                    <option *ngFor="let obj of values.get('Gattung') | keyvalue: null" [value]="obj.key">
                      {{obj.key}}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-select-container>
              </div>
              <div [class]="isInspektion ? 'clr-col' : 'clr-col-12'">
                <clr-select-container>
                  <label for="stamm">{{ "stammdaten" | titlecase | mrTranslate }}</label>
                  <select clrSelect formControlName="stamm" name="stamm">
                    <option [value]="null" disabled></option>
                    <option *ngFor="let obj of values.get('Stamm') | keyvalue" [value]="obj.key">
                      {{obj.key}}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-select-container>
              </div>
            </ng-container>
            <!-- Prüfart -->
            <div [class]="isInspektion ? (typ_Line_Point | objtype: 'point')
            	? 'clr-col-auto' : 'clr-col' : 'clr-col-12'">
              <clr-datalist-container>
                <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                  for="pruefart" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-4'">
                  {{ "pruefart" | titlecase | mrTranslate }}
                </label>
                <input clrDatalistInput type="search" autocomplete="off" class="clr-col-md-8" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-8'"
                  formControlName="pruefart" name="pruefart" />
                <datalist>
                  <option *ngFor="let obj of values.get('Pruefart') | keyvalue" [value]="obj.key">
                    {{obj.key}}
                  </option>
                </datalist>
                <clr-control-error *clrIfError="'required'">
                  {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                </clr-control-error>
              </clr-datalist-container>
            </div>
            <!-- n.I.O. -->
            <div *ngIf="(typ_Line_Point | objtype: 'point') && isInspektion"
             [class]="isInspektion ? 'clr-col-auto' : 'clr-col-12'">
              <clr-toggle-container>
                <label for="nIO"> {{ 'n.I.O.' | mrTranslate }} </label>
                <clr-toggle-wrapper>
                  <input type="checkbox" clrToggle name="nIO" formControlName="nIO"/>
                </clr-toggle-wrapper>
              </clr-toggle-container>
            </div>
            <div class="clr-break-row" *ngIf="typ_Line_Point != 2"></div>
            <!-- Lage, Mangel, IH -->
            <!-- Umfang, Meh, FK, BG, Bemerkung -->
            <ng-container *ngIf="(typ_Line_Point | objtype: 'line') || mangelGr.enabled"
              formGroupName="mangelGr">
              <div [class]="isInspektion ? 'clr-col' : 'clr-col-12'">
                <clr-select-container *ngIf="typ_Line_Point | objtype: 'line'; else datalist">
                  <label for="lage">{{ "lage" | titlecase | mrTranslate }}</label>
                  <select clrSelect formControlName="lage" name="lage">
                    <option [value]="null"></option>
                    <option *ngFor="let obj of values.get('Lage') | keyvalue"
                      [value]="obj.key">{{obj.key}}</option>
                  </select>
                </clr-select-container>

                <ng-template #datalist>
                  <clr-datalist-container>
                    <label for="lage" [class]="isInspektion 
                      ? 'clr-col-md-12' : 'clr-col-md-4'">
                      {{ "lage" | titlecase | mrTranslate }}</label>
                    <input clrDatalistInput type="search" autocomplete="off"  class="clr-col-md-8" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-8'"
                      formControlName="lage" name="lage" />
                    <datalist>
                      <option *ngFor="let obj of values.get('Lage') | keyvalue"
                        [value]="obj.key">{{obj.key}}</option>
                    </datalist>
                    <clr-control-error *clrIfError="'required'">
                      {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                    </clr-control-error>
                  </clr-datalist-container>
                </ng-template>
              </div>
              <div [class]="isInspektion ? 'clr-col' : 'clr-col-12'">
                <clr-datalist-container>
                  <label style="text-wrap: nowrap; text-overflow: ellipsis; width: 100%;"
                    for="mangel" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-4'">
                    {{ "mangel" | titlecase | mrTranslate }}
                    <button class="btn btn-sm btn-icon btn-outline"
                      style="margin: -7px 0; float: inline-end;" tabindex="-1"
                      type="button" [title]="'Beschreibung' | mrTranslate"
                      (click)="openHelper()" [disabled]="formTopGroup.disabled || mangelGr.get('mangel').invalid">
                      <clr-icon shape="help"></clr-icon>
                    </button>
                  </label>
                  <input clrDatalistInput type="search" autocomplete="off"  class="clr-col-md-8" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-8'"
                    formControlName="mangel" name="mangel" />
                  <datalist>
                    <option *ngFor="let obj of values.get('Mangel') | keyvalue" [value]="obj.key">
                      {{obj.key}}
                    </option>
                  </datalist>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-datalist-container>
              </div>
              <div [class]="isInspektion ? 'clr-col' : 'clr-col-12'">
                <clr-datalist-container>
                  <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                    for="instandsetzung" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-4'">
                    {{ "instandsetzung" | titlecase | mrTranslate }}
                  </label>
                  <input clrDatalistInput type="search" autocomplete="off"  class="clr-col-md-8" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-8'"
                    formControlName="instandsetzungshinweis" name="instandsetzung" />
                  <datalist>
                    <option *ngFor="let obj of values.get('Instandsetzung') | keyvalue" [value]="obj.key">
                      {{obj.key}}
                    </option>
                  </datalist>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-datalist-container>
              </div>
              <div class="clr-break-row"></div>
              <div [class]="isInspektion ? 
                'clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6' : 'clr-col-12'">
                <clr-input-container>
                  <label for="umfang" style="text-wrap: nowrap; text-overflow: ellipsis; width: 100%;">
                    {{ 'umfang' | titlecase | mrTranslate }}
                    <button *ngIf="typ_Line_Point | objtype: 'line'"
                      class="btn btn-sm btn-icon btn-outline"
                      style="margin: -7px 0; float: inline-end;" tabindex="-1"
                      type="button" [title]="'Prozentrechner' | mrTranslate"
                      (click)="calModal.open()" [disabled]="formTopGroup.disabled 
                        || linieGr.invalid
                        || (formValue.umfang | check: notProzent)">
                      <clr-icon shape="calculatur"></clr-icon>
                    </button>
                  </label>
                  <input clrInput name="umfang" type="number" formControlName="umfang"
                    [step]="1"/>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-input-container>
              </div>
              <div [class]="isInspektion ? 
                'clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6' : 'clr-col-12'">
                <clr-select-container>
                  <label for="mengeneinheit" class="d-f row w-100-p">
                    <span class="clr-col nxp ellipsis">
                      {{ "mengeneinheit" | titlecase | mrTranslate }}</span>
                    <button class="btn btn-sm btn-icon btn-outline clr-col-auto"
                      style="margin: -7px 0; float: inline-end;" tabindex="-1"
                      type="button" [title]="'Tabelle verwalten' | mrTranslate"
                      (click)="openTabelle()" [disabled]="formTopGroup.disabled">
                      <clr-icon shape="pencil"></clr-icon>
                    </button>
                  </label>
                  <select clrSelect formControlName="mengeneinheit" name="mengeneinheit">
                    <option *ngFor="let obj of values.get('Mengeneinheit') | keyvalue" [value]="obj.key">
                      {{obj.key}}
                    </option>
                    <option *ngIf="selectedMangel?.addMeh" class="invalidRed"
                      [value]="selectedMangel.mengeneinheit"> 
                      {{selectedMangel.mengeneinheit}}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-select-container>
              </div>
              <div [class]="isInspektion ? 
                'clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6' : 'clr-col-12'">
                <clr-select-container>
                  <label for="fehlerklasse" style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;">{{ "fehlerklasse" | titlecase | mrTranslate }}</label>
                  <select clrSelect formControlName="fehlerklasse" name="fehlerklasse">
                    <option *ngFor="let fk of valuesFK" [value]="fk">
                      {{ fk }}
                    </option>
                  </select>
                  <clr-control-helper *ngIf="sperrungFK">
                    {{"Fehlerklasse ist nicht nach unter änderbar" | mrTranslate}}
                  </clr-control-helper>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-select-container>
              </div>
              <div [class]="isInspektion ? 
                'clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6' : 'clr-col-12'">
                <clr-toggle-container>
                  <label for="betriebsgefahr" style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;">
                    {{ 'Betriebsgefahr' | mrTranslate }} </label>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle name="betriebsgefahr" formControlName="betriebsgefahr"/>
                  </clr-toggle-wrapper>
                </clr-toggle-container>
              </div>
              <div *ngIf="opt_Abteilung" [class]="isInspektion ? 
                'clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6' : 'clr-col-12'">
                <clr-datalist-container>
                  <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                    for="abteilung" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-4'">
                    {{ "zuständige abteilung" | titlecase | mrTranslate }}
                  </label>
                  <input clrDatalistInput type="search" autocomplete="off"  class="clr-col-md-8" [class]="isInspektion ? 'clr-col-md-12' : 'clr-col-md-8'"
                    formControlName="abteilung" name="abteilung" />
                  <datalist>
                    <option *ngFor="let abt of valuesAbteilung" [value]="abt">
                      {{ abt }}
                    </option>
                  </datalist>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-datalist-container>
              </div>
              <div [class]="isInspektion ? 'clr-col' : 'clr-col-12'">
                <clr-input-container>
                  <label for="bemerkung" style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;">
                    {{ 'Bemerkung' | mrTranslate }} </label>
                  <input clrInput name="bemerkung" type="text" formControlName="bemerkung" />
                </clr-input-container>
              </div>
            </ng-container>
          </div>
        </form>
        <ng-container *ngIf="!isInspektion">
          <button type="button" class="btn btn-primary margin-36-t margin-12-l"
            (click)="f.ngSubmit.emit()">
            {{'Speichern' | mrTranslate}}
          </button>
        </ng-container>
        <clr-datagrid #mangelTableGrid *ngIf="isInspektion && table" 
          class="datagrid-compact dg-overflow-fix limitHeight"
          [(clrDgSingleSelected)]="selectedMangel" [clrDgRowSelection]="true"
          [dymanicStyle]="heightRef">
          <clr-dg-column clrDgField="status" [style.width.px]="10">
            <ng-container *clrDgHideableColumn="{
              hidden: !isInspektion || isMangelValidStatus
            }">
              {{ "✔" | mrTranslate }}
            </ng-container>
          </clr-dg-column>
    
          <ng-container *ngIf="typ_Line_Point | objtype: 'line'">
            <clr-dg-column [clrDgField]="(!drehung.isOn ? 'von' : 'bis')"
              [clrDgSortOrder]="direction * (drehung.isOn ? -1 : 1)">
              {{ 'von' | titlecase | mrTranslate }}
            </clr-dg-column>
            <clr-dg-column [clrDgField]="(!drehung.isOn ? 'bis' : 'von')">
              {{ 'bis' | titlecase | mrTranslate }}
            </clr-dg-column>
          </ng-container>
    
          <clr-dg-column *ngFor="let column of table['columns'] | array:'filter': exceptStartEnd" [clrDgField]="column.id">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns: ['b_parentid','bild64']}">
              {{ column.id | titlecase | mrTranslate }}
            </ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of table['rows']" [clrDgItem]="row">
            <clr-dg-cell>
              <clr-icon *ngIf="row.status | compareDate:'before'"
                class="customIcon invalidRed" size="20"
                shape="exclamation-triangle">
              </clr-icon>
            </clr-dg-cell>
    
            <ng-container *ngIf="typ_Line_Point | objtype: 'line'">
              <clr-dg-cell class="name-cell" [innerHTML]="row[(!drehung.isOn ? 'von' : 'bis')] | drehung: drehung.summe | tablePrettyPrint">
              </clr-dg-cell>
              <clr-dg-cell class="name-cell" [innerHTML]="row[(!drehung.isOn ? 'bis' : 'von')] | drehung: drehung.summe | tablePrettyPrint">
              </clr-dg-cell>
            </ng-container>
    
            <clr-dg-cell class="name-cell" *ngFor="let cell of table['columns'] | array:'filter': exceptStartEnd"
              [innerHTML]="row[cell.id] | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>{{table['rows']?.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
      </div>
      <div class="clr-col-12 nxp"
        [class]="isInspektion ? 'clr-col-md-3' : 'clr-col-lg-6'">
        <div *ngIf="isInspektion" class="clr-row nxm clr-col-12 margin-12-tb nxp">
          <div class="btn-group btn-icon">
            <button class="btn btn-icon" [title]="'Neue Mangelverknüpfung erstellen' | mrTranslate"
              (click)="addMangelTree()" [disabled]="!isnewmangeltree">
              <clr-icon shape="mangelbaum_add"></clr-icon>
            </button>
          </div>
          <div class="btn-group btn-primary btn-icon">
            <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
              (click)="addMangel()"[disabled]="setInsert">
                <!-- || !selectedMangel && (typ_Line_Point | objtype: 'point')"> -->
              <clr-icon shape="file"></clr-icon>
            </button>
            <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
              (click)="deleteMangel()" [disabled]="!selectedMangel">
              <clr-icon shape="times"></clr-icon>
            </button>
            <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate"
              (click)="f.ngSubmit.emit()" [disabled]="!(selectedMangel || setInsert)">
              <clr-icon shape="floppy"></clr-icon>
            </button>
          </div>
        </div>
        <div class="clr-row nxm clr-col-12 nxp">
          <app-preview-thumbnails
            [drag_drop]="!isInspektion"
            [hidden]="mangelGr.disabled"
            [maxAmount]="6" (deleteFile)="deletedFiles.push($event)">
          </app-preview-thumbnails>
        </div>
        <ng-container *ngIf="isInspektion; else historie"></ng-container>
      </div>
    </div>
    
  </div>
</div>
<ng-template #historie>
  <div *ngIf="table" class="clr-row nxm clr-col-12" style="max-height: 50%;" >
    <h3>{{'Historie' | mrTranslate}}</h3>
    <div class="clr-col-12 nxp scroll" style="max-height: calc(100% - 24px - 24px - 6px);">
      <table class="table margin-6-t table-compact nowrap">
        <thead style="position: sticky; top: 0; z-index: 3;">
          <tr>
            <ng-container *ngFor="let column of table.columns">
              <th *ngIf="!(column.id | hideIDColumns: ['bildpfad', 'rueckbilder'])" class="left">
                {{ column.id | titlecase | mrTranslate}}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of table.rows">
            <ng-container *ngFor="let column of table.columns">
              <td *ngIf="!(column.id | hideIDColumns: ['bildpfad', 'rueckbilder'])" class="left"
                [innerHTML]="row[column.id] | tablePrettyPrint">
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<clr-modal #calModal [clrModalClosable]="true">
  <h3 class="modal-title">{{"Prozentrechner" | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm #calForm="ngForm" class="width100 padding-12-r" clrLabelSize="4">
      <clr-input-container>
        <label>{{'Anzahl pro Schwelle' | mrTranslate}}</label>
        <input clrInput type="number" min="1" ngModel name="anzahl" />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
      (click)="setFaktor(calForm.value.anzahl); calModal.close()">
      {{ 'OK' | mrTranslate }}
    </button>
    <button type="button" class="btn" (click)="calModal.close()">
      {{ 'Abbrechen' | mrTranslate }}
    </button>
  </div>
</clr-modal>

<app-hilfe-beschreibung-modal [readonly]="true"></app-hilfe-beschreibung-modal>
<app-basedatamodal></app-basedatamodal>
