<clr-modal [(clrModalOpen)]="modalOpen" [clrModalSize]="'xl'" [clrModalClosable]="false" id="messungmodal">
  <h3 class="modal-title" style="display:flex">{{'Streckenmessdaten importieren' | mrTranslate}}</h3>
  <div class="modal-body" id="settingsModalBody">
    <form clrForm class="clr-form clr-form-horizontal">

      <div class="clr-row">
        <div class="clr-col-9" style="padding-left: 5px;">
          <clr-tabs>
            <clr-tab>
              <button clrTabLink (click)="tabselect('track')">{{'Gleismessdaten' | mrTranslate}}</button>
              <ng-template [(clrIfActive)]="trackdActive">
                <clr-tab-content>
                  <div class="clr-row" style="margin-top: 10px;">
                    <div class="clr-col-4">
                      <span>{{'Auswahl Messgerät' | mrTranslate}}:</span>
                    </div>

                    <div class="clr-col-2">
                      <clr-select-container class="messgeraet">
                        <!-- class="messgeraet" -->
                        <select clrSelect name="options" [(ngModel)]="selectedMessgeraet" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                          <option value="0">Vogel & Plötscher - EMA</option>
                          <option value="1">Vogel & Plötscher - CDM</option>
                          <option value="2">Vogel & Plötscher - CTS</option>
                          <option value="3">KZV - Krabbe</option>
                          <option value="4">Cemafer - SGMT5 / Diamond-S</option>
                          <option value="5">Amberg - GRP5000</option>
                          <option value="6">Geismar - Amber</option>
                          <option value="7">Geismar - Emerald</option>
                          <option value="8">DB AG - GMTZ</option>
                          <option value="9">Goldschmidt - Graw</option>
                        </select>
                      </clr-select-container>
                    </div>
                  </div>
                  <hr/>


                  <clr-stack-view style="margin-top: 10px;" [formGroup]="setting">
                    <clr-stack-block [clrSbExpanded]="false">
                      <clr-stack-label>{{ "Diagramm" | mrTranslate }}</clr-stack-label>
                        <clr-stack-block>
                          <clr-stack-content>
                            <div class="limit-height" style="margin-left: -350px; width: 830px;">

                              <clr-datagrid #dgStammdaten *ngIf="toleranzen?.columns; else elseTemplate" style="padding-top: 0px" class="datagrid-compact" >
                                <clr-dg-column *ngFor=" let column of toleranzen['columns']" [clrDgField]="column.id"
                                  [hidden]="column.id | hideIDColumns:['typ','Profiltyp','SchHMax','BildBase64','Profil']">{{column.id | titlecase | mrTranslate}}</clr-dg-column>
                                  <clr-dg-row *clrDgItems="let row of toleranzen['rows']" [clrDgItem]="row">
                                    <clr-dg-cell class="name-cell" *ngFor="let cell of toleranzen['columns']"
                                      [hidden]="cell.id | hideIDColumns:['typ','profiltyp', 'schhmax','bildbase64','profil']" [innerHTML]="row[cell.id] | tablePrettyPrint">
                                    </clr-dg-cell>
                                  </clr-dg-row>
                                  <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpen($event)">
                                    <clr-dg-detail>
                                      <clr-dg-detail-header>{{ detail['bezeichnung'] }}</clr-dg-detail-header>
                                      <clr-dg-detail-body>
                                        <form clrForm id="kavForm" [formGroup]="tol">
                                          <div class="clr-row">
                                            <div class="clr-col-3">
                                            </div>
                                            <div class="clr-col-2">
                                              <clr-checkbox-container class="margin-0-t">
                                                <clr-checkbox-wrapper>
                                                  <label>{{'Anwenden' | mrTranslate}}</label>
                                                  <input type="checkbox" clrCheckbox  formControlName="auswahl"/>
                                                </clr-checkbox-wrapper>
                                              </clr-checkbox-container>
                                            </div>
                                          </div>
                                          <div class="clr-row">
                                            <div class="clr-col-3">
                                              {{'Sollwert' | mrTranslate}}
                                            </div>
                                            <div class="clr-col-9">
                                              <input type="number" class="clr-input" formControlName="sollwert"/>
                                            </div>
                                          </div>
                                          <div class="clr-row" style="margin-top: 15px;">
                                            <div class="clr-col-3">
                                              {{'Y-Min' | mrTranslate}}
                                            </div>
                                            <div class="clr-col-9">
                                              <input type="number" class="clr-input" formControlName="ymin"/>
                                            </div>
                                          </div>
                                          <div class="clr-row" style="margin-top: 15px;">
                                            <div class="clr-col-3">
                                              {{'Y-Max' | mrTranslate}}
                                            </div>
                                            <div class="clr-col-9">
                                              <input type="number" class="clr-input" formControlName="ymax"/>
                                            </div>
                                          </div>
                                          <!-- <br> -->
                                          <div class="clr-row" style="margin-top: 15px;">
                                            <div class="clr-col-3">
                                              {{'SRG -' | mrTranslate}}
                                            </div>
                                            <div class="clr-col-9">
                                              <input type="number" class="clr-input" formControlName="srmin"/>
                                            </div>
                                          </div>
                                          <!-- <br> -->
                                          <div class="clr-row" style="margin-top: 15px;">
                                            <div class="clr-col-3">
                                              {{'SRG +' | mrTranslate}}
                                            </div>
                                            <div class="clr-col-9">
                                              <input type="number" class="clr-input" formControlName="srmax"/>
                                            </div>
                                          </div>
                                          <!-- <br> -->
                                          <div class="clr-row">
                                            <div class="clr-col-3">
                                              <button class="btn btn-primary btn-sm" type="submit" (click)="saveItems(detail.id)">{{'Speichern' | mrTranslate}} </button>
                                            </div>
                                          </div>
                                        </form>
                                      </clr-dg-detail-body>
                                    </clr-dg-detail>
                                  </ng-template>
                                <clr-dg-footer>
                                  <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{toleranzen['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                                </clr-dg-footer>
                              </clr-datagrid>
                              <ng-template #elseTemplate> <div style="height: 400px;"></div> </ng-template>
                            </div>
                          </clr-stack-content>
                      </clr-stack-block>
                    </clr-stack-block>
                    <clr-stack-block [clrSbExpanded]="false">
                      <clr-stack-label>{{ "Einstellungen" | mrTranslate }}</clr-stack-label>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Messpunktabstand Rohdaten" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input clrInput type="number" formControlName="messpunktabstand"/>
                        </clr-stack-content>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Verwindungsbasis" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input clrInput type="number" formControlName="verwindungsbasis"/>
                        </clr-stack-content>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Ausgabe Verwindung" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <select clrSelect name="options" formControlName="verwindungseinheit">
                            <option value="mm">mm</option>
                            <option value="promill">‰</option>
                          </select>
                        </clr-stack-content>
                      </clr-stack-block>


                      <clr-stack-block>
                        <clr-stack-label>{{ "Messender" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input clrInput type="text" formControlName="bediener"/>
                        </clr-stack-content>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "radiusabhänige Toleranzen" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="rtol" (change)="changeRadiusToleranzen($event)"/>
                        </clr-stack-content>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Bauwerke visualisieren" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="showbauw" (change)="changeBauwerke($event)" />
                        </clr-stack-content>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Spurweite autokorrektur" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="spurauto" />
                        </clr-stack-content>

                        <clr-stack-block>
                          <clr-stack-label>{{ "Wert" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <input clrInput type="number" formControlName="spurautowert"/>
                          </clr-stack-content>
                        </clr-stack-block>

                      </clr-stack-block>


                      <clr-stack-block>
                        <clr-stack-label>{{ "Überhöhung glätten" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="uehglatt" />
                        </clr-stack-content>

                        <clr-stack-block>
                          <clr-stack-content>
                            <clr-radio-container clrInline class="margin-0-t" style="margin-top: 15px;">
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio formControlName="uehglattopt" [value]="0" />
                                <label>{{'Frequenzfilter' | mrTranslate}}</label>
                              </clr-radio-wrapper>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio formControlName="uehglattopt" [value]="1" />
                                <label>{{'Mittelwert' | mrTranslate}}</label>
                              </clr-radio-wrapper>
                            </clr-radio-container>
                          </clr-stack-content>
                        </clr-stack-block>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Radius glätten" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="radglatt" />
                        </clr-stack-content>
                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Sollwerte aus Trassierung" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="usetrass" />
                        </clr-stack-content>

                        <clr-stack-block>
                          <clr-stack-label>{{ "Überhöhung" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <input type="checkbox" clrCheckbox formControlName="usetrassueh" />
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>{{ "Radius" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <input type="checkbox" clrCheckbox formControlName="usetrassrad" />
                          </clr-stack-content>
                        </clr-stack-block>

                      </clr-stack-block>

                      <clr-stack-block>
                        <clr-stack-label>{{ "Mehrjahresvergleich Diagramm" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <input type="checkbox" clrCheckbox formControlName="mehrjahr" />
                        </clr-stack-content>
                      </clr-stack-block>
                    </clr-stack-block>

                    <clr-stack-block [clrSbExpanded]="false" *ngIf="selectbauwerke === true">
                      <clr-stack-label>{{ "Bauwerke auswählen" | mrTranslate }}</clr-stack-label>
                      <clr-stack-block>
                        <clr-stack-content>

                          <div class="limit-height" style="margin-left: -350px; width: 830px;">
                            <clr-datagrid *ngIf="bauwerke?.columns; else elseTemplate" style="padding-top: 0px" class="datagrid-compact" >
                              <clr-dg-column *ngFor=" let column of bauwerke['columns']" [clrDgField]="column.id"
                                [hidden]="column.id | hideIDColumns:['typ','colorhtml','colorint','BildBase64','Profil']">{{column.id | titlecase | mrTranslate}}</clr-dg-column>
                                <clr-dg-row *clrDgItems="let row of bauwerke['rows']" [clrDgItem]="row">
                                  <clr-dg-cell class="name-cell" *ngFor="let cell of bauwerke['columns']"
                                    [hidden]="cell.id | hideIDColumns:['typ','colorhtml', 'colorint','bildbase64','profil']" [innerHTML]="row[cell.id] | tablePrettyPrint">
                                  </clr-dg-cell>
                                </clr-dg-row>
                                <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpenBW($event)">
                                  <clr-dg-detail>
                                    <clr-dg-detail-header>{{ detail['bezeichnung'] }}</clr-dg-detail-header>
                                    <clr-dg-detail-body>
                                      <form clrForm [formGroup]="bauw">
                                        <div class="clr-row">
                                          <div class="clr-col-3">
                                          </div>
                                          <div class="clr-col-2">
                                            <clr-checkbox-container class="margin-0-t">
                                              <clr-checkbox-wrapper>
                                                <label>{{'Anwenden' | mrTranslate}}</label>
                                                <input type="checkbox" clrCheckbox  formControlName="auswahl"/>
                                              </clr-checkbox-wrapper>
                                            </clr-checkbox-container>
                                          </div>
                                        </div>
                                        <div class="clr-row">
                                          <div class="clr-col-3">
                                            {{'Reihenfolge' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-9">
                                            <input type="number" class="clr-input" formControlName="pos"/>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'Farbe' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-9">
                                            <!-- <p style="margin-top: -2px; border-radius: 3px; width: 22px;height: 22px;" [ngStyle]="{'background': detail.colorhtml}" formControlName="color" (click)="close()" class="cursor-pointer"></p> -->
                                            <input id="forecolor" type="color" class="cursor-pointer"  value="{{detail.colorhtml}}" (change)="onBWColor($event.target.value)" formControlName="color">
                                          </div>
                                        </div>
                                        <br>
                                        <div class="clr-row">
                                          <div class="clr-col-3">
                                            <button class="btn btn-primary btn-sm" type="submit" (click)="saveItemsBW(detail.id)">{{'Speichern' | mrTranslate}} </button>
                                          </div>
                                        </div>
                                      </form>
                                    </clr-dg-detail-body>
                                  </clr-dg-detail>
                                </ng-template>
                              <clr-dg-footer>
                                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{toleranzen['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                              </clr-dg-footer>
                            </clr-datagrid>
                            <ng-template #elseTemplate> <div style="height: 400px;"></div> </ng-template>
                          </div>
                        </clr-stack-content>
                      </clr-stack-block>
                    </clr-stack-block>

                    <clr-stack-block [clrSbExpanded]="false" *ngIf="selecttoleranzen === true">
                      <clr-stack-label>{{ "radiusabhänige Toleranzen" | mrTranslate }}</clr-stack-label>
                      <clr-stack-block>
                        <clr-stack-label>{{ "Toleranzen" | mrTranslate }}</clr-stack-label>
                        <clr-stack-content>
                          <div class="clr-row">
                            <div class="clr-col-3">
                              <clr-checkbox-container style="margin-top: 10px;">
                                <clr-checkbox-wrapper>
                                  <label>{{'SRG' | mrTranslate}}</label>
                                  <input type="checkbox" clrCheckbox formControlName="anzeigeKAVDok" />
                                </clr-checkbox-wrapper>
                              </clr-checkbox-container>
                            </div>
                            <div class="clr-col-3">
                              <clr-checkbox-container style="margin-top: 10px;">
                                <clr-checkbox-wrapper>
                                  <label>{{'SR lim' | mrTranslate}}</label>
                                  <input type="checkbox" clrCheckbox formControlName="anzeigeKAVDok" />
                                </clr-checkbox-wrapper>
                              </clr-checkbox-container>
                            </div>
                            <div class="clr-col-3">
                              <clr-checkbox-container style="margin-top: 10px;">
                                <clr-checkbox-wrapper>
                                  <label>{{'SR 100' | mrTranslate}}</label>
                                  <input type="checkbox" clrCheckbox formControlName="anzeigeKAVDok" />
                                </clr-checkbox-wrapper>
                              </clr-checkbox-container>
                            </div>
                            <div class="clr-col-3">
                              <div class="btn-group btn-sm btn-icon">
                                <button class="btn btn-success" (click)="addItemsMessTol()">
                                  <clr-icon shape="plus"></clr-icon>
                                  <span class="clr-icon-title">Neu</span>
                                </button>
                                <button class="btn btn-danger" [disabled]="selTolID == 0" (click)="deleteItemsMessTol()">
                                  <clr-icon shape="times"></clr-icon>
                                  <span class="clr-icon-title">Löschen</span>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="limit-height" style="margin-left: -350px; width: 830px;">
                            <clr-datagrid *ngIf="tolradius?.columns; else elseTemplate" style="padding-top: 0px" class="datagrid-compact" >
                              <clr-dg-column *ngFor=" let column of tolradius['columns']" [clrDgField]="column.id"
                                [hidden]="column.id | hideIDColumns:['typ','Profiltyp','SchHMax','BildBase64','Profil']">{{column.id | titlecase| mrTranslate}}</clr-dg-column>
                                <clr-dg-row *clrDgItems="let row of tolradius['rows']" [clrDgItem]="row">
                                  <clr-dg-cell class="name-cell" *ngFor="let cell of tolradius['columns']"
                                    [hidden]="cell.id | hideIDColumns:['typ','profiltyp', 'schhmax','bildbase64','profil']" [innerHTML]="row[cell.id] | tablePrettyPrint">
                                  </clr-dg-cell>
                                </clr-dg-row>
                                <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpenMessTol($event)">
                                  <clr-dg-detail>
                                    <clr-dg-detail-header>{{ detail['bezeichnung'] }}</clr-dg-detail-header>
                                    <clr-dg-detail-body>
                                      <form clrForm [formGroup]="tolrad">
                                        <div class="clr-row">
                                          <div class="clr-col-3">
                                            {{'Messart' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-3">
                                            <clr-select-container >
                                              <select class="bedingungen" clrSelect name="options" #mySelect (change)='onOptionsSelectedMessart(mySelect.value)' formControlName="messart">
                                                <option value="Spurweite">{{'Spurweite' | mrTranslate}}</option>
                                                <option value="Verwindung">{{'Verwindung' | mrTranslate}}</option>
                                                <option value="Rillentiefe">{{'Rillentiefe' | mrTranslate}}</option>
                                                <option value="Überhöhung">{{'Überhöhung' | mrTranslate}}</option>
                                              </select>
                                            </clr-select-container>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'Bedingung' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-3">
                                            <clr-select-container >
                                              <select class="bedingungen" clrSelect name="options" #mySelect (change)='onOptionsSelectedBedingung(mySelect.value)' formControlName="bed">
                                                <option value=""></option>
                                                <option value="Radius">{{'Radius' | mrTranslate}}</option>
                                              </select>
                                            </clr-select-container>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{('Bedingung' | mrTranslate) + " " + ('min' | mrTranslate)}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="bedmin"/>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{('Bedingung' | mrTranslate) + " " + ('max' | mrTranslate)}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="bedmax"/>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'Sollwert' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="soll"/>
                                          </div>
                                        </div>
                                       <!-- <br> -->
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'SRG -' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="srgm"/>
                                          </div>
                                        </div>
                                        <!-- <br> -->
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'SRG +' | mrTranslate}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="srgp"/>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'SRlim -'}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="srlm"/>
                                          </div>
                                        </div>
                                        <!-- <br> -->
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'SRlim +'}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="srlp"/>
                                          </div>
                                        </div>
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'SR100 -'}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="sr1m"/>
                                          </div>
                                        </div>
                                        <!-- <br> -->
                                        <div class="clr-row" style="margin-top: 15px;">
                                          <div class="clr-col-3">
                                            {{'SR100 +'}}
                                          </div>
                                          <div class="clr-col-3">
                                            <input type="number" class="clr-input" formControlName="sr1p"/>
                                          </div>
                                        </div>

                                        <!-- <br> -->
                                        <div class="clr-row">
                                          <div class="clr-col-3">
                                            <button class="btn btn-primary btn-sm" type="submit" (click)="saveItemsMessTol(detail.ID)">{{'Speichern' | mrTranslate}} </button>
                                          </div>
                                        </div>
                                      </form>
                                    </clr-dg-detail-body>
                                  </clr-dg-detail>
                                </ng-template>
                              <clr-dg-footer>
                                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{toleranzen['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                              </clr-dg-footer>
                            </clr-datagrid>
                            <ng-template #elseTemplate> <div style="height: 400px;"></div> </ng-template>
                          </div>
                        </clr-stack-content>
                      </clr-stack-block>
                    </clr-stack-block>
                  </clr-stack-view>

                  <div *ngIf="filelist.length > 0" class="d-f w-100-p margin-10-l margin-7-b">
                    <h5>{{'Dateien' | mrTranslate}}{{': ' + filelist.length}}</h5>
                  </div>

                  <div class="files-list">
                    <div class="single-file" *ngFor="let file of filelist; let i = index">
                        <div class="file-icon" style="width: 50px">
                          <clr-icon shape="cloud" badge="info" size="40"></clr-icon>
                        </div>

                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <clr-progress-bar [clrValue]="file?.progress" clrColor="success" clrLabeled></clr-progress-bar>
                      </div>
                      <div class="delete" (click)="deleteFile(i)">
                        <clr-icon shape="times-circle" solid="true"></clr-icon>
                        <a *ngIf="file.error" href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
                          <clr-icon shape="info-circle" size="22"></clr-icon>
                          <span class="tooltip-content">{{file.errortext}}</span>
                        </a>
                      </div>
                    </div>
                  </div>


                </clr-tab-content>
              </ng-template>
            </clr-tab>

            <clr-tab>
              <button clrTabLink (click)="tabselect('profil')">{{'Schienenprofil' | mrTranslate}}</button>
              <ng-template [(clrIfActive)]="profilesActive">
                <clr-tab-content>
                  <div class="clr-row" style="margin-top: 10px;">
                    <div class="clr-col-4">
                      <span>{{'Auswahl Messgerät' | mrTranslate}}:</span>
                    </div>

                    <div class="clr-col-2">
                      <clr-select-container class="messgeraet">
                        <!-- class="messgeraet" -->
                        <select clrSelect name="options" [(ngModel)]="selectedMessgeraet" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                          <option value="10">Vogel & Plötscher - EMA</option>
                          <option value="11">Linsinger - Fräsmaschiene</option>
                        </select>
                      </clr-select-container>
                    </div>
                  </div>
                  <hr/>
                  <ng-container *ngIf="messgeraettyp == 11 && downloadingFile == false">

                    <clr-stack-view style="margin-top: 10px;">
                      <clr-stack-block [clrSbExpanded]="true">
                        <clr-stack-label>{{ "Messdaten auswählen" | mrTranslate }}</clr-stack-label>

                        <clr-stack-block>
                          <clr-stack-label>
                          <ng-container *ngIf="haslinspfileleft == false">
                            <clr-icon shape="info-standard" class="is-error" size="22"></clr-icon>
                          </ng-container>
                          {{ "Profilmessung linke Schiene" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <button class="btn btn-sm"(click)="initUpload('left', 11)">{{ "Datei auswählen" | mrTranslate }}</button>
                          </clr-stack-content>
                        </clr-stack-block>


                        <clr-stack-block>
                          <clr-stack-label>
                            <ng-container *ngIf="haslinspfileright == false">
                              <clr-icon shape="info-standard" class="is-error" size="22"></clr-icon>
                            </ng-container>
                            {{ "Profilmessung rechte Schiene" | mrTranslate }}
                          </clr-stack-label>
                          <clr-stack-content>
                            <button class="btn btn-sm" (click)="initUpload('right', 11)">{{ "Datei auswählen" | mrTranslate }}</button>
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>
                            <ng-container *ngIf="haslinslwmfileleft == false">
                              <clr-icon shape="info-standard" class="is-error" size="22"></clr-icon>
                            </ng-container>
                            {{ "Längswelligkeit linke Schiene" | mrTranslate }}
                          </clr-stack-label>
                          <clr-stack-content>
                            <button class="btn btn-sm"(click)="initUpload('left', 12)">{{ "Datei auswählen" | mrTranslate }}</button>
                          </clr-stack-content>
                        </clr-stack-block>


                        <clr-stack-block>
                          <clr-stack-label>
                            <ng-container *ngIf="haslinslwmfileright == false">
                              <clr-icon shape="info-standard" class="is-error" size="22"></clr-icon>
                            </ng-container>
                            {{ "Längswelligkeit rechte Schiene" | mrTranslate }}
                          </clr-stack-label>
                          <clr-stack-content>
                            <button class="btn btn-sm" (click)="initUpload('right', 12)">{{ "Datei auswählen" | mrTranslate }}</button>
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>
                            <ng-container *ngIf="haslinsfilesleft == false">
                              <clr-icon shape="info-standard" class="is-error" size="22"></clr-icon>
                            </ng-container>
                            {{ "Ordner Messungen linke Schiene" | mrTranslate }}
                          </clr-stack-label>
                          <clr-stack-content>
                            <button class="btn btn-sm" (click)="initUploadFolder('left')">{{ "Ordner auswählen" | mrTranslate }}</button>
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>
                            <ng-container *ngIf="haslinsfilesright == false">
                              <clr-icon shape="info-standard" class="is-error" size="22"></clr-icon>
                            </ng-container>
                            {{ "Ordner Messungen rechte Schiene" | mrTranslate }}
                          </clr-stack-label>
                          <clr-stack-content>
                            <button class="btn btn-sm" (click)="initUploadFolder('right')">{{ "Ordner auswählen" | mrTranslate }}</button>
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>{{ "Messdatum" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <input type="date" name="date1" clrDate
                              [ngModel]="dateField | localeDate"
                              (ngModelChange)="dateField = localeDate.tosql($event)"
                              [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>{{ "Objekt auswählen" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <clr-select-container style="display: inline-block; margin-top: 10px;">
                              <select clrSelect #sel (change)="changedGleis(sel.value)" >
                                <option [value]="undefined" disabled selected>
                                  {{ "Objekt auswählen" | mrTranslate }}
                                </option>
                                <ng-container *ngIf="stammdatenauswahl">
                                  <option *ngFor="let gleis of stammdatenauswahl.rows" [value]="gleis.OSTAMMID">
                                    {{ gleis.Bezeichnung}}
                                  </option>
                                </ng-container>
                              </select>
                            </clr-select-container>
                          </clr-stack-content>
                        </clr-stack-block>

                        <clr-stack-block>
                          <clr-stack-label>{{ "Längeneinheit" | mrTranslate }}</clr-stack-label>
                          <clr-stack-content>
                            <clr-select-container style="display: inline-block; margin-top: 10px;">
                              <select clrSelect #sel1 (change)="changedEinheit(sel1.value)">
                                <option [value]="undefined" disabled selected>
                                  {{ "Einheit auswählen" | mrTranslate }}
                                </option>
                                <option value="m">m</option>
                                <option value="km">km</option>
                                <option value="mile">mile</option>
                                <option value="yard">yard</option>
                                <option value="foot">foot</option>
                              </select>
                            </clr-select-container>
                          </clr-stack-content>
                        </clr-stack-block>

                      </clr-stack-block>
                    </clr-stack-view>
                    <input id="folderInput" style="display:none" type="file" (change)="uploadFiles($event.target.files)" webkitdirectory multiple />
                    <input id="fileInput" style="display:none" type="file" (change)="onChange($event.target.files)">
                  </ng-container>




                  <div *ngIf="filelist.length > 0" class="d-f w-100-p margin-10-l margin-7-b">
                    <h5>{{'Dateien' | mrTranslate}}{{': ' + filelist.length}}</h5>
                  </div>

                  <div class="files-list">
                    <div class="single-file" *ngFor="let file of filelist; let i = index">
                        <div class="file-icon" style="width: 50px">
                          <clr-icon shape="cloud" badge="info" size="40"></clr-icon>
                        </div>

                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <clr-progress-bar [clrValue]="file?.progress" clrColor="success" clrLabeled></clr-progress-bar>
                      </div>
                      <div class="delete" (click)="deleteFile(i)">
                        <clr-icon shape="times-circle" solid="true"></clr-icon>
                        <a *ngIf="file.error" href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
                          <clr-icon shape="info-circle" size="22"></clr-icon>
                          <span class="tooltip-content">{{file.errortext}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </clr-tab-content>
              </ng-template>
            </clr-tab>

            <clr-tab>
              <button clrTabLink (click)="tabselect('power')">{{'Stellkraftmessung' | mrTranslate}}</button>
              <ng-template [(clrIfActive)]="powerActive">
                <clr-tab-content>
                  <div class="clr-row" style="margin-top: 10px;">
                    <div class="clr-col-4">
                      <span>{{'Auswahl Messgerät' | mrTranslate}}:</span>
                    </div>

                    <div class="clr-col-2">
                      <clr-select-container class="messgeraet">
                        <!-- class="messgeraet" -->
                        <select clrSelect name="options" [(ngModel)]="selectedMessgeraet" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                          <option value="12">Sylla Industrietechnik GmbH - safeTEC</option>
                          <option value="13">HANNING & KAHL - HZM</option>
                        </select>
                      </clr-select-container>
                    </div>
                  </div>

                  <clr-stack-view style="margin-top: 10px;" [formGroup]="setting">
                    <clr-stack-block [clrSbExpanded]="false">
                      <clr-stack-label>{{ "Einstellungen" | mrTranslate }}</clr-stack-label>
                        <clr-stack-block>
                          <clr-stack-content>

                          </clr-stack-content>
                        </clr-stack-block>
                    </clr-stack-block>
                  </clr-stack-view>

                  <hr/>
                  <div *ngIf="filelist.length > 0" class="d-f w-100-p margin-10-l margin-7-b">
                    <h5>{{'Dateien' | mrTranslate}}{{': ' + filelist.length}}</h5>
                  </div>

                  <div class="files-list">
                    <div class="single-file" *ngFor="let file of filelist; let i = index">
                        <div class="file-icon" style="width: 50px">
                          <clr-icon shape="cloud" badge="info" size="40"></clr-icon>
                        </div>

                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <clr-progress-bar [clrValue]="file?.progress" clrColor="success" clrLabeled></clr-progress-bar>
                      </div>
                      <div class="delete" (click)="deleteFile(i)">
                        <clr-icon shape="times-circle" solid="true"></clr-icon>
                        <a *ngIf="file.error" href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
                          <clr-icon shape="info-circle" size="22"></clr-icon>
                          <span class="tooltip-content">{{file.errortext}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </clr-tab-content>
              </ng-template>
            </clr-tab>

<!--
            <clr-tab>
              <button clrTabLink (click)="tabselect('corrugation')">{{'Längswelligkeit' | mrTranslate}}</button>
              <ng-template [(clrIfActive)]="riffelActive">
                <clr-tab-content>
                  <div class="clr-row" style="margin-top: 10px;">
                    <div class="clr-col-4">
                      <span>{{'Auswahl Messgerät' | mrTranslate}}:</span>
                    </div>

                    <div class="clr-col-2">
                      <clr-select-container class="messgeraet">
                        <select clrSelect name="options" [(ngModel)]="selectedMessgeraet" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                          <option value="10">Linsinger - Fräsmaschiene</option>
                        </select>
                      </clr-select-container>
                    </div>
                  </div>
                  <hr/>

                  <div *ngIf="filelist.length > 0" class="d-f w-100-p margin-10-l margin-7-b">
                    <h5>{{'Dateien ' | mrTranslate}}: {{filelist.length}}</h5>
                  </div>

                  <div class="files-list">
                    <div class="single-file" *ngFor="let file of filelist; let i = index">
                        <div class="file-icon" style="width: 50px">
                          <clr-icon shape="cloud" badge="info" size="40"></clr-icon>
                        </div>

                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <clr-progress-bar [clrValue]="file?.progress" clrColor="success" clrLabeled></clr-progress-bar>
                      </div>
                      <div class="delete" (click)="deleteFile(i)">
                        <clr-icon shape="times-circle" solid="true"></clr-icon>
                        <a *ngIf="file.error" href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
                          <clr-icon shape="info-circle" size="22"></clr-icon>
                          <span class="tooltip-content">{{file.errortext}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </clr-tab-content>
              </ng-template>
            </clr-tab>
           -->

          </clr-tabs>

        </div>
        <div class="clr-col-3">
          <div class="clr-row" style="height:400px;">

            <div class="upload-container"
                  [hidden]="messgeraettyp == 11"
                  [ngStyle]="{'background-color': backgroundcolor1, 'border-color': bordercolor }"
                  #uploadzone
                  dropzone
                  [class.hovering]="isHovering"
                  (dragleave)="onDragLeave()"
                  (hovered)="onDragOver()"
                  (dropped)="onDrop($event)"
                  (click)="onClick()">
              <p class="title" style="font-size: 20px;">{{'Hochladen' | mrTranslate}}</p>
              <p class="icon"><clr-icon shape="cloud" style="width: 72px; height: 72px;"></clr-icon></p>
              <p class="title" style="font-size: 13px;">{{'Klicken oder ziehen Sie die Datei in diesen Bereich' | mrTranslate}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="clr-col-9" style="padding-left: 5px;">

<!--
        <div *ngIf="filelist.length > 0" class="d-f w-100-p margin-10-l margin-7-b">
          <h5>{{'Dateien ' | mrTranslate}}: {{filelist.length}}</h5>
        </div>

        <div class="files-list">
          <div class="single-file" *ngFor="let file of filelist; let i = index">
              <div class="file-icon" style="width: 50px">
                <clr-icon shape="cloud" badge="info"></clr-icon>
              </div>

            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
              <clr-progress-bar [clrValue]="file?.progress" clrColor="success" clrLabeled></clr-progress-bar>
            </div>
            <div class="delete" (click)="deleteFile(i)">
              <clr-icon shape="times-circle" solid="true"></clr-icon>
              <a *ngIf="file.error" href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
                <clr-icon shape="info-circle" size="22"></clr-icon>
                <span class="tooltip-content">{{file.errortext}}</span>
              </a>
            </div>
          </div>
        </div>
 -->

      </div>
      <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}" style="display:none;"/>
    </form>

  </div>
  <div class="modal-footer">

    <ng-container *ngIf="uploadprocessrun == true">
      <clr-progress-bar [clrValue]="uploadprocessprogress" clrLabeled style="margin-top: 10px; max-width: 600px;"></clr-progress-bar>
    </ng-container>


    <ng-container *ngIf="downloadingFile" style="margin-top: 10px;">
      <ng-container *ngIf="connected != true">
        <clr-spinner clrInline style="margin-top: 5px;">uploading</clr-spinner>
      </ng-container>
      <span style="margin-top: 5px;">  {{spinnertitel}}   </span>
    </ng-container>
    <button [disabled]="filelist.length == 0" type="button" class="btn btn-primary" (click)="runUpload()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="close();">{{'Beenden' | mrTranslate}}</button>
    <!-- [disabled]="downloadingFile == true" -->
  </div>
</clr-modal>

