<header class="header-2">
  <div class="branding">
      <span class="title">{{'Spezifikation verwalten' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
    <!-- <clr-dropdown>
      <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
        <clr-icon shape="cog"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a (click)="addObjekt()" clrDropdownItem>{{'Neues Objekt erstellen' | mrTranslate}}</a>
        <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
        <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a>
      </clr-dropdown-menu>
    </clr-dropdown> -->
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon  shape="times" (click)="close()"></clr-icon >
    </a>
  </div>
</header>


<div class="overlay-inner-wrapper">
  <div id="objekttpyWrapper">
    <div class="clr-row">
      <div class="clr-col-3">
        <div *ngIf="UseKomponenten === true" class="content-area">
          <h3  class="margin-11-tb">{{ "Objekte / Komponenten" | mrTranslate }}</h3>
          <br/>
          <clr-tree>
            <clr-tree-node *clrRecursiveFor="let item of listTreeNote; getChildren: getChildren" [(clrExpanded)]="item.expanded">

              <button (click)="treeClick(item)" class="clr-treenode-link" routerLinkActive="active">
                <clr-icon class="customIcon" [attr.shape]="'objekttyp' + item.typ" size="28"></clr-icon>
                {{ item.name }}
              </button>

              <!-- <a class="clr-treenode-link" routerLinkActive="active" (click)='treeClick(item)' (dblclick)='treeDoubleClick(item)'>{{item.name}}</a> -->

            </clr-tree-node>
          </clr-tree>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>


      <div class="clr-col-9">
        <h3 class="margin-11-tb">{{ titel }}</h3>
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-success" [disabled]="!canadditem" (click)="newitem()">
            <clr-icon shape="file"></clr-icon>
            <span class="clr-icon-title">Add</span>
          </button>
          <button class="btn btn-outline" (click)="resort()">
            <clr-icon shape="sync"></clr-icon>
            <span class="clr-icon-title">Sort</span>
          </button>
          <button class="btn btn-danger" [disabled]="selid == 0" (click)="deletitem()">
            <clr-icon shape="times"></clr-icon>
            <span class="clr-icon-title">Delete</span>
          </button>
        </div>

        <div *ngIf="cansort" class="inline-block float-right">
          <clr-icon *ngIf="cansortuptabelle" size="24" shape="arrow" (click)="sortUptable()"
            class="cursor-pointer"></clr-icon>
          <clr-icon *ngIf="cansortdowntabelle" size="24" shape="arrow" (click)="sortDowntable()"
            class="cursor-pointer" style="transform: rotate(180deg);"></clr-icon>
        </div>


        <div class="limit-height" *ngIf="spezifikation?.rows?.length">
          <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [(clrDgSingleSelected)]="selobjekt" (clrDgSingleSelectedChange)="selectionObjektChanged($event)">
            <clr-dg-column>{{'Feldtyp' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-column>{{'Feldname' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-column>{{'Pflichtfeld' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-column>{{'Weteliste' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-column>{{'Sortierung' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-column>{{'Benutzer' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-column>{{'Zeitstempel' | mrTranslate | titlecase}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let item of spezifikation['rows']" [clrDgItem]="item" [clrDgSelected]="selobjekt">

              <clr-dg-cell style="color:#000">
                <clr-icon class="customIcon" [attr.shape]="'feldtyp' + item['Typ'] " size="22"></clr-icon>
              </clr-dg-cell>
              <clr-dg-cell [innerHTML]="item['KFELDNA'] | tablePrettyPrint" [title]="item['KFELDNA'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
              <clr-dg-cell [innerHTML]="item['PFLICHT'] | tablePrettyPrint" [title]="item['PFLICHT'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
              <clr-dg-cell [innerHTML]="item['USEWERT'] | tablePrettyPrint" [title]="item['USEWERT'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
              <clr-dg-cell> {{ item['KSORT'] | tablePrettyPrint | dotDotDot:30 }} </clr-dg-cell>
              <clr-dg-cell> {{ item['UserName'] | tablePrettyPrint | dotDotDot:30 }} </clr-dg-cell>
              <clr-dg-cell> {{ item['Status'] | tablePrettyPrint | dotDotDot:30 }} </clr-dg-cell>

            </clr-dg-row>
            <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpen($event)">

            <clr-dg-detail>
              <clr-dg-detail-header>{{ detail['KFELDNA'] }}</clr-dg-detail-header>
              <clr-dg-detail-body>
                <div class="clr-row">
                  <div class="clr-col-3">
                    {{'Feldtyp' | mrTranslate}}
                  </div>
                  <div class="clr-col-3">
                    <clr-icon class="customIcon" [attr.shape]="'feldtyp' + detail['Typ'] " size="22"></clr-icon>
                  </div>
                </div>
                <br/>
                <div class="clr-row">
                  <div class="clr-col-3">
                    {{'Feldname' | mrTranslate}}
                  </div>
                  <div class="clr-col-6">
                    <input type="text" id="basic" placeholder="Enter value here" #bez class="clr-input" value="{{detail['KFELDNA']}}" #feldname/>
                  </div>
                </div>
                <br/>
                <div class="clr-row">
                  <div class="clr-col-3">
                    {{'Pflichtfeld' | mrTranslate}}
                  </div>
                  <div class="clr-col-1">
                        <input type="checkbox" clrCheckbox [checked]="detail['PFLICHT'] == true" #pflicht [disabled]="!canUsePflichtfeld"/>
                  </div>
                </div>
                <br/>
                <div class="clr-row">
                  <div class="clr-col-3">
                    {{'zeige in Dokumentation' | mrTranslate}}
                  </div>
                  <div class="clr-col-1">
                        <input type="checkbox" clrCheckbox [checked]="detail['Export'] == true" #export [disabled]="!canUseExport"/>
                  </div>
                </div>
                <br/>
                <div class="clr-row">
                  <div class="clr-col-3">
                    {{'Werteliste verwenden' | mrTranslate}}
                  </div>
                  <div class="clr-col-1">
                    <input type="checkbox" clrCheckbox [checked]="detail['USEWERT'] == true" #usewert [disabled]="!canUseWerteliste" />
                  </div>
                  <div class="clr-col-2">
                    <div *ngIf="detail['USEWERT'] == true">
                        <clr-icon shape="note" size="22" (click)="openWerteliste()" class="cursor-pointer"></clr-icon>
                    </div>
                  </div>
                </div>
                <br/>

                <div class="clr-row" *ngIf="usewerteliste == true && showwertelisteSelect == true" style="margin-top: -15px;">
                  <div class="clr-col-3">
                    <div style="margin-top: 25px;">
                      {{'verfügbare Werteliste' | mrTranslate}}
                    </div>
                  </div>
                  <div class="clr-col-3">
                    <clr-combobox [(ngModel)]="selection" name="myState" required style="margin-left: -30px;" [disabled]="!canUseWerteliste">
                      <clr-options>
                        <clr-option *clrOptionItems="let item of wertelisteauswahl" [clrValue]="item.bezeichnung">
                          <clr-icon class="customIcon" [attr.shape]="'objekttyp' + item.typid" size="24"></clr-icon>
                          {{ item.bezeichnung}}
                        </clr-option>
                      </clr-options>
                    </clr-combobox>
                  </div>
                </div>
                <br/>
                <div class="clr-row">
                  <div class="clr-col-3">
                    <button class="btn btn-primary" type="submit" (click)="saveItems(detail.ID)" [disabled]="!cansavedetail">{{'Speichern' | mrTranslate}} </button>
                  </div>
                </div>
              </clr-dg-detail-body>
            </clr-dg-detail>
          </ng-template>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{spezifikation['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
        <br/>
        </div>
      </div>
  </div>
  <div class="content-container">
    <div id="content-area" class="content-area">
      <app-basedatamodal></app-basedatamodal>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="isnewvisible" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{modaltitel | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm>
      <div class="clr-row" style="margin-top: -10px;">
        <div class="clr-col-3">
          <div style="margin-top: 25px;">
            {{'Feldtyp' | mrTranslate}}
          </div>
        </div>
        <div class="clr-col-3">
          <select clrSelect name="options" [(ngModel)]="selectedfeldtyp" #feldtyp style="margin-left: -30px;" (change)="selectChanged(feldtyp.value)">
            <option value="Text">
              {{'Text' | mrTranslate}}
            </option>
            <option  value="Ganzzahl">
              {{'Ganzzahl' | mrTranslate}}
            </option>
            <option  value="Kommazahl">
              {{'Kommazahl' | mrTranslate}}
            </option>
            <option  value="Ja/Nein">
              {{'Ja/Nein' | mrTranslate}}
            </option>
            <option  value="Datum">
              {{'Datum' | mrTranslate}}
            </option>
        </select>
        </div>
      </div>

      <br/>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Feldname' | mrTranslate}}
        </div>
        <div class="clr-col-6">
          <input type="text" id="basic" placeholder="Enter value here" class="clr-input" #feldname/>
        </div>
      </div>
      <br/>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Pflichtfeld' | mrTranslate}}
        </div>
        <div class="clr-col-1">
              <input type="checkbox" clrCheckbox #pflicht [disabled]="!canUsePflichtfeld"/>
        </div>
      </div>
      <br/>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Werteliste verwenden' | mrTranslate}}
        </div>
        <div class="clr-col-1">
          <input type="checkbox" clrCheckbox #usewert (change)="fieldsChange($event)" [disabled]="!canUseWerteliste"/>
        </div>
      </div>
      <br/>
      <div class="clr-row" *ngIf="usewerteliste == true" style="margin-top: -15px;">
        <div class="clr-col-3">
          <div style="margin-top: 25px;">
            {{'verfügbare Werteliste' | mrTranslate}}
          </div>
        </div>
        <div class="clr-col-3">
          <clr-combobox [(ngModel)]="selection" name="myState" required style="margin-left: -30px;">
            <clr-options>
              <clr-option *clrOptionItems="let item of wertelisteauswahl" [clrValue]="item.bezeichnung">
                <clr-icon class="customIcon" [attr.shape]="'objekttyp' + item.typid" size="28"></clr-icon>
                {{ item.bezeichnung}}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </div>
      </div>

    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addItems()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isnewvisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
