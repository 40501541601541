import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm, NgModel, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClarityModule, ClrDatagrid, ClrForm } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { Subject, firstValueFrom } from 'rxjs';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { Table } from 'src/app/models/ui/table';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { MrUnitTextPipe } from 'src/app/pipes/mrUnitText.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { BasicStoreService } from 'src/app/services/BasicStore/basic-store.service';
import { VerwaltungsView, WartungsItem, WartungsMaske, WartungsMaskeItem, WartungsZuordnung } from '../wartung.types';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

type Objekt = { OSTAMMID: number };


@Component({
  selector: 'app-wartungsobjektzuordnung',
  templateUrl: './wartungsobjektzuordnung.component.html',
  styleUrls: ['./wartungsobjektzuordnung.component.scss'],
  imports: [ CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, MrUnitTextPipe, HideIDColumnsPipe, TablePrettyPrintPipe, BackendLocaleDatePipe, DynamicStyleDirective ],
  providers: [ BackendLocaleDatePipe, MrTranslatePipe ],
  standalone: true
})
export class WartungsObjektZuordnungComponent {
  @ViewChild('zuordnungsGrid', { read: ClrDatagrid, static: false })
    private zuordnungsGrid: ClrDatagrid<WartungsZuordnung>
  ;
  @ViewChild('dateModel', { read: NgModel, static: false })
    private dateModel: NgModel
  ;
  @ViewChild(ClrForm, { static: false })
    private clrForm: ClrForm
  ;

  @Output() view = new EventEmitter<VerwaltungsView>();
  protected alert: VerwaltungsView;

  @Input() refresh: Subject<void>;

  //#region objekttypen
  @Input() protected set otypid(value: number) {
    this._otypid = value;
    this.apiService
      .getWartungsItem('masken-names', this.otypid)
      .subscribe(({success, error, table: masken}: any) => {
        if (success) {
          this.maskenListe = masken.rows;
          if (this.maskenListe.length) this.loadPersonal();
          else this.alert = 'masken';
        }
        else
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });

    this.selectedObjekte = [];
    this.objekteTable = this.maskenItems = null;
  }
  protected get otypid(): number {
    return this._otypid;
  }
  private _otypid: number;
  //#endregion
  protected personalListe: WartungsItem[];
  protected maskenListe: WartungsMaske[];
  protected maskenItems: WartungsMaskeItem[];
  //#region objekte-Table
  protected objekteTable: Table<Objekt>;

  private _selectedObjekte: Objekt[] = [];
  protected get selectedObjekte(): Objekt[] {
    return this._selectedObjekte;
  }
  protected set selectedObjekte(value: Objekt[]) {
    this._selectedObjekte = value;
    this.setZuordnungTable();
    if (!value.length || this.selectedZuordnung) {
      this.resetZuordnungStatus();
    }
  }
  //#endregion
  //#region verknüpfungen
  protected zuordnungenTable: Table<WartungsZuordnung>;

  private _selectedZuordnung: WartungsZuordnung;
  protected get selectedZuordnung(): WartungsZuordnung {
    return this._selectedZuordnung;
  }
  protected set selectedZuordnung(value: WartungsZuordnung) {
    this._selectedZuordnung = value;
    if (!value) return;
    this.insertItem = false;
    this.zuordnungFormGroup.enable();
    this.zuordnungFormGroup.patchValue(value);
  }

  protected zuordnungFormGroup = new UntypedFormGroup({
    mID: new UntypedFormControl('', [Validators.required]),
    intervall: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
    einheit: new UntypedFormControl('Tag'),
    pID: new UntypedFormControl('', [Validators.required]),
    intervallbeginn: new UntypedFormControl('', [Validators.required]), /* get; set; as dateControl*/
  });

  protected insertItem: boolean = false;
  //#endregion
  //#region load()
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    protected localeDate: BackendLocaleDatePipe,
    private store: BasicStoreService
  ) {}

  private loadPersonal() {
    this.apiService
      .getWartungsItem('personal', this.otypid)
      .subscribe(({ success, error, table: personal }: any) => {
        if (success) {
          this.personalListe = personal.rows;
          if (this.personalListe.length) this.loadObjekte();
          else this.alert = 'personal';
        }
        else
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }

  private loadObjekte() {
    this.apiService
      .getWartungsObjekte(this.otypid)
      .subscribe(({ success, error, table }: any) => {
        if (success) {
          if (this.objekteTable?.columns?.length)
            this.objekteTable.rows = table.rows;
          else this.objekteTable = table;
        }
        else
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
    this.alert = null;
  }

  private async setZuordnungTable() {
    const result = this.selectedObjekte.length == 1
      ? await firstValueFrom(this.apiService.getWartungsZuordnungen(this.selectedObjekte[0].OSTAMMID))
      : null;
    this.zuordnungenTable = result?.table;
    if (result?.error) this.toastr.error(result.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
  }
  //#endregion
  //#region verknüpfungen()
  protected addItem() {
    this.insertItem = true;
    this.zuordnungsGrid?.selection.clearSelection();
    this.zuordnungFormGroup.enable();
    this.dateModel?.reset();
    this.zuordnungFormGroup.reset({ einheit: 'Tag', intervallbeginn: BackendLocaleDatePipe.now });
  }

  protected saveIfValid() {
    this.clrForm.markAsTouched();
    if (this.zuordnungFormGroup.valid) this.saveItem();
  }

  private saveItem() {
    const sendObj = {
      ID: this.selectedZuordnung?.ID,
      objekte: this.selectedObjekte.map(obj => obj.OSTAMMID),
      ...this.zuordnungFormGroup.value,
    };

    this.apiService
      .setWartungsZuordnungen(sendObj)
      .subscribe(({ success, error, neuRow }: any) => {
        if (success) {
          this.refresh?.next();
          this.store.deleteComponentStoreAsync('wartung');
          this.toastr.success(this.mrTranslate.transform('Daten gespeichert'));
          if (this.selectedObjekte.length == 1) {
            const neuItem: WartungsZuordnung = {
              ...neuRow,
              ...this.zuordnungFormGroup.value,
              maske: this.maskenListe.find(maske => maske.ID == sendObj.mID).Name,
              verantwortlich: this.personalListe.find(pers => pers.ID == sendObj.pID).Bezeichnung,
            }
            if (this.insertItem)
              this.zuordnungenTable.rows.unshift(neuItem);
            else
              this.zuordnungenTable.rows.splice(
                this.zuordnungenTable.rows.indexOf(this.selectedZuordnung),
                1, neuItem
              );
            this.zuordnungsGrid.singleSelected = neuItem;
          } else this.resetZuordnungStatus();
        } else {
          this.toastr.error(
            error, this.mrTranslate.transform('Etwas ist schief gelaufen')
          );
        }
      });
  }

  protected deleteItem() {
    const text = this.mrTranslate.transform('Eintrag wirklich löschen?');
    if (confirm(text)) {
      this.apiService
        .deleteWartungsItem('zuordnung', this.selectedZuordnung.ID)
        .subscribe(({ success, error }: any) => {
          if (success) {
            this.refresh?.next();
            this.store.deleteComponentStoreAsync('wartung');
            this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
            this.zuordnungenTable.rows.splice(this.zuordnungenTable.rows.indexOf(this.selectedZuordnung), 1);
            this.resetZuordnungStatus();
          } else {
            this.toastr.error(
              error, this.mrTranslate.transform('Fehler beim Löschen')
            );
          }
        });
    }
  }
  //#endregion
  //#region maske()
  protected showMaskeDetail(id: number) {
    this.apiService
      .getWartungsMaskeList(id, this.otypid)
      .subscribe(({ success, error, list: arten }: any) => {
        if (success) {
          this.maskenItems = arten;
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }

  protected useMaskeInForm(id: number) {
    this.zuordnungFormGroup.get('mID').setValue(id);
  }
  //#endregion
  //#region helpers()
  private resetZuordnungStatus() {
    this.zuordnungsGrid?.selection.clearSelection();
    this.zuordnungFormGroup.disable();
    this.zuordnungFormGroup.reset();
    this.dateModel?.reset();
    this.insertItem = false;
  }

  protected get dateControl(): string {
    return this.zuordnungFormGroup.get('intervallbeginn').value;
  }
  protected set dateControl(value: string) {
    this.zuordnungFormGroup.get('intervallbeginn').setValue(value);
  }
  //#endregion
}
