<div class="overlay-inner-wrapper">
  <div id="auftragDetailWrapper">
    <div class="clr-row nxm">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0">{{ "Auftrag bearbeiten" | mrTranslate }}</h3>
        </div>
        <div class="inline-block" style="float: right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
        <div class="inline-block float-right" style="padding-right: 2%">
          <button class="btn btn-danger-outline btn-sm" [disabled]="trial || auftrag?.Auftragsstatus != 0"
            (click)="deleteAuftrag()" style="margin-top: 0px; margin-bottom: 0px">
            {{ "Auftrag löschen" | mrTranslate }}
          </button>
        </div>
      </div>
    </div>
    <clr-tabs>
      <clr-tab>
        <button #auftragTabButton clrTabLink (click)="tabChange()">
          {{ "Auftrag" | mrTranslate }} {{ auftragId | leftPadd:5 }}
        </button>
        <ng-template [(clrIfActive)]="activeTab.auftrag">
          <clr-tab-content>
            <form clrForm [formGroup]="auftragFormGroup" (ngSubmit)="saveAuftrag()"
              [clrLabelSize]="4" class="width100" #heightRef>
              <div class="clr-row nxm padding-10-tb">
                <div class="clr-col-lg clr-col-12">
                  <div class="clr-row">
                    <div class="clr-col-sm">
                      <clr-datalist-container>
                        <label class="clr-col-12 clr-col-md-4">{{
                          "Titel" | mrTranslate
                          }}</label>
                        <input clrDatalistInput id="titel" list="titellist" placeholder="" name="Option"
                          formControlName="titel" class="clr-col-12 clr-col-md-8" />
                        <datalist id="titellist">
                          <option *ngFor="let c of titel" [value]="c"></option>
                        </datalist>
                      </clr-datalist-container>
                      <clr-select-container>
                        <label>{{ "Firma" | mrTranslate }}</label>
                        <select clrSelect formControlName="firma">
                          <option [value]="auftragFormGroup.get('firma').value">
                            {{ auftragFormGroup.get("firma").value }}
                          </option>
                        </select>
                      </clr-select-container>
                      <clr-date-container>
                        <label>{{ "Termin-Start" | mrTranslate }}</label>
                        <input type="date" clrDate name="termin" 
                          [ngModel]="auftragFormGroup.get('termin').value | localeDate"
                          (ngModelChange)="auftragFormGroup.get('termin').setValue(localeDate.tosql($event))"
                          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                          [disabled]="auftragFormGroup.disabled" required />
                      </clr-date-container>
                      <clr-date-container>
                        <label>{{ "Termin-Ende" | mrTranslate }}</label>
                        <input type="date" clrDate name="terminEnde" 
                          [ngModel]="auftragFormGroup.get('terminEnde').value | localeDate"
                          (ngModelChange)="auftragFormGroup.get('terminEnde').setValue(localeDate.tosql($event))"
                          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                          [disabled]="auftragFormGroup.disabled" required />
                      </clr-date-container>
                      <clr-select-container>
                        <label>{{ "Auftragsgruppe" | mrTranslate }}</label>
                        <select clrSelect id="auftragsgruppe" formControlName="gruppenid">
                          <option [value]="
                              getAuftragsgruppeName(
                                auftragFormGroup.get('gruppenid').value
                              )
                            " selected>
                            Auftragsgruppe auswählen
                          </option>
                          <option *ngFor="let groups of auftragsgroups" [value]="groups.id">
                            {{ groups.mBezeichnung }}
                          </option>
                        </select>
                      </clr-select-container>
                    </div>
                    <div class="clr-col-sm-1"></div>
                    <div class="clr-col-sm">
                      <clr-datalist-container>
                        <label class="clr-col-12 clr-col-md-4">{{
                          "Auftragsart" | mrTranslate
                          }}</label>
                        <input clrDatalistInput id="auftragsart" list="auftragsartlist" placeholder="" name="Option"
                          formControlName="auftragsart" class="clr-col-12 clr-col-md-8" />
                        <datalist id="auftragsartlist">
                          <option *ngFor="let c of auftragsarten" [value]="c"></option>
                        </datalist>
                      </clr-datalist-container>
                      <clr-input-container>
                        <label>{{ "Auftragsnummer" | mrTranslate }}</label>
                        <input clrInput formControlName="auftragsnummer" />
                      </clr-input-container>
                      <clr-datalist-container>
                        <label class="clr-col-12 clr-col-md-4">{{
                          "Kostenart" | mrTranslate
                          }}</label>
                        <input clrDatalistInput id="kostenart" list="kostenartlist" placeholder="" name="Option"
                          formControlName="kostenart" class="clr-col-12 clr-col-md-8" />
                        <datalist id="kostenartlist">
                          <option *ngFor="let c of kostenart" [value]="c"></option>
                        </datalist>
                      </clr-datalist-container>
                      <clr-textarea-container>
                        <label>{{ "Bemerkung" | mrTranslate }}</label>
                        <textarea clrTextarea formControlName="bemerkung" name="bemerkung" rows="3"></textarea>
                      </clr-textarea-container>
                    </div>
                  </div>
                </div>
                <div class="clr-col-lg-2 clr-col-12 margin-24-t" style="flex-grow: 0; align-self: end">
                  <button class="btn btn-primary btn-sm" [disabled]="trial" type="submit">
                    {{ "Auftrag speichern" | mrTranslate }}
                  </button>
                </div>
              </div>
            </form>
            <div style="min-height: 250px;" [dymanicStyle]="heightRef">
              <div class="clr-row" style="height: 45%;">
                <div class="clr-col h-100">
                  <clr-datagrid #objDg *ngIf="objTable.rows != null" class="datagrid-compact auftragDetailObjectTable">
                    <clr-dg-column *ngFor="let column of objTable['columns']"
                      [style.width.px]="column.id == 'status' ? 100 : column.id.length * 7.5 + 26"
                      [clrDgField]="column.id">
                      <ng-container *clrDgHideableColumn="{
                          hidden:
                            column.id
                            | hideIDColumns : ['kauftsid', 'auftragid', 'bezirk', 'stellwerk']
                        }">{{ column.id | titlecase | mrTranslate }}
                      </ng-container>
                    </clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of objTable['rows']" [clrDgItem]="row">
                      <!-- <clr-dg-action-overflow>
                          <button class="action-item" (click)="ostammid = row.OSTAMMID">Objekt öffnen</button>
                      </clr-dg-action-overflow> -->
                      <ng-container *ngFor="let cell of objTable['columns']">
                        <ng-container *ngIf="cell.id != 'typ'; else iconcell">
                          <clr-dg-cell [innerHTML]="
                              row[cell.id] | dotDotDot : 30 | tablePrettyPrint
                            " [style.width.px]="cell.id.length * 7.5 + 26" [title]="row[cell.id] | tablePrettyPrint"
                            class="ellipsis">
                          </clr-dg-cell>
                        </ng-container>
                        <ng-template #iconcell>
                          <clr-dg-cell style="color: #000" [style.width.px]="2 * 7.5 + 26">
                            <clr-icon class="customIcon" [attr.shape]="'objekttyp' + row['typ']" size="28">
                            </clr-icon>
                          </clr-dg-cell>
                        </ng-template>
                      </ng-container>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" #pagination>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
              </div>
              <div class="clr-row" style="height: 55%; ">
                <div class="clr-col h-100">
                  <clr-datagrid #mnglDg *ngIf="mangelTable?.rows?.length"
                    class="datagrid-compact auftragDetailMangelTable" id="maengelTable">
                    <clr-dg-column [style.width.px]="72">{{
                      "GIS Map" | mrTranslate
                      }}</clr-dg-column>
                    <clr-dg-column [style.width.px]="55">{{
                      "Bilder" | mrTranslate
                      }}</clr-dg-column>
                    <clr-dg-column *ngFor="let column of mangelTable['columns']"
                      [style.width.px]="column.id == 'status' ? 100 : column.id.length * 7.5 + 26">
                      <ng-container *clrDgHideableColumn="{
                          hidden:
                            column.id
                            | hideIDColumns
                              : ['kauftmid', 'auftragid', 'bezirk', 'stellwerk', 'bild', 'rueckbilder']
                        }">{{ column.id | titlecase | mrTranslate }}
                      </ng-container>
                    </clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of mangelTable['rows']">
                      <clr-dg-cell [style.width.px]="72">
                        <ng-container *ngIf="
                            getObjectGeometry(row) !== null;
                            else elseTemplate
                          ">
                          <clr-tooltip>
                            <a href="javascript:void(0)" (click)="previewMap(row)">
                              <clr-icon size="20" shape="search"></clr-icon>
                            </a>
                          </clr-tooltip>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <clr-icon size="20" shape="search" disabled></clr-icon>
                        </ng-template>
                      </clr-dg-cell>
                      <clr-dg-cell [style.width.px]="55">
                        <a href="javascript:void(0);" (click)="mangelMedia = media.openMangelMedia(row.Bild, row.rueckbilder)"
                          [class.deactivate]="!row.Bild && !row.rueckbilder">
                          <clr-icon shape="image-gallery" size="20"></clr-icon>
                        </a>
                      </clr-dg-cell>
                      <clr-dg-cell *ngFor="let cell of mangelTable['columns']" [innerHTML]="
                          row[cell.id] | dotDotDot : 30 | tablePrettyPrint
                        " [title]="row[cell.id] | tablePrettyPrint" [style.width.px]="cell.id.length * 7.5 + 26"
                        class="ellipsis">
                      </clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="20">
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
              </div>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [disabled]="!showRueckTab">
          {{ "Rückmeldung" | mrTranslate }}
        </button>
        <!-- <ng-template [(clrIfActive)]="activeTab.rueckmeldung"> -->
          <clr-tab-content>
            <form clrForm [formGroup]="rueckmeldungForm" [clrLabelSize]="4" class="width100">
              <ng-template [(clrIfActive)]="activeTab.rueckmeldung">
                <div class="clr-row nxm">
                  <div class="clr-col-sm">
                    <clr-date-container>
                      <!--  style="margin-top: 0.6em" -->
                      <label>{{ "Datum" | mrTranslate }}</label>
                      <input type="date" clrDate name="datum"
                        [ngModel]="rueckmeldungForm.get('datum').value | localeDate"
                        (ngModelChange)="rueckmeldungForm.get('datum').setValue(localeDate.tosql($event))"
                        [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                        [disabled]="rueckmeldungForm.disabled" required/>
                    </clr-date-container>
                    <clr-datalist-container>
                      <label class="clr-col-12 clr-col-md-4">{{
                        "Verantwortlich" | mrTranslate
                        }}</label>
                      <input clrDatalistInput id="verantwortlichmangel" list="verantwortlichlist" placeholder=""
                        name="Option" formControlName="verantwortlich" class="clr-col-12 clr-col-md-8" />
                      <datalist id="verantwortlichlist">
                        <option *ngFor="let c of verantwortlicher" [value]="c"></option>
                      </datalist>
                    </clr-datalist-container>
                    <clr-textarea-container>
                      <label>{{ "Rückmeldetext" | mrTranslate }}</label>
                      <textarea rows="3" clrTextarea formControlName="rueckmeldetext" name="description"></textarea>
                    </clr-textarea-container>
                  </div>
                  <div class="clr-col-sm-1"></div>
                  <div class="clr-col-sm">
                    <clr-datalist-container>
                      <label class="clr-col-12 clr-col-md-4">{{
                        "Kostenart" | mrTranslate
                        }}</label>
                      <input clrDatalistInput id="kostenartmangel" list="kostenartlist" placeholder="" name="Option"
                        formControlName="kostenart" class="clr-col-12 clr-col-md-8" />
                      <datalist id="kostenartmangellist">
                        <option *ngFor="let c of kostenartmangel" [value]="c"></option>
                      </datalist>
                    </clr-datalist-container>
                    <clr-textarea-container>
                      <label>{{ "Teilerneuerung" | mrTranslate }}</label>
                      <textarea rows="2" clrTextarea formControlName="teilerneuerung" name="description"></textarea>
                    </clr-textarea-container>
                    <clr-textarea-container>
                      <label>{{ "Bemerkung" | mrTranslate }}</label>
                      <textarea rows="2" clrTextarea formControlName="bemerkung" name="description"></textarea>
                    </clr-textarea-container>
                  </div>
                </div>
              </ng-template>

              <div class="w-100-p margin--12-l">
                <app-preview-thumbnails
                  [drag_drop]="true"
                  [readonly]="auftrag?.Auftragsstatus != 0"
                  [getBigFileFnc]="media.getApiFunctionMediaBig">
                </app-preview-thumbnails>
              </div>
            </form>
            <ng-template [(clrIfActive)]="activeTab.rueckmeldung">
              <div class="clr-row nxm nxp">
                <div class="clr-col" style="
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-end;
                  ">
                  <button type="button" class="btn btn-primary" style="flex-shrink: 0" [disabled]="trial || auftrag?.Auftragsstatus != 0"
                    (click)="sendRueckmeldung()">
                    {{ mangelTablrueck?.rows?.length }}
                    {{ "Mängel rückmelden" | mrTranslate }}
                  </button>
                </div>
              </div>
              <div class="clr-row nxm">
                <div class="clr-col h-100">
                  <clr-datagrid #mnglRueckDg *ngIf="mangelTablrueck.rows != null"
                    class="datagrid-compact auftragDetailMangelTable" id="mangelTablrueck">
                    <clr-dg-column *ngFor="let column of mangelTable['columns']"
                      [style.width.px]="column.id == 'status' ? 100 : column.id.length * 7.5 + 26">
                      <ng-container *clrDgHideableColumn="{
                          hidden:
                            column.id
                            | hideIDColumns
                              : ['kauftmid', 'auftragid', 'bezirk', 'stellwerk', 'bild']
                        }">{{ column.id | titlecase | mrTranslate }}
                      </ng-container>
                    </clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of mangelTablrueck['rows']">
                      <clr-dg-action-overflow *ngIf="auftrag?.Auftragsstatus == 0">
                        <button class="action-item" (click)="deleteMangelAuftrag(row)">
                          {{ "Mangel aus Auftrag löschen" | mrTranslate }}
                        </button>
                      </clr-dg-action-overflow>
                      <clr-dg-cell *ngFor="let cell of mangelTablrueck['columns']"
                        [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                        [title]="row[cell.id] | tablePrettyPrint" class="ellipsis"
                        [style.width.px]="cell.id.length * 7.5 + 26">
                      </clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" #pagination>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
              </div>
            </ng-template>
          </clr-tab-content>
        <!-- </ng-template> -->
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [disabled]="auftrag?.Auftragsstatus == 0 || !firmenId">
          {{ "Abnahme nach Umbau" | mrTranslate }}
        </button>
        <ng-template [(clrIfActive)]="activeTab.abnahme">
          <clr-tab-content>
            <div class="clr-row">
              <div class="clr-col" style="
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: flex-end;
                ">
                <button type="button" class="btn btn-primary" [disabled]="trial" (click)="openAbnahmeProtokoll()">
                  {{ "neues Protokoll erstellen" | mrTranslate }}
                </button>
              </div>
            </div>
            <!-- clr-datagrid für bestehende AbnahmeProtokolls -->
            <div class="clr-row" style="height: calc(100% - 48px)">
              <div class="clr-col h-100">
                <clr-datagrid #abnahmeDg *ngIf="abnahmeTable.rows?.length" class="datagrid-compact"
                  style="height: auto;">
                  <clr-dg-column *ngFor="let column of abnahmeTable['columns']" [clrDgField]="column.id"
                    [style.width.px]="column.id == 'status' ? 100 : column.id.length * 7.5 + 26">
                    <ng-container *clrDgHideableColumn="{
                        hidden:
                          column.id
                          | hideIDColumns
                            : ['abnahmeid', 'auftragid', 'abteilung']
                      }">{{ column.id | titlecase | mrTranslate }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of abnahmeTable['rows']">
                    <clr-dg-action-overflow>
                      <button class="action-item" (click)="openAbnahmeProtokoll(row)">
                        {{ "Bearbeiten" | mrTranslate }}
                      </button>
                    </clr-dg-action-overflow>
                    <clr-dg-cell *ngFor="let cell of abnahmeTable['columns']"
                      [innerHTML]=" row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                      [title]="row[cell.id] | tablePrettyPrint" class="ellipsis"
                      [style.width.px]="cell.id.length * 7.5 + 26">
                    </clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination class="dg-pagination" #pagination>
                      {{ pagination.firstItem + 1 }} -
                      {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                      {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </div>
            </div>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange()">
          {{ "Kosten" | mrTranslate }}
        </button>
        <ng-template [(clrIfActive)]="activeTab.kosten">
          <clr-tab-content>
            <app-kostenerfassung [kauftrid]="auftragId" [refresh]="tabRefresh" [auftrag]="auftrag"
              (refreshGesamtkosten)="kostenChanged = true"></app-kostenerfassung>
          </clr-tab-content>
        </ng-template>
      </clr-tab>
    </clr-tabs>
  </div>
</div>

<clr-modal [(clrModalOpen)]="confirmDeletMangelAuftragVisible" [clrModalSize]="'md'" [clrModalClosable]="true">
  <h3 class="modal-title">Löschen bestätigen?</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <p>
        Sind sie sicher, dass sie den Mangel aus dem Auftrag löschen möchten
      </p>
      <div>
        <br />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="dodeleteMangelAuftrag()">
      OK
    </button>
    <button type="button" class="btn" (click)="confirmDeletMangelAuftragVisible = false">
      Abbrechen
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showGIS" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'GIS Map' | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm>
      <div style="width:854px;height:580px">
        <div id="previewMap" style="width:100%; height:100%;z-index: 2;"></div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showGIS = false;">
      {{"Schließen" | mrTranslate}}</button>
  </div>
</clr-modal>

<app-mediaviewmodal *ngIf="mangelMedia"
  (onClose)="mangelMedia = undefined"
  [srcs]="mangelMedia"
  [getMediaFromServerAll]="media.getApiFunctionMedia"
  [getBigFileFnc]="media.getApiFunctionMediaBig">
</app-mediaviewmodal>
