import { Pipe, PipeTransform } from '@angular/core';
import { ZählerName, ZählerWert } from './zaehler.types';

@Pipe({
  name: 'filterTolByOptions',
  standalone: true
})
export class FilterTolByNameOptionsPipe implements PipeTransform {
  transform(wertColumns: { id: keyof ZählerWert }[], options: ZählerName): { id: keyof ZählerWert }[] {
    return wertColumns?.filter(col => {
      const colName = col.id;
      if (!options.SR100 && colName.toLowerCase().includes('sr100'))
        return false;
      if (!options.SRG && colName.toLowerCase().includes('srg'))
        return false;
      else
        return true;
    })
  }
}