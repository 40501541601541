<div class="login-wrapper">
  <div class="login" >
    <form clrForm [formGroup]="loginGroup">
      <section class="title">
        <h3 class="welcome">{{ "Willkommen bei" | mrTranslate }}</h3>
        <img src="assets/mrprocloudlogo{{(!isDarkThemeAktiv ? '-dark' : '')}}.svg" class="mw-100-p" style="max-height: 180px;">
      </section>
      <div class="login-group">
        <clr-input-container class="w100">
          <label>{{ "Email" | mrTranslate }}</label>
          <input clrInput type="email" formControlName="email" data-test-id="login-user-email" /><br />
          <clr-control-error>{{ "Geben Sie eine gültige Email Adresse ein" | mrTranslate }}</clr-control-error>
        </clr-input-container>
        <clr-input-container class="w100">
          <label>{{ "Passwort" | mrTranslate }}</label>
          <input clrInput type="password" formControlName="password" data-test-id="login-user-password" /><br />
          <clr-control-error>{{ "Bitte geben Sie ein Passwort ein" | mrTranslate }}</clr-control-error>
        </clr-input-container>
        <button class="btn btn-primary" (click)="doLogin($event)">{{ "Anmelden" | mrTranslate }}</button>
      </div>
      <div>
        <br>
      </div>
    </form>
    <div>
      <button class="button" (click)="isforgotPWModalVisible = true" data-test-id="login-user-btn">{{ "Passwort vergessen ?" | mrTranslate }}</button>
    </div>
    <div *ngIf="showOktaLogin">
      <button id="login-button" data-test-id="login-okta-btn" class="ui primary button" role="button"
        (click)="oktaLogin()">Okta Anmeldung</button>
    </div>
    <div *ngIf="showMsalLogin">
      <button id="login-button" data-test-id="login-okta-btn" class="ui primary button" role="button"
        (click)="msalLogin()">Anmelden mit Azure AD</button>
    </div>

  </div>
</div>

<clr-modal *ngIf="isforgotPWModalVisible" [(clrModalOpen)]="isforgotPWModalVisible" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Passwort vergessen ?" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" style="margin-top:-25px">
      <p>{{ "Bitte geben Sie ihre Email unter ein und sie erhalten eine Bestätigungscode per Email mit dem Sie ihr Passwort zurücksetzten können." | mrTranslate }}</p>
      <clr-input-container>
        <label class="clr-col-md-1">{{ "Email" | mrTranslate }}</label>
        <input style="width: 400px;" clrInput id="exforgotemail" type="email" #exmail /><br />
      </clr-input-container>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getMail($event)">{{ "Senden" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="isforgotPWModalVisible = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>


<clr-modal *ngIf="isTenentMailVisible" [(clrModalOpen)]="isTenentMailVisible" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Konfiguration für SSO fehlt" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" style="margin-top:-25px">
      <p>{{ "Bitte geben Sie ihre Email unter ein." | mrTranslate }}</p>
      <clr-input-container>
        <label class="clr-col-md-1">{{ "Email" | mrTranslate }}</label>
        <input style="width: 400px;" clrInput id="exforgotemail" type="email" #exmail /><br />
      </clr-input-container>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getMSALMail()">{{ "Senden" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="isTenentMailVisible = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>



<clr-modal *ngIf="isCodeModalVisible" [(clrModalOpen)]="isCodeModalVisible" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Bestätigungscode eingeben" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" style="margin-top:-25px">
      <p>{{ "Bitte geben Sie ihren Bestätigungscode ein." | mrTranslate }}</p>
      <clr-input-container>
        <label class="clr-col-md-1">{{ "Code" | mrTranslate }}</label>
        <input clrInput style="width: 200px;" id="excode" type="text" #excode /><br />
      </clr-input-container>
      <br/>
      <br/>
      <div class="progress-block">
        <clr-progress-bar style="height: 5px;" id="progressbar" mode="determinate" [clrValue]="progressbarValue"></clr-progress-bar>
      </div>
      <div>
        <span>{{dateSec}}</span>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="getValidCode($event)">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="isCodeModalVisible = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>

<clr-modal *ngIf="isNewPasswordVisible" [(clrModalOpen)]="isNewPasswordVisible" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Neues Passwort erstellen" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" style="margin-top:-25px">
      <clr-input-container>
        <label class="clr-col-md-5">{{ "neues Passwort" | mrTranslate }}</label>
        <input clrInput style="width: 400px;" id="exnewpw" type="password" #exnewpw /><br />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-col-md-5">{{ "Passwort bestätigen" | mrTranslate }}</label>
        <input clrInput style="width: 400px;"  id="exnewpwconf" type="password" #exnewpwconf /><br />
      </clr-input-container>
      <div>
        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="setNewPassword($event)">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="isNewPasswordVisible = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>


<clr-modal *ngIf="isRSVDatabaseSelectVisible" [(clrModalOpen)]="isRSVDatabaseSelectVisible" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">Kundendatenbank auswählen</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <div class="clr-row">
        <div class="clr-col-12">
          <div class="comboboxcontainer">
            <div class="select">
              <clr-select-container id="auftragnehmerWrapper">
                <select id="auftragnehmer" clrSelect #seldb style="width: 300px">
                  <option [value]="null" disabled selected>Kundendatenbank auswählen</option>
                  <option *ngFor="let db of dbs" [value]="db.name">{{ db.kunde }}</option>
                </select>
              </clr-select-container>
            </div>
          </div>
        </div>
      </div>
      <div>
        <br>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="doLoginIntern($event)">OK</button>
    <button type="button" class="btn" (click)="isRSVDatabaseSelectVisible = false">Abbrechen</button>
  </div>
</clr-modal>


<clr-modal *ngIf="is2FA" [(clrModalOpen)]="is2FA" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Anmeldung bestätigen" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" style="margin-top:-25px">

      <div *ngIf="timer2FAfinished == false; else loggedOut">
        <p>{{ "Bitte geben Sie ihren SMS-Bestätigungscode ein." | mrTranslate }}</p>
        <br/>
        <div class="clr-input-wrapper">
          <input clrinput="" placeholder="SMS-Code" id="excode" type="text" style="width: 350px;" #excode class="clr-input">
        </div>
        <br/>
        <br/>
      </div>

      <ng-template #loggedOut>
        <p style="background-color:Tomato; padding: 15px;">{{ "Die Zeit für die Eingabe eines Bestätigungscodes ist abgelaufen. Bitte fordern Sie eine neuen Code per SMS an." | mrTranslate }}</p>
        <br/>
        <br/>
      </ng-template>

      <div class="clr-row">
        <div class="clr-col-6">
          <span>{{ "Keinen Code erhalten?" | mrTranslate }}</span>
        </div>
        <div class="clr-col-6">
          <a href="javascript://" (click)="do2FAsendSMS()" class="signup">{{ "Nochmals senden" | mrTranslate }}</a>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="do2FA($event)" [disabled]="timer2FAfinished == true">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="is2FA = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>


<clr-modal *ngIf="googleSetupShow" [(clrModalOpen)]="googleSetupShow" [clrModalSize]="'lg'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "2‑Faktor-Authentifizierung mit Google Authenticator" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal">
      <strong> {{'1. Schritt' | mrTranslate}} </strong>
      <div>{{ "Um die Google Autenthicator App zu nutzen, müssen Sie den folgenden QR-Code scannen:" | mrTranslate }}</div>
      <br/>
      <img [src]="googleqrimage" style="display: block; margin-left: auto; margin-right: auto; width: 40%;">
      <br/>
      <div>{{ "Alternativ können sie folgenden Code in die Google Autenthicator App eingeben:" | mrTranslate }}</div>
      <div style="font-size: 10px;">{{ googleentrykey }}</div>
      <br/>
      <br/>
      <strong> {{'2. Schritt' | mrTranslate}} </strong>
      <div>{{ "Geben Sie den 6-stelligen Code aus der Autenthicator App ein." | mrTranslate }}</div>
      <div class="clr-input-wrapper">
        <input clrinput="" placeholder="Code" id="excode" type="text" style="width: 200px;"  #input class="clr-input" (keyup.enter)="verifyGoogleCode()">
      </div>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="verifyGoogleCode()">{{ "Anmelden" | mrTranslate }}</button>
    <!-- <button type="button" class="btn" (click)="googleSetupShow = false">{{ "Abbrechen" | mrTranslate }}</button> -->
  </div>
</clr-modal>


<clr-modal *ngIf="googleCodeShow" [(clrModalOpen)]="googleCodeShow" [clrModalSize]="'md'"
  [clrModalClosable]="true">
  <h3 class="modal-title">{{ "2‑Faktor-Authentifizierung mit Google Authenticator" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal">
      <div>{{ "Geben Sie den 6-stelligen Code aus der Autenthicator App ein." | mrTranslate }}</div>
      <div class="clr-input-wrapper" style="margin-top: 10px;">
        <input clrinput="" placeholder="Code" id="excode" type="text" style="width: 200px;" #input class="clr-input" (keyup.enter)="verifyGoogleCode()">
      </div>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="verifyGoogleCode()">{{ "Anmelden" | mrTranslate }}</button>
    <!-- <button type="button" class="btn" (click)="googleCodeShow = false">{{ "Abbrechen" | mrTranslate }}</button> -->
  </div>
</clr-modal>

