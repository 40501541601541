<!-- <ng-template #autraegeDatagridTemplate let-auftraege="auftraege"> -->
    <div #auftraegeDatagridWrapper id="auftraegeDatagridWrapper">
        <clr-datagrid id="auftragslisteDatagrid" *ngIf="auftraege?.rows?.length > 0" class="datagrid-compact"
            #auftraegeDatagrid>
            <clr-dg-column *ngFor="let column of auftraege.columns; let i = index;" [clrDgField]="column.id"
                [style.min-width.px]="column.id == 'status' ? 150 : column.id.length * 7.5 + 45">
                <!-- [style.min-width]="column.id == 'AuftragNr' ? '11em' : column.id == 'Auftragsstatus' ? '7em' : column.id == 'Titel' ? '22em' : '18em'"
                style="height: 40px;"> -->
                <ng-container
                    *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['firmaid', 'parenttyp', 'parentstatus', 'ks_inttyp', 'ks_typid']}">{{
                    column.id | titlecase | mrTranslate }}
                </ng-container>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let row of auftraege.rows; trackBy: trackByAuftrNr" [clrDgItem]="row">
                <clr-dg-action-overflow>
                    <button class="action-item" (click)="openAuftrag(row)">{{'Auftrag öffnen' | mrTranslate}}</button>
                </clr-dg-action-overflow>
                <clr-dg-cell *ngFor="let cell of auftraege.columns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint"
                    [title]="row[cell.id] | tablePrettyPrint" [style.min-width.px]="cell.id.length * 7.5 + 45"
                    class="name-cell">
                </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
                <span>
                    <strong>{{'Kosten' | mrTranslate}}:</strong><br />
                    {{(summen | async).kosten | currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <span>
                    <strong>{{'Plankosten' | mrTranslate}}:</strong><br />
                    {{(summen | async).plankosten | currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <span>
                    <strong>{{'Personalstunden' | mrTranslate}} / {{'geplant' | mrTranslate}}:</strong><br />
                    {{(summen | async).personalstunden | number: '1.2-3' : 'de'}} / {{(summen |
                    async).personalstundenPlanung | number: '1.2-3' : 'de'}}
                </span>
                <span>
                    <strong>{{'Personalkosten' | mrTranslate}} / {{'geplant' | mrTranslate}}:</strong><br />
                    {{(summen | async).typKosten["PERSONAL"] |
                    currency:'EUR':'symbol':'1.2-2'}} / {{(summen | async).typPlankosten[1] |
                    currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <span>
                    <strong>{{'Fremdleistung' | mrTranslate}} / {{'geplant' | mrTranslate}}:</strong><br />
                    {{(summen | async).typKosten["FREMDLEISTUNG"] |
                    currency:'EUR':'symbol':'1.2-2'}} / {{(summen | async).typPlankosten[4] |
                    currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <span>
                    <strong>{{'Material' | mrTranslate}} / {{'geplant' | mrTranslate}}:</strong><br />
                    {{(summen | async).typKosten["MATERIAL"] |
                    currency:'EUR':'symbol':'1.2-2'}} / {{(summen | async).typPlankosten[2] |
                    currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <span>
                    <strong>{{'Maschinen' | mrTranslate}} / {{'geplant' | mrTranslate}}:</strong><br />
                    {{(summen | async).typKosten["MASCHINE"] |
                    currency:'EUR':'symbol':'1.2-2'}} / {{(summen | async).typPlankosten[3] |
                    currency:'EUR':'symbol':'1.2-2'}}
                </span>
                <!-- </div> -->
                <clr-dg-pagination class="dg-pagination w-100-p" #pagination [clrDgPageSize]="pageSize">
                    <span>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                        {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </span>
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>
<!-- </ng-template> -->

<!-- <ng-container *ngTemplateOutlet="autraegeDatagridTemplate; context: {auftraege: (auftraege | async)}"></ng-container> -->