import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule, ClrForm } from "@clr/angular";
import dayjs from "dayjs";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription, pairwise } from "rxjs";
import { first } from "rxjs/operators";

import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { APIService } from "../../../../services/APIService/api.service";
import { PaintingComponent } from "../../_shared/paint/painting.component";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { InTableValidatorDirective } from "src/app/directives/validators/in-table-validator.directive";

export type HerstellDaten = {
  ID: number;
  Hersteller: string;
  Herstelldatum: string; // DATE
  Einbaufirma: string;
  Einbaudatum: string; // DATE
  Erneuerungsjahr: string; // YYYY
  Nutzungsdauer: number;
  DauerGewaehrleistung: number;
  EinheitGewaehrleistung: "Tag" | "Monat" | "Jahr"; // Tag, Monat, Jahr
  EndeGewaehrleistung: string; // DATE
};

@Component({
  selector: "app-rueckmeldungdialog",
  templateUrl: "./rueckmeldungdialog.component.html",
  styleUrls: ["./rueckmeldungdialog.component.scss"],
  imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, PaintingComponent, MrTranslatePipe, FormsModule, InTableValidatorDirective,
    ReactiveFormsModule, BackendLocaleDatePipe, ObjTypePipe, HideIDColumnsPipe, TablePrettyPrintPipe],
  providers: [BackendLocaleDatePipe],
  standalone: true
})
export class RueckmeldungdialogComponent {

  @ViewChild(ClrForm, { static: false })
  private clrForm: ClrForm
    ;

  ostammid: number;
  firmen: Array<any>;
  mangel: any;
  mangelErl: any;
  messungen: any;
  messungenErl: any;
  selectedmangel: any = [];
  selectedmessung: any = [];
  openTab = "mangel";
  stamm: any;
  typ_Line_Point: any;
  otypid: number;
  inmangel: any;
  selectedInstMangel: any = [];
  selectedInstMessung: any = [];
  toleranz: any;
  spez: any;
  sbfek: any;
  hersteller: any;
  einbaufirma: any;
  private sub: Subscription;

  ismodteilinst = false;
  candoteilinst = false;
  caneditumfang = false;
  modalsize: string = 'lg';
  Opt_F_Drawing: any;
  isload = false;

  ismoderneuerung = false;
  protected herstellDatenForm = new UntypedFormGroup({
    von: new UntypedFormControl("", [Validators.required]),
    bis: new UntypedFormControl("", [Validators.required]),
    Hersteller: new UntypedFormControl("", [Validators.required]),
    Herstelldatum: new UntypedFormControl(BackendLocaleDatePipe.now, [Validators.required]),
    Einbaufirma: new UntypedFormControl("", [Validators.required]),
    Einbaudatum: new UntypedFormControl(BackendLocaleDatePipe.now, [Validators.required]),
    Erneuerungsjahr: new UntypedFormControl("", [Validators.required]),
    Nutzungsdauer: new UntypedFormControl("", [Validators.required]),
    DauerGewaehrleistung: new UntypedFormControl(2, [Validators.required]),
    EinheitGewaehrleistung: new UntypedFormControl("Jahr", [Validators.required]),
    EndeGewaehrleistung: new UntypedFormControl(
      dayjs().add(2, 'years').format(BackendLocaleDatePipe.format),
      [Validators.required]
    ),
  });



  stammstartmeter: any;
  stammendmeter: any;
  lpid: any;

  @ViewChild('_modalvon') _modalvon: ElementRef;
  @ViewChild('_modalbis') _modalbis: ElementRef
  @ViewChild('_modalumfang') _modalumfang: ElementRef
  @ViewChild('_modaleinheit') _modaleinheit: ElementRef
  @ViewChild('_neumodalvon') _neumodalvon: ElementRef;
  @ViewChild('_neumodalbis') _neumodalbis: ElementRef

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();
  @ViewChild(PreviewThumbnailsComponent, { static: false }) preview: PreviewThumbnailsComponent;




  set data(dataIn: any) {
    this.ostammid = dataIn.id;
    this.stamm = dataIn.stamm;
    this.typ_Line_Point = dataIn.typ_Line_Point;
    this.otypid = dataIn.otypid;
    this.Opt_F_Drawing = dataIn.Opt_F_Drawing;

    //this.inmangel = dataIn.mangel
    if (dataIn.dataRefresh) {
      this.dataRefresh = dataIn.dataRefresh;
    }


    let startmeter = this.stamm.rows.find(
      (row: any) => row.DBField == "StartMeter"
    );
    let endmeter = this.stamm.rows.find(
      (row: any) => row.DBField == "EndMeter"
    );
    let lpid = this.stamm.rows.find(
      (row: any) => row.DBField == "LPID"
    );

    this.lpid = lpid?.Bezeichnung,
      this.stammstartmeter = startmeter?.Bezeichnung,
      this.stammendmeter = endmeter?.Bezeichnung,

      this.apiservice
        .getAnlagenAuftrag(this.ostammid, 1)
        .pipe(first())
        .subscribe((val: any) => {
          if (val) {
            this.firmen = val.firmen;
            this.mangel = val.mangel;
            this.messungen = val.messung;
            this.mangelErl = val.mangelErl;
            this.messungenErl = val.messungErl;
            this.otypid = val.otypid;
            this.toleranz = val.toleranzen;

            if (!this.mangel?.rows.length && !this.mangelErl?.rows.length && (this.messungen?.rows.length || this.messungenErl?.rows.length)) this.openTab = "messung";

            this.isload = true;

          }
        });
  }

  trial = false;

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data,
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private authService: AuthService,
    protected localeDate: BackendLocaleDatePipe,
    private overlayService: OverlayService,
  ) {
    this.trial = this.authService.getToken().trial === true;
    this.formGroup.controls.instandsetzungsdatum.setValue(BackendLocaleDatePipe.now);
  }

  protected formGroup = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(''),
    rueckmeldetext: new UntypedFormControl(''),
    teilerneuerung: new UntypedFormControl(''),
    firma: new UntypedFormControl('', [Validators.required]),
    instandsetzungsdatum: new UntypedFormControl("", [Validators.required]),
  });

  protected isDarkThemeAktiv = localStorage.getItem("dark") == "dark";

  selectedmangelChanged(rows: any) {
    this.candoteilinst = false;
    this.caneditumfang = false;

    if (rows != undefined) {
      if (rows.length == 1) {

        let row = rows[0];

        if (this.typ_Line_Point == 2 || this.typ_Line_Point == 4) {
          this.modalsize = 'lg';
          let v = row.von;
          let b = row.bis;
          let l = Math.abs(b - v);

          if (l != 0) {

            this.candoteilinst = true;

            if ((row.mengeneinheit == this.mrTranslate.transform("m") || row.mengeneinheit == this.mrTranslate.transform("Stück")) && row.umfang != null) {
              this.caneditumfang = true;
            }

            this._modalvon.nativeElement.value = row.von;
            this._modalbis.nativeElement.value = row.bis;
            this._modalumfang.nativeElement.value = row.umfang;
            this._modaleinheit.nativeElement.value = row.mengeneinheit;

          }
        }
        else {
          this.modalsize = 'md';

          if ((row.mengeneinheit == this.mrTranslate.transform("m") || row.mengeneinheit == this.mrTranslate.transform("Stück")) && row.umfang != null) {
            this.caneditumfang = true;
            this.candoteilinst = true;

            this._modalumfang.nativeElement.value = 1;
            this._modaleinheit.nativeElement.value = row.mengeneinheit;

          }
        }
      }
    }
  }

  showTeilinstandsetzung(event) {
    let val = this.formGroup.value;
    this.clrForm.markAsTouched();
    if (this.formGroup.valid) {
      this.ismodteilinst = true;
    }

  }
  showErneuerung(event) {
    this.apiservice
      .getKspezErneuerung(this.ostammid)
      .pipe(first())
      .subscribe((val: any) => {
        if (val) {
          this.spez = val.spez;
          this.sbfek = val.sbfek;
          this.hersteller = val.hersteller;
          this.einbaufirma = val.einbaufirma;

          this.catchChanges();
          this.ismoderneuerung = true;

          setTimeout(() => {
            this._neumodalvon.nativeElement.value = this.stammstartmeter;
            this._neumodalbis.nativeElement.value = this.stammendmeter;
          }, 10);


        }
      });

  }

  changed(typ, value) {
    if (typ == "Code") {
      let v = value;

      let bf = this.sbfek.rows.find(x => x.SBFEK === v);

      if (bf != undefined) {

        for (let i = 1; i < this.spez.length; i++) {
          let sp = this.spez[i];
          if (sp.orgName == 'strValue02') {
            sp.bezeichnung = bf.SSCHF;
          }
          if (sp.orgName == 'strValue03') {
            sp.bezeichnung = bf.SSCHW;
          }
          if (sp.orgName == 'strValue04') {
            sp.bezeichnung = bf.SBEFT;
          }
          if (sp.orgName == 'strValue05') {
            sp.bezeichnung = bf.SEIND;
          }
        }
      }
    }
  }

  creatErneuerung() {

    let von = this._neumodalvon.nativeElement.value;
    let bis = this._neumodalbis.nativeElement.value;


    if (this.stammendmeter > this.stammstartmeter) {
      if (bis < von) {
        this.toastr.error(this.mrTranslate.transform("Richtung stimmt nicht...!"));
        return;
      }
      if (von < this.stammstartmeter) {
        this.toastr.error(this.mrTranslate.transform("Eintrag von < Startmeter vom Gleis...!"));
        return;
      }
      if (bis > this.stammendmeter) {
        this.toastr.error(this.mrTranslate.transform("Eintrag bis > Endmeter vom Gleis...!"));
        return;
      }
    }
    else {

      if (von < bis) {
        this.toastr.error(this.mrTranslate.transform("Richtung stimmt nicht...!"));
        return;
      }
      if (von > this.stammstartmeter) {
        this.toastr.error(this.mrTranslate.transform("Eintrag bis < Startmeter vom Gleis...!"));
        return;
      }
      if (bis < this.stammendmeter) {
        this.toastr.error(this.mrTranslate.transform("Eintrag bis < Startmeter vom Gleis...!"));
        return;
      }
    }

    let val = this.herstellDatenForm.value;

    let deltamangel = [];
    deltamangel.push({
      ostammid: this.ostammid,
      otypid: this.otypid,
      lpid: this.lpid,
      omazsid: -999999,
      herstelldaten: val,
      spezdaten: [...this.spez],
      status: 'erneuerung',
      neuvon: von,
      neubis: bis,
      stammSMeter: this.stammstartmeter,
      stammEMeter: this.stammendmeter,
      caneditumfang: this.caneditumfang,
    });

    this.apiservice
      .sendRueckmeldungMangel({ deltamangel: deltamangel, files: [] })
      .pipe(first())
      .subscribe((val: any) => {
        if (val) {
          this.mangel.rows = val.mangel.rows;
          this.mangelErl.rows = val.mangelErl.rows;
        }

        this.ismoderneuerung = false;
        this.dataRefresh.emit();
      });

  }

  private catchChanges() {
    this.sub = this.herstellDatenForm.valueChanges.pipe(pairwise()).subscribe(
      ([old, {
        Einbaudatum: start,
        DauerGewaehrleistung: dauer,
        EinheitGewaehrleistung: einheit
      }]: HerstellDaten[]) => {
        if (
          old.Einbaudatum != start ||
          old.DauerGewaehrleistung != dauer ||
          old.EinheitGewaehrleistung != einheit
        )
          this.calcEndeGewährleistung(start, dauer, einheit);

      }
    );
  }

  private calcEndeGewährleistung(start: string, dauer: number, einheit: string) {
    einheit = einheit == "Tag" ? "days" : einheit == "Monat" ? "months" : "years";
    this.herstellDatenForm.get("EndeGewaehrleistung").setValue(
      dayjs(start || undefined).add(
        dauer ?? 2, <dayjs.ManipulateType>einheit
      ).format(BackendLocaleDatePipe.format)
    );
  }

  createTeilinstandsetzung() {

    if (this.typ_Line_Point == 2 || this.typ_Line_Point == 4) {

      let row = this.selectedmangel[0];
      let von = parseFloat(this._modalvon.nativeElement.value);
      let bis = parseFloat(this._modalbis.nativeElement.value);
      let um;

      if (this.isNumeric(this._modalumfang.nativeElement.value)) {
        um = parseFloat(this._modalumfang.nativeElement.value);
      }

      let umneu = row.umfang;

      if (this.caneditumfang) {
        if (this.isNumeric(row.umfang) && this.isNumeric(um)) {
          umneu = parseFloat(row.umfang) - um;
        }
      }

      if (this.stammendmeter > this.stammstartmeter) {
        if (bis < von) {
          this.toastr.error(this.mrTranslate.transform("Richtung stimmt nicht...!"));
          return;
        }
        if (von < this.stammstartmeter) {
          this.toastr.error(this.mrTranslate.transform("Eintrag von < Startmeter vom Gleis...!"));
          return;
        }
        if (bis > this.stammendmeter) {
          this.toastr.error(this.mrTranslate.transform("Eintrag bis > Endmeter vom Gleis...!"));
          return;
        }
      }
      else {

        if (von < bis) {
          this.toastr.error(this.mrTranslate.transform("Richtung stimmt nicht...!"));
          return;
        }
        if (von > this.stammstartmeter) {
          this.toastr.error(this.mrTranslate.transform("Eintrag bis < Startmeter vom Gleis...!"));
          return;
        }
        if (bis < this.stammendmeter) {
          this.toastr.error(this.mrTranslate.transform("Eintrag bis < Startmeter vom Gleis...!"));
          return;
        }
      }

      let deltamangel = [];
      let val = this.formGroup.value;
      deltamangel.push({
        omazsid: row.OMAZSID,
        date: val.instandsetzungsdatum,
        instandsetzer: parseInt(val.firma),
        bemerkung: val.bemerkung,
        rueckmeldetext: val.rueckmeldetext,
        teilerneuerung: val.teilerneuerung,
        status: 'teilerneuerung',
        von: row.von,
        bis: row.bis,
        umfang: row.umfang,
        neuvon: von,
        neubis: bis,
        neuumfang: umneu,
        stammSMeter: this.stammstartmeter,
        stammEMeter: this.stammendmeter,
        caneditumfang: this.caneditumfang,
      });


      this.apiservice
        .sendRueckmeldungMangel({ deltamangel: deltamangel, files: [] })
        .pipe(first())
        .subscribe((val: any) => {
          if (val) {
            this.mangel.rows = val.mangel.rows;
            this.mangelErl.rows = val.mangelErl.rows;
          }

          this.ismodteilinst = false;
          this.dataRefresh.emit();
        });
    }
    else {
      let row = this.selectedmangel[0];
      let um;

      if (this.isNumeric(this._modalumfang.nativeElement.value)) {
        um = parseFloat(this._modalumfang.nativeElement.value);
      }

      let umneu = row.umfang;

      if (this.isNumeric(row.umfang) && this.isNumeric(um)) {
        umneu = parseFloat(row.umfang) - um;
      }
      let istatus = 'clone';
      if (umneu <= 0) {
        istatus = 'update';
      }

      let deltamangel = [];
      let val = this.formGroup.value;
      deltamangel.push({
        omazsid: row.OMAZSID,
        date: val.instandsetzungsdatum,
        instandsetzer: parseInt(val.firma),
        bemerkung: val.bemerkung,
        rueckmeldetext: val.rueckmeldetext,
        teilerneuerung: val.teilerneuerung,
        status: 'teilerneuerung',
        istatus: istatus,
        umfang: um,
        neuumfang: umneu,
      });


      this.apiservice
        .sendRueckmeldungMangel({ deltamangel: deltamangel, files: [] })
        .pipe(first())
        .subscribe((val: any) => {
          if (val) {
            this.mangel.rows = val.mangel.rows;
            this.mangelErl.rows = val.mangelErl.rows;
          }

          this.ismodteilinst = false;
          this.dataRefresh.emit();
        });

    }
  }

  createMangel(event) {
    const startmeter = this.stamm.rows.find(
      (row: any) => row.DBField == "StartMeter"
    );
    const endmeter = this.stamm.rows.find(
      (row: any) => row.DBField == "EndMeter"
    );


    this.overlayService.setOverlay({
      overlay: "mangelerfassung",
      isTopOverlay: true,
      id: {
        ostammid: this.ostammid,
        typ_Line_Point: this.typ_Line_Point,
        otypid: this.otypid,
        mangel: this.mangel?.rows?.length ? this.mangel : undefined,
        startmeter: this.stammstartmeter,
        endmeter: this.stammendmeter,
        opruefid: this.lpid
      },
      zIndex: 999,
      dataRefresh: this.dataRefresh,
    });
  }

  findFirmNameByID(ID: number) {
    let res = null;
    this.firmen.forEach((firm) => {
      if (firm.ID == ID) {
        res = firm.mFirma;
      }
    });
    return res;
  }

  findFirmIdbyName(name: string) {
    let res = null;
    this.firmen.forEach((firm) => {
      if (firm.mFirma == name) {
        res = firm.ID;
      }
    });
    return res;
  }

  async onMangelSubmit() {
    let deltamangel = [];
    let isValid = true;
    let files = [];

    if (this.preview != undefined) {
      try {
        files = await this.preview.packUnsavedFiles();
      } catch (er) {
        this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten..!"));
      }
    }

    let val = this.formGroup.value;

    this.clrForm.markAsTouched();
    isValid = this.formGroup.valid;

    if (isValid) {
      this.selectedmangel.forEach((mangel: any) => {
        deltamangel.push({
          omazsid: mangel.OMAZSID,
          date: val.instandsetzungsdatum,
          instandsetzer: parseInt(val.firma),
          bemerkung: val.bemerkung,
          rueckmeldetext: val.rueckmeldetext,
          teilerneuerung: val.teilerneuerung,
        });
      });

      this.apiservice
        .sendRueckmeldungMangel({ deltamangel: deltamangel, files: files })
        .pipe(first())
        .subscribe((resval: any) => {
          if (resval?.rows.length) {
            this.toastr.success(
              this.mrTranslate.transform("Daten gespeichert")
            );


            resval.rows.forEach((row: any) => {
              let sid = row["OMAZSID"];
              let index = this.mangel["rows"].findIndex((element) => element["OMAZSID"] == sid);
              if (index > -1) {
                this.mangel["rows"].splice(index, 1);
              }

            });
            /*
                        this.selectedmangel.forEach((slctdmngl: any) => {
                          let index = this.mangel["rows"].indexOf(slctdmngl);
                          if (index > -1) {
                            this.mangel["rows"].splice(index, 1);
                          }
                        });
            */

            this.mangelErl.rows = resval.rows;
            this.preview.clearPreview();

            this.formGroup.controls.rueckmeldetext.setValue('');
            this.formGroup.controls.teilerneuerung.setValue('');
            this.formGroup.controls.bemerkung.setValue('');

            this.dataRefresh?.emit(true);
          }
          else {
            this.toastr.error(
              this.mrTranslate.transform("Beim Speichern ist ein Fehler aufgetreten")
            );
          }
        });
    } else {
      this.toastr.warning(
        this.mrTranslate.transform(
          "Bitte Datum und Firma angeben."
        )
      );
    }
  }

  onBlurMethod(row, event) {
    if (event == '') {
      row.IAusfall = null;
    }
    else {
      if (this.isNumeric(row.Soll)) {
        if (!this.isNumeric(event)) {
          this.toastr.info(this.mrTranslate.transform("Eingabe ist ungültig"));
          row.IstNeu = '';
          row.IAusfall = null;
        }

        let tol = this.toleranz[0];
        let soll = parseFloat(row.Soll);
        let istneu = parseFloat(event);
        let tolverlneu = null;

        if (tol["USE_100"] == 1) {
          if (istneu > soll + parseFloat(row[tol["TEXT_100_p"]]) || istneu < soll - parseFloat(row[tol["TEXT_100_m"]])) {
            tolverlneu = tol["TEXT_100_A"];
          }
        }

        if (tol["USE_L"] == 1) {
          if (istneu > soll + parseFloat(row[tol["TEXT_L_p"]]) || istneu < soll - parseFloat(row[tol["TEXT_L_m"]])) {
            tolverlneu = tol["TEXT_L_A"];
          }
        }

        if (tol["USE_G"] == 1) {
          if (istneu > soll + parseFloat(row[tol["TEXT_G_p"]]) || istneu < soll - parseFloat(row[tol["TEXT_G_m"]])) {
            tolverlneu = tol["TEXT_G_A"];
          }
        }

        row.IAusfall = tolverlneu;
        if (tolverlneu != null) {
          if (!confirm(`Wollen Sie die Messung ${row.Bezeichnung} mit einer Toleranzverletzung: ${tolverlneu} wirklich übernehmen?`)) {
            row.IstNeu = '';
            row.IAusfall = null;
          }
        }
      }
    }
  }

  isNumeric = (val: any): boolean => {
    var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    if (!floatRegex.test(val))
      return false;

    val = parseFloat(val);
    if (isNaN(val))
      return false;
    return true;
  }


  onMessungSubmit() {
    let deltamessung = [];
    let val = this.formGroup.value;
    this.clrForm.markAsTouched();
    let isValid = this.formGroup.valid;

    if (!isValid) {
      this.toastr.warning(this.mrTranslate.transform("Bitte Firma angeben."));
      return;
    }

    this.selectedmessung.forEach((mess: any) => {
      deltamessung.push({
        id: mess.ID,
        date: val.instandsetzungsdatum,
        instandsetzer: parseInt(val.firma),
        istneu: mess.IstNeu,
        tolverlneu: mess.IAusfall,
      });

    });


    this.apiservice
      .sendRueckmeldungMessung(deltamessung)
      .pipe(first())
      .subscribe((resval: any) => {
        if (resval && resval.status && resval.status == "ok") {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.dataRefresh.emit();

          if (!this.messungenErl) this.messungenErl = {};
          if (!this.messungenErl["columns"])
            this.messungenErl["columns"] = this.messungen["columns"];
          if (!this.messungenErl["rows"]) this.messungenErl["rows"] = [];

          this.selectedmessung.forEach((row: any) => {
            let sid = row["ID"];
            let index = this.messungen["rows"].findIndex((element) => element["ID"] == sid);
            if (index > -1) {
              let r = this.mangel["rows"][index];

              row["Ist_Neu"] = row.IstNeu;
              row["Instand_Datum"] = val.instandsetzungsdatum;
              row["Instandsetzer"] = this.findFirmNameByID(val.firma);

              this.messungenErl["rows"].push(row);
              this.messungen["rows"].splice(index, 1);

              this.messungenErl.sort((a, b) => a.ID - b.ID);
            }
          });

        }
      });
  }

  onMessungDelRueckmeldung() {
    let deltamessung = [];

    let ID = this.selectedInstMessung.ID;
    deltamessung.push({
      id: ID,
      status: "deleteMessungRueckmeldung",
    });

    this.apiservice
      .sendRueckmeldungMessung(deltamessung)
      .pipe(first())
      .subscribe((resval: any) => {

        this.apiservice
          .getAnlagenAuftrag(this.ostammid, 1)
          .pipe(first())
          .subscribe((val: any) => {
            if (val) {
              this.messungen.rows = val.messung.rows;
              this.messungenErl.rows = val.messungErl.rows;
              this.dataRefresh.emit();
            }
          });

        this.toastr.info(
          this.mrTranslate.transform("Rückmeldung wurde gelöscht")
        );

      });
  }


  onMangelDelRueckmeldung() {

    let deltamangel = [];
    let files = [];

    let OMAZSID = this.selectedInstMangel.OMAZSID;
    deltamangel.push({
      omazsid: OMAZSID,
      status: "deleteRueckmeldung",
    });

    this.apiservice
      .sendRueckmeldungMangel({ deltamangel: deltamangel, files: files })
      .pipe(first())
      .subscribe((resval: any) => {

        this.apiservice
          .getAnlagenAuftrag(this.ostammid, 1)
          .pipe(first())
          .subscribe((val: any) => {
            if (val) {
              this.mangel.rows = val.mangel.rows;
              this.mangelErl.rows = val.mangelErl.rows;
              this.dataRefresh.emit();
            }
          });

        this.toastr.info(
          this.mrTranslate.transform("Rückmeldung wurde gelöscht")
        );

      });
  }


  tabChange(tab) {
    this.openTab = tab;
  }


  public close() {
    this.closedialog.next(true);
  }
}
