<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
        <span class="title">{{'Komponenten verwalten' | mrTranslate}}</span>
    </div>
    <div class="header-actions">
  <!--
      <clr-dropdown>
        <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
          <clr-icon shape="cog"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a (click)="addObjekt()" clrDropdownItem>{{'Neues Objekt erstellen' | mrTranslate}}</a>
          <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
          <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
          <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
          <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a>
        </clr-dropdown-menu>
      </clr-dropdown>
       -->
      <a href="javascript://" class="nav-link nav-icon">
        <clr-icon  shape="times" (click)="close()"></clr-icon >
      </a>
    </div>
  </header>
  <div class="clr-row nxm" style="align-items: center;">
    <div class="clr-col-auto margin-12-tb">
      <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
          (click)="newitem()">
          <clr-icon shape="file"></clr-icon>
        </button>

        <button class="btn btn-icon margin-12-l" [title]="'Import' | mrTranslate"
          (click)="importitem()">  <!--[disabled]="selOKOMPID == 0"> -->
          <clr-icon shape="import"></clr-icon>
        </button>

        <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
          (click)="deletitem()" [disabled]="selOKOMPID == 0">
          <clr-icon shape="times"></clr-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="clr-row nxm" style="margin-top: -20px;">
    <div class="clr-col-4">
      <h4>{{"Komponenten" | mrTranslate}}</h4>
        <div *ngIf="rootObjekt" class="content-area">
          <clr-tree>
            <clr-tree-node *clrRecursiveFor="let item of rootObjekt; getChildren: getChildren" [(clrExpanded)]="item.expanded"
              [ngClass]="(item == selnode && item.children.length == 0) 
                ? isDarkThemeAktiv ? 'highlighted' : 'highlightedhell' : ''">
              <a class="clr-treenode-link" routerLinkActive="active" (click)='treeClick(item)'>{{item.name}}</a>
            </clr-tree-node>
          </clr-tree>
          <br>
          <br>
        </div>

    </div>
    <div *ngIf="spezData.length>0" class="clr-col-8">
      <h4>{{"Eigenschaften" | mrTranslate}} {{selnodename}}</h4>

      <form #spezFormPoint clrForm ngForm (ngSubmit)="saveIfValid()"
      class="width100 margin-12-t" clrLabelSize="12">
      <table  class="table margin-0-t table-compact nowrap">
        <thead style="position: sticky; top: 0; z-index: 3;">
          <tr>
            <th class="left">{{'Objekt' | mrTranslate}}</th>
            <th class="left" style="width: 50%;">{{'Bezeichnung' | mrTranslate}}</th>
            <th>{{'Tabelle' | mrTranslate}}</th>
            <th>{{'Pflicht' | mrTranslate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let spez of spezData" [hidden]="spez.objekt == 'OSPEZID'">
            <ng-container *ngIf="!spez.isHeader; else header">
              <td class="left" style="vertical-align: bottom;">
                {{ spez.objekt | mrTranslate }}
              </td>
              <td [ngSwitch]="spez.fieldType">
                <ng-container *ngSwitchCase="'str'">
                  <!-- ! STRIKT Texteingabefeld nur vom Tabelle -->
                  <ng-container *ngIf="spez.sField; else datalist">
                    <clr-select-container>
                      <select clrSelect [name]="spez.objekt" [(ngModel)]="spez.bezeichnung"
                        [required]="!!spez.pflichtFeld" [inTableValidator]="spez.wListe">
                        <option [disabled]="spez.pflichtFeld" value=""></option>
                        <option *ngIf="spez.bezeichnung && !spez.wListe.includes(spez.bezeichnung)"
                          [value]="spez.bezeichnung" class="invalidRed">{{ spez.bezeichnung }}</option>
                        <option *ngFor="let wert of spez.wListe" [value]="wert">{{ wert }}</option>
                      </select>
                      <clr-control-error *clrIfError="'not-in-Table'; error as err">
                        {{err | mrTranslate}}
                      </clr-control-error>
                      <clr-control-error *clrIfError="'required'">
                        {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                      </clr-control-error>
                    </clr-select-container>
                  </ng-container>
                  <!-- * FREI Texteingabefeld mit den bisherigen Werten -->
                  <ng-template #datalist>
                    <clr-datalist-container>
                      <input class="clr-col-12" clrDatalistInput type="search" [name]="spez.objekt"
                        [(ngModel)]="spez.bezeichnung" [required]="!!spez.pflichtFeld" />
                      <datalist>
                        <option *ngFor="let wert of spez.wListe" [value]="wert"></option>
                      </datalist>
                      <clr-control-error *clrIfError="'required'">
                        {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                      </clr-control-error>
                    </clr-datalist-container>
                  </ng-template>
                </ng-container>
                <!-- Zahleneingabefeld number -->
                <ng-container *ngSwitchCase="'dbl'">
                  <ng-container *ngTemplateOutlet='number; context: {float: true}'></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'int'">
                  <ng-container *ngTemplateOutlet='number; context: {float: false}'></ng-container>
                </ng-container>
                <ng-template #number let-float="float">
                  <clr-input-container>
                    <input clrInput [name]="spez.objekt" [(ngModel)]="spez.bezeichnung" type="number"
                      [step]="float ? 0.1 : 1" [required]="!!spez.pflichtFeld" />
                    <clr-control-error *clrIfError="'required'">
                      {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                    </clr-control-error>
                  </clr-input-container>
                </ng-template>
                <!-- Ja/Nein Einegabe -->
                <ng-container *ngSwitchCase="'chk'">
                  <clr-toggle-container>
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [name]="spez.objekt" [(ngModel)]="spez.bezeichnung"/>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>
                </ng-container>
                <!-- Datum -->
                <ng-container *ngSwitchCase="'dat'">
                  <clr-date-container>
                    <input clrDate [name]="spez.objekt" autocomplete="off" type="date"
                      [required]="!!spez.pflichtFeld" [ngModel]="spez.bezeichnung | localeDate"
                      (ngModelChange)="spez.bezeichnung = localeDate.tosql($event)"
                      [ngModelOptions]="{updateOn: 'blur'}"/>
                    <clr-control-error *clrIfError="'required'">
                      {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                    </clr-control-error>
                  </clr-date-container>
                </ng-container>
              </td>
              <td>
                <clr-icon *ngIf="spez.sField" [title]="'Liste bearbeiten' | mrTranslate" shape="pencil" size="20"
                  (click)="openTabelle(spez)"></clr-icon>
              </td>
              <td>
                <clr-icon *ngIf="spez.pflichtFeld" shape="success-standard" class="is-solid"
                  style="fill: #2EC0FF;"></clr-icon>
              </td>
            </ng-container>
            <ng-template #header>
              <th class="headerRow left">
                {{ spez.objekt | mrTranslate }}
              </th>
              <th class="headerRow"></th>
              <th class="headerRow"></th>
              <th class="headerRow"></th>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </form>

    <div *ngIf="selnode" class="clr-row nxm padding-24-b">
      <div class="clr-col-12">
        <app-preview-thumbnails [acceptTypes]="'image/*'" [maxAmount]="6" ></app-preview-thumbnails>
      </div>
    </div>
    <ng-container *ngIf="selnode">
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ngForm.ngSubmit.emit()">{{'Speichern' | mrTranslate}}</button>
      </div>
    </ng-container>

  </div>




  </div>
</div>

<clr-modal [(clrModalOpen)]="isnewAdd" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{"Komponenten auswählen" | mrTranslate}}</h3>
  <div class="modal-body">
    <div class="content-container">
      <div *ngIf="root" class="content-area">
        <clr-tree>
          <clr-tree-node *clrRecursiveFor="let item of root; getChildren: getChildren" [(clrSelected)]="item.selected" [(clrExpanded)]="item.expanded">
            <a class="clr-treenode-link" routerLinkActive="active">{{item.name}}</a>
          </clr-tree-node>
        </clr-tree>
        <br>
        <br>
      </div>
    </div>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addKompItems()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isnewAdd = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="objekteModal" [clrModalClosable]="true" [clrModalSize]="'xl'">
  <h3 class="modal-title">{{"Komponenten importieren" | mrTranslate}}</h3>
  <div class="modal-body">
    <div class="clr-row nxm">
      <div class="clr-col-7">
        <h5>{{"Objektauswahl" | mrTranslate}}</h5>
        <clr-datagrid *ngIf="objekte?.rows?.length > 0 && objekteModal" class="datagrid-compact" style="height: 650px;"
          [(clrDgSingleSelected)]="selectedObjekt" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionGridChanged($event)">
          <clr-dg-column *ngFor="let column of objekte['columns']" [clrDgField]="column.id"
            [style.min-width.px]="(column.id.length * 7.5) + 26">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sp', 'lf','st','rep']}">
              {{ column.id | titlecase | mrTranslate }}
            </ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of objekte['rows']" [clrDgItem]="row">
            <ng-container *ngFor="let cell of objekte['columns']">
              <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                  [title]="row[cell.id] | tablePrettyPrint">
                </clr-dg-cell>
              </ng-container>
              <ng-template #iconcell>
                <clr-dg-cell style="color:#000">
                  <ng-container [ngSwitch]="cell.id.toLowerCase()">
                    <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                      [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="22">
                    </clr-icon>
                  </ng-container>
                </clr-dg-cell>
              </ng-template>
            </ng-container>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="100">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
              </clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>

      <div class="clr-col-5">
        <div *ngIf="rootObjektImport" class="content-area">
          <h5>{{"Komponenten auswählen" | mrTranslate}}</h5>
          <clr-tree>
            <clr-tree-node *clrRecursiveFor="let item of rootObjektImport; getChildren: getChildren" [(clrSelected)]="item.selected" [(clrExpanded)]="item.expanded">

              <a class="clr-treenode-link" routerLinkActive="active">{{item.name}}</a>

            </clr-tree-node>
          </clr-tree>
          <br>
          <br>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="importKomponent()">{{ 'Importieren' | mrTranslate }}</button>
    <button type="button" class="btn" (click)="objekteModal = false">{{ 'Beenden' | mrTranslate }}</button>
  </div>
</clr-modal>
<app-basedatamodal *ngIf="isBasedataModalOpen"></app-basedatamodal>