import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, viewChild } from '@angular/core';
import { FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClarityModule, ClrSidePanel } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, firstValueFrom, map, of, startWith, switchMap } from 'rxjs';
import { Table } from 'src/app/models/ui/table';
import { CheckByCallbackFunctionPipe } from 'src/app/pipes/check-by-callback.pipe';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { BasicStoreService } from 'src/app/services/BasicStore/basic-store.service';
import { db } from 'src/app/services/dexieDB';
import { Override } from 'src/app/types/shared';
import { BasedatamodalComponent } from '../../_components/_modals/basedatamodal/basedatamodal.component';
import { HilfeBeschreibung, HilfeBeschreibungModalComponent } from '../../_components/_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { WartungProtokollNode, WartungsPlanNode } from '../../_components/_overlays/wartungsverwaltung/wartung.types';
import { ZaehlerStore } from '../../_components/_tabs/zaehlertab/zaehler.store.service';
import { VerknüpfungsTyp } from '../../_components/_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.enums';
import { ZaehlertabComponent } from '../../_components/_tabs/zaehlertab/zaehlertab.component';
import { DetailViewAnsicht, DetailviewComponent } from '../../_components/detailview/detailview.component';

type WartungResponseAPI = Override<WartungProtokollNode, { zuordnungen: string }>[];

type GroupedProtokollNodes = Map</* idOfHeader */ number, { 
  children: WartungProtokollNode[],
  name?: string,
  helper?: HilfeBeschreibung
}>;


@Component({
  selector: 'app-wartungdialog',
  templateUrl: './wartungdialog.component.html',
  styleUrls: ['./wartungdialog.component.scss'],
  imports: [
    CommonModule,
    ClarityModule,
    // TODO: return bei MangelTab aktiviert
    // HideIDColumnsPipe,
    // TablePrettyPrintPipe,
    // MangelLogModalComponent,
    // MediaViewModalComponent,
    BasedatamodalComponent,
    HilfeBeschreibungModalComponent,
    CheckByCallbackFunctionPipe,
    BackendLocaleDatePipe,
    MrTranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    DetailviewComponent,
    ZaehlertabComponent
  ],
  providers: [ BackendLocaleDatePipe, ZaehlerStore ],
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class WartungDialogComponent implements OnInit {
  public closedialog = new Subject<boolean>();
  private dataRefresh: Subject<void>;
  // private maengelRefresh = new Subject<void>;

  private readonly basedatamodal = viewChild(BasedatamodalComponent);

  private readonly hilfeModal = viewChild(HilfeBeschreibungModalComponent);
  protected wartung$: Observable<GroupedProtokollNodes>;
  protected maengel$: Observable<Table>;

  protected bemerkungen$: Observable<string[]>;
  protected meh$: Observable<string[]>;
  protected requiredFK$: Observable<boolean>;

  protected rangeFK: number[] = [];
  protected wartungsdate = BackendLocaleDatePipe.now;
  protected bemerkung: string;

  protected objekt: WartungsPlanNode;

  protected isReadOnly: boolean;
  protected isNew: boolean;
  protected showZaehlerTab = this.zaehlerStore.isZaehlerAssigned;
  protected isDetailPanelOpened = false;
  // protected mangelLog: number;
  // protected mangelMedia: TitleAndFileNames[];

  protected showHilfeColumn = false;
  protected allSelected = (rows: WartungProtokollNode[]) => rows.every(row => row.artTyp == 0 || row.iO);
  
  public set data(dataIn: any) {
    this.dataRefresh = dataIn.dataRefresh;
    this.objekt = dataIn.objekt;
    this.isReadOnly = dataIn.mode === 'readonly';
    this.isNew = dataIn.mode === 'neu';

    this.zaehlerStore.verknüpfung.set(VerknüpfungsTyp.Wartungszähler);
    this.zaehlerStore.objekt.set(this.objekt);
    this.zaehlerStore.isReadOnly.set(this.isReadOnly);
    this.zaehlerStore.isNew.set(this.isNew);

    this.setWartung();
    // this.setMaengel();

    if (!this.isNew) {
      this.wartungsdate = this.objekt.letzteDatum;
      this.bemerkung = this.objekt.bemerkung;
    }

    if (!this.isReadOnly) {
      this.bemerkungen$ = this.apiService.getWartungsBemerkungenList();
      this.meh$ = this.apiService.getMaengelEinheiten();
      this.requiredFK$ = this.apiService.getSysOptionsForObject(this.objekt.OTYPID).
        pipe(map(options => options['Opt_I_Fehlerklasse'] == 1));
    }
  }

  constructor(
    protected localeDate: BackendLocaleDatePipe,
    private apiService: APIService,
    private store: BasicStoreService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    // private overlayService: OverlayService,
    private zaehlerStore: ZaehlerStore,
    // protected media: MangelMediaApiFuncService,
  ) {}

  private setWartung() {
    this.wartung$ = this.dataRefresh.pipe(
      startWith(null),
      switchMap(() => this.apiService.getWartungNachMaske(
        this.objekt.mID,
        this.isNew ? -1 : this.objekt.wID,
        this.isReadOnly
      )),
      map((rows: WartungResponseAPI) => this.isReadOnly
        ? rows as unknown as WartungProtokollNode[]
        : rows.map(row => {
          const zuordnungen = (row.zuordnungen || null)?.split('&.&').reduce(
            ({ arbeiten, FKs }, zuordnung) => {
              // split('&.&') - verschiedene Gruppen von arbeitID, arbeitBez u. Priorität
              const [id, bez, fk] = zuordnung.split('|.|');
              arbeiten[bez] = +id;
              FKs[+id] = +fk;
              return { arbeiten, FKs };
            },
            { arbeiten: {}, FKs: {} }
          );
          return { ...row, zuordnungen } as WartungProtokollNode;
        })
      ),
      map(nodes => nodes.reduce<{ groups: GroupedProtokollNodes; letzteHeaderID: number }>(
        ({groups, letzteHeaderID}, node) => {
          if (node.artTyp == 0) {
            const { HelpBeschreibung, HelpBildBase64, FileTyp } = node;
            groups.set(node.artID, {
              children: this.isReadOnly ? [] : [node],
              name: node.artName,
              helper: { HelpBeschreibung, HelpBildBase64, FileTyp }
            });
            letzteHeaderID = node.artID;
          } else {
            if (letzteHeaderID === null) {
              letzteHeaderID = 0;
              groups.set(0, {children: []});
            }
            groups.get(letzteHeaderID).children.push({ ...node, FK: node.FK ?? null });
            this.showHilfeColumn ||= !!(node.HelpBeschreibung || node.HelpBildBase64);
          }
          return { groups, letzteHeaderID };
        },
        { groups: new Map(), letzteHeaderID: null }
      ).groups)
    );
  }

  // private setMaengel() {
  //   this.maengel$ = merge(this.dataRefresh, this.maengelRefresh).pipe(
  //     startWith(null),
  //     switchMap(() => this.apiService.getMaengelFromInspektion(
  //       this.objekt.OTYPID,
  //       this.objekt.inspektionID,
  //     ))
  //   );
  // }

  ngOnInit() {
    const {
      einstellung: { optMINNumFK = 0, optMAXNumFK = 4 } = {},
    } = this.store.getComponentStore('einstellung');
    for (let fk = optMINNumFK; fk <= optMAXNumFK; fk++) {
      this.rangeFK.push(fk);
    }
  }

  protected async createArbeitAndFindFK(row: WartungProtokollNode) {
    const { zuordnungen: { FKs, arbeiten } = {}, arbeitName } = row;
    if (!arbeitName) return;

    const arbeitID = arbeiten?.[arbeitName];
    
    if (arbeitID) {
      row.arbeitID = arbeitID;
      const fk = FKs?.[arbeitID];
      if (this.rangeFK.includes(fk)) row.FK = fk;
    } else {
      row.arbeitID = this.apiService.isRep ? await db.GetArbeitID(arbeitName,this.objekt.OTYPID) : await firstValueFrom(this.apiService.requestGET(
        `wartung/findOrCreateArbeitID/${arbeitName}/${this.objekt.OTYPID}`
      ));
      const sendObj = {
        artID: row.artID,
        arbeitID: row.arbeitID,
        Priorität: row.FK || 0
      };
      firstValueFrom(this.apiService.setWartungsbaumNode(this.objekt.OTYPID, sendObj));
    }
  }

  protected async saveIfValid(form: NgForm) {
    if (form.invalid) return;

    const sendObj = {
      ID: this.isNew ? null : this.objekt.wID,
      opruefid:this.isNew ? null : this.objekt.OPRUEFID,
      ostammid: this.objekt.OSTAMMID,
      otypid: this.objekt.OTYPID,
      mID: this.objekt.mID,
      maske: this.objekt.maske,
      personal: this.objekt.verantwortlich,
      datum: this.wartungsdate || BackendLocaleDatePipe.now,
      bemerkung: this.bemerkung,
      form: form.dirty ? form.value : null
    }

    const { success, wID, pruefID } = await firstValueFrom(this.apiService.setWartung(sendObj));
    if (success) {
      this.objekt.wID = wID;
      this.objekt.OPRUEFID = pruefID;
      this.isNew = false;
      this.zaehlerStore.isNew.set(this.isNew);
      form.form.markAsPristine();

      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert!'),
        this.mrTranslate.transform('Wartung')
      );
      this.dataRefresh.next();

      if (this.zaehlerStore.isZaehlerAssigned())
        this.zaehlerStore.onSave(pruefID); 
    } else this.toastr.error(
      this.mrTranslate.transform('Etwas ist schief gelaufen'),
      this.mrTranslate.transform('Wartung')
    );
  }

  private split50 = false;
  protected handleDetailviewEnlarge(
    $event: DetailViewAnsicht,
    detailPanel: ClrSidePanel
  ) {
    switch ($event.mode) {
      case 'full':
        detailPanel.size = $event.val ? 'full-screen' : this.split50 ? 'lg' : 'md';
        break;
      case 'half':
        this.split50 = $event.val;
        if (detailPanel.size !== 'full-screen')
          detailPanel.size = this.split50 ? 'lg' : 'md';
        break;
      case 'close': 
        detailPanel.close();
        break;
    }
  }

  protected checkAll(select: boolean, headerBlock: FormGroup) {
    Object.values(headerBlock.controls).forEach(
      (control: FormGroup) => control.controls['i.O.']?.setValue(select)
    );
    headerBlock.markAsDirty();
  }

  protected setValidations(iO: boolean, artGroup: FormGroup, requiredFK: boolean) {
    const { massnahmen: arbCtrl, fk: fkCtrl } = artGroup.controls;
    arbCtrl.setValidators(iO ? null : Validators.required);
    arbCtrl.updateValueAndValidity();
    if (requiredFK) {
      fkCtrl.setValidators(iO ? null : Validators.required);
      fkCtrl.updateValueAndValidity();
    }
  }

  protected openTabelle() {
    const inData = {
      titel: 'Mengeneinheiten',
      tabelle: 'smeh',
      OTYPID: this.objekt.OTYPID,
      tname: '',
    };
    this.basedatamodal().open(inData);

    const sub = this.basedatamodal().onOK.subscribe((res) => {
      if (res.isChanged)
        this.meh$ = of(res.stammdaten.rows.map((row: any) => row.Bezeichnung));
      sub?.unsubscribe();
      this.basedatamodal().close();
    });
  }

  protected openHelper(item: WartungProtokollNode | HilfeBeschreibung) {
    this.hilfeModal().item = { ...item };
  }

  // protected createMangel(maengel: Table) {
  //   this.overlayService.setOverlay({
  //     overlay: "mangelerfassung",
  //     isTopOverlay: true,
  //     id: {
  //       ostammid: this.objekt.OSTAMMID,
  //       typ_Line_Point: this.objekt.typ_Line_Point,
  //       otypid: this.objekt.OTYPID,
  //       mangel: maengel?.rows?.length ? maengel : undefined,
  //       startmeter: this.objekt.Startmeter,
  //       endmeter: this.objekt.Endmeter,
  //       opruefid: this.objekt.inspektionID
  //     },
  //     dataRefresh: this.maengelRefresh,
  //   });
  // }

  protected close() {
    this.closedialog.next(true);
  }
}
