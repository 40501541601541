<div class="overlay-inner-wrapper">
  <div id="profileViewerWrapper">
    <div class="clr-row clr-justify-content-between">
      <div>
        <h3 class="mt-0">{{'Profil-Viewer' | mrTranslate}}</h3>
      </div>
      <div>
        <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
      </div>
    </div>

    <div class="clr-row">
      <div>
        <div class="clr-row nxm">
          <div class="clr-col-3" style="padding-left:0px; padding-right:0px;">
            <p class="mt-0">
              <strong>{{'links' | mrTranslate}}</strong>  {{ profiles[index]?.Position }}
              <br />
              <strong>{{'Name' | mrTranslate}}:</strong> {{ profiles[index]?.ProfilL }}
              <br />
              <strong>dH:</strong> {{ ds.lh }}
            </p>
          </div>
          <div class="clr-col-3">
            <p class="mt-0">
              <strong>dS1:</strong> {{ ds.l1 }}
              <br />
              <strong>dS2:</strong> {{ ds.l2 }}
              <br />
              <strong>
                <clr-icon (click)="download('L')" class="cursor-pointer download_button" shape="export"></clr-icon>
              </strong>
            </p>
          </div>

          <div class="clr-col-3" style="padding-left:0px; padding-right:0px;">
            <p class="mt-0">
              <strong>{{'rechts' | mrTranslate}}</strong>  {{ profiles[index]?.Position }}
              <br />
              <strong>{{'Name' | mrTranslate}}:</strong> {{ profiles[index]?.ProfilR }}
              <br />
              <strong>dH:</strong> {{ ds.rh }}
            </p>
          </div>
          <div class="clr-col-3">
            <p class="mt-0">
              <strong>dS1:</strong> {{ ds.r1 }}
              <br />
              <strong>dS2:</strong> {{ ds.r2 }}
              <br />
              <strong>
                <clr-icon (click)="download('R')" class="cursor-pointer download_button" shape="export"></clr-icon>
              </strong>
            </p>
          </div>
        </div>
        <!-- <div class="mt-10">
          <canvas #myCanvasL [height]="sizeh" [width]="sizew" class="profileL" style="border:1px solid #000;"></canvas>
        </div> -->
        <div *ngIf="graphL.show" class="card-block">
          <div class="clr-row">
              <div id="plotly_Left"class="clr-col-6"></div>
              <div id="plotly_Right"class="clr-col-6"></div>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col-6">
            <button [title]="'MoveUp Links' | mrTranslate"  type="button" (click)="doMove(-moveStepL,'L','Y')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow up"></clr-icon>
            </button>

            <button [title]="'MoveLeft Links' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(-moveStepL,'L','X')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow left"></clr-icon>
            </button>

            <button [title]="'MoveRight Links' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(moveStepL,'L','X')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow right"></clr-icon>
            </button>

            <button  [title]="'MoveDown Links' | mrTranslate" type="button" style="margin-left:16px;" (click)="doMove(moveStepL,'L','Y')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow down"></clr-icon>
            </button>

            <button [title]="'Speichere Links' | mrTranslate" type="button" style="margin-left:16px;" (click)="saveProfile('L')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="floppy"></clr-icon>
            </button>
            <input type="number" style="margin-left:16px; width: 50px;" value="1.0" step="0.1" (change)="setMoveStep($event,'L')" class="moveStepL"/> mm
          </div>

          <div class="clr-col-6">
            <button [title]="'MoveUp Rechts' | mrTranslate"  type="button" (click)="doMove(-moveStepR,'R','Y')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow up"></clr-icon>
            </button>
            <button [title]="'MoveLeft Rechts' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(-moveStepR,'R','X')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow left"></clr-icon>
            </button>
            <button [title]="'MoveRight Rechts' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(moveStepR,'R','X')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow right"></clr-icon>
            </button>
            <button [title]="'MoveDown Rechts' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(moveStepR,'R','Y')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="arrow down"></clr-icon>
            </button>
            <button [title]="'Speichere Rechts' | mrTranslate"  type="button" style="margin-left:16px;" (click)="saveProfile('R')" class="btn nxr btn-icon btn-primary">
              <clr-icon shape="floppy"></clr-icon>
            </button>
            <input type="number" style="margin-left:16px; width: 50px;" value="1.0" step="0.1"  (change)="setMoveStep($event,'R')" class="moveStepR"/> mm
            <!-- <div>
              <label style="margin-left:16px;">x={{(RightSet.x1 - RightSet.x2).toFixed(2)}} mm - y={{(RightSet.y2 - RightSet.y1).toFixed(2)}} mm - dist={{distR}} mm</label>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="clr-row nxm mt-10 custom-slider">
      <ngx-slider [(value)]="ind" [options]="slideroptions"></ngx-slider>

    </div>
  </div>