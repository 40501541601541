<div class="clr-row nxm clr-justify-content-between clr-align-items-center">
  <div class="clr-col-auto margin-12-tb">
    <div *ngIf="!isMulti; else multiButtons" class="btn-group btn-primary">
      <button class="btn" (click)="openWartung('neu')"
        [disabled]="!selectedPlanNode?.mID">
        <clr-icon shape="file" [title]="'Neue Wartung durchführen' | mrTranslate"></clr-icon>
        {{ 'neu' | mrTranslate }}
      </button>
      <button class="btn" (click)="openWartung('readonly')"
        [disabled]="!selectedPlanNode?.wID">
        <clr-icon shape="folder-open" [title]="'Letzte Wartung öffnen' | mrTranslate"></clr-icon>
        {{ 'öffnen' | mrTranslate }}
      </button>
      <button class="btn" (click)="openWartung()"
        [disabled]="!selectedPlanNode?.wID">
        <clr-icon shape="note" [title]="'Letzte Wartung bearbeiten' | mrTranslate"></clr-icon>
        {{ 'bearbeiten' | mrTranslate }}
      </button>
    </div>
    <ng-template #multiButtons>
      <div class="btn-group">
        <button class="btn btn-primary" (click)="multiModal.open()"
          [disabled]="!selectedPlanNodes.length">
          <clr-icon shape="step-forward-2" [title]="'Auswahl bestätigen' | mrTranslate"></clr-icon>
          {{ 'auswählen' | mrTranslate }}
        </button>
      </div>
    </ng-template>
    <div class="btn-group btn-icon">
      <button *ngIf="!isRep" class="btn" (click)="openVerwaltung()">
        <clr-icon shape="cog" [title]="'Wartungsverwaltung öffnen' | mrTranslate"></clr-icon>
      </button>
      <button class="btn" (click)="isMulti = !isMulti; clearMulti(isMulti)">
        <clr-icon [attr.shape]="isMulti ? 'times-circle' : 'layers'" solid="true" [title]="('Sammelrückmeldung' | mrTranslate) + ' ' + (isMulti ? 'abbrechen' : 'öffnen' | mrTranslate)"></clr-icon>
      </button>
    </div>
  </div>
  <div class="clr-col-auto margin-12-tb">
    <div class="btn-group btn-icon">
      <button class="btn" (click)="historiePanel.open(); isHistoriePanelOpened = true"
        [disabled]="!selectedPlanNode?.OSTAMMID">
        <clr-icon shape="tree-view" [title]="'Historie öffnen' | mrTranslate"></clr-icon>
      </button>
    </div>
  </div>
</div>
<div class="padding-10-lr padding-12-b w-100-p h-60">
  <ng-container *ngIf="!isLoading && (wartungen$ | async) as table; else loading">
    <ng-container *ngIf="table?.rows?.length; else noDaten">
      <ng-container *ngIf="isMulti; then multiSelection; else singleSelection"></ng-container>
      <ng-template #singleSelection>
        <clr-datagrid class="datagrid-compact h-100-force"
          [clrDgRowSelection]="true"
          [(clrDgSingleSelected)]="selectedPlanNode" deselectButton>
          <clr-dg-column class="mw-0" [clrDgField]="'icon'">
            {{ 'Typ' | mrTranslate }}
          </clr-dg-column>
          <clr-dg-column *ngFor="let column of table.columns"
            [clrDgField]="column.id" [clrDgSortOrder]="column.id == 'nextDatum' ? 1 : 0">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:[
              'strbez', 'wid', 'mid', 'pid', 'icon', 'inspektionid'
            ]}">{{ column.id == 'nextDatum'
              ? 'Nächste Wartung'
              : column.id == 'letzteDatum'
              ? 'Letzte Wartung'
              : column.id == 'diffFromNow'
              ? 'Fälligkeit in Tagen'
              : (column.id | titlecase) | mrTranslate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of table.rows" [clrDgItem]="row">
            <clr-dg-cell class="mw-0">
              <div style="width: max-content; margin: 5px auto 0;">
                <clr-icon style="color: black;" [attr.shape]="row['icon']
                  ? 'objekttyp' + row['icon']
                  : 'unknown-status'" size="28">
                </clr-icon>
              </div>
            </clr-dg-cell>
            <ng-container *ngFor="let cell of table.columns">
              <clr-dg-cell *ngIf="cell.id != 'diffFromNow'; else daysLeft" class="name-cell"
                [innerHTML]="cell.id == 'einheit'
                  ? (row[cell.id] | check: getEinheitText | mrTranslate)
                  : (row[cell.id] | tablePrettyPrint)">
              </clr-dg-cell>
              <ng-template #daysLeft>
                <clr-dg-cell class="name-cell" [class.invalidRed]="row.diffFromNow < 3">
                  {{ row.diffFromNow }} &nbsp;
                  <clr-icon *ngIf="row.diffFromNow < 0"
                    class="customIcon invalidRed" size="20"
                    shape="exclamation-triangle">
                  </clr-icon>
                </clr-dg-cell>
              </ng-template>
            </ng-container>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="20">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ "Zeilen pro Seite" | mrTranslate }}
              </clr-dg-page-size>
              {{ pagination.firstItem + 1 }} -
              {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
              {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </ng-template>
      <ng-template #multiSelection>
        <clr-datagrid class="datagrid-compact h-100-force" [(clrDgSelected)]="selectedPlanNodes">
          <clr-dg-column class="mw-0" [clrDgField]="'icon'">
            {{ 'Typ' | mrTranslate }}
          </clr-dg-column>
          <clr-dg-column *ngFor="let column of table.columns"
            [clrDgField]="column.id" [clrDgSortOrder]="column.id == 'nextDatum' ? 1 : 0">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:[
              'strbez', 'wid', 'mid', 'pid', 'icon', 'inspektionid'
            ]}">{{ column.id == 'nextDatum'
              ? 'Nächste Wartung'
              : column.id == 'letzteDatum'
              ? 'Letzte Wartung'
              : column.id == 'diffFromNow'
              ? 'Fälligkeit in Tagen'
              : (column.id | titlecase) | mrTranslate
            }}</ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of table.rows" [clrDgItem]="row">
            <clr-dg-cell class="mw-0">
              <div style="width: max-content; margin: 5px auto 0;">
                <clr-icon style="color: black;" [attr.shape]="row['icon']
                  ? 'objekttyp' + row['icon']
                  : 'unknown-status'" size="28">
                </clr-icon>
              </div>
            </clr-dg-cell>
            <ng-container *ngFor="let cell of table.columns">
              <clr-dg-cell *ngIf="cell.id != 'diffFromNow'; else daysLeft" class="name-cell"
                [innerHTML]="cell.id == 'einheit'
                  ? (row[cell.id] | check: getEinheitText | mrTranslate)
                  : (row[cell.id] | tablePrettyPrint)">
              </clr-dg-cell>
              <ng-template #daysLeft>
                <clr-dg-cell class="name-cell" [class.invalidRed]="row.diffFromNow < 3">
                  {{ row.diffFromNow }} &nbsp;
                  <clr-icon *ngIf="row.diffFromNow < 0"
                    class="customIcon invalidRed" size="20"
                    shape="exclamation-triangle">
                  </clr-icon>
                </clr-dg-cell>
              </ng-template>
            </ng-container>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="20">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ "Zeilen pro Seite" | mrTranslate }}
              </clr-dg-page-size>
              {{ pagination.firstItem + 1 }} -
              {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
              {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </ng-template>
    </ng-container>
    <ng-template #noDaten>
      <div class="margin-3-t">{{'keine Daten vorhanden' | mrTranslate}}</div>
    </ng-template>
  </ng-container>
  <ng-template #loading>
    <div class="d-f center h-100">
      <clr-spinner></clr-spinner>
    </div>
  </ng-template>
</div>
<clr-side-panel #historiePanel class="h-100-stop">
  <h3 class="side-panel-title">{{ 'Historie' | mrTranslate }}</h3>
  <div class="side-panel-body h-100 h-100-parent">
    <div class="padding-10-t">
      <strong class="caption nm">
        {{ selectedPlanNode?.strBez || ('Objekt' | mrTranslate) }}
      </strong>
    </div>
    <div class="h-60">
      <app-historie-tree *ngIf="isHistoriePanelOpened"
        [ostammid]="selectedPlanNode?.OSTAMMID">
      </app-historie-tree>
    </div>
  </div>
  <div class="side-panel-footer">
    <button type="button" class="btn btn-outline" (click)="historiePanel.close()">
      {{'Schliessen' | mrTranslate}}
    </button>
  </div>
</clr-side-panel>

<clr-modal #multiModal [clrModalClosable]="true" (clrModalOpenChange)="clearMulti($event)">
  <h3 class="modal-title">{{'Sammelrückmeldung' | mrTranslate}}</h3>
  <div class="modal-body width100">
    <clr-date-container class="mt-0">
      <label for="date">
        {{'Datum' | mrTranslate}}
      </label>
      <input type="date" clrDate
        [ngModel]="wartungsdate | localeDate"
        (ngModelChange)="wartungsdate = localeDate.tosql($event)"
        [ngModelOptions]="{updateOn: 'blur', standalone: true}"
      />
    </clr-date-container>
    <clr-datalist-container class="mt-0">
      <label class="clr-col-12 clr-col-md-12">
        {{'Bemerkung' | mrTranslate}}
      </label>
      <input clrDatalistInput type="search"
        class="clr-col-12 clr-col-md-12 w-100-p"
        [(ngModel)]="bemerkung"
        [ngModelOptions]="{standalone: true}"
      />
      <datalist>
        <option *ngFor="let wert of bemerkungen$ | async" [value]="wert"></option>
      </datalist>
    </clr-datalist-container>
    <!-- ? Arbeiter
    <clr-input-container class="mt-0">
      <label>
        {{'Zuständig' | mrTranslate}}
      </label>
      <input clrInput type="text" readonly 
        class="ellipsis"
        [ngModel]="objekt?.verantwortlich"
        [ngModelOptions]="{standalone: true}"
      />
    </clr-input-container>
    -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="sendMultiWartung(multiModal)">
      {{'OK' | mrTranslate}}
    </button>
    <button type="button" class="btn" (click)="multiModal.close()">
      {{'Abbrechen' | mrTranslate}}
    </button>
  </div>
</clr-modal>