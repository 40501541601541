import {
  Component,
  ViewChild,
  OnDestroy,
  ElementRef,
  EventEmitter,
  AfterViewInit,
  QueryList,
  ViewChildren,
  HostListener,
} from "@angular/core";
import { fromEvent, Subject, Subscription } from "rxjs";
import { BasicStoreService } from "../../services/BasicStore/basic-store.service";
import { ClrDatagrid, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrDropdownModule, ClrIconModule, ClrConditionalModule, ClrDatagridModule, ClrModalModule, ClrCommonFormsModule, ClrRadioModule, ClrTextareaModule, ClrInputModule } from "@clr/angular";
import { APIService } from "src/app/services/APIService/api.service";
import { delay, filter, first, startWith, takeUntil } from "rxjs/operators";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "src/app/services/Auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { getUniqueValues } from "src/app/models/ui/table";
import { ColumnFilterAction, ColumnFilterChange, ColumnFilterComponent } from "../_components/filter/column-filter/column-filter.component";
import { FilterService } from "../_components/filter/filter.service";
import { ExcelService } from "src/app/services/Shared/excel.service";
import { SharedService } from "src/app/services/Shared/shared.service";
import { db } from "src/app/services/dexieDB";
import QrScanner from "qr-scanner";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../pipes/mr-translate.pipe";
import { HideIDColumnsPipe } from "../../pipes/hide-idcolumns.pipe";
import { MrUnitTextPipe } from "../../pipes/mrUnitText.pipe";
import { TablePrettyPrintPipe } from "../../pipes/tablePrettyPrint.pipe";
import { ProgressbarComponent } from "../_components/progressbar/progressbar.component";
import { DetailviewComponent } from "../_components/detailview/detailview.component";
import { NgStyle, NgIf, NgFor, NgSwitch, NgSwitchCase, NgClass, DecimalPipe, TitleCasePipe } from "@angular/common";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";

@Component({
  selector: "app-anlagen",
  templateUrl: "./anlagen.component.html",
  styleUrls: ["./anlagen.component.scss"],
  imports: [NgStyle, NgIf, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrDropdownModule, ClrIconModule, ClrConditionalModule, NgFor, ClrDatagridModule, ColumnFilterComponent, NgSwitch, NgSwitchCase, NgClass, DetailviewComponent, ProgressbarComponent, ClrModalModule, ClrCommonFormsModule, ClrRadioModule, ClrTextareaModule, ClrInputModule, DecimalPipe, TitleCasePipe, TablePrettyPrintPipe, MrUnitTextPipe, HideIDColumnsPipe, MrTranslatePipe_1,
  ],
  standalone: true,
})
export class AnlagenComponent implements OnDestroy, AfterViewInit {
  private destroyed$ = new Subject<void>();
  production: boolean;
  table = {
    rows: [],
    columns: [],
  };
  tableRowsOriginal = [];
  rights: any;
  lpid: number = 0;
  filterService: FilterService;
  isRep = localStorage.getItem("repID") != null && localStorage.getItem("repDB") == localStorage.getItem("DB");

  iconColumns: string[] = ["typ", "sp", "st", "si", "lf", "rep"];

  _ostammid: number = undefined;
  set ostammid(val: number) {
    if (val === null) return;
    if (val !== undefined && !this.showdetailview) {
      this.showdetailview = true;
    }
    this._ostammid = val;

    var curRow = this.data.find(x => x.OSTAMMID == val);
    this.typid = curRow?.["typ_id"];
    this.copyStammBez = curRow?.["bezeichnung"];
  }

  get ostammid() {
    return this._ostammid;
  }

  @ViewChild("alvstatus") alvstatus: ElementRef;
  @ViewChild("alvbem") alvbem: ElementRef;
  @ViewChildren(ColumnFilterComponent) columnFilters: QueryList<ColumnFilterComponent>;

  showFilters = false;

  getExcelList() {
    this.excelService.exportAsExcelFile(this.data, this.mrTranslate.transform('Anlagen'));
  }
  getQRCode() {
    this.qrScanModal = true;
    setTimeout(() => {
      const videoelem = document.getElementById("video") as HTMLVideoElement;
      const qrScanner = new QrScanner(
        videoelem, (result) => {
          console.log('decoded qr code:', result);
          if (result.data.length > 6) {
            this.restoreSelection(parseInt(result.data));
            this.qrScanModal = false;
          }

        }, {}
      );
      qrScanner.start();
    }, 200);

  }



  getSammelausdruck() {
    this.overlayService.setOverlay({
      overlay: "sammeldialog",
      table: this.table,
      dataRefresh: this.dataRefresh,
      zindex: 5
    });
  }

  showoverlay: boolean = false;
  showdetailview: boolean = false;
  hidedetailview: boolean = false;
  showprogressbar: boolean = false;

  split50 = false;
  sidenavenlarged = false;
  progressbarenlarged = false;
  progressbars: any;
  componentStore = {};
  useFAT: boolean = false;

  indexScrub: number = -1;

  @ViewChild(ClrDatagrid, { static: false }) private grid: ClrDatagrid;
  protected page = 1;
  protected pageCount = 10;

  objectFilter = [];
  filter_invert = false;
  hasMap = true;
  token: any;

  selOPRUEFID: number = 0;
  setOSTAMMID: number = 0;
  alvHeader: string = "";
  isALVModalVisible: any = false;
  alvtyp: number = 0;
  tableUniqueColumnValues: Record<string, Set<any>>;
  sotyp: any;

  PRDEL: boolean = false;
  STDEL: boolean = false;
  otypid: number = 0;
  typid: number = 0;
  qrScanModal = false;

  copyStammModal = false;
  copyStammBez = '';
  typ: any;
  @ViewChild("newStammBez") newStammBez: ElementRef;

  constructor(
    private basicStore: BasicStoreService,
    private apiservice: APIService,
    private spinnerservice: SpinnerService,
    private router: Router,
    private authService: AuthService,
    private overlayService: OverlayService,
    private toastr: ToastrService,
    public shared: SharedService,
    private mrTranslate: MrTranslatePipe,
    private excelService: ExcelService,
  ) { }

  private loadSOTYP() {
    this.apiservice
      .getSOTYP()
      .pipe(first())
      .subscribe(val => {

        let rights = JSON.parse(this.authService.decrypt(localStorage.getItem('rights')));
        Object.entries(rights.type).forEach(
          ([key, value]) => !value[0].value && delete val[+key]
        );
        this.sotyp = Object.values(val);

      });
  }

  deleteStamm(row) {

  }

  deletePruef(row) {

  }



  ngOnInit(): void {
    this.production = this.shared.production;
    this.token = this.authService.getToken();
    this.hasMap = this.authService.decrypt(localStorage.getItem("hasMap")) === 'true' || false;
    this.spinnerservice.enable();
    this.getAnlagenStore();
    this.loadSOTYP();

    var filters = JSON.parse(sessionStorage.getItem('filter'));
    if (filters && Object.keys(filters).length > 0) {
      this.data = this.filterService.setChanges(filters)
    }
  }

  reloadAnlagen(reloadTabsWaitReady: EventEmitter<any> = undefined) {
    this.spinnerservice.enable();
    if (reloadTabsWaitReady) {
      reloadTabsWaitReady.pipe(first((isReady: any) => isReady?.refreshAnlagen)).subscribe(() => {
        this.apiservice.isRep ? db.getAnlagen() : this.getAnlagenStore();
        this.restoreSelection(this.ostammid);
      });
    } else {

      this.apiservice.getAnlagen().pipe().subscribe((data: any) => {
        let store = this.basicStore.getComponentStore('anlagen');
        this.basicStore.setComponentStore('anlagen', { ...store, anlagen: data, ostammid: this.ostammid ?? store.ostammid });
        setTimeout(() => {
          this.getAnlagenStore();
        }, 10);
      });
    }
  }
  restoreSelection(id: number) {
    setTimeout(() => {
      if (this.grid) {
        const all = this.table.rows;
        const selected = all.findIndex(row => row.OSTAMMID == id);
        this.grid.singleSelected = all[selected]?.OSTAMMID;
        this.page = Math.ceil((selected + 1) / this.pageCount);
      }
      else this.showdetailview = false;
    }, 50);
  }
  getAnlagenStore() {
    this.basicStore
      .getComponentStoreAsync("anlagen")
      .pipe(first((val) => val != undefined))
      .subscribe((componentStore) => {
        componentStore = JSON.parse(componentStore);
        this.table = componentStore["anlagen"];

        if (!this.table) return;
        console.debug("all data", this.data);
        this.tableUniqueColumnValues = getUniqueValues(this.table);
        this.tableRowsOriginal = componentStore["anlagen"]["rows"];
        const filterApplied = this.filterService?.getChanges();
        this.filterService = new FilterService(this.tableRowsOriginal);
        this.data = this.filterService.setChanges(filterApplied);
        this.showdetailview = componentStore["showdetailview"] ?? false;
        this.hidedetailview = componentStore["hidedetailview"] ?? false;
        if (componentStore["ostammid"] && !this.ostammid) {
          this.restoreSelection(componentStore["ostammid"]);
        }
        this.split50 = componentStore["split50"] ?? false;
        this.sidenavenlarged = componentStore["sidenavenlarged"] ?? false;
        this.progressbarenlarged = componentStore["progressbarenlarged"] ?? false;


        this.spinnerservice.disable();
      });
  }


  getUniqueValuesForColumn(tableValues: Record<string, Set<any>>, columnId: string): Set<any> {
    return tableValues[columnId] || new Set();
  }

  refreshSubscription: Subscription;
  dataRefresh = new EventEmitter();

  ngAfterViewInit(): void {
    fromEvent(screen.orientation, 'change').pipe(
      startWith(null), delay(500),
      takeUntil(this.destroyed$),
    ).subscribe(() => {
      const height = (this.grid?.datagrid as ElementRef<HTMLElement>)?.nativeElement.clientHeight || 540;
      this.pageCount = Math.floor((height - 40) / 50);
    });

    let optFat = localStorage.getItem("optUseFAT");
    if (optFat != null) {
      if (optFat == '1') {
        this.useFAT = true;
      }
    }
  }

  openFATObjectcreation() {
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.reloadAnlagen();
    });

    this.overlayService.setOverlay({
      overlay: "createFATObject",
      zIndex: 8,
      dataRefresh: this.dataRefresh,
    });
  }

  openSonderinspektion(row: any) {
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.reloadAnlagen();
    });

    this.overlayService.setOverlay({
      overlay: "sonderinspektionsdialog",
      ostammid: row.OSTAMMID,
      otypid: row.OTYPID,
      zIndex: 8,
      dataRefresh: this.dataRefresh,
    });
  }

  openFATPruefungcreation(ostammid: number) {
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.reloadAnlagen();
    });
    this.ostammid = ostammid;

    this.overlayService.setOverlay({
      overlay: "createFATPruefung",
      id: ostammid,
      zIndex: 8,
      dataRefresh: this.dataRefresh,
    });
  }

  openFATPruefungcreationEdit(ostammid: number, lpid: number) {
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.reloadAnlagen();
    });
    this.ostammid = ostammid;

    this.overlayService.setOverlay({
      overlay: "createFATPruefung",
      id: ostammid,
      lpid: lpid,
      zIndex: 8,
      dataRefresh: this.dataRefresh,
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.basicStore.setComponentStore("anlagen", {
      anlagen: this.table,
      ostammid: this._ostammid,
      split50: this.split50,
      sidenavenlarged: this.sidenavenlarged,
      showdetailview: this.showdetailview,
      hidedetailview: this.hidedetailview,
      progressbarenlarged: this.progressbarenlarged,
    });

    sessionStorage.setItem("filter", JSON.stringify(this.filterService.getChanges()));
    this.destroyed$.next();
    this.refreshSubscription?.unsubscribe();
  }

  enlargeSidenav(event) {
    if (event.mode == "full") {
      this.sidenavenlarged = event.val;
    } else if (event.mode == "half") {
      this.split50 = event.val;
    } else if (event.mode == "progress") {
      this.showprogressbar = event.val;
      this.progressbars = {};
    } else if (event.mode == "hidden") {
      this.hidedetailview = event.val;
    } else if (event.mode == "close") {
      this.ostammid = undefined;
      this.showprogressbar = false;
      setTimeout(() => {
        this.showdetailview = false;
      });
    }
  }

  applyFilter(event?: any) {
    if (event) {
      this.objectFilter = event;
    }
    if (this.objectFilter.length == 0) {
      this.table.rows = this.tableRowsOriginal;
    } else {
      if (!this.filter_invert) {
        this.table.rows = [
          ...this.tableRowsOriginal.filter(
            (row) => this.objectFilter.indexOf(row.OSTAMMID) != -1
          ),
        ];
      } else {
        this.table.rows = [
          ...this.tableRowsOriginal.filter(
            (row) => this.objectFilter.indexOf(row.OSTAMMID) == -1
          ),
        ];
      }
    }

    setTimeout(() => {
      this.grid.resize();
    });
  }

  invertFilter(event) {
    this.filter_invert = event;
    this.applyFilter();
  }

  handleScrub(event) {
    this.indexScrub = event;
  }

  showProgressData(data) {
    if (data) {
      data.once = false;
      this.progressbars = data;
      this.showprogressbar = true;
    }
  }

  enlargeProgressbar(val?) {
    if (val) {
      this.progressbarenlarged = true;
    } else {
      this.progressbarenlarged = !this.progressbarenlarged;
    }
  }

  openObjectInGISMap(ostammid: number) {
    this.router.navigate(["/karte"], {
      queryParams: { ostammid: ostammid },
    });
  }

  protected copyViewStamm(ostammid: number) {
    this.newStammBez.nativeElement.value = this.copyStammBez + "_" + this.mrTranslate.transform("Kopie");
    this.copyStammModal = true
  }

  protected copyStamm() {

    let newbez = this.newStammBez.nativeElement.value;

    if (newbez == '' || newbez == undefined) {
      this.toastr.warning(this.mrTranslate.transform("Geben Sie eine Bezeichung ein"));
      return;
    }

    var curRow = this.data.filter(x => x.bezeichnung == newbez && x.typ_id == this.typid);
    if (curRow.length != 0) {
      this.toastr.warning(this.mrTranslate.transform("Objekt existiert bereits"));
      return;
    }

    let toSend = {};
    toSend["bezeichung"] = newbez;

    this.apiservice
      .copyStammDaten(this.ostammid, toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res.success == true) {
          this.reloadAnlagen();

          this.copyStammModal = false;
          this.toastr.success(
            this.mrTranslate.transform("Eintrag erfolgreich gespeichert")
          );
          this.grid.resize();
        } else {
          this.toastr.warning(
            res.join("<br>"),
            this.mrTranslate.transform("Ein Fehler ist aufgetreten")
          );
        }
      });
  }


  protected openNeuStamm(typ: any) {
    this.refreshSubscription?.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.reloadAnlagen();
    });
    this.overlayService.setOverlay({
      overlay: "openStammdatenDialog",
      id: -1,
      otypid: typ.id,
      type: typ.typ,
      bezeichnung: typ.bezeichnung,
      zIndex: 5,
      dataRefresh: this.dataRefresh,
    });
  }

  set_ALV_Checked(opruefid: number, type: number) {
    this.selOPRUEFID = opruefid;
    this.alvHeader = "Rückmeldung des Anlagenverantwortlichen";

    if (type == 2) this.alvHeader = "Rückmeldung des Eisenbahnbetriebsleiters";

    this.isALVModalVisible = true;
    this.alvtyp = type;
  }

  updateALVStatus() {
    let b = this.alvbem.nativeElement.value;
    let s = this.alvstatus.nativeElement.value;
    let toSend = {};
    toSend["OPRUEFID"] = this.selOPRUEFID;
    toSend["Status"] = s;
    toSend["Bemerkung"] = b;
    toSend["Type"] = this.alvtyp;

    this.apiservice
      .setAnlagenALVStatus(toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res.status && res.status == "OK") {
          this.reloadAnlagen();

          this.isALVModalVisible = false;
          this.toastr.success(
            this.mrTranslate.transform("Eintrag erfolgreich gespeichert")
          );
          this.grid.resize();
        } else {
          this.toastr.warning(
            res.join("<br>"),
            this.mrTranslate.transform("Ein Fehler ist aufgetreten")
          );
        }
      });
  }

  deleteAllFilters() {
    this.filterService.clearFilters();
    if (this.columnFilters) {
      this.columnFilters.forEach(filterColumn => filterColumn.forceResetSubmit());
    }
    this.data = this.table['rows'];
  }

  data: any[] = [];

  // call setFilterIconAndButtonClass for the columnId.. the clr-dg-column usually has an id data-test-id="datagrid-maengel-col-osperrid" where "datagrid-maengel" is the "filterChanges.tableName"
  // so we need to find the column to change the active sign, right...?
  onFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.data = this.filterService.filter(columnFilterChange);
    }
  }

  normalizeId(id: string): string {
    return id.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
  }
}

export function getTokenRight(rights: any[], key: string) {
  let res = undefined;
  for (const right of rights) {
    if (right.key == key) {
      res = right.value;
      break;
    }
  }
  return res;
}
