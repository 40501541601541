<!-- <link rel="stylesheet" type="text/css" href="detailview.component.scss" /> -->
<clr-modal [(clrModalOpen)]="messungVglModal.open" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{messungVglModal.title}}</h3>
  <div class="modal-body">
    <app-plotly-plot *ngIf="messungVglModal.open" [className]="" [data]="messungVglModal.graph.data"
      [layout]="messungVglModal.graph.layout" [config]="messungVglModal.graph.config" [delay]="200">
    </app-plotly-plot>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="messungVglModal.open = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="messungVglModal.open = false">Ok</button>
  </div> -->
</clr-modal>

<div id="detailviewSwitcherTriggerWrapper"
  [style.left.px]="isShownDetailView() ? -25 : -35">
  <div id="detailviewSwitcherTrigger" class="cursor-pointer"
    (click)="toggleDetailViewIsShown()">
    <a href="javascript:void(0);" class="cursor-pointer">
      <clr-icon size="32" shape="angle-double right"
        [style.rotate.deg]="isShownDetailView() ? 0 : 180">
      </clr-icon>
    </a>
  </div>
</div>

<div id="detailviewwrapper" #detailviewwrapper [ngStyle]="{'padding-bottom': progressbarenlarged ? '75px' : ''}">
  <div *ngIf="!(loading | async)" class="h-100">
    <div style="display: flex; flex-flow: row-reverse nowrap; justify-content: space-between;">
      <clr-icon size="20" shape="times" (click)="closeHandler()" class="cursor-pointer"
        [attr.title]="'Schließen' | mrTranslate"></clr-icon>
      <clr-button-group *ngIf="showProtected"
        class="btn-icon detailViewToolbar wrap-button set-width"
        [clrMenuPosition]="'bottom-right'">
        <ng-container *w-media-query="{ min: 1000 }">
          <clr-button (click)="expand('full')">
            <clr-icon [attr.title]="'Vollbild' | mrTranslate" size="20"
              [attr.shape]="'angle ' + (expanded ? 'right' : 'left')"></clr-icon>
          </clr-button>

          <clr-button (click)="expand('half')">
            <clr-icon [attr.title]="'Splitscreen' | mrTranslate" size="20" shape="map"></clr-icon>
          </clr-button>
        </ng-container>

        <ng-container *ngIf="progVersion == 45 && authService?.token?.rights?.status > 4">
          <clr-button (click)="createInspektion()" [disabled]="deactivateAll">
            <clr-icon [attr.title]="'Neue Inspektion' | mrTranslate" size="20" shape="inspektion"></clr-icon>
          </clr-button>
        </ng-container>

        <clr-button *ngIf="showProtected.assignment && authService?.token?.rights?.status > 2"
          (click)="createAssignment($event)" [disabled]="!hasOpenMangel || deactivateAll || isRep">
          <clr-icon [attr.title]="'Auftrag erstellen' | mrTranslate" size="20" shape="pinboard"></clr-icon>
        </clr-button>

        <clr-button *ngIf="showProtected.feedback && authService?.token?.rights?.status > 2"
          (click)="createFeedback($event)" [disabled]="!hasMangel || deactivateAll || isRep">
          <clr-icon [attr.title]="'Rückmeldung anlegen' | mrTranslate" size="20" shape="redo"></clr-icon>
        </clr-button>

        <clr-button *ngIf="showProtected.interference && authService?.token?.rights?.status > 2" (click)="createDefect($event)" [disabled]="deactivateAll || isRep">
          <clr-icon [attr.title]="'Störung anlegen' | mrTranslate" size="19" shape="warning-standard"></clr-icon>
        </clr-button>

        <clr-button *ngIf="showProtected.blockings && authService?.token?.rights?.status > 2" (click)="createBlocking($event)" [disabled]="deactivateAll || isRep">
          <clr-icon [attr.title]="'Sperrung anlegen' | mrTranslate" size="20" shape="times-circle"></clr-icon>
        </clr-button>

        <clr-button *ngIf="showProtected.speedRestrictions && authService?.token?.rights?.status > 2"
          (click)="createLAStelle($event)" [disabled]="deactivateAll || isRep">
          <clr-icon [attr.title]="'Langsamfahrstelle anlegen' | mrTranslate" size="20" shape="lastelle" class="margin-3-t"></clr-icon>
        </clr-button>

        <ng-container [el-media-query]="{ max: 655, el: detailviewwrapper }" debounce_time="10" #q="query">

          <clr-button *ngIf="authService?.token?.rights?.status > 2 && STEDIT == true" [clrInMenu]="q.matches || (!split50 && !expanded)"
            (click)="editStammdaten($event)" [disabled]="deactivateAll || deactivateRep">
            <clr-icon [attr.title]="'Stammdaten bearbeiten' | mrTranslate" size="20" shape="form" class="margin-3-t"></clr-icon>
            <span class="clr-icon-title">&nbsp;{{'Stammdaten' | mrTranslate}}</span>
          </clr-button>

          <clr-button *ngIf="authService?.token?.rights?.status > 2 && SPEDIT == true" [clrInMenu]="q.matches || (!split50 && !expanded)"
            (click)="createSpezifikationen()" [disabled]="deactivateAll || deactivateRep">
            <clr-icon [attr.title]="'Spezifikation bearbeiten' | mrTranslate" size="20" shape="list"></clr-icon>
            <span class="clr-icon-title">&nbsp;{{'Spezifikation' | mrTranslate}}</span>
          </clr-button>

          <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="openHerstellModal()">
            <clr-icon [attr.title]="'Herstelldaten' | mrTranslate" size="20" shape="factory"></clr-icon>
            <span class="clr-icon-title">&nbsp;{{'Herstelldaten' | mrTranslate}}</span>
          </clr-button>

          <clr-button *ngIf="typ_Line_Point == 4 && authService?.token?.rights?.status > 2 && BWEDIT == true"
            [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getBauwerke()" [disabled]="deactivateAll || deactivateRep">
            <clr-icon [attr.title]="'Bauwerke' | mrTranslate" size="20" shape="bauwerke"></clr-icon>
            <span class="clr-icon-title">&nbsp;{{'Bauwerke' | mrTranslate}}</span>
          </clr-button>

          <clr-button *ngIf="objOpt?.Opt_F_BUESicht == 1"
            [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getBahnuebergaenge()">
            <clr-icon [attr.title]="'Bahnuebergaenge' | mrTranslate" size="20" shape="bahnuebergang"></clr-icon>
            <span class="clr-icon-title">&nbsp;{{'Bahnübergänge' | mrTranslate}}</span>
          </clr-button>

          <ng-container *ngIf="typ_Line_Point | objtype: 'line'">
            <ng-container *ngIf="!isRep">


            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getProfile()">
              <clr-icon [attr.title]="'Profil-Viewer' | mrTranslate" size="20" shape="line-chart"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Profil-Viewer' | mrTranslate}}</span>
            </clr-button>

            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getMessungen()">
              <clr-icon [attr.title]="'Messungen' | mrTranslate" size="19" shape="ruler-pencil"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Messungen' | mrTranslate}}</span>
            </clr-button>

            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getMessungenLWM()">
              <clr-icon [attr.title]="'Längswelligkeismessungen' | mrTranslate" size="19" shape="tick-chart"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Längswelligkeismessungen' | mrTranslate}}</span>
            </clr-button>

            <ng-container *ngIf="objOpt?.Opt_F_Trassierung == 1 && authService?.token?.rights?.status > 2 && TRAEDIT == true">
              <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="setTrassierung()" [disabled]="deactivateAll">
                <clr-icon [attr.title]="'Trassierungseditor' | mrTranslate" size="19" shape="bell-curve"></clr-icon>
                <span class="clr-icon-title">&nbsp;{{'Trassierungseditor' | mrTranslate}}</span>
              </clr-button>
            </ng-container>

            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getMangelverteilung()">
              <clr-icon [attr.title]="'Mangel-/Bauformverteilung' | mrTranslate" size="19" shape="box-plot"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Mangel-/Bauformverteilung' | mrTranslate}}</span>
            </clr-button>
          </ng-container>
          </ng-container>

          <ng-container *ngIf="objOpt?.Opt_O_UseKomponenten == 1 && authService?.token?.rights?.status > 2 && KOMEDIT == true && !isRep">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getKomponenten()">
              <clr-icon [attr.title]="'Komponenten' | mrTranslate" size="20" shape="tree-view" solid="true"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Komponenten' | mrTranslate}}</span>
            </clr-button>
          </ng-container>

          <ng-container *ngIf="objOpt?.Opt_O_UseRailProfil == 1">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getRailmonitor()">
              <clr-icon [attr.title]="'Railmonitor' | mrTranslate" size="20" shape="line-chart"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Railmonitor' | mrTranslate}}</span>
            </clr-button>
          </ng-container>

          <ng-container *ngIf="hasStellkraftmessung && objOpt?.Opt_F_Stellkraftmessung == 1 && !isRep">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="openStellkraftmessung()">
              <clr-icon [attr.title]="'Stellkraftmessung' | mrTranslate" size="20" shape="curve-chart"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Stellkraftmessung' | mrTranslate}}</span>
            </clr-button>
          </ng-container>

          <ng-container *ngIf="excelPath">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="downloadExcelFile()">
              <clr-icon [attr.title]="'Excel' | mrTranslate" size="20" shape="file"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Excel' | mrTranslate}}</span>
            </clr-button>
          </ng-container>

          <ng-container *ngIf="opruefid !== 0">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="getPDFFile()"  [disabled]="isRep">
              <clr-icon [attr.title]="'PDF' | mrTranslate" size="20" shape="pdffile"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'PDF' | mrTranslate}}</span>
            </clr-button>
          </ng-container>

          <!--<ng-container *ngIf="STDEL == true">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="deleteStamm()">
              <clr-icon [attr.title]="'Stamm löschen' | mrTranslate" size="20" shape="trash"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Stamm löschen' | mrTranslate}}</span>
            </clr-button>
          </ng-container>

          <ng-container *ngIf="opruefid !== 0 && PRDEL == true">
            <clr-button [clrInMenu]="q.matches || (!split50 && !expanded)" (click)="deletePruef()">
              <clr-icon [attr.title]="'Prüfung löschen' | mrTranslate" size="20" shape="eraser"></clr-icon>
              <span class="clr-icon-title">&nbsp;{{'Prüfung löschen' | mrTranslate}}</span>
            </clr-button>
          </ng-container>-->

        </ng-container>
      </clr-button-group>
    </div>


    <clr-tabs *ngIf="ostammid && activeTab"
      dynamicOverflow class="w-100-p overflow-tab">

      <!-- Stammdaten -->
      <clr-tab class="w-100-p">
        <button clrTabLink (click)="tabChange('stammdaten')" [title]="'Stammdaten' | mrTranslate">{{'Stammdaten' |  mrTranslate}}</button>
        <ng-template [(clrIfActive)]="activeTab['stamm']">
          <clr-tab-content>
            <div class="padding-10-t">
              <strong class="caption nm">
                {{ bezeichnung || ('Objekt' | mrTranslate) }}
              </strong>
              <app-image-slide-show [data]="files$ | async"></app-image-slide-show>
              <div *ngIf="skizzeData">
                <span>{{ 'Messpunktskizze' | mrTranslate }}</span><br />
                <app-image-slide-show [data]="[{ base64: skizzeData }]" invert height="25vh">
                </app-image-slide-show>
              </div>
            </div>

            <ng-container *ngIf="showKAV == true">
            <div class="kavbar card" *ngIf="kav.value && showProtected.kav">
              <clr-checkbox-container class="clr-form-control" style="margin-top: 0px;">
                <clr-checkbox-wrapper>
                  <label>{{'KAV aktiv' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox (change)="kavaktiv = $event" [checked]="kavaktiv === true" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <div *ngIf="kavaktiv">
                <span style="margin-right:0.5em">{{kav.value}}</span>
                <clr-icon shape="circle" class="is-solid" [ngStyle]="{'color': kav.color}">
                </clr-icon>
              </div>
            </div>
            </ng-container>
<!-- [style.height]="kav.value && showProtected.kav ? 'calc(100% - 130px)' : 'calc(100% - 80px)'" -->
            <div *ngIf="tables['stamm'] !== undefined" style="overflow: auto;">
              <table class="table margin-0-t table-compact nowrap">
                <thead>
                  <tr>
                    <th class="left" style="width: 25%;">{{'Objekt' | mrTranslate}}</th>
                    <th class="left">{{'Bezeichnung' | mrTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of tables['stamm']['rows']">
                    <ng-container *ngIf="!item.isHeader; else header">
                      <td class="left">
                        {{ (item['Translate'] ? (item.Objekt | mrTranslate) : item.Objekt) | mrUnitText }}
                      </td>
                      <td class="left" [innerHTML]="item.Bezeichnung | tablePrettyPrint"></td>
                    </ng-container>
                    <ng-template #header>
                      <th class="headerRow left">
                        {{ item['Translate'] ? (item.Objekt | mrTranslate) : item.Objekt }}
                      </th>
                      <th class="headerRow"></th>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="width100 w-100-p">
              <clr-textarea-container class="clr-form-control" style="margin: 3px 0 1px -8px;">
                <label class="clr-col-md-4">{{'Bemerkung' | mrTranslate}}</label>
                <textarea class="clr-col-md-12" clrTextarea [(ngModel)]="bemerkung"></textarea>
              </clr-textarea-container>
            </div>



          </clr-tab-content>
        </ng-template>
      </clr-tab>

      <!-- Spezifikation -->
      <ng-container *ngIf="(typ_Line_Point | objtype) == 'line' && (tables['spez'])">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('spez')" [title]="('Spezifikation' | titlecase | mrTranslate)">
            {{('Spezifikation' | titlecase | mrTranslate)}}
          </button>
          <ng-template [(clrIfActive)]="activeTab['spez']">
            <clr-tab-content>
              <clr-datagrid class="datagrid-compact dg-overflow-fix" [(clrDgSingleSelected)]="selectedSpez">
                <clr-dg-column style="min-width: 50px; width: 50px;">{{ "Map" | mrTranslate }}</clr-dg-column>
                <clr-dg-column *ngFor="let column of tables['spez']['columns']" [clrDgField]="column.id+'.value'"
                  [hidden]="column.id | hideIDColumns:hideColumnsArr">
                  {{ column.id | titlecase | mrUnitText }}
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let row of tables['spez']['rows']" [clrDgItem]="row">
                  <clr-dg-cell style="min-width: 50px; width: 50px;">
                    <a href="javascript:void(0);" (click)="previewMap(row)" disabled="!geometry">
                      <clr-icon shape="search" size="20"></clr-icon>
                    </a>
                  </clr-dg-cell>
                  <clr-dg-cell class="name-cell" *ngFor="let cell of tables['spez']['columns']"
                    [hidden]="cell.id | hideIDColumns:hideColumnsArr" [innerHTML]="row[cell.id].value | tablePrettyPrint ">
                  </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{tables['spez']['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
              </clr-datagrid>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <ng-container *ngIf="(typ_Line_Point | objtype) == 'point' && (tables['spez'])">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('spez')" [title]="('Spezifikation' | titlecase | mrTranslate)">
            {{('Spezifikation' | titlecase | mrTranslate)}}
          </button>
          <ng-template [(clrIfActive)]="activeTab['spez']">
            <clr-tab-content>
              <table class="table margin--3-t table-compact nowrap">
                <thead>
                  <tr>
                    <th class="left" style="width: 25%;">{{'Objekt' | mrTranslate}}</th>
                    <th class="left">{{'Bezeichnung' | mrTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of tables['spez']" [hidden]="item.objekt == 'OSPEZID'">
                    <ng-container *ngIf="!item.isHeader; else header">
                      <td class="left">
                        {{ item.objekt | mrTranslate | mrUnitText }}
                      </td>
                      <td class="left" [innerHTML]="(item.fieldType == 'chk' ?
                        !!item.bezeichnung : item.bezeichnung) | tablePrettyPrint">
                      </td>
                    </ng-container>
                    <ng-template #header>
                      <th class="headerRow left">
                        {{ item.objekt | mrTranslate }}
                      </th>
                      <th class="headerRow"></th>
                    </ng-template>
                  </tr>
                </tbody>
              </table>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <!-- Komponenten -->
      <ng-container *ngIf="(tables['komponenten'])">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('komp')" [title]="('Komponenten' | titlecase | mrTranslate)">
            {{('Komponenten' | titlecase | mrTranslate)}}
          </button>
          <ng-template [(clrIfActive)]="activeTab['komp']">
            <clr-tab-content>
              <clr-tree>
                <clr-tree-node *clrRecursiveFor="let item of rootObjekt; getChildren: getChildren" [(clrExpanded)]="item.expanded">

                  <a class="clr-treenode-link" routerLinkActive="active" (click)='treeClick(item)'>{{item.name}}</a>
                  <!-- (click)="selectTreeNode(item)" -->
                  <!-- {{item.name}} -->
                </clr-tree-node>
              </clr-tree>
              <ng-container *ngIf="spezData.length>0">
                <div class="w-100-p scroll border-table margin-6-t">
                  <table class="table margin-0-t table-compact nowrap">
                    <thead>
                      <tr>
                        <th class="left">{{'Objekt' | mrTranslate}}</th>
                        <th class="left" style="width: 70%;">{{'Bezeichnung' | mrTranslate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let spez of spezData" [hidden]="spez.objekt == 'OSPEZID'">
                        <td class="left">{{ spez.objekt | mrTranslate }}</td>
                        <td class="left">{{ spez.bezeichnung | mrTranslate }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br/>
              </ng-container>
              <app-image-slide-show [data]="bilder"></app-image-slide-show>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>


      <!-- Mängel Gleis-->
      <ng-container *ngIf="(typ_Line_Point | objtype) == 'line' && tables['mangel']">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('mangel')" [hidden]="!tables['mangel']"
            [title]="('Mängel' | mrTranslate)">{{('Mängel' | mrTranslate)}}</button>
          <ng-template [(clrIfActive)]="activeTab['mangel']">
            <clr-tab-content>
              <clr-datagrid class="datagrid-compact h-100">
                <clr-dg-column style="min-width: 50px; width: 50px;">{{ "Map" | mrTranslate }}</clr-dg-column>
                <clr-dg-column style="min-width: 50px; width: 50px;">{{ "Log" | mrTranslate }}</clr-dg-column>
                <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                <clr-dg-column *ngFor="let column of tables['mangel']['columns']" [clrDgField]="column.id"
                  [style.min-width.px]="column.id == 'status' ? 100 : (column.id.length * 7.5) + 45"
                  style="width: 20px;">
                  <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns : ['bildpfad', 'rueckbilder']}">{{
                    column.id | titlecase | mrUnitText }}
                  </ng-container>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let row of tables['mangel']['rows']; trackBy: trackByOmazsID" [clrDgItem]="row">

                  <clr-dg-action-overflow *ngIf="row.erledigtStatus == false">
                    <button class="action-item" (click)="openBemerkung(row.bemerkung, row.OMAZSID)">{{'Bemerkung eintragen' | mrTranslate}}</button>
                    <button class="action-item" (click)="openSonderinspektion(row)">{{'Sonderinspektion anlegen' | mrTranslate}}</button>
                  </clr-dg-action-overflow>

                  <clr-dg-cell style="min-width: 50px; width: 50px;">
                    <a href="javascript:void(0);" (click)="previewMap(row)" disabled="!geometry">
                      <clr-icon shape="search" size="20"></clr-icon>
                    </a>
                  </clr-dg-cell>


                  <clr-dg-cell style="min-width: 50px; width: 50px;">
                    <a href="javascript:void(0);" (click)="mangelLog = row.OMAZSID">
                      <clr-icon shape="view-list" size="20"></clr-icon>
                    </a>
                  </clr-dg-cell>
                  <clr-dg-cell style="min-width: 50px; width: 60px;">
                    <a href="javascript:void(0);" (click)="mangelMedia = media.openMangelMedia(row.bildpfad, row.rueckbilder)"
                      [class.deactivate]="!row.bildpfad && !row.rueckbilder">
                      <clr-icon shape="image-gallery" size="20"></clr-icon>
                    </a>
                  </clr-dg-cell>
                  <ng-container *ngFor="let cell of tables['mangel']['columns']">
                      <clr-dg-cell class="name-cell"
                        [style.min-width.px]="cell.id.length * 7.5 + 45"
                        style="width: 20px;"
                        [innerHTML]="((cell.id == 'von' || cell.id == 'bis') ? (row[cell.id] | roundTo: 2) : row[cell.id]) | tablePrettyPrint "
                        >
                      </clr-dg-cell>
                  </ng-container>
                </clr-dg-row>
                <clr-dg-footer>
                  {{tables['mangel']['rows'].length}} {{'Zeilen' | mrTranslate}}
                </clr-dg-footer>
              </clr-datagrid>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <!-- Mängel Punkt-->
      <ng-container *ngIf="(typ_Line_Point | objtype) == 'point' && tables['mangel']">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('mangel')" [title]="('mangel' | titlecase | mrTranslate)">{{('mängel' |
            titlecase | mrTranslate)}}</button>
          <ng-template [(clrIfActive)]="activeTab['mangel']">
            <clr-tab-content>
              <clr-datagrid class="datagrid-compact">
                <clr-dg-column [style.width.px]="15">{{ "Log" | mrTranslate }}</clr-dg-column>
                <clr-dg-column [style.width.px]="15">{{ "Medien" | mrTranslate }}</clr-dg-column>
                <clr-dg-column *ngFor="let column of tables['mangel']['columns']" [clrDgField]="column.id">
                  <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns : ['bildpfad', 'rueckbilder']}">
                    {{ column.id | titlecase | mrUnitText }}
                  </ng-container>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let row of tables['mangel']['rows']; trackBy: trackByOmazsID" [clrDgItem]="row">

                  <clr-dg-action-overflow *ngIf="row.erledigtStatus == false">
                    <button class="action-item" (click)="openBemerkung(row.bemerkung, row.OMAZSID)">{{'Bemerkung eintragen' | mrTranslate}}</button>
                    <button class="action-item" (click)="openSonderinspektion(row)">{{'Sonderinspektion anlegen' | mrTranslate}}</button>
                  </clr-dg-action-overflow>


                  <clr-dg-cell>
                    <a href="javascript:void(0);" (click)="mangelLog = row.OMAZSID">
                      <clr-icon shape="view-list" size="20"></clr-icon>
                    </a>
                  </clr-dg-cell>
                  <clr-dg-cell>
                    <a href="javascript:void(0);" (click)="mangelMedia = media.openMangelMedia(row.bildpfad, row.rueckbilder)"
                    [class.deactivate]="!row.bildpfad && !row.rueckbilder">
                      <clr-icon shape="image-gallery" size="20"></clr-icon>
                    </a>
                  </clr-dg-cell>
                  <ng-container *ngFor="let cell of tables['mangel']['columns']">
                      <clr-dg-cell class="name-cell" [innerHTML]="row[cell.id] | tablePrettyPrint ">
                      </clr-dg-cell>
                  </ng-container>
                </clr-dg-row>
                <clr-dg-footer>{{tables['mangel']['rows'].length}} {{'Zeilen' |
                  mrTranslate}}</clr-dg-footer>
              </clr-datagrid>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <!-- Messungen Punkt-->
      <ng-container *ngIf="(typ_Line_Point | objtype) == 'point' && (tables['messungen'] || tables['zaehler'])">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('messungen')"
            [title]="('messungen' | titlecase | mrTranslate)">
            {{('Messungen' | titlecase | mrTranslate)}}
          </button>

          <ng-template [(clrIfActive)]="activeTab['messungen']">
            <clr-tab-content>
              <div #skizzeRef>
                <ng-container *ngIf="skizzeData">
                  <span>{{ 'Skizze' | mrTranslate }}</span><br />
                  <app-image-slide-show [data]="[{ base64: skizzeData }]" invert height="25vh">
                  </app-image-slide-show>
                </ng-container>
              </div>

              <div class="tablemessung" *ngIf="tables['zaehler']; else dgMessungen">
                <clr-accordion class="zaehlerAccordion">
                  <clr-accordion-panel *ngIf="tables['messungen']">
                    <clr-accordion-title>{{'Messungen' | mrTranslate}}</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                      <div *ngTemplateOutlet="dgMessungen"></div>
                    </clr-accordion-content>
                  </clr-accordion-panel>
                    <clr-accordion-panel *ngFor="let zTable of tables['zaehler'] | keyvalue">
                      <clr-accordion-title>{{'' + zTable.key}}</clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>
                        <clr-datagrid class="datagrid-compact h-100 w-100-p">
                          <!-- <clr-dg-column>{{'Vgl.' | mrTranslate}}</clr-dg-column> -->
                          <clr-dg-column *ngFor="let column of zTable.value['columns']" [clrDgField]="column.id"
                            [hidden]="column.id | hideIDColumns">
                            <ng-container
                              *clrDgHideableColumn="{hidden: column.id | lowercase | hideIDColumns:hideColumnsArr}">
                              {{ (column.id.includes("SR") || column.id.includes("SRG")) ? (column.id | mrUnitText) :
                              (column.id
                              | titlecase | mrUnitText) }}
                            </ng-container>
                          </clr-dg-column>

                          <clr-dg-row *clrDgItems="let row of zTable.value['rows']">
                            <!-- <clr-dg-cell class="name-cell">
                                <a href="javascript:void(0);" (click)="openMessungVgl(row)">
                                  <clr-icon shape="line-chart" size="20"></clr-icon>
                                </a>
                              </clr-dg-cell> -->
                            <clr-dg-cell class="name-cell" *ngFor="let cell of zTable.value['columns']"
                              [hidden]="cell.id | hideIDColumns"
                              [innerHTML]="row[cell.id] | tablePrettyPrint "></clr-dg-cell>
                          </clr-dg-row>
                          <clr-dg-footer>
                            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="30">
                              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                              {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                            </clr-dg-pagination>
                          </clr-dg-footer>
                        </clr-datagrid>
                      </clr-accordion-content>
                    </clr-accordion-panel>
                </clr-accordion>
              </div>

              <ng-template #dgMessungen>
                <clr-datagrid [dymanicStyle]="skizzeRef" class="datagrid-compact h-100">
                  <clr-dg-column>{{'Vgl.' | mrTranslate}}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tables['messungen']['columns']" [clrDgField]="column.id">
                    <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:hideColumnsArr}">
                      {{ (column.id.includes("SR") || column.id.includes("SRG")) ? (column.id | mrUnitText) :
                      (column.id
                      | titlecase | mrUnitText) }}
                    </ng-container>
                  </clr-dg-column>

                  <clr-dg-row *clrDgItems="let row of tables['messungen']['rows']">
                    <clr-dg-cell class="name-cell">
                      <a href="javascript:void(0);" (click)="openMessungVgl(row)">
                        <clr-icon shape="line-chart" size="20"></clr-icon>
                      </a>
                    </clr-dg-cell>
                    <clr-dg-cell class="name-cell" *ngFor="let cell of tables['messungen']['columns']"
                      [innerHTML]="row[cell.id] | tablePrettyPrint "></clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="30">
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </ng-template>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <!-- Dokumente -->
      <ng-container *ngIf="(tables['dokheader'])">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('dokumente')"
            [title]="('dokumente' | titlecase | mrTranslate)">
            {{('Dokumente' | titlecase | mrTranslate)}}
          </button>
          <ng-template [(clrIfActive)]="activeTab['dokumente']">
            <clr-tab-content>
              <div class="dokumenteControls btn-group btn-primary btn-sm padding-10-t wrap-button set-width">
                <button class="btn" (click)="addDok()"><clr-icon shape="plus"></clr-icon> {{ "Dokument" | mrTranslate }}</button>

                <ng-container *ngIf="authService?.token?.rights?.status == 6 || authService.token.rights.dev == true">
                  <button class="btn" (click)="addKategorie()"><clr-icon shape="plus"></clr-icon> {{ "Kategorie" | mrTranslate }}</button>
                  <button class="btn" (click)="editKategorie()"><clr-icon shape="note"></clr-icon> {{ "Kategorie" | mrTranslate }}</button>
                  <button *ngIf="selAnzDok == 0" class="btn" (click)="delKategorie()"><clr-icon shape="times"></clr-icon> {{ "Kategorie" | mrTranslate }}</button>
                </ng-container>

                <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}" style="display:none;"/>
              </div>

              <clr-tabs dynamicOverflow class="overflow-tab">
                <clr-tab *ngFor="let tab of docheader; let i = index;" [attr.data-index]="i">
                    <button clrTabLink (click)="doctabChange(i)">{{tab}}</button>
                    <clr-tab-content>
                      <ng-container *ngIf="docs['rows'].length !== 0; else notdocsintab">

                        <clr-datagrid class="datagrid-compact" [(clrDgSingleSelected)]="selecteddocrow" (clrDgSingleSelectedChange)="rowDocChanged($event)">
                          <clr-dg-column *ngFor="let column of docs['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns: hideColumnsArr">
                            {{ column.id | titlecase | mrTranslate }}
                          </clr-dg-column>
                          <clr-dg-row *clrDgItems="let row of docs['rows']" [clrDgItem]="row">

                            <clr-dg-action-overflow>
                              <button class="action-item" (click)="openDokument(row)">{{'Öffnen' | mrTranslate}}</button>
                              <button class="action-item" (click)="deleteDokument(row)">{{'Löschen' | mrTranslate}}</button>
                            </clr-dg-action-overflow>

                            <clr-dg-cell class="name-cell" *ngFor="let cell of docs['columns']" [hidden]="cell.id | hideIDColumns: hideColumnsArr" [innerHTML]="row[cell.id] | tablePrettyPrint ">
                            </clr-dg-cell>
                          </clr-dg-row>
                          <clr-dg-footer>{{docs['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
                        </clr-datagrid>
                      </ng-container>
                    <ng-template #notdocsintab>
                      {{'Es sind keine Dokumente vorhanden' | mrTranslate}}
                    </ng-template>
                    </clr-tab-content>
                </clr-tab>
              </clr-tabs>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <!-- Historie -->
      <clr-tab class="w-100-p">
        <button clrTabLink (click)="tabChange('historie')" [disabled]="!showProtected.history"
          [title]="'Historie' | mrTranslate">{{'Historie' | mrTranslate}}</button>
        <clr-tab-content>
          <app-historie-tree *ngIf="initTab['historie']" [ostammid]="ostammid"></app-historie-tree>
        </clr-tab-content>
      </clr-tab>

      <!-- Restliche Tabellen -->
      <ng-container *ngFor="let table of (tables | keyvalue) | array:'filter': getRestTabellen">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange(table.key)"
            [title]="table.key == 'spez' ?
              ('Spezifikation' | mrTranslate)
              : (table.key | titlecase | mrTranslate)">
            {{table.key == 'spez' ?
              ('Spezifikation' | mrTranslate)
              : (table.key | titlecase | mrTranslate)}}</button>
          <ng-template [(clrIfActive)]="activeTab[table.key]">
            <clr-tab-content>
              <clr-datagrid class="datagrid-compact">
                <clr-dg-column style="min-width: 50px; width: 50px;">{{ "Map" | mrTranslate }}</clr-dg-column>
                <clr-dg-column *ngFor="let column of table.value?.columns" [clrDgField]="column.id">
                  <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns: hideColumnsArr}">
                    {{ column.id | titlecase | mrTranslate }}</ng-container>
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of table.value?.rows">
                  <clr-dg-cell *ngIf="table.key == 'bauwerke'" style="min-width: 50px; width: 50px;">
                      <a href="javascript:void(0);" (click)="previewMap(row)" disabled="!geometry">
                        <clr-icon shape="search" size="20"></clr-icon>
                      </a>
                  </clr-dg-cell>
                  <clr-dg-cell class="name-cell" *ngFor="let cell of table.value?.columns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint ">
                  </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{ table.value?.rows?.length ?? 0 }} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
              </clr-datagrid>
            </clr-tab-content>
          </ng-template>
        </clr-tab>
      </ng-container>

      <!-- Mangelskizzen -->
      <ng-container *ngIf="(typ_Line_Point | objtype) == 'point' && objOpt?.Opt_F_Drawing == 1">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('paint')"
            [title]="'Mangelskizzen' | mrTranslate">{{'Mangelskizzen' | mrTranslate}}</button>
          <clr-tab-content>
            <app-painting [setostammid]="ostammid" [setinittyp]="1" [setUserStatus]="userstatus"></app-painting>
          </clr-tab-content>
        </clr-tab>
      </ng-container>

    </clr-tabs>

    <app-mangel-log-modal *ngIf="mangelLog" [(open)]="mangelLog"></app-mangel-log-modal>

  </div>
</div>

<app-sonderinspektionmodal></app-sonderinspektionmodal>

<!-- Modalfenster "Hersteller" -->
<app-herstell-daten-modal *ngIf="isHerstellDatenVisible"
  (onClose)="closeHerstellModal($event)"
  [disable]="deactivateAll || deactivateRep"
  [ospezid]="selectedSpezID">
</app-herstell-daten-modal>


<clr-modal [(clrModalOpen)]="isKatVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{TitelKat}}</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row">
      <div class="clr-col-12">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_group_name_new" class="clr-input combobox" placeholder="{{'Kategorie' | mrTranslate}}" name="_alias" #kategorie />
          </div>
        </div>
        <br>
      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveKategorie()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isKatVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>


<app-mediaviewmodal *ngIf="mangelMedia"
  (onClose)="mangelMedia = undefined"
  [srcs]="mangelMedia"
  [getMediaFromServerAll]="media.getApiFunctionMedia"
  [getBigFileFnc]="media.getApiFunctionMediaBig">
</app-mediaviewmodal>


<clr-modal [(clrModalOpen)]="showbemerkung">
  <h3 class="modal-title">{{'Bemerkung eintragen' | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm>
      <clr-textarea-container style="margin-top: -10px;">
        <textarea id="aa" class="clr-col-md-12" placeholder="Tragen Sie hier die Bemerkung für den Mangel ein"
          clrTextarea [(ngModel)]="mangelbemerkung" name="bemerkung" required
          style=" width:100%;height:150px"></textarea>
      </clr-textarea-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveMangelBemerkung()">
      {{"Bemerkung speichern" | mrTranslate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showGIS" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'GIS Map' | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm>
      <div style="width:854px;height:580px">
        <div id="previewMap" style="width:100%; height:100%;z-index: 2;"></div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showGIS = false;">
      {{"Schließen" | mrTranslate}}</button>
  </div>
</clr-modal>



<!-- TODO: Verwendung bei Stammarchivierung, wenn das mal gemacht wird -->
<!-- <app-mediaviewmodal *ngIf="anlagenMedia"
  (onClose)="anlagenMedia = undefined"
  [srcs]="anlagenMedia"
  [getMediaFromServerAll]="anlMedia.getApiFunctionMedia"
  [getBigFileFnc]="anlMedia.getApiFunctionMediaBig">
</app-mediaviewmodal> -->
