import { inject, Pipe, PipeTransform } from '@angular/core';
import { ZählerWert } from './zaehler.types';
import { SollMinusPlusOptions, ZArtLongNames, ZArtOptions, ZType } from './zaehler.enums';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';

@Pipe({
  name: 'werteRowValues',
  standalone: true
})
export class WerteRowValuesPipe implements PipeTransform {

  private mrTranslate = inject(MrTranslatePipe);

  transform(wert: ZählerWert, colName: keyof ZählerWert): unknown {
    let option: keyof SollMinusPlusOptions;
    switch (colName) {
      case 'Soll':
        option = 'soll';
        break;
      case 'SRlimplus':
      case 'SRGplus':
      case 'SR100plus':
        option = 'plus';
        break;
      case 'SRlimminus':
      case 'SR100minus':
      case 'SRGminus':
        option = 'minus';
        break;
      case 'ZArt':
        return this.mrTranslate.transform(ZArtLongNames.get(+wert.ZArt));
      case 'ZType':
        return wert.ZType == ZType.Header;
      default: 
        return wert[colName];
    }

    let text: string = ZArtOptions.get(+wert.ZArt)?.[option]?.text ?? '';
    // const genau = option == 'soll' ? wert.SGenau : wert.TGenau;
    if (text == 'Ja') 
      text = this.mrTranslate.transform('Ja');
    return wert[colName]
      ? `${text} ${wert[colName]/* ?.toFixed(genau) */}`
      : text;
  }
}