import { Pipe, PipeTransform } from '@angular/core';
import { Dayjs } from 'dayjs';

@Pipe({
  name: 'dayjs_to_date'
})
export class ToDateDayjsPipe implements PipeTransform {

  transform(date: Dayjs): Date {
    return date.toDate();
  }

}
