<header class="header-2" style="min-height:4rem;">
  <div class="branding">
    <span class="title">{{'Werksabnahme - neues Objekt erstellen' | mrTranslate | titlecase }}</span>
  </div>
  <div class="header-actions" style="min-height:4rem;">


    <form id="fatobjekterstellendialog-gruppe-form" style="padding: 0px;padding-right:1.5rem;"
      (ngSubmit)="sendForFormulare()">
      <div class="clr-row">
        <!-- Interne Gruppe (only shown for Admin) -->
        <div class="clr-col-6" *ngIf="showInternalDropdown">
          <div class="clr-form-control" style="margin-top:0.3rem;">
            <label for="internegruppeid" class="clr-control-label" style="color:#FAFAFA;">
              {{ internalDropdownTitle | mrTranslate }}
            </label>
            <div class="clr-control-container" [ngClass]="{ 'clr-error': kunde.length > 0 && !gruppeinternegruppeid }">
              <div class="clr-select-wrapper">
                <select id="internegruppeid" class="clr-select" name="gruppeinternegruppeid" [disabled]="!gruppeEnabled"
                  [(ngModel)]="gruppeinternegruppeid" required style="color:#FAFAFA;min-width: 10rem;">
                  <option *ngFor="let val of internerkunde" [value]="val.ID">
                    {{ val.Bezeichnung | oktaGroupName }}
                  </option>
                </select>
                <cds-icon *ngIf=" kunde.length > 0 && !gruppeinternegruppeid" class="clr-validate-icon"
                  shape="exclamation-circle"></cds-icon>
              </div>
              <span class="clr-subtext" *ngIf=" kunde.length > 0 && !gruppeinternegruppeid">
                {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
              </span>
              <span class="clr-subtext" *ngIf=" kunde.length === 0">{{ "Interne Gruppe Setup Dropdown" | mrTranslate }}</span>
            </div>
          </div>
        </div>

        <!-- Kunden Gruppe (always shown) -->
        <div class="clr-col-6">
          <div class="clr-form-control" style="margin-top:0.3rem;">
            <label for="gruppeid" class="clr-control-label" style="color:#FAFAFA;">
              {{ customerDropdownTitle | mrTranslate }}
            </label>
            <div class="clr-control-container" [ngClass]="{ 'clr-error':  kunde.length > 0 && !gruppegruppeid }">
              <div class="clr-select-wrapper">
                <select id="gruppeid" class="clr-select" name="gruppegruppeid" [(ngModel)]="gruppegruppeid" required
                  [disabled]="!gruppeEnabled" style="color:#FAFAFA;min-width: 10rem;">
                  <option *ngFor="let val of kunde" [value]="val.ID">
                    {{ val.Bezeichnung | oktaGroupName }}
                  </option>
                </select>
                <cds-icon *ngIf=" kunde.length > 0 && !gruppegruppeid" class="clr-validate-icon"
                  shape="exclamation-circle"></cds-icon>
              </div>
              <span class="clr-subtext" *ngIf=" kunde.length > 0 && !gruppegruppeid">
                {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
              </span>
              <span class="clr-subtext" *ngIf=" kunde.length === 0">{{ "Kunden Gruppe Setup Dropdown" | mrTranslate }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>

    <a *ngIf="editable" href="javascript://" class="nav-link nav-icon" [class.deactivate]="editable"><clr-icon
        shape="plus-circle" (click)="createGroup()"></clr-icon></a>
    <a href="javascript://" class="nav-link nav-icon"><clr-icon shape="floppy"
        (click)="sendForFormulare()"></clr-icon></a>
    <a href="javascript://" class="nav-link nav-icon"><clr-icon shape="times" (click)="close()"></clr-icon></a>
  </div>
</header>

<div class="overlay-inner-wrapper" style="height: calc(100% + 60px);">
  <div id="datenTabs">
    <clr-tabs>
      <!-- 1. TAB: FICHE DE CONTRÔLE -->
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [title]="'Formularerstellung' | mrTranslate">{{ 'Weiche' | mrTranslate
          | titlecase }}</button>
        <clr-tab-content *clrIfActive>
          <div id="formularerstellungsWrapper">
            <div class="clr-row">
              <div class="clr-col-3">
              </div>
              <div class="clr-col-12 clr-col-lg-6">

                <form clrForm class="clr-form clr-form-horizontal" *ngIf="formReflectData.length > 0">
                  <ng-container *ngFor="let reflectData of formReflectData">
                    <h1 id="mainTitle" *ngIf="reflectData.mainTitle"> {{ reflectData.mainTitle }}</h1>
                    <h4 id="title" *ngIf="reflectData.title">{{ reflectData.title }}</h4>
                    <ng-container *ngIf="reflectData.subheading">
                      <ng-container *ngIf="reflectData.fieldType === 'text'">
                        <div class="objektTyp1">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">
                              <input clrInput style="width: 300px;" type="text" [(ngModel)]="reflectData.value"
                                [ngModelOptions]="{ standalone: true }" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="reflectData.fieldType === 'dropdown'">
                        <div class="objektTyp2">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">

                              <input clrInput style="width: 300px;" type="number" min="0"
                                [(ngModel)]="reflectData.value" [ngModelOptions]="{ standalone: true }" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="reflectData.fieldType === 'date'">
                        <div class="objektTyp3">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">

                              <input clrInput style="width: 300px;" type="date" [(ngModel)]="reflectData.value"
                                [ngModelOptions]="{ standalone: true }" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="reflectData.fieldType === 'checkbox'">
                        <div class="objektTypCheckbox">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">
                              <clr-checkbox-container>
                                <clr-checkbox-wrapper>
                                  <input type="checkbox" clrCheckbox [(ngModel)]="reflectData.value" class="cb" />
                                </clr-checkbox-wrapper>
                              </clr-checkbox-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <br>
                  <br>
                  <br>
                  <br>
                </form>
              </div>
            </div>

          </div>
        </clr-tab-content>
      </clr-tab>

      <!-- 2. TAB: Auswahl Zählerarten -->
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [title]="'Tabellenerstellung' | mrTranslate">{{ 'Messwerte' |
          mrTranslate | titlecase }}</button>
        <clr-tab-content *clrIfActive>
          <div id="formularerstellungsWrapper">
            <div class="clr-row">
              <div class="clr-col-3">
              </div>
              <div class="clr-col-12 clr-col-lg-6" *ngIf="formulare">
                <h3 id="title">{{ 'Messarten auswählen' | mrTranslate }}:</h3>

                <div>
                  <clr-accordion>
                    <clr-accordion-panel *ngFor="let tabelle of tabellenNames"
                      (clrAccordionPanelOpenChange)="panelChange($event, tabelle)">
                      <clr-accordion-title>

                        <clr-checkbox-wrapper>
                          <input type="checkbox" clrCheckbox
                            [checked]="tabellenCheckboxStates[selectedVorlageName][tabelle.ID]"
                            (change)="onCheckboxChange(tabelle.ID, $event.target.checked)" />
                          <label>{{ tabelle.Bezeichnung }} | {{ getCode(tabelle.ID) }}</label>
                        </clr-checkbox-wrapper>
                      </clr-accordion-title>
                      <clr-accordion-content *clrIfExpanded>

                        <ng-container *ngIf="tabelle.HelpBildBase64 != null">
                          <button class="btn btn-sm" (click)="openHelper(tabelle)">
                            <clr-icon shape="help-info"></clr-icon>
                          </button>
                        </ng-container>

                        <span>{{ tabelle.bemerkung }}</span>

                        <ng-container *ngIf="tabellensourcetype == 0; else checkboxTemplate">
                          <div class="limit-height-500">
                            <clr-datagrid *ngIf="tabellen" style="padding-top: 0px;" class="datagrid-compact">
                              <clr-dg-column clrDgField="delete" [hidden]="locked">{{ 'Löschen' | mrTranslate
                                }}</clr-dg-column>
                              <clr-dg-column *ngFor="let column of tabellen['columns']" [clrDgField]="column.id"
                                [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid', 'zart']">
                                {{ column.id }}
                              </clr-dg-column>
                              <clr-dg-row *clrDgItems="let row of tabellen['rows']" [clrDgItem]="row">
                                <!-- <clr-dg-cell [hidden]="locked">
                                  <clr-icon shape="times" (click)="deleteForTabellen(row['ID'])"
                                    class="cursor-pointer"></clr-icon>
                                </clr-dg-cell> -->
                                <clr-dg-cell *ngFor="let cell of tabellen['columns']"
                                  [innerHTML]="row[cell.id] | tablePrettyPrint"
                                  [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid', 'zart']"></clr-dg-cell>
                              </clr-dg-row>
                              <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination
                                [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' |
                                mrTranslate}}</clr-dg-pagination>
                            </clr-datagrid>
                          </div>
                        </ng-container>

                        <ng-template #checkboxTemplate>
                          <clr-datagrid *ngIf="tabellen" style="padding-top: 0px;" class="datagrid-compact">
                            <!-- ! deleteForTabellen not exist -->
                            <!-- <clr-dg-column class="widthdelete" clrDgField="delete" [hidden]="locked">{{ 'Löschen' |
                              mrTranslate }}</clr-dg-column> -->
                            <clr-dg-column *ngFor="let column of tabellen['columns']" [clrDgField]="column.id"
                              [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid', 'soll', 'tol +', 'tol -', 'zart' ]">
                              {{ column.id }}
                            </clr-dg-column>
                            <clr-dg-row *clrDgItems="let row of tabellen['rows']" [clrDgItem]="row">
                              <!-- <clr-dg-cell class="widthdelete" [hidden]="locked">
                                <clr-icon shape="times" (click)="deleteForTabellen(row['ID'])"
                                  class="cursor-pointer"></clr-icon>
                              </clr-dg-cell> -->
                              <clr-dg-cell *ngFor="let cell of tabellen['columns']"
                                [innerHTML]="row[cell.id] | tablePrettyPrint"
                                [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid', 'soll', 'tol +', 'tol -' , 'zart']"></clr-dg-cell>
                            </clr-dg-row>
                            <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination
                              [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' |
                              mrTranslate}}</clr-dg-pagination>
                          </clr-datagrid>
                        </ng-template>


                        <br>
                      </clr-accordion-content>
                    </clr-accordion-panel>
                  </clr-accordion>
                </div>
                <br>
              </div>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </div>
</div>


<app-hilfe-beschreibung-modal [readonly]="true"></app-hilfe-beschreibung-modal>

<clr-modal [(clrModalOpen)]="showmodalselectVorlage" [clrModalClosable]="false" [clrModalSize]="'sm'">
  <h3 class="modal-title">{{'Vorlage auswählen' | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <select clrSelect #_vorlage_new style="width: 220px;">
        <option *ngFor="let val of vorlageNames">{{val.VorlageName}}</option>
      </select>
    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="selectVorlage()">{{'OK' | mrTranslate}}</button>
    <!-- <button type="button" class="btn" (click)="showmodalselectVorlage = false">{{'Abbrechen' | mrTranslate}}</button> -->
  </div>
</clr-modal>



<clr-modal [(clrModalOpen)]="isGroupVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{ groupModalTitle | mrTranslate }} </h3>
  <div class="modal-body">
    <form clrForm #gruppeForm clrLayout="horizontal" [formGroup]="gruppeedit" #f='ngForm' (ngSubmit)="saveIfValid()">

      <div class="clr-row">
        <div class="clr-col-12">
          <clr-datalist-container>
            <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;" for="kunde"
              class="'clr-col-md-2'">
              {{ "Firma" | mrTranslate }}
            </label>
            <input clrDatalistInput type="search" class="clr-col-md-8" formControlName="kname" name="kunde"
              style="width: 450px;" />
            <datalist>
              <option *ngFor="let obj of kundenname" [value]="obj">{{obj}}</option>
            </datalist>
            <clr-control-error *clrIfError="'required'">
              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
            </clr-control-error>
          </clr-datalist-container>
        </div>
      </div>


      <br>
      <div class="clr-row">
        <div class="clr-col-12">
          <clr-datalist-container>
            <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;" for="kunde"
              class="'clr-col-md-2'">
              {{ "Ort" | mrTranslate }}
            </label>
            <input clrDatalistInput type="search" class="clr-col-md-8" formControlName="kort" name="kunde"
              style="width: 450px;" />
            <datalist>
              <option *ngFor="let obj of kundenort" [value]="obj">{{obj}}</option>
            </datalist>
            <clr-control-error *clrIfError="'required'">
              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
            </clr-control-error>
          </clr-datalist-container>
        </div>
      </div>
      <br>
      <br>
    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="f.ngSubmit.emit(); isGroupVisible = false;">{{"OK" |
      mrTranslate}}</button>
    <button type="button" class="btn" (click)="isGroupVisible = false">{{"Abbrechen" | mrTranslate}}</button>
  </div>
</clr-modal>