import { Injectable, signal } from '@angular/core';
import { VerknüpfungsTyp } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.enums';
import { WartungsPlanNode } from '../../_overlays/wartungsverwaltung/wartung.types';

@Injectable()
export class ZaehlerStore {
  public verknüpfung = signal<VerknüpfungsTyp>(VerknüpfungsTyp.Hauptzähler);
  public isZaehlerAssigned = signal<boolean>(true);

  public isNew = signal(false);
  public isReadOnly = signal(false);
  public objekt = signal<WartungsPlanNode>(undefined);

  public onSave: (opruefid: number) => void;
}
