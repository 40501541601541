<clr-modal *ngIf="bildData" [(clrModalOpen)]="showBildModal" [clrModalSize]="'lg'" class="customModal"
  [clrModalClosable]="true" [clrModalStaticBackdrop]="false">
  <div class="modal-body">
    <img class="modalImage" [src]="bildData" />
  </div>
</clr-modal>

<header class="header-2">
  <div class="branding">
    <span class="title">{{'do Factory Acceptance Test [FAT]' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
    <a href="javascript://" class="nav-link nav-icon"><clr-icon shape="floppy" (click)="sendForFormulare()"></clr-icon></a>
    <a href="javascript://" class="nav-link nav-icon"><clr-icon shape="times" (click)="close()"></clr-icon></a>
  </div>
</header>

<div  #widthRef class="overlay-inner-wrapper" style="height: calc(100% + 60px);">
  <div id="datenTabs">
    <app-cameramodal [parentChildConnection]="openModal" (modalClosed)="onModalClose()"></app-cameramodal>
    <clr-tabs>
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [title]="'Formularerstellung' | mrTranslate">{{ vorlagename | mrTranslate | titlecase }}</button>
        <clr-tab-content *clrIfActive>
          <div id="formularerstellungsWrapper">
            <div class="clr-row">
              <div class="clr-col-3">
                <button class="btn btn-sm" (click)="triggerLock()">
                  <clr-icon [attr.shape]="locked ? 'lock' : 'unlock'"></clr-icon>
                </button>
              </div>
              <div class="clr-col-7">
                <form clrForm class="clr-form clr-form-horizontal" *ngIf="formReflectData.length > 0">
                  <ng-container *ngFor="let reflectData of formReflectData">
                    <h1 id="mainTitle" *ngIf="reflectData.mainTitle"> {{ reflectData.mainTitle }}</h1>
                    <h4 id="title" *ngIf="reflectData.title">{{ reflectData.title }}</h4>
                    <ng-container *ngIf="reflectData.subheading">
                      <ng-container *ngIf="reflectData.fieldType === 'text'">
                        <div class="objektTyp1">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">
                              <input clrInput style="width: 300px;" type="text" [(ngModel)]="reflectData.value" [ngModelOptions]="{ standalone: true }" [readonly]="locked"/>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="reflectData.fieldType === 'dropdown'">
                        <div class="objektTyp2">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">
                              <input clrInput style="width: 300px;" type="number" min="0" [(ngModel)]="reflectData.value" [ngModelOptions]="{ standalone: true }" [readonly]="locked"/>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="reflectData.fieldType === 'date'">
                        <div class="objektTyp3">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                              <ng-container *ngIf="!locked; else lockedCell">
                                <div class="clr-date-container clr-col-md-8">
                                  <input type="date" style="width: 265px;" id="date" clrDate
                                    [ngModel]="reflectData.value | localeDate"
                                    (ngModelChange)="reflectData.value = localeDate.tosql($event)"
                                    [ngModelOptions]="{standalone: true, updateOn: 'blur'}">
                                </div>
                              </ng-container>
                              <ng-template #lockedCell>
                                <div class="clr-date-container clr-col-md-8">
                                  <input clrInput style="width: 300px;" type="text"
                                    [ngModel]="reflectData.value"
                                    [ngModelOptions]="{standalone: true}" readonly>
                                </div>
                              </ng-template>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="reflectData.fieldType === 'checkbox'">
                        <div class="objektTypCheckbox">
                          <div class="comboboxcontainer clr-form-control clr-row">
                            <label class="clr-col-md-4 clr-control-label" id="subheading"
                              *ngIf="reflectData.subheading">{{ reflectData.subheading }}</label>
                            <div class="clr-control-container clr-col-md-8">
                              <clr-checkbox-container>
                                <clr-checkbox-wrapper>
                                  <input type="checkbox" clrCheckbox [(ngModel)]="reflectData.value" class="cb" [readonly]="locked"/>
                                </clr-checkbox-wrapper>
                              </clr-checkbox-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <br>
                  <br>
                  <br>
                  <br>
                </form>
              </div>
              <div class="clr-col-2">
                <br/>
                <br/>
                <div>
              <ng-container *ngIf="os === 'Windows'; else camera">
                    <button class="btn" type="button" (click)="openCamera(undefined)">
                      <clr-icon shape="camera"></clr-icon> {{'Foto' | mrTranslate}}
                    </button>
                  </ng-container>

                  <ng-template #camera>
                      <button class="btn" type="button" (click)="fileInput.click()">
                        <clr-icon shape="image-gallery"></clr-icon> {{'Foto' | mrTranslate}}
                      </button>
                      <input type="file" #fileInput id="fileInput" accept="image/*" capture="camera" name="fileInput" style="display: none;" multiple (change)="onFileChange($event)">
                  </ng-template>

                </div>
                <div class="clr-row previewThumbnailsContainer" *ngIf="previewThumbnails.length > 0"
                  [style.justify-content]="'space-evenly'">
                  <div *ngFor="let thumb of previewThumbnails" class="uploadImagePreviewContainer">
                    <a class="deletePreview" href="javascript:void(0);"
                      (click)="previewThumbnails.splice((previewThumbnails.indexOf(thumb)),1)">
                      <clr-icon shape="times-circle" class="is-solid"></clr-icon>
                    </a>
                    <a class="deletePreview1" href="javascript:void(0);"
                      (click)="showPreviewImage(thumb)">
                      <clr-icon shape="search"></clr-icon>
                    </a>
                    <img [src]="thumb.src" alt="" class="uploadImagePreview">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="content-container">
            <div id="content-area" class="content-area">
              <app-webcammodal [modalOpen]="iswebcam" (modalOpenChange)="iswebcam = $event"></app-webcammodal>
            </div>
          </div> -->

        </clr-tab-content>
      </clr-tab>

      <clr-tab *ngFor="let tab of clrtab; index as i">
        <button clrTabLink [clrTabLinkInOverflow]="i > 2" (click)="tabChange()">{{ tab.titel | titlecase }}</button>
        <clr-tab-content *clrIfActive>
          <div id="formularerstellungsWrapper">
            <div class="clr-row">
              <div class="clr-col-2">
              </div>
              <div class="clr-col-8">
                <!-- <h3 id="title">{{ 'valeurs mesurées de intermediate' | mrTranslate }}:</h3> -->
                <div>
                  <clr-accordion [clrAccordionMultiPanel]="true">
                    <clr-accordion-panel *ngFor="let item of tab.items" (clrAccordionPanelOpenChange)="panelChange($event, item)">
                      <clr-accordion-title>
                        <ng-container *ngIf="item.iscompleted === true">
                            <clr-icon shape="success-standard"  [ngStyle]="{'color': 'green'}"> </clr-icon>&nbsp;
                        </ng-container>
                        <label>{{item.title }}
                          <ng-container *ngIf="item.hasfaults === true">
                            &nbsp;<clr-icon shape="error-standard" [ngStyle]="{'color': 'red'}"></clr-icon>
                          </ng-container>
                        </label>

                      </clr-accordion-title>
                      <clr-accordion-content>
                        <ng-container *ngIf="item.SourceType === 0; else visuell">
                          <ng-container *ngIf="item.HelpBildBase64 != null">
                            <button class="btn btn-sm" (click)="openHelper(item)">
                              <clr-icon shape="help-info"></clr-icon>
                            </button>
                          </ng-container>

                          <span>{{ item.bemerkung }}</span>

                          <clr-datagrid style="padding-top: 0px;" class="datagrid-compact" [(clrDgSingleSelected)]="selectedmessrow" (clrDgSingleSelectedChange)="selectionmMessrowChanged($event)">
                            <clr-dg-column *ngFor="let column of item.tableData.columns" [clrDgField]="column.id"
                            [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid','zart', 'code']">
                            {{ (column.id | titlecase | mrTranslate) }}
                            </clr-dg-column>
                            <clr-dg-column>{{ 'Wert' | mrTranslate }}</clr-dg-column>
                            <clr-dg-column>{{ 'Fehler' | mrTranslate }}</clr-dg-column>
                            <clr-dg-column>{{ 'Bemerkung' | mrTranslate }}</clr-dg-column>
                            <clr-dg-row *clrDgItems="let row of item.tableData.rows" [clrDgItem]="row">

                              <clr-dg-action-overflow>

                                <ng-container *ngIf="os === 'Windows'; else camera">
                                  <button class="action-item" (click)="openCamera(row)">{{'Foto' | mrTranslate}}</button>

                                </ng-container>

                                <ng-template #camera>
                                  <button class="action-item" (click)="fileInput.click()">{{'Foto' | mrTranslate}}</button>
                                  <input type="file" #fileInput id="fileInput" accept=".jpg" capture="camera" name="fileInput" style="display: none;" (change)="onMessFileChange($event, row)">
                                </ng-template>
                                <button class="action-item" (click)="openBem(row)">{{'Bemerkung' | mrTranslate}}</button>
                              </clr-dg-action-overflow>

                              <clr-dg-cell *ngFor="let cell of item.tableData.columns"
                              [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid','zart', 'code']">
                              {{ row[cell.id] }}
                              </clr-dg-cell>
                              <clr-dg-cell>
                                <input clrInput style="width: 80px;" type="number" [(ngModel)]="row.istValue" (blur)="validateRow(row, item)" />
                              </clr-dg-cell>
                              <clr-dg-cell>
                                <ng-container *ngIf="row.ausfallValue !== null && row.ausfallValue !== undefined">
                                  <clr-icon shape="error-standard" size="22" class="is-solid" [ngStyle]="{'color': 'red'}"></clr-icon>
                                </ng-container>
                                <!-- {{ row.ausfallValue }} -->
                              </clr-dg-cell>
                              <clr-dg-cell>
                                <ng-container *ngIf="row.bemerkung">
                                  <clr-icon shape="info-circle" size="24" class="is-info cursor-pointer" (click)="openBem(row)"></clr-icon>
                                </ng-container>
                              </clr-dg-cell>
                            </clr-dg-row>
                            <clr-dg-footer>
                              <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                            </clr-dg-footer>
                          </clr-datagrid>
                        </ng-container>
                        <ng-template #visuell>
                          <form clrForm class="clr-form clr-form-horizontal">

                            <div class="clr-row">
                              <!-- <div class="clr-col-2">

                              </div> -->
                              <div class="clr-col-10">
                                <table class="table table-noborder table-compact clr-thead-bgcolor">
                                  <thead>
                                    <tr>
                                      <th class="left"><label>{{'Kontrolle' | mrTranslate}}</label></th>
                                      <th colspan="2"><label>{{'Zustand' | mrTranslate}}</label></th>
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  <ng-container *ngFor="let cell of item.tableData.rows">
                                      <tr>
                                        <td class="left">{{ cell.Bezeichnung }}</td>
                                        <td>
                                          <clr-radio-wrapper>
                                            <input type="radio" clrRadio name="{{ cell.Bezeichnung }}" [(ngModel)]="cell.istValue" value="0" (change)="handleChange(0, cell, item)" (click)="handleChange(0, cell, item)"/>
                                            <label>{{'Accepted' | mrTranslate}}</label>
                                          </clr-radio-wrapper>
                                        </td>
                                        <td>
                                          <clr-radio-wrapper>
                                            <input type="radio" clrRadio name="{{ cell.Bezeichnung }}" [(ngModel)]="cell.istValue" value="1" (change)="handleChange(1, cell, item)" (click)="handleChange(1, cell, item)"/>
                                            <label>{{'Not Accepted' | mrTranslate}}</label>
                                          </clr-radio-wrapper>
                                        </td>
                                        <td>

                                          <ng-container *ngIf="os === 'Windows'; else camera">
                                            <button [disabled]="!(cell.istValue == 1 || cell.istValue == 0)"  type="button" class="btn btn-sm" (click)="openCamera(cell)">{{'Foto' | mrTranslate}}</button>
                                          </ng-container>

                                          <ng-template #camera>
                                            <button [disabled]="!(cell.istValue == 1 || cell.istValue == 0)"  type="button" class="btn btn-sm" (click)="fileInput.click()">{{'Foto' | mrTranslate}}</button>
                                          </ng-template>
                                          <!-- <button [disabled]="!(cell.istValue == 1 || cell.istValue == 0)"  type="button" class="btn btn-sm" (click)="fileInput.click()">{{'Foto' | mrTranslate}}</button> -->
                                        </td>

                                        <td>
                                          <button [disabled]="!(cell.istValue == 1 || cell.istValue == 0)"  type="button" class="btn btn-sm" (click)="openBem(cell)">{{'Bemerkung' | mrTranslate}}</button>
                                        </td>

                                        <td>
                                          <ng-container *ngIf="cell.bemerkung">
                                            <clr-icon shape="info-circle" size="24" class="is-info cursor-pointer" (click)="openBem(cell)"></clr-icon>
                                          </ng-container>
                                        </td>

                                      </tr>
                                      <input type="file" #fileInput id="fileInput" accept=".jpg" capture="camera" name="fileInput" style="display: none;" (change)="onMessFileChange($event, cell)">
                                  </ng-container>
                                </tbody>
                                </table>
                              </div>
                              <div class="clr-col-2">
                                <div style="margin-top: 47px" class="clr-row previewThumbnailsContainer" *ngIf="previewMessThumbnails.length > 0"
                                  [style.justify-content]="'space-evenly'">
                                  <div *ngFor="let thumb of previewMessThumbnails" class="uploadImagePreviewContainer">
                                    <a class="deletePreview" href="javascript:void(0);"
                                      (click)="previewMessThumbnails.splice((previewMessThumbnails.indexOf(thumb)),1)">
                                      <clr-icon shape="times-circle" class="is-solid"></clr-icon>
                                    </a>
                                    <a class="deletePreview1" href="javascript:void(0);"
                                      (click)="showPreviewImage(thumb)">
                                      <clr-icon shape="search"></clr-icon>
                                    </a>
                                    <img [src]="thumb.src" alt="" class="uploadImagePreview">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </ng-template>

                        <!-- <div class="limit-height-500"> -->

                        <!-- </div> -->

                        <ng-container *ngIf="item.image != null">
                          <div class="clr-row" style="min-height: 180px; max-height: 180px; text-align: center;">
                              <img [src]="item.image" alt="" style="max-height: 178px" class="uploadImagePreview"/>
                          </div>
                        </ng-container>

                        <app-preview-thumbnails [data]="item.images" [readonly]="true"></app-preview-thumbnails>


                        <br>
                      </clr-accordion-content>
                    </clr-accordion-panel>
                  </clr-accordion>
                </div>
                <br>
                <br>
                <br>
                <br>
              </div>

              <div class="clr-col-2">
                <div style="margin-top: 47px" class="clr-row previewThumbnailsContainer" *ngIf="previewMessThumbnails.length > 0"
                  [style.justify-content]="'space-evenly'">
                  <div *ngFor="let thumb of previewMessThumbnails" class="uploadImagePreviewContainer">
                    <a class="deletePreview" href="javascript:void(0);"
                      (click)="previewMessThumbnails.splice((previewMessThumbnails.indexOf(thumb)),1)">
                      <clr-icon shape="times-circle" class="is-solid"></clr-icon>
                    </a>
                    <a class="deletePreview1" href="javascript:void(0);"
                      (click)="showPreviewImage(thumb)">
                      <clr-icon shape="search"></clr-icon>
                    </a>
                    <img [src]="thumb.src" alt="" class="uploadImagePreview">
                  </div>
                </div>
              </div>


            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink [clrTabLinkInOverflow]="clrtab.length > 3" (click)="tabChange()" [title]="'Unterschrift' | mrTranslate">{{ 'Unterschrift' | mrTranslate | titlecase }}</button>
        <clr-tab-content *clrIfActive>

          <div class="clr-row">
            <div class="clr-col-12 clr-col-lg-6">
              <form clrForm [formGroup]="resultForm" class="clr-form clr-form-horizontal">
                <div class="comboboxcontainer clr-form-control clr-row">
                  <label class="clr-col-md-4 clr-control-label">{{"Datum" | mrTranslate}}</label>
                  <div class="clr-control-container clr-col-md-8">
                    <input type="date" style="width: 265px;" id="date" clrDate
                      [ngModel]="resultForm.get('datum').value | localeDate"
                      (ngModelChange)="resultForm.get('datum').setValue(localeDate.tosql($event))"
                      [ngModelOptions]="{standalone: true, updateOn: 'blur'}">
                  </div>
                </div>

                <div class="comboboxcontainer clr-form-control clr-row">
                  <label class="clr-col-md-4 clr-control-label">{{"Ort" | mrTranslate}}</label>
                  <div class="clr-control-container clr-col-md-8">
                    <input clrInput style="width: 300px;" type="text" formControlName="ort"/>
                  </div>
                </div>

                <div class="comboboxcontainer clr-form-control clr-row">
                  <label class="clr-col-md-4 clr-control-label">{{"Prüfer" | mrTranslate}}</label>
                  <div class="clr-control-container clr-col-md-8">
                    <input clrInput style="width: 300px;" type="text" formControlName="pruefername"/>
                  </div>
                </div>

                <div class="clr-form-control clr-row fileUpload">
                  <label class="clr-col-md-4 clr-control-label">{{'Unterschrift Prüfer' | mrTranslate}}</label>
                  <div class="clr-control-container clr-col-md-8">
                    <button type="button" class="btn btn-icon" aria-label="home" (click)="addSignatur('pruefer')">
                      <clr-icon shape="signatur"></clr-icon>
                    </button>
                  </div>
                </div>

                <ng-container *ngIf="pruefersignatur.length > 0">
                  <div class="clr-row">
                    <div class="clr-col-4">
                    </div>
                    <div class="clr-col-5">
                      <div class="clr-row previewThumbnailsContainer"
                        [style.justify-content]="'space-evenly'">
                        <div *ngFor="let thumb of pruefersignatur" class="uploadImagePreviewContainer">
                          <a class="deletePreview" href="javascript:void(0);"
                            (click)="pruefersignatur.splice((pruefersignatur.indexOf(thumb)),1)">
                            <clr-icon shape="times-circle" class="is-solid"></clr-icon>
                          </a>
                          <a class="deletePreview1" href="javascript:void(0);"
                            (click)="showPreviewImage(thumb)">
                            <clr-icon shape="search"></clr-icon>
                          </a>
                          <img [src]="thumb.src" alt="" class="uploadImagePreview">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="comboboxcontainer clr-form-control clr-row">
                  <label class="clr-col-md-4 clr-control-label">{{"Kunde" | mrTranslate}}</label>
                  <div class="clr-control-container clr-col-md-8">
                    <input clrInput style="width: 300px;" type="text" formControlName="kundename"/>
                  </div>
                </div>

                <div class="clr-form-control clr-row fileUpload">
                  <label class="clr-col-md-4 clr-control-label" style="font-size: .65rem; font-weight: var(--clr-forms-label-font-weight, 600);">{{'Unterschrift Kunde' | mrTranslate}}</label>
                  <div class="clr-col-md-8" style="display:flex; justify-content: flex-start; align-items: center;">
                    <button type="button" class="btn btn-icon" aria-label="home" (click)="addSignatur('kunde')">
                      <clr-icon shape="signatur"></clr-icon>
                    </button>
                  </div>
                </div>

                <ng-container *ngIf="kundesignatur.length > 0">
                  <div class="clr-row">
                    <div class="clr-col-4">
                    </div>
                    <div class="clr-col-5">
                      <div class="clr-row previewThumbnailsContainer"
                        [style.justify-content]="'space-evenly'">
                        <div *ngFor="let thumb of kundesignatur" class="uploadImagePreviewContainer">
                          <a class="deletePreview" href="javascript:void(0);"
                            (click)="kundesignatur.splice((kundesignatur.indexOf(thumb)),1)">
                            <clr-icon shape="times-circle" class="is-solid"></clr-icon>
                          </a>
                          <a class="deletePreview1" href="javascript:void(0);"
                            (click)="showPreviewImage(thumb)">
                            <clr-icon shape="search"></clr-icon>
                          </a>
                          <img [src]="thumb.src" alt="" class="uploadImagePreview">
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="comboboxcontainer clr-form-control clr-row">
                  <label class="clr-col-md-4 clr-control-label">{{"Bemerkung" | mrTranslate}}</label>
                  <div class="clr-control-container clr-col-md-8">
                    <clr-textarea-container>
                      <textarea style="width: 300px; margin-left: 85px;" rows="8" clrTextarea formControlName="bemerkung" name="description"></textarea>
                    </clr-textarea-container>
                  </div>
                </div>
                <br/>
                <br/>
                <br/>
              </form>
            </div>
          </div>

        </clr-tab-content>
      </clr-tab>

    </clr-tabs>
  </div>
</div>

<app-hilfe-beschreibung-modal [readonly]="true"></app-hilfe-beschreibung-modal>

<clr-modal [(clrModalOpen)]="showsignaturpad" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{modaltitel}}</h3>
  <div class="modal-body">
    <form clrForm>
      <canvas #canvas width="780" height="430"></canvas>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"  (click)="updatesignatur()">{{"Übernehmen" | mrTranslate}}</button>
    <button type="button" class="btn"  (click)="deletesignatur()">{{"Löschen" | mrTranslate}}</button>
    <button type="button" class="btn" (click)="showsignaturpad = false">{{"Beenden" | mrTranslate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="openModalBem" [clrModalClosable]="true">
  <h3 class="modal-title">{{"Bemerkung" | mrTranslate}}</h3>
<div class="modal-body">
  <form clrForm style="width: 100%; height: 100%">
          <textarea clrTextarea rows="8" style="margin-top:-15px; width: 100%; height: 100%"  #rowBem name="description"></textarea>
  </form>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="setBem()">{{ 'OK' | mrTranslate }}</button>
    <button type="button" class="btn" (click)="openModalBem = false">{{ 'Abbrechen' | mrTranslate }}</button>
  </div>
</clr-modal>
