import { Pipe, PipeTransform } from '@angular/core';
import { ToleranzenBezeichnungen } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.types';

@Pipe({
  name: 'getAusfallColor',
  standalone: true
})
export class AusfallColorPipe implements PipeTransform {

  transform(value: string, tol: ToleranzenBezeichnungen): string {
    switch (value) {
      case tol.TEXT_100_A:
        return tol.COLOR_100;
      case tol.TEXT_L_A:
        return tol.COLOR_L;
      case tol.TEXT_G_A:
        return tol.COLOR_G;
      default:
        return '#d0d0d0';
    }
  }
}
