<div class="overlay-inner-wrapper">
  <div id="profileViewerWrapper">
    <header class="header-2">
      <div class="branding">
          <span class="title">{{'Railmonitor' | mrTranslate}}</span>
      </div>
      <div class="header-actions">
        <button type="button" class="btn btn-success"  [disabled] ="authService.token.rights.status == 1" (click)="setImportModal()">{{ 'Import' | mrTranslate }}</button>
        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon  shape="times" (click)="close()"></clr-icon >
        </a>
      </div>
    </header>
    
    <div>
      <clr-datagrid *ngIf="profiles && sollProfile" style="max-height:142px" [(clrDgSelected)]="selected" (clrDgSelectedChange)="selectionChanged($event)" class="datagrid-compact">
        <clr-dg-column>
          {{'Profil' | mrTranslate}}
        </clr-dg-column>
        <clr-dg-column>
          {{'Datum' | mrTranslate}}
        </clr-dg-column>
        <clr-dg-column>
          {{'Zeit' | mrTranslate}}
        </clr-dg-column>
        <clr-dg-column>
          {{'Spurweite' | mrTranslate}}
        </clr-dg-column>
        <clr-dg-column>
          {{'Benutzer' | mrTranslate}}
        </clr-dg-column>
        <clr-dg-column>
          {{'Status' | mrTranslate}}
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let row of profiles" [clrDgItem]="row">
          <clr-dg-action-overflow>
            <button class="action-item" (click)="deleteRailMonitor(row.ID)">
              {{ "Löschen" | mrTranslate }}
            </button>
          </clr-dg-action-overflow>
          <clr-dg-cell>
            {{ row.SollProfil.Bezeichnung }}
          </clr-dg-cell>
          <clr-dg-cell>
            {{ row.MessDatum | tablePrettyPrint }}
          </clr-dg-cell>
          <clr-dg-cell>
            {{ row.MessZeit }}
          </clr-dg-cell>
          <clr-dg-cell>
            {{ row.Spurweite }}
          </clr-dg-cell>
          <clr-dg-cell>
            {{ row.UserName }}
          </clr-dg-cell>
          <clr-dg-cell>
            {{ row.Status | tablePrettyPrint }}
          </clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>
    </div>


    <div *ngIf="graphL.show" class="card-block">
      <div class ="clr-row">
            <app-plotly-plot class="clr-col-6" [className]="" [data]="graphL.data" [layout]="graphL.layout"
            [config]="graphL.config" [style]="graphL.style" (plotID)="getPlotID($event,'l')">
          </app-plotly-plot>
            <app-plotly-plot  class="clr-col-6" [className]="" [data]="graphR.data" [layout]="graphR.layout"
            [config]="graphR.config" [style]="graphR.style" (plotID)="getPlotID($event,'r')">
          </app-plotly-plot>
      </div>
    </div>
  </div>

  <clr-modal [(clrModalOpen)]="showImportModal" [clrModalSize]="'xl'" [clrModalClosable]="false">
  
    <h3 class="modal-title">{{ 'Import Railmonitor' | mrTranslate }}</h3>
    <div class="modal-body" style="margin-left:10px">
      <form clrForm clrLayout="horizontal" [formGroup]="modalImportGroup">
        <div class="clr-row">
          <div class="comboboxcontainer clr-form-control clr-row">
            <label class="clr-col-md-2 clr-control-label">{{'Sollprofil' | mrTranslate}}</label>
            <div class="clr-control-container clr-col-md-10">
              <input class="clr-input combobox" id="sollprofil" list="sollprofillist" name="Option" 
              formControlName="sollprofil" (change)="loadSoll($event)" />
            </div>
            <datalist id="sollprofillist">
              <option *ngFor="let c of sollProfile | keyvalue" [value]="c.key"></option>
            </datalist>
          </div>
          <clr-input-container>
            <label>{{ "Spurweite" | mrTranslate }}</label>
            <input type="number" clrInput formControlName="spurweite" />
          </clr-input-container>
          <clr-date-container>
            <label>{{ "Messdatum" | mrTranslate }}</label>
            <input type="date" clrDate
              [ngModel]="modalImportGroup.get('messdate').value | localeDate"
              (ngModelChange)="modalImportGroup.get('messdate').setValue(localeDate.tosql($event))"
              [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
          </clr-date-container>
        </div>
        <button type="button" class="btn btn-outline"  [disabled] ="curSollProfL == undefined" (click)="importRailmonitor()">Import...</button>
        
        <button [title]="'MoveUp Links' | mrTranslate"  type="button" (click)="doMove(-moveStepL,'L','Y')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow up"></clr-icon>
        </button>
      
        <button [title]="'MoveLeft Links' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(-moveStepL,'L','X')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow left"></clr-icon>
        </button>
      
        <button [title]="'MoveRight Links' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(moveStepL,'L','X')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow right"></clr-icon>
        </button>
     
        <button  [title]="'MoveDown Links' | mrTranslate" type="button" style="margin-left:16px;" (click)="doMove(moveStepL,'L','Y')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow down"></clr-icon>
        </button>
        <input type="number" style="margin-left:16px; width: 50px;" value="5.0" step="0.1" (change)="setMoveStep($event,'L')" class="moveStepL"/> mm


        <button type="button" class="btn btn-outline" style="margin-left: 75px;"  [disabled] ="curSollProfL == undefined" (click)="importRailmonitorRechts()">Import...</button>
        
        <button [title]="'MoveUp Links' | mrTranslate"  type="button" (click)="doMove(-moveStepR,'R','Y')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow up"></clr-icon>
        </button>
      
        <button [title]="'MoveLeft Links' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(-moveStepR,'R','X')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow left"></clr-icon>
        </button>
      
        <button [title]="'MoveRight Links' | mrTranslate"  type="button" style="margin-left:16px;" (click)="doMove(moveStepR,'R','X')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow right"></clr-icon>
        </button>
     
        <button  [title]="'MoveDown Links' | mrTranslate" type="button" style="margin-left:16px;" (click)="doMove(moveStepR,'R','Y')" class="btn nxr btn-icon btn-primary">
          <clr-icon shape="arrow down"></clr-icon>
        </button>
        <input type="number" style="margin-left:16px; width: 50px;" value="5.0" step="0.1" (change)="setMoveStep($event,'R')" class="moveStepL"/> mm

        <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}" style="display:none;"
          (change)="startImport('links')" />
        <input type="file" id="fileUpload2" name="fileUpload2" multiple="multiple" accept="{{accept}}"
          style="display:none;" (change)="startImport('rechts')" />


        <div  class="card-block" style="height:470px">
          <div class="clr-row" *ngIf="graphL.show">
            <app-plotly-plot class="clr-col-6" [className]="" [data]="graphL.data" [layout]="graphL.layout" [config]="graphL.config" [style]="graphL.style" (plotID)="getPlotID($event,'l')">
            </app-plotly-plot>
            <app-plotly-plot  class="clr-col-6" [className]="" [data]="graphR.data" [layout]="graphR.layout" [config]="graphR.config" [style]="graphR.style" (plotID)="getPlotID($event,'r')">
            </app-plotly-plot>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="closeImportModal()">Abbrechen</button>
      <button type="button" class="btn btn-outline" (click)="saveImport()">Import speichern</button>
    </div>
  </clr-modal>
