
<div class="overlay-inner-wrapper">
  <div id="mangelbaumWrapper">
    <header class="header-2">
      <div class="branding">
          <span class="title">{{title | mrTranslate}}</span>
      </div>
      <div class="header-actions">

        <clr-toggle-container>
          <clr-toggle-wrapper style="margin-top: -5px;">
            <input type="checkbox" clrToggle value="option1" name="options"  (change)="changeansicht($event.target.checked)"/>
            <label>Ansicht Liste</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        &nbsp;
        &nbsp;
        <clr-dropdown id="PropertiesDropdown">
          <!-- <button style="width: 60px;" class="nav-link nav-icon cursor-pointer" clrDropdownTrigger
            title="{{ titlebutton }}">
            <clr-icon size="22"  class="customIcon" [attr.shape]="'objekttyp'+ symbol"  [ngStyle]="{ color: activeTheme == 1 ? '#1D3A53' : '#AEB8BC' }" style="transform: translate(-60%, -46%); margin-left: -50px;"></clr-icon>
              {{ titlebutton }}</button> -->

              <a clrDropdownTrigger><clr-icon size="22"  class="customIcon" [attr.shape]="'objekttyp'+ symbol"  [ngStyle]="{ color: activeTheme == 1 ? '#1D3A53' : '#AEB8BC' }"></clr-icon> &nbsp; {{ titlebutton }} &nbsp; &nbsp;
                <clr-icon size="16"  shape="angle" style="transform: rotate(180deg);"  [ngStyle]="{ color: activeTheme == 1 ? '#1D3A53' : '#AEB8BC' }"></clr-icon>
              </a>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <ng-container *ngFor="let row of objekte.rows">
              <a (click)="openSymbol(row)" clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" class="customIcon" size="22" [attr.shape]="row['symbol'] ? 'objekttyp'+row['symbol'] : 'unknown-status'"></clr-icon> {{ row.bezeichnung }}</a>
            </ng-container>
          </clr-dropdown-menu>
        </clr-dropdown>

        <ng-container *ngIf="typid == 3 || typid == 4">
          <clr-dropdown id="PropertiesDropdown">

            <a href="javascript://" class="nav-link nav-icon" clrDropdownTrigger>
              <clr-icon  shape="cog"></clr-icon >
            </a>

            <clr-dropdown-menu  *clrIfOpen clrPosition="bottom-right">
              <a (click)="addGattung()" clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" shape="plus"></clr-icon> {{ 'Gattung bearbeiten' | mrTranslate}}</a>

              <ng-container *ngIf="stammtablename">
                <a (click)="addStamm()" clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" shape="plus"></clr-icon> {{ 'Stammdaten bearbeiten' | mrTranslate}}</a>
              </ng-container>

            </clr-dropdown-menu>
          </clr-dropdown>
        </ng-container>

        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon  shape="times" (click)="close()"></clr-icon >
        </a>
      </div>
    </header>

    <ng-container *ngIf="!viewliste; else showlist">
      <form clrForm clrLayout="horizontal" [formGroup]="formSpezTop" style="width: 100%;">
        <div class="clr-row"  style="margin-left: 10px">
          <div class="clr-col-2">
            <div  style="margin-top:18px;">
              <div class="btn-group btn-icon">
                <button class="btn btn-success" (click)="addTree()">
                  <clr-icon shape="file"></clr-icon>
                  <span class="clr-icon-title">Neu</span>
                </button>
                <button class="btn btn-danger" [disabled]="seltree == null" (click)="deleteTree()">
                  <clr-icon shape="times"></clr-icon>
                  <span class="clr-icon-title">Löschen</span>
                </button>
                <button class="btn btn-primary" [disabled]="selectedTree == null"  (click)="saveTree()">
                  <clr-icon shape="floppy"></clr-icon>
                  <span class="clr-icon-title">Speichern</span>
                </button>
                <button class="btn" (click)="reloadTree()">
                  <clr-icon shape="refresh"></clr-icon>
                  <span class="clr-icon-title">Neu Laden</span>
                </button>
                <ng-container *ngIf="seltree != null">
                  <button class="btn"  [disabled]="seltree.children.length > 0" (click)="editTree()">
                    <clr-icon shape="note"></clr-icon>
                    <span class="clr-icon-title">Bearbeiten</span>
                  </button>
                </ng-container>

              </div>
            </div>
          </div>
          <div class="clr-col-10">
            <div class="clr-row">
              <div class="clr-col-1" >
                <span>{{'Bedingung' | mrTranslate}}:</span>
              </div>
              <div class="clr-col-1" >
              </div>
              <div class="clr-col-1" >
              </div>
              <div class="clr-col-1" >
              </div>
              <div class="clr-col-1" >
              </div>
              <div class="clr-col-1" >
              </div>
              <div class="clr-col" >
                <span>{{'Preis' | mrTranslate}}:</span>
              </div>
              <div class="clr-col" >
                <span>{{'LV-Position' | mrTranslate}}:</span>
              </div>
              <div class="clr-col" >
                <span>{{'zuständige Abteilung' | mrTranslate}}:</span>
              </div>
              <div class="clr-col" >
                <span>{{'Equiptment' | mrTranslate}}:</span>
              </div>
            </div>
            <div class="clr-row">
              <div class="clr-col-1">
                <select clrSelect formControlName="beding1" style="margin-top: -36px; width: 100%;">
                  <option *ngFor="let val of bedarray" [value]="val">{{val}}</option>
                </select>
              </div>
              <div class="clr-col-1">
                <input type="number" [min]="0" formControlName="bedingval1"value="" class="clr-input combobox" />
              </div>
              <div class="clr-col-1">
                <select clrSelect formControlName="bedingund" style="margin-top: -36px; width: 100%;">
                  <option *ngFor="let val of bedund" [value]="val">{{val | mrTranslate}}</option>
                </select>
              </div>
              <div class="clr-col-1">
                <select clrSelect formControlName="beding2" style="margin-top: -36px; width: 100%;">
                  <option *ngFor="let val of bedarray" [value]="val">{{val | mrTranslate}}</option>
                </select>
              </div>
              <div class="clr-col-1">
                <input type="number" [min]="0" formControlName="bedingval2"value="" class="clr-input combobox" />
              </div>
              <div class="clr-col-1">
                <select clrSelect formControlName="bedingeinheit" style="margin-top: -36px; width: 100%;">
                  <option *ngFor="let val of bedeinh" [value]="val">{{val | mrTranslate}}</option>
                </select>
              </div>
              <div class="clr-col">
                <input type="number" [min]="0" formControlName="preis" value="" class="clr-input combobox" />
              </div>
              <div class="clr-col">
                <input type="text" formControlName="lv" value="" class="clr-input combobox" />
              </div>
              <div class="clr-col">
                <input type="text" formControlName="abt" value="" class="clr-input combobox" />
              </div>
              <div class="clr-col">
                <input type="text" formControlName="equi" value="" class="clr-input combobox" />
              </div>
            </div>
          </div>
        </div>
      </form>


      <div class="clr-row" style="max-height: 50px; margin-top:20px; margin-left:10px; max-width: calc(100% - 20px);">
        <ng-container *ngIf="typid == 4">
        </ng-container>

        <form clrForm clrLayout="horizontal" [formGroup]="formSpezTop" style="width: 100%;">

          <div class="clr-row">
            <div class="clr-col" *ngIf="typid == 3 || typid == 4">
              <span>{{'Gattung' | mrTranslate}}:</span>
            </div>
            <div class="clr-col" *ngIf="typid == 4">
              <span >{{'Stamm' | mrTranslate}}:</span>
            </div>
            <div class="clr-col">
              <span>{{'Prüfart' | mrTranslate}}:</span>
            </div>
            <div class="clr-col" *ngIf="typid != 4">
              <span>{{'Lage' | mrTranslate}}:</span>
            </div>
            <div class="clr-col">
              <span>{{'Mangel' | mrTranslate}}:</span>
            </div>
            <div class="clr-col-1">
              <span>{{'FK' | mrTranslate}}:</span>
            </div>
            <div class="clr-col">
              <span>{{'Instandsezung' | mrTranslate}}:</span>
            </div>
            <div class="clr-col" >
              <span>{{'Mengeneinheit' | mrTranslate}}:</span>
            </div>

          </div>

          <div class="clr-row">
            <div class="clr-col" *ngIf="typid == 3 || typid == 4">
              <select clrSelect formControlName="gattung" style="margin-top: -36px;" #selgattung (change)="changedgattung(selgattung.value)">
                <ng-container *ngIf="gattung?.rows">
                  <option *ngFor="let val of gattung.rows" [value]="val.intID">{{val.Bezeichnung}}</option>
                </ng-container>
              </select>
            </div>
            <div class="clr-col" *ngIf="typid == 4">
              <select  clrSelect formControlName="stamm" style="margin-top: -36px;" #selst (change)="changedstamm(selst.value)">
                <option *ngFor="let val of selstamm" [value]="val.ID">{{val.Bezeichnung}}</option>
              </select>
            </div>
            <div class="clr-col">
              <input type="text" value="" class="clr-input combobox" formControlName="pruefart"/>
            </div>
            <div class="clr-col" *ngIf="typid != 4">
              <input *ngIf="typid != 4" type="text" formControlName="lage"value="" class="clr-input combobox" />
            </div>
            <div class="clr-col">
              <input type="text" formControlName="mangel"value="" class="clr-input combobox" />
            </div>
            <div class="clr-col-1">
              <select clrSelect formControlName="fehlerklasse" style="margin-top: -36px;">
                <ng-container *ngIf="fehlerklassen">
                  <option *ngFor="let val of fehlerklassen" [value]="val">{{val}}</option>
                </ng-container>

              </select>
            </div>
            <div class="clr-col">
              <input type="text" formControlName="instandsetzung"value="" class="clr-input combobox" />
            </div>
            <div class="clr-col" >
              <select clrSelect formControlName="mengeneinheit" #selmeih (change)="changedMEH(selmeih.value)">
                <ng-container *ngIf="mengeneinheit">
                  <option *ngFor="let val of mengeneinheit.rows" [value]="val.ID">{{val.Bezeichnung}}</option>
                </ng-container>
              </select>
            </div>

          </div>

        <ng-container *ngIf="listTreeNote">
          <div class="clr-row">
            <div class="clr-col-5">
              <div class="clr-row">
                <div class="card">
                  <div class="card-block clr-row">
                    <div class="card-title clr-col-12">{{'Zuordnung' | mrTranslate}}</div>
                    <div class="clr-col-12">

                      <clr-spinner *ngIf="isLoading" class="centred"></clr-spinner>
                      <clr-tree [clrLazy]="true" *ngIf="!isLoading">
                        <clr-tree-node *clrRecursiveFor="let item of listTreeNote; getChildren: getChildren" [(clrExpanded)]="item.expanded">
                          <a class="clr-treenode-link" routerLinkActive="active" (click)='treeClick(item)'><clr-icon [attr.shape]="item.typ" size="18"></clr-icon> {{item.name}}</a>
                        </clr-tree-node>
                      </clr-tree>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clr-col-7">
              <div class="clr-row">
                <div class="clr-col-6">
                  <app-mangelbaumitems [tablename]="'sprue'" [setotypid]="otypid" [titel]="'Prüfart'" (selected)="selectionChanged($event)"></app-mangelbaumitems>
                </div>
                <div class="clr-col-6">
                  <app-mangelbaumitems [tablename]="'smang'" [setotypid]="otypid" [titel]="'Mangel'" (selected)="selectionChanged($event)"></app-mangelbaumitems>
                </div>
              </div>
              <ng-container *ngIf="typid !== 4 else nurgleis">
                <div class="clr-row" >
                  <div class="clr-col-6">
                    <app-mangelbaumitems [tablename]="'slage'" [setotypid]="otypid" [titel]="'Lage'" (selected)="selectionChanged($event)"></app-mangelbaumitems>
                  </div>
                  <div class="clr-col-6">
                    <app-mangelbaumitems [tablename]="'sinst'" [setotypid]="otypid"  [titel]="'Instandsetzung'" (selected)="selectionChanged($event)"></app-mangelbaumitems>
                  </div>
                </div>
              </ng-container>
              <ng-template #nurgleis>
                <app-mangelbaumitems [tablename]="'sinst'" [setotypid]="otypid"  [titel]="'Instandsetzung'" (selected)="selectionChanged($event)"></app-mangelbaumitems>
              </ng-template>
            </div>
          </div>

        </ng-container>
        </form>
      </div>

    </ng-container>
    <ng-template #showlist>
      <div class="limit-height-full">
        <clr-datagrid style="padding-top: 0px" class="datagrid-compact"> <!-- [(clrDgSingleSelected)]="seledlistitem" (clrDgSingleSelectedChange)="selectionListChanged($event)"> -->
          <ng-container *ngIf="typid == 3 || typid == 4">
            <clr-dg-column>{{'Gattung' | mrTranslate | titlecase}}</clr-dg-column>
          </ng-container>
          <ng-container *ngIf="typid == 4">
            <clr-dg-column>{{'Stamm' | mrTranslate | titlecase}}</clr-dg-column>
          </ng-container>
          <clr-dg-column>{{'Prüfart' | mrTranslate | titlecase}}</clr-dg-column>
          <ng-container *ngIf="typid != 4">
            <clr-dg-column>{{'Lage' | mrTranslate | titlecase}}</clr-dg-column>
          </ng-container>
          <clr-dg-column>{{'Mangel' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'Instandsetzung' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'ME' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'1. Bedingung Auslöser' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'1. Bedingung Wert' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'UND' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'2. Bedingung Auslöser' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'2. Bedingung Wert' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'Bedingungseinheit' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'Fehlerklasse' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'EP' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'LV' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'zuständige Abteilung' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'Equiptment' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'AÄ - Abnutzungsäquivalent' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'AW - Anteilige Wichtung' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'KAV®' | mrTranslate | titlecase}} {{'Einheit' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'Benutzername' | mrTranslate | titlecase}}</clr-dg-column>
          <clr-dg-column>{{'Zeitstempel' | mrTranslate | titlecase}}</clr-dg-column>

          <clr-dg-row *clrDgItems="let item of grid['rows']" [clrDgItem]="item"> <!-- [clrDgSelected]="seledlistitem"> -->

            <clr-dg-action-overflow>
              <button class="action-item" (click)="deleteRow(item)">{{'Löschen' | mrTranslate}}</button>
            </clr-dg-action-overflow>

            <ng-container *ngIf="typid == 3; else notedit">
              <clr-dg-cell>
                <select clrSelect [(ngModel)]="item['GattungID']" class="datagrid-dropdown" #selListgattung (change)="updateField(selListgattung.value, item, 'GattungID')">
                  <ng-container *ngIf="gattung?.rows">
                    <option *ngFor="let val of gattung.rows" [value]="val.intID">{{val.Bezeichnung}}</option>
                  </ng-container>
                </select>
              </clr-dg-cell>
            </ng-container>
            <ng-template #notedit>
              <ng-container *ngIf="typid == 4">
                <clr-dg-cell [innerHTML]="item['Gattung'] | tablePrettyPrint" [title]="item['Gattung'] | tablePrettyPrint"></clr-dg-cell>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="typid == 4">
              <clr-dg-cell [innerHTML]="item['Stamm'] | tablePrettyPrint" [title]="item['Stamm'] | tablePrettyPrint"></clr-dg-cell>
            </ng-container>
            <clr-dg-cell [innerHTML]="item['Prüfart'] | tablePrettyPrint" [title]="item['Prüfart'] | tablePrettyPrint"></clr-dg-cell>
            <ng-container *ngIf="typid != 4">
              <clr-dg-cell [innerHTML]="item['Lage'] | tablePrettyPrint" [title]="item['Lage'] | tablePrettyPrint"></clr-dg-cell>
            </ng-container>
            <clr-dg-cell [innerHTML]="item['Mangel'] | tablePrettyPrint" [title]="item['Mangel'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Instandsetzung'] | tablePrettyPrint" [title]="item['Instandsetzung'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Mengeneinheit'] | tablePrettyPrint" [title]="item['Mengeneinheit'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Bedingung'] | tablePrettyPrint" [title]="item['Bedingung'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Min'] | tablePrettyPrint" [title]="item['Min'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['UND'] | tablePrettyPrint" [title]="item['UND'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Bedingung2'] | tablePrettyPrint" [title]="item['Bedingung2'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Max'] | tablePrettyPrint" [title]="item['Max'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Bedingungseinheit'] | tablePrettyPrint" [title]="item['Bedingungseinheit'] | tablePrettyPrint"></clr-dg-cell>

            <clr-dg-cell>
              <select clrSelect [(ngModel)]="item['Fehlerklasse']" class="datagrid-dropdown-fk" #selListfk (change)="updateField('FK', item, 'Fehlerklasse')">
                <ng-container *ngIf="fehlerklassen">
                  <option *ngFor="let val of fehlerklassen" [value]="val">{{val}}</option>
                </ng-container>
              </select>
            </clr-dg-cell>
            <clr-dg-cell>
              <input clrInput style="width: 80px;" type="number" [min]="0" [(ngModel)]="item['EP']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('EP', item,'EP')" />
            </clr-dg-cell>

            <!-- <clr-dg-cell [innerHTML]="item['LV'] | tablePrettyPrint" [title]="item['LV'] | tablePrettyPrint"></clr-dg-cell> -->

            <clr-dg-cell>
              <input clrInput style="width: 100px;" type="text" [(ngModel)]="item['LV']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('LVPos', item, 'LV')" />
            </clr-dg-cell>

            <clr-dg-cell>
              <input clrInput style="width: 100px;" type="text" [(ngModel)]="item['Abteilung']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('zuAbt', item, 'Abteilung')" />
            </clr-dg-cell>

            <clr-dg-cell>
              <input clrInput style="width: 100px;" type="text" [(ngModel)]="item['Equipment']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('Equipment', item, 'Equipment')" />
            </clr-dg-cell>

            <clr-dg-cell>
              <input clrInput style="width: 80px;" type="number" [min]="0" [max]="1" [step]="0.1" [(ngModel)]="item['AAE']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('AAE', item,'AAE')" />
            </clr-dg-cell>

            <clr-dg-cell>
              <input clrInput style="width: 80px;" type="number" [min]="0" [max]="3" [step]="0.1" [(ngModel)]="item['AW']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('AW', item,'AW')" />
            </clr-dg-cell>

            <clr-dg-cell>
              <input clrInput style="width: 100px;" type="text" [(ngModel)]="item['KAV_ME']" (ngModelChange)="dataChanged($event,item)" (blur)="updateField('KAV_ME', item,'KAV_ME')" />
            </clr-dg-cell>

            <clr-dg-cell [innerHTML]="item['Username'] | tablePrettyPrint" [title]="item['Username'] | tablePrettyPrint"></clr-dg-cell>
            <clr-dg-cell [innerHTML]="item['Status'] | tablePrettyPrint" [title]="item['Status'] | tablePrettyPrint"></clr-dg-cell>

          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="rownr">
              <clr-dg-page-size [clrPageSizeOptions]="[20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
              </clr-dg-page-size>
              {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
              {{ "von" | mrTranslate }} {{ pagination.totalItems }}
              {{ "Zeilen" | mrTranslate }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </ng-template>


    <div class="clr-row"  style="margin-left: 10px;">
    </div>
    <div class="content-container">
      <div id="content-area" class="content-area">
        <app-basedatamodal></app-basedatamodal>
      </div>
    </div>
  </div>
</div>
