import { AsyncPipe, CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { Observable, first, from, of, switchMap } from 'rxjs';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { Table } from 'src/app/models/ui/table';
import { CheckInArrayPipe } from 'src/app/pipes/array-methods.pipe';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { MrUnitTextPipe } from 'src/app/pipes/mrUnitText.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { MangelMediaApiFuncService } from 'src/app/services/_abstract/mangel-media-api-func.service';
import { db } from 'src/app/services/dexieDB';
import { ImageSlideShowComponent } from '../../_shared/media-slide-show/image-slide-show.component';
import { MediaViewModalComponent, TitleAndFileNames } from '../../_shared/mediaviewmodal/mediaviewmodal.component';
import { MangelLogModalComponent } from '../mangel-log-modal/mangel-log-modal.component';

type HistorieSubTree = {
  Key: string;
  Value: {
    datum: string;
    id?: number;
  }[];
}[];

export type HistorieTree = {
  Key: HistorieMode;
  Value: { date: string, id?: number }[] | HistorieSubTree;
}[];

export type HistorieMode = keyof typeof HistoryIcons;

@Component({
  selector: 'app-historie-tree',
  templateUrl: './historie-tree.component.html',
  styleUrls: ['./historie-tree.component.scss'],
  imports: [CommonModule, ClarityModule, DynamicStyleDirective,MangelLogModalComponent,ImageSlideShowComponent, MrTranslatePipe,DatePipe,
    TitleCasePipe,TablePrettyPrintPipe,MrUnitTextPipe,AsyncPipe,CheckInArrayPipe, HideIDColumnsPipe, MediaViewModalComponent ],
  standalone: true
})
export class HistorieTreeComponent {
  @Input() public set ostammid(value: number) {
    this._ostammid = value;
    this.table$ = this.files$ = undefined;
    this.historie$ =
     this.apiService.isRep ?
    from(db.getAnlagenHistorie(this.ostammid)):
    this.apiService.getAnlagenHistorie(this.ostammid);
  }
  public get ostammid(): number {
    return this._ostammid;
  }
  private _ostammid: number;

  protected historie$: Observable<HistorieTree>;
  protected table$: Observable<Table>;
  protected files$: Observable<any>;

  protected mode: HistorieMode;
  protected icons = HistoryIcons;
  protected mangelMedia: TitleAndFileNames[];
  protected mangelLog: number;

  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    protected media: MangelMediaApiFuncService, // * in Template
  ) {}

  protected historySelect(mode: HistorieMode, idOrDate: number | string) {
    this.mode = mode;
    this.table$ =
      this.apiService.isRep ?
      from(db.getHistoryItem(this.ostammid,1,{ mode, idOrDate })):
      this.apiService.getHistoryItem(this.ostammid, mode, idOrDate);
    this.files$ = (mode != 'inspektionen') ? of(null)
    : this.table$.pipe(first(), switchMap(
      table => this.apiService.getPassBilder(table?.rows?.[0]?.pruefbild)
    ));
  }
}

enum HistoryIcons {
  inspektionen = 'search',
  begehung = 'clock',
  sonderinspektion = 'warning-standard',
  herstellerdaten = 'home',
  messungen = 'ruler-pencil',
  sperrungen = 'times',
  stoerungen = 'exclamation-triangle',
  langsamfahrstellen = 'lastelle',
  instandsetzungen = 'wrench',
  wartungen = 'wartung',
  archiv = 'library',
}
