<clr-tabs style="padding: 0 10px 10px;">
  <clr-tab>
    <button clrTabLink>{{ "Auftragsliste" | mrTranslate }}</button>
    <clr-tab-content *clrIfActive>
      <div class="clr-row nxm h-100">
        <div class="h-100" #datagridwrapper style="position: relative" [ngStyle]="{
            width: !showdetailview || hidedetailview ? '100%' : split50 ? '50%' : '66.66%',
            'z-index': newAuftragModalOpen ? 3 : 'auto'
          }">
          <ng-container *ngIf="auftraege?.rows?.length !== 0; else noAuftragTemplate">
            <div id="auftrDgWrapper" class="h-100" [dymanicStyle]="heightRef" maxSize="100%" (resized)="scroll()">
              <div class="clr-row">
                <app-newauftragmodal class="clr-col-12" (modalOpen)="newAuftragModalOpen = $event"
                  (reload)="reloadTabs()">
                </app-newauftragmodal>
              </div>

              <clr-datagrid #auftrDg *ngIf="auftraege?.rows.length" deselectButton
                [(clrDgSingleSelected)]="selectedAuftrag" class="datagrid-compact">
                <clr-dg-column *ngFor="let column of auftraege?.columns; let i = index" [clrDgField]="column.id"
                  [style.min-width.px]="column.id == 'status' ? 150 : column.id == 'abnahmeStatus' ? 55 : column.id.length * 7.5 + 45">
                  <ng-container *clrDgHideableColumn="{
                      hidden:
                        column.id
                        | hideIDColumns
                          : [
                              'firmaid',
                              'bezirk',
                              'stellwerk',
                              'kostenart',
                              'gruppenid',
                              'gruppenname'
                            ]
                    }">
                    <ng-container *ngIf="column.id != 'abnahmeStatus'; else iconAbnahme">{{ column.id | titlecase | mrTranslate }}</ng-container>
                    <ng-template #iconAbnahme>
                      <clr-icon style="flex: 0 0 auto;" size="20" shape="certificate"
                        [title]="'Abnahme' | mrTranslate"></clr-icon>
                    </ng-template>
                  </ng-container>
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of auftraege?.rows; trackBy: trackByAuftrId" [clrDgItem]="row"
                  id="a-{{row.auftrag}}">
                  <clr-dg-action-overflow>
                    <button class="action-item" (click)="openAuftrag(row)">
                      {{ "Auftrag öffnen" | mrTranslate }}
                    </button>
                    <button class="action-item" (click)="openPDF(row)">
                      {{ "PDF öffnen" | mrTranslate }}
                    </button>
                  </clr-dg-action-overflow>
                  <ng-container *ngFor="let cell of auftraege?.columns">
                    <ng-container *ngIf="cell.id != 'abnahmeStatus'; else iconAbnahme" >
                      <clr-dg-cell [innerHTML]="
                      row[cell.id] | tablePrettyPrint
                      " [title]="row[cell.id] | dotDotDot : 18 | tablePrettyPrint"
                      [style.min-width.px]="cell.id.length * 7.5 + 45"
                      class="name-cell">
                      </clr-dg-cell>
                    </ng-container>
                    <ng-template #iconAbnahme>
                      <clr-dg-cell [title]="'Abnahme' | tablePrettyPrint"
                          [style.min-width.px]="55">
                          <ng-container *ngIf="row['abnahmeStatus']">
                            <clr-icon size="20" shape="certificate"></clr-icon>
                          </ng-container>
                      </clr-dg-cell>
                    </ng-template>
                  </ng-container>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination class="dg-pagination" #pagination 
                    [clrDgPageSize]="50" [clrDgPage]="auftrPage">
                    <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100, 500]">{{ "Zeilen pro Seite" | mrTranslate }}
                    </clr-dg-page-size>
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
              <!-- <app-gantt [termine]="auftraege | async" (auftrag)="selectAuftrag($event)"
                    (modalOpen)="newAuftragModalOpen = $event" (reloadAuftraege)="reloadAuftrage()"></app-gantt> -->
            </div>
          </ng-container>
          <ng-template #noAuftragTemplate>
            <div id="noAuftragWrap">
              <app-newauftragmodal (modalOpen)="newAuftragModalOpen = $event" (reloadAuftraege)="reloadTabs()">
              </app-newauftragmodal>
            </div>
          </ng-template>
          <div #heightRef id="blockObjMng" class="main-background">
            <ng-container *ngIf="objTable?.rows?.length > 0; else elseTemplate">
              <div class="h-100">
                <div #heightRefObj class="dgsWrapper objektTable">
                  <div>
                    <h4 class="tableHeadline">{{ "Objekte" | mrTranslate }}</h4>
                  </div>
                  <clr-datagrid #objDg [(clrDgSelected)]="selectedObjects"
                    class="datagrid-compact" id="ganttDatagrid">
                    <clr-dg-column *ngFor="let column of objTable['columns']"
                      [style.min-width.px]="column.id == 'status' ? 150 : column.id.length * 7.5 + 45"
                      [clrDgField]="column.id">
                      <ng-container *clrDgHideableColumn="{
                          hidden:
                            column.id
                            | hideIDColumns
                              : ['kauftsid', 'auftragid', 'bezirk', 'stellwerk']
                        }">{{ column.id | titlecase | mrTranslate }}
                      </ng-container>
                    </clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of objTable['rows']; trackBy: trackByOstammID" [clrDgItem]="row">
                      <clr-dg-action-overflow>
                        <button class="action-item" (click)="ostammid = row.ostammid">
                          Objekt öffnen
                        </button>
                      </clr-dg-action-overflow>
                      <ng-container *ngFor="let cell of objTable['columns']">
                        <ng-container *ngIf="cell.id != 'typ'; else iconcell">
                          <clr-dg-cell [innerHTML]="
                              row[cell.id] | tablePrettyPrint
                            " [style.min-width.px]="cell.id.length * 7.5 + 45"
                            [title]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint" class="name-cell">
                          </clr-dg-cell>
                        </ng-container>
                        <ng-template #iconcell>
                          <clr-dg-cell style="color: #000" [style.min-width.px]="3 * 7.5 + 45">
                            <clr-icon class="customIcon" [attr.shape]="'objekttyp' + row['typ']" size="28">
                            </clr-icon>
                          </clr-dg-cell>
                        </ng-template>
                      </ng-container>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="5">
                        <clr-dg-page-size [clrPageSizeOptions]="[3, 5, 10]">{{ "Zeilen pro Seite" | mrTranslate }}
                        </clr-dg-page-size>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
                <div class="dgsWrapper mangelTable">
                  <h4 class="tableHeadlineMangel">
                    {{ "Mängel" | mrTranslate }}
                  </h4>
                  <clr-datagrid #mnglDg class="datagrid-compact phSmall"
                    [dymanicStyle]="heightRefObj" styleMode="max-height"
                    addPx="310" diminutiv="100svh" maxSize="50vh">
                    <clr-dg-column [style.min-width.px]="72">{{
                      "GIS Map" | mrTranslate
                      }}</clr-dg-column>
                    <clr-dg-column [style.min-width.px]="55">{{
                      "Bilder" | mrTranslate
                      }}</clr-dg-column>
                    <clr-dg-column *ngFor="let column of mnglTable['columns']"
                      [style.min-width.px]="column.id == 'status' ? 150 : column.id.length * 7.5 + 45"
                      [clrDgField]="column.id">
                      <ng-container *clrDgHideableColumn="{
                          hidden:
                            column.id
                            | hideIDColumns
                              : [
                                  'kauftmid',
                                  'kauftsid',
                                  'auftragid',
                                  'bezirk',
                                  'stellwerk',
                                  'bild',
                                  'rueckbilder'
                                ]
                        }">{{ column.id | titlecase | mrTranslate }}
                      </ng-container>
                    </clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of mnglTable['rows']" [clrDgItem]="row">
                      <clr-dg-cell [style.min-width.px]="72">
                        <ng-container *ngIf="getObjectGeometry(row) !== null; else elseTemplate">
                          <clr-dg-cell style="min-width: 50px; width: 50px;">
                            <a href="javascript:void(0);" (click)="previewMap(row)" disabled="!geometry">
                              <clr-icon shape="search" size="20"></clr-icon>
                            </a>
                          </clr-dg-cell>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <clr-icon size="20" shape="search" disabled></clr-icon>
                        </ng-template>
                      </clr-dg-cell>
                      <clr-dg-cell [style.min-width.px]="55">
                        <a href="javascript:void(0);" (click)="mangelMedia = media.openMangelMedia(row.Bild, row.rueckbilder)"
                          [class.deactivate]="!row.Bild && !row.rueckbilder">
                          <clr-icon shape="image-gallery" size="20"></clr-icon>
                        </a>
                      </clr-dg-cell>
                      <clr-dg-cell *ngFor="let cell of mnglTable['columns']" [innerHTML]="
                          row[cell.id] | tablePrettyPrint
                        " [title]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                        [style.min-width.px]="cell.id.length * 7.5 + 45"
                        class="name-cell">
                      </clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" #paginationMng [clrDgPageSize]="5">
                        <clr-dg-page-size [clrPageSizeOptions]="[5, 10, 20, 30]">{{ "Zeilen pro Seite" | mrTranslate }}
                        </clr-dg-page-size>
                        {{ paginationMng.firstItem + 1 }} -
                        {{ paginationMng.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ paginationMng.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <!-- <clr-datagrid class="objectTable"></clr-datagrid> -->
            </ng-template>
          </div>
        </div>

        <div #dvwrapdper id="wrapperdetailview" *ngIf="showdetailview"
          [ngClass]="{ sidenavenlarged, split50, hidedetailview }">
          <app-detailview [ostammid]="ostammid" [progressbarenlarged]="progressbarenlarged"
            [expanded]="sidenavenlarged" [hidden]="hidedetailview" [split50]="split50" (enlarge)="enlargeSidenav($event)"
            (enlargePB)="enlargeProgressbar(true)" (progressData)="showProgressData($event)"
            (indexScrub)="handleScrub($event)" (reload)="loadAuftraege()" #detailView></app-detailview>
        </div>

        <div id="progresstrigger" *ngIf="showprogressbar" [ngClass]="{ bottom: !progressbarenlarged }">
          <div class="wrap" (click)="enlargeProgressbar()">
            <clr-icon [ngStyle]="{
                transform: progressbarenlarged
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)'
              }" shape="caret" class="expandProgressbarButton cursor-pointer"></clr-icon>
          </div>
        </div>
        <footer *ngIf="showprogressbar && progressbarenlarged" id="wrapperprogressbars"
          class="clr-row nxm clr-align-items-center">
          <app-progressbar [dataIn]="progressbars" [indexScrub]="indexScrub"
            (enlargeProgressbar)="enlargeProgressbar(true)" 
            style="display: inline-block; width: calc(100% - 35px)"></app-progressbar>
        </footer>
      </div>
    </clr-tab-content>
  </clr-tab>
  <clr-tab>
    <button clrTabLink>
      {{ "Kosten" | mrTranslate }}
    </button>
    <clr-tab-content *clrIfActive>
      <app-kostenliste (dataRefresh)="reloadTabs()"></app-kostenliste>
    </clr-tab-content>
  </clr-tab>
</clr-tabs>

<clr-modal [(clrModalOpen)]="showGIS" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{'GIS Map' | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm>
      <div style="width:854px;height:580px">
        <div id="previewMap" style="width:100%; height:100%;z-index: 2;"></div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="showGIS = false;">
      {{"Schließen" | mrTranslate}}</button>
  </div>
</clr-modal>

<app-mediaviewmodal *ngIf="mangelMedia"
  (onClose)="mangelMedia = undefined"
  [srcs]="mangelMedia"
  [getMediaFromServerAll]="media.getApiFunctionMedia"
  [getBigFileFnc]="media.getApiFunctionMediaBig">
</app-mediaviewmodal>
