import { Toleranzen, ToleranzenAktivOptions, ToleranzenBezeichnungen } from "./zaehler.types";

export const enum ZählerMode {
  ZAEHLER = 1,
  KRAFTMESSUNG
}

export const enum ZType {
  NotHeader = 0,
  Header = 6
}

export const enum ZArt {
  Numerisch      = 1,
  SollGreat      = 2,
  SollEqGreat    = 21,
  SollLess       = 3,
  SollEqLess     = 31,
  Mindestwert    = 4,
  Maximalwert    = 5,
  ZwischenMinMax = 9,
  JaNein         = 6
}
type SollMinusPlusText = '=' | '<' | '>' | '<=' | '>=' | '>...<' | 'Ja' | '';
export type SollMinusPlusOptions = {
  [key in 'soll' | 'plus' | 'minus']?: {
    text: SollMinusPlusText
    input: 'Num' | 'Text' | 'Select'
  }
};

export const ZArtOptions: Map<ZArt, SollMinusPlusOptions> = new Map([
  [ZArt.Numerisch, { 
    soll: { input: 'Num', text: ''/* '=' */ },
    plus: { input: 'Num', text: '>' },
    minus: { input: 'Num', text: '<' }
  }],
  [ZArt.SollGreat, {
    soll: { input: 'Num', text: '>' },
    minus: { input: 'Text', text: '<=' }
  }],
  [ZArt.SollEqGreat, {
    soll: { input: 'Num', text: '>=' },
    minus: { input: 'Text', text: '<' }
  }],
  [ZArt.SollLess, {
    soll: { input: 'Num', text: '<' },
    plus: { input: 'Text', text: '>=' }
  }],
  [ZArt.SollEqLess, {
    soll: { input: 'Num', text: '<=' },
    plus: { input: 'Text', text: '>' }
  }],
  [ZArt.Mindestwert, {
    soll: { input: 'Text', text: ''/* '>=' */ },
    minus: { input: 'Num', text: '<' }
  }],
  [ZArt.Maximalwert, {
    soll: { input: 'Text', text: ''/* '<=' */ },
    plus: { input: 'Num', text: '>' }
  }],
  [ZArt.ZwischenMinMax, {
    soll: { input: 'Text', text: '>...<' },
    plus: { input: 'Num', text: '>' },
    minus: { input: 'Num', text: '<' }
  }],
  [ZArt.JaNein, {
    soll: { input: 'Select', text: 'Ja' }
  }],
]);

export const ZArtLongNames: Map<ZArt, string> = new Map([
  [ZArt.Numerisch,      'Numerische Toleranzen'],
  [ZArt.SollGreat,      'Ist-Wert <= Soll, dann Ausfall'],
  [ZArt.SollEqGreat,    'Ist-Wert < Soll, dann Ausfall'],
  [ZArt.SollLess,       'Ist-Wert >= Soll, dann Ausfall'],
  [ZArt.SollEqLess,     'Ist-Wert > Soll, dann Ausfall'],
  [ZArt.Mindestwert,    'Ist-Wert < Mindestwert, dann Ausfall'],
  [ZArt.Maximalwert,    'Ist-Wert > Maximalwert, dann Ausfall'],
  [ZArt.ZwischenMinMax, 'Ist-Wert liegt zwischen Min und Max'],
  [ZArt.JaNein,         'Ja/Nein-Prüfung'],
]);

export const enum VerknüpfungsTyp {
  Hauptzähler,
  Wartungszähler
};

export const enum MessungDropdownList {
  Messender = 'messender',
  Messgerät = 'geraet'
};

export const enum AusfallStatus {
  iO,
  X,
  XX,
  XXX
};

export const matchTolOption: Record<
  keyof Toleranzen |
  keyof ToleranzenAktivOptions, keyof ToleranzenBezeichnungen
> = {
  'SR100minus': 'TEXT_100_m',
  'SR100plus': 'TEXT_100_p',
  'aktivSR100': 'TEXT_100_S',
  
  'SRGminus': 'TEXT_G_m',
  'SRGplus': 'TEXT_G_p',
  'aktivSRG': 'TEXT_G_S',
  
  'SRlimminus': 'TEXT_L_m',
  'SRlimplus': 'TEXT_L_p',
  'aktivSRlimm': 'TEXT_L_S',

  'SR100': 'TEXT_100_S',
  'SRG': 'TEXT_G_S',
};

export const defaultTolBez: ToleranzenBezeichnungen = {
  //#region Tol SR100
  TEXT_100_m: 'SR100 -',
  TEXT_100_p: 'SR100 +',
  TEXT_100_A: 'SR100',
  TEXT_100_S: 'SR100',
  COLOR_100: '#90EE90',
  //#endregion
  //#region Tol SRLim
  TEXT_L_m: 'SRLim -',
  TEXT_L_p: 'SRLim +',
  TEXT_L_A: 'SRLim',
  TEXT_L_S: 'SRLim',
  COLOR_L: '#FFFF00',
  //#endregion
  //#region Tol SRG
  TEXT_G_m: 'SRG -',
  TEXT_G_p: 'SRG +',
  TEXT_G_A: 'SRG',
  TEXT_G_S: 'SRG',
  COLOR_G: 'FF0000'
  //#endregion
};