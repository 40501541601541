import { Pipe, type PipeTransform } from '@angular/core';
import { Toleranzen, ZählerWert } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.types';
import { ZArt } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.enums';

@Pipe({
  name: 'addTol',
  standalone: true,
})
export class AddToleranzenPipe implements PipeTransform {

  transform(colName: keyof Toleranzen | 'Soll', wert: ZählerWert): number | string {
    switch (colName) {
      case 'Soll': {
        const tol = wert[colName];
        return wert.ZArt != ZArt.JaNein ? `${tol ?? ''} [${wert.Einheit}]` : null;
      }
      case 'SR100plus':
      case 'SRlimplus':
      case 'SRGplus': {
        const tol = wert[colName];
        if (wert.ZArt == ZArt.Numerisch && wert.Soll !== null && tol !== null)
          return +wert.Soll - tol;
        else return wert.ZArt == ZArt.JaNein ? null : tol;
      }
      case 'SR100minus':
      case 'SRlimminus':
      case 'SRGminus': {
        const tol = wert[colName];
        if (wert.ZArt == ZArt.Numerisch && wert.Soll !== null && tol !== null)
          return +wert.Soll + tol;
        else return wert.ZArt == ZArt.JaNein ? null : tol;
      }
    }
  }

}
