<div class="overlay-inner-wrapper overlay-fullscreen">
  <div #widthRef id="inspektionWrapper" class="h-100">

    <header class="header-2 scroll-x no-scroll-y">
      <div class="branding" style="min-width: 0;">
        <span class="title">{{'Inspektion' | mrTranslate}}</span>
      </div>
      <div class="header-actions">

        <ng-container *ngIf="type | objtype: 'line'">
          <clr-toggle-container clrInline>
            <clr-toggle-wrapper style="margin-top: -5px;">
              <label style="color: white;">
                {{ "zur Eingabe drehen" | mrTranslate }}
              </label>
              <input type="checkbox" clrToggle
                [(ngModel)]="drehung.isOn" [ngModelOptions]="{standalone: true}"/>
            </clr-toggle-wrapper>
          </clr-toggle-container>
        </ng-container>
        <clr-date-container class="mt-0 clr-align-self-center">
          <label class="padding-5-l headerFont" for="date">
            {{'Inspektionsdatum' | mrTranslate}}
          </label>
          <input type="date" clrDate
            class="headerFont"
            [ngModel]="inspektiondate | localeDate"
            (ngModelChange)="inspektiondate = localeDate.tosql($event)"
            [ngModelOptions]="{updateOn: 'blur', standalone: true}" />
        </clr-date-container>
        <!-- <div class="width100 clr-align-self-center"
          style="flex: 1 1 0; max-width: 200px;">
        </div> -->
        <clr-dropdown>
          <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="cog"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <ng-container *ngIf="type | objtype: 'point'; else Line">
            <clr-dropdown>
              <ng-container *ngIf="activeTab.mangel == true">
              <button clrDropdownTrigger>{{'Checkliste' | mrTranslate}}</button>
              <clr-dropdown-menu clrPosition="bottom-left">
                <clr-dropdown>
                  <button clrDropdownTrigger>{{'Öffnen' | mrTranslate}}</button>
                  <clr-dropdown-menu clrPosition="bottom-left">
                    <ng-container *ngFor="let checkliste of checklistennamen">
                      <button clrDropdownItem (click)="mangelTab.openChecklist(checkliste)">{{ checkliste }}</button>
                    </ng-container>
                  </clr-dropdown-menu>
                </clr-dropdown>
                <button clrDropdownItem (click)="listNameModal.open()">{{'Speichern unter' | mrTranslate}}</button>
                <clr-dropdown>
                  <button clrDropdownTrigger>{{'Fehlende Prüfarten ergänzen' | mrTranslate}}</button>
                  <clr-dropdown-menu clrPosition="bottom-left">
                    <ng-container *ngFor="let checkliste of checklistennamen;">
                      <button clrDropdownItem (click)="addPartChecklist(checkliste)">{{ checkliste }}</button>
                    </ng-container>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </clr-dropdown-menu>
              <div class="dropdown-divider" role="separator"></div>
              <button clrDropdownItem (click)="check_mess_verletzung()">{{'Messwertverletzungen prüfen' | mrTranslate}}</button>
              <button clrDropdownItem (click)="clear_mess_verletzung()">{{'alte Messwertverletzungen löschen' | mrTranslate}}</button>
                <div class="dropdown-divider" role="separator"></div>
              </ng-container>
              <button clrDropdownItem (click)="deletePruefung()">{{'aktuelle Inspektion löschen' | mrTranslate}}</button>
            </clr-dropdown>
          </ng-container>

          <ng-template #Line>
            <clr-dropdown>
              <button clrDropdownItem (click)="deletePruefung()">{{'aktuelle Inspektion löschen' | mrTranslate}}</button>
            </clr-dropdown>

          </ng-template>

          </clr-dropdown-menu>
        </clr-dropdown>



        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon  shape="floppy" (click)="saveitems()"></clr-icon >
        </a>


        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon  shape="window-close" (click)="close()"></clr-icon >
        </a>
      </div>
    </header>
    <clr-tabs class="h-60 scroll-x">
      <clr-tab>
        <button clrTabLink (click)="tabChange('stamm')">
          {{ "Stammdaten" | mrTranslate }}
          <clr-icon *ngIf="success.stamm == false" solid shape="exclamation-triangle" class="invalidRed"></clr-icon >
        </button>
          <clr-tab-content>
            <app-stammdatendialog [settypeid]="type" [setotypid]="otypid" [setostammid]="ostammid" [setopruefid]="pruefid"></app-stammdatendialog>
          </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange('spez')">
          {{ "Spezifikation" | mrTranslate }}
          <clr-icon *ngIf="success.spez == false" solid shape="exclamation-triangle" class="invalidRed"></clr-icon >
        </button>
          <clr-tab-content>
            <app-spezifikationsdialog  [settypeid]="type" [setotypid]="otypid" [setostammid]="ostammid" [setstammData]="stammData"></app-spezifikationsdialog>
          </clr-tab-content>
      </clr-tab>
      <ng-container *ngIf="type == 4">
        <clr-tab>
          <button clrTabLink (click)="tabChange('bauwerk')"
            [clrTabLinkInOverflow]="widthRef.offsetWidth < 450">
            {{ "Bauwerke" | mrTranslate }}
            <clr-icon *ngIf="success.bauwerke == false" solid shape="exclamation-triangle" class="invalidRed"></clr-icon >
            </button>
            <clr-tab-content>
              <app-bauwerksdialog *ngIf="type == 4" [setotypid]="otypid" [setostammid]="ostammid" [setstammData]="stammData"></app-bauwerksdialog>
            </clr-tab-content>
        </clr-tab>
      </ng-container>
      <clr-tab>
        <button clrTabLink (click)="tabChange('mangel')"
          [clrTabLinkInOverflow]="widthRef.offsetWidth < (type == 4 ? 550 : 450)">
          {{ "Mangel" | mrTranslate }} / {{ "Zustand" | mrTranslate }}
          <clr-icon *ngIf="success.mangel == false" solid shape="exclamation-triangle" class="invalidRed"></clr-icon >
        </button>
          <clr-tab-content>
            <app-mangel-dialog [settypeid]="type" [setotypid]="otypid" [setostammid]="ostammid" [(setpruefid)]="pruefid" [setstammData]="stammData" [setCloseDialog]="closedialog"></app-mangel-dialog>
          </clr-tab-content>
      </clr-tab>

      <!-- Messungen Punkt-->
      <ng-container *ngIf="messungen">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('messungen')"
            [clrTabLinkInOverflow]="widthRef.offsetWidth < 550">
            {{ "Messungen" | mrTranslate }}
          </button>
            <clr-tab-content>
              <div #skizzeRef>
                <img *ngIf="skizzeData" [src]="skizzeData"
                  (click)="imageZoom('skizze')" draggable="false"
                  class="invertWhenDark cursor-pointer skizze" />
              </div>

              <clr-datagrid [dymanicStyle]="skizzeRef"
                class="clr-row nxm clr-col datagrid-compact h-100"> 

                <clr-dg-column *ngFor="let column of messungen['columns']" [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns">
                  <ng-container *clrDgHideableColumn="{hidden: column.id | lowercase | hideIDColumns}">
                    {{ (column.id.includes("SR") || column.id.includes("SRG")) ? (column.id | mrUnitText) :
                    (column.id
                    | titlecase | mrUnitText) }}
                  </ng-container>
                </clr-dg-column>

                <clr-dg-row *clrDgItems="let row of messungen['rows']">
                  <clr-dg-cell class="name-cell" *ngFor="let cell of messungen['columns']"
                    [hidden]="cell.id | hideIDColumns" [innerHTML]="row[cell.id] | tablePrettyPrint "></clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="30">
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                    {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </clr-tab-content>
        </clr-tab>
      </ng-container>

      <!-- Historie -->
      <clr-tab class="w-100-p">
        <button clrTabLink (click)="tabChange('hist')"
          [clrTabLinkInOverflow]="widthRef.offsetWidth < 600">
          {{ "Historie" | mrTranslate }}
        </button>
          <clr-tab-content>
            <app-historie-tree [ostammid]="ostammid"></app-historie-tree>
          </clr-tab-content>
      </clr-tab>
    </clr-tabs>

  </div>
</div>

<clr-modal *ngIf="skizzeData" [(clrModalOpen)]="showSkizzeModal" [clrModalSize]="'lg'" class="customModal"
  [clrModalStaticBackdrop]="false">
  <div class="modal-body">
    <img [src]="skizzeData" class="invertWhenDark" />
  </div>
</clr-modal>

<clr-modal *ngIf="bildData" [(clrModalOpen)]="showBildModal" [clrModalSize]="'lg'" class="customModal"
  [clrModalClosable]="true" [clrModalStaticBackdrop]="false">
  <div class="modal-body">
    <img class="modalImage" [src]="bildData" />
  </div>
</clr-modal>

<clr-modal #listNameModal [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Die Checkliste unter anderem Namen speichern" | mrTranslate }}</h3>
  <div class="modal-body clr-row nxm">
    <form clrForm class="clr-col-11 width100" #f="ngForm" clrLayout="horizontal"
      (ngSubmit)="f.valid && saveCheckliste(f.value.name) && listNameModal.close()">
      <clr-input-container *ngIf="listNameModal._open">
        <label for="Name">{{ "Name" | mrTranslate }}</label>
        <input clrInput type="text" name="name" [autofocus]="listNameModal._open"
          [inTableValidator]="checklistennamen" doesNotInclude required
          ngModel [ngModelOptions]="{ updateOn: 'blur' }"/>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'in-Table'; error as err">
          {{err | mrTranslate}}
        </clr-control-error>
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
      [disabled]="f.invalid" (click)="f.ngSubmit.emit()">
      {{'OK' | mrTranslate}}
    </button>
    <button type="button" class="btn" (click)="listNameModal.close()">
      {{'Abbrechen' | mrTranslate}}
    </button>
  </div>
</clr-modal>
<clr-modal #messVerlModal [(clrModalOpen)]="messVerlMeldung" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Fehler Messwertverletzungen' | mrTranslate}}</h3>
  <div class="modal-body" [innerHTML]="messVerlMeldung">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="messVerlModal.close();">
      {{'OK' | mrTranslate}}
    </button>
  </div>
</clr-modal>