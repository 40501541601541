import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrDatagrid } from '@clr/angular';
import dayjs from "dayjs";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { BasicStoreService } from "../../../../services/BasicStore/basic-store.service";

// export interface FormulareRow {
//   ID: number;
//   Typ: string;
//   FeldName: string;
//   AuswahlOption: string;
//   Bezeichnung: string;
// }

@Component({
  selector: 'app-zaehlerdialog',
  templateUrl: './zaehlerdialog.component.html',
  styleUrls: ['./zaehlerdialog.component.scss'],
  imports: [ CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, HideIDColumnsPipe, TablePrettyPrintPipe ],
  standalone: true
})
export class ZaehlerdialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();
  set data(dataIn: any) {
    this.formType = dataIn.type;
    this.dataRefresh = dataIn.dataRefresh;
  }

  public close() {
    this.closedialog.next(true);
  }

  modalNewTitleGroup = new UntypedFormGroup({
    code: new UntypedFormControl("", [Validators.required]),
    bezeichnung: new UntypedFormControl("", [Validators.required]),
    optionsSRG: new UntypedFormControl(""),
    optionsSR100: new UntypedFormControl(""),
  });
  modalZEditGroup = new UntypedFormGroup({
    code: new UntypedFormControl("", [Validators.required]),
    bezeichnung: new UntypedFormControl("", [Validators.required]),
    soll: new UntypedFormControl("", [Validators.required]),
    zArt : new UntypedFormControl(1, [Validators.required]),
    zType : new UntypedFormControl(false, [Validators.required]),
    zEinheit : new UntypedFormControl("", [Validators.required]),
    SR100plus: new UntypedFormControl(""),
    SR100minus: new UntypedFormControl(""),
    aktivSR100: new UntypedFormControl(""),
    SRLimplus: new UntypedFormControl(""),
    SRLimminus: new UntypedFormControl(""),
    aktivSRLimm: new UntypedFormControl(""),
    SRGplus: new UntypedFormControl(""),
    SRGminus: new UntypedFormControl(""),
    aktivSRG: new UntypedFormControl(""),
    SGenau: new UntypedFormControl(0),
    TGenau: new UntypedFormControl(0),
  });

  formType = 0;
  szaehwID = '';
  lastZaehlerRowIndex: number = 0;
  modaltitel: string = '';
  // copyformular: number = 0;
  copyvorlageID: number = 0;
  dorename: boolean = false;
  renameInitName: string = "";
  copytabelle: number = 0;
  // selectedzaehler: any;
  selectedtabellen: any;
  cansortup: boolean = false;
  cansortdown: boolean = false;
  cansortuptabelle: boolean = false;
  cansortdowntabelle: boolean = false;
  // selectionFormulareIndex: number = 0;
  // selectionvorlageID: number = 0;
  isZTitelAddVisible: boolean = false;
  isZaehlerEinheitenEditVisible : boolean = false;
  image :{
    file:File | undefined,
    src:any | undefined,
    date:string | "",
  }
  // structures: any;
  tblEinheiten: any;
  tblObjektTypen:any;
  tblZaehler: any;
  tblTitel: any;
  locked: boolean = true;
  // typOptions = ['Normales Feld', 'Überschrift', 'Titel'];
  titles :{
    topTitle: string,
    titleLeft: string,
    titleRight: string,
  }
  // objektTypOptions = [1, 2, 3];
  // auswahlOptions = ['Stammdaten', 'Spezifikationsdaten', 'Herstellerdaten'];
  // firstColumnId = 'Typ';
  // secondColumnId = 'FeldName';
  // formReflectData: {
  //   mainTitle: string | null;
  //   title: string | null;
  //   subheading: string | null;
  //   fieldType: string | null;
  // }[] = [];
  // vorlageNames: Array<{ ID: number, VorlageName: string }>;
  // selectedVorlageName: string;
  // selectedTabelleName: string;
  selectedTabelleID: number | null = null;
  // selected: any = [];
  selectedObjektTyp: number | 0;
  selectedObjektTypEinheit: number | 0;

  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  // @ViewChild(ClrForm) form: ClrForm;

  // @Output() modalOpen = new EventEmitter<boolean>();

  // _currentPage: number = 1;
  // set currentPage(newPage: number) {
  //   this._currentPage = newPage;
  // }
  // get currentPage() {
  //   return this._currentPage;
  // }

  // set _modalOpen(newVal: boolean) {
  //   this.modalOpen.emit(newVal);
  // }

  // loading: boolean = true;

  // activeTab: any = {
  //   formulare: false,
  //   tabellen: false
  // };

  isVorlageAddVisible: boolean = false;
  isZaehlerAddVisible: boolean = false;
  // @ViewChild('_vorlage_new') _vorlage_new: ElementRef;


  // tabellensourcetype:number = 0;
  // options: any = 'messen';

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private basicStore: BasicStoreService,
    private domSanitizer: DomSanitizer,

  ) {
    this.selectedObjektTyp = 1;
    this.getStructures();
    this.image = {
      src: undefined,
      file: undefined,
      date: undefined,
    }
  }

  getStructures() {
    this.apiservice
      .getZaehlerEinheiten()
      .pipe(first())
      .subscribe((val: any) => {
        this.tblEinheiten = val.rows;
      });

      this.apiservice
      .getZaehlerObjekttypen()
      .pipe(first())
      .subscribe((val: any) => {
        this.tblObjektTypen = val;
      });
  }

  zaehlerArtSelected(type:string) {

  }

  changeZaehler(dir : number)  {
    let c = this.modalZEditGroup.value;
    // let test = true;
    // c.forEach((changedTabellen: any) => {
    //   if (!changedTabellen || !changedTabellen.Code) {
    //     this.toastr.warning(this.mrTranslate.transform("Bitte bei der Zeile den Code angeben."));
    //     test = false;
    //     return;
    //   }
    //   changedTabellen.SZAEHNID = this.selectedTabelleID;
    // });
    // if (test) {
    // }
    c.id = this.szaehwID;
    c.szaehnID = this.selectedTabelleID;
    c.zType = c.zType == 1? 6 : 0;
    this.apiservice.editTabellen(c).pipe(first()).subscribe(val => {
      this.editZaehlerRow(this.tblZaehler.rows[this.lastZaehlerRowIndex + dir],this.lastZaehlerRowIndex + dir);
    });
    this.locked = true;
  }

  // addPictureToZaehler() {
  //   this.apiservice
  //     .getZaehlerObjekttypen() //Save Picture
  //     .pipe(first())
  //     .subscribe((val: any) => {
  //       this.tblObjektTypen = val;
  //     });
  // }

  onFileChange(event: any) {
    let files: File[] = event.target.files;

    for (const file of files) {
      if (file.type == "image/jpeg" || file.type == "image/png") {
        let fileReader = new FileReader();
        fileReader.onload = (e: any) => {
          this.image = {
            src: this.domSanitizer.bypassSecurityTrustUrl(
              e.target.result.toString()
            ),
            file: file,
            date: dayjs().format("DD.MM.YYYY HH.mm.ss.SSS"),
          };
          var body = {
            src: this.image.src.changingThisBreaksApplicationSecurity,
            id: this.selectedtabellen.ID
          }
          this.apiservice.setZaehlerImage(body).subscribe((val) => {

          });
        };
        fileReader.readAsDataURL(file);

      }
    }
  }

  objTypChanged(typ:string) {
    this.selectedObjektTyp = parseInt(typ);
    this.reloadTabellen();
  }

  objTypChangedInEinheit(typ:string) {
    this.selectedObjektTypEinheit = parseInt(typ);
  }


  selectTabelleID(selectedTabelleID: number) {
    this.selectedTabelleID = selectedTabelleID;
    this.fetchDataForTabelleName(selectedTabelleID);
  }

  reloadTabellen() {
    this.apiservice.getTabellenNames(this.selectedObjektTyp,this.formType+1).subscribe(
      namesAndIDs => {
        this.tblTitel = namesAndIDs;
        if (this.tblTitel.rows.length > 0) {
          this.selectTabelleID(this.tblTitel.rows[0].ID);
        }
      }
    );
    this.apiservice.getTabellen().pipe(first()).subscribe(val => {
      this.tblZaehler = val;
    });
  }


  // getVorlageIDByName(name: string): number | null {
  //   const vorlage = this.vorlageNames.find((vorlage: {
  //     ID: number,
  //     VorlageName: string
  //   }) => vorlage.VorlageName === name);
  //   return vorlage ? vorlage.ID : null;
  // }

  addZaehler(){
    let c = this.modalNewTitleGroup.value;
    if (c.code == "" || c.bezeichnung == ""){
      this.toastr.warning(this.mrTranslate.transform("Bitte füllen Sie Code und Bezeichnung aus."));
      return;
    }

    const tab = this.tblTitel?.rows.find(
      (tab) => tab.Code.toLowerCase() === c.code.toLowerCase()
      );

    if (tab !== undefined && !this.dorename){
      this.toastr.warning(this.mrTranslate.transform("Tabelle existiert bereits"));
      return;
    }

    let typ = 1;

    if (this.formType == 1)
      typ = 2;

    c.FATVOLID = this.copyvorlageID.toString();
    c.typ = typ.toString();
    c.TypID = this.selectedObjektTyp;
    c.copyid = this.copytabelle.toString();
    c.rename = this.dorename.toString();

    this.apiservice.addZaehler(c)
      .pipe(first())
      .subscribe((val) => {
        if (!val.toString().includes('error')) {
          this.dorename = false;
          this.isZTitelAddVisible=false;
          this.modalNewTitleGroup.value.code = '';
          this.modalNewTitleGroup.value.bezeichnung = '';
          this.selectedTabelleID = null;
          this.copytabelle = 0;
          this.copyvorlageID = 0;
          this.reloadTabellen();
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else{
          this.toastr.error(this.mrTranslate.transform("Daten wurden nicht gespeichert"));
        }
      });
  }

  // addVorlage() {
  //    const isvorlage = this.vorlageNames.find((vorlage) => vorlage.VorlageName.toLowerCase() === this._vorlage_new.nativeElement.value.toLowerCase());

  //   if (isvorlage !== undefined){
  //     this.toastr.warning(this.mrTranslate.transform("Vorlage existiert bereits"));
  //     return;
  //   }

  //   let data = {
  //     data: this._vorlage_new.nativeElement.value,
  //     copyid: this.copyformular
  //   }

  //   this.apiservice.addVorlage(data)
  //     .pipe(first())
  //     .subscribe((val) => {
  //       if (val) {

  //         this._vorlage_new.nativeElement.value = '';
  //         this.isVorlageAddVisible = false;
  //         this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
  //         this.copyformular=0;

  //       }
  //       else{
  //         this.toastr.error(this.mrTranslate.transform("Daten wurden nicht gespeichert"));
  //       }
  //     });

  // }
  selectionTabelleTitelChanged(value: any) {
    this.selectedTabelleID = value.ID;
    this.fetchDataForTabelleName(this.selectedTabelleID);
  }


  selectionTabellenChanged(value: any) {

    this.cansortuptabelle = false;
    this.cansortdowntabelle = false;

    var index = this.tblZaehler.rows.indexOf(value);
    if (index > 0)
      this.cansortuptabelle = true;

    if (index < this.tblZaehler.rows.length - 1)
      this.cansortdowntabelle = true;


  }

  sortUptable() {
    var index = this.tblZaehler.rows.indexOf(this.selectedtabellen);
    this.tblZaehler.rows = this.swapArrayTabellen(this.tblZaehler.rows, index, index - 1);
    this.selectionTabellenChanged(this.selectedtabellen);
  }

  sortDowntable() {
    var index = this.tblZaehler.rows.indexOf(this.selectedtabellen);
    this.tblZaehler.rows = this.swapArrayTabellen(this.tblZaehler.rows, index, index + 1);
    this.selectionTabellenChanged(this.selectedtabellen);
  }

  swapArrayTabellen(Array: any, Swap1: number, Swap2: number): any {
    let sort1 = Array[Swap1].I_SORT;
    let sort2 = Array[Swap2].I_SORT;
    let id1 = Array[Swap1].ID;
    let id2 = Array[Swap2].ID;

    Array[Swap2].I_Sort = sort1;
    Array[Swap1].I_Sort = sort2;

    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2]
    Array[Swap2] = temp


    let resort = {
      id1: id1,
      id2: id2,
      sort1: sort2,
      sort2: sort1,
    }

    this.apiservice
      .editTabellen({ resort })
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      })

    return Array;
  }

  // selectionFormulareChanged(value: any) {
  //   this.cansortup = false;
  //   this.cansortdown = false;

  //   var index = this.tblZaehler.rows.indexOf(value);
  //   if (index > 1)
  //     this.cansortup = true;

  //   if (index < this.tblZaehler.rows.length - 1)
  //     this.cansortdown = true;

  // }

  // sortUp() {
  //   var index = this.tblZaehler.rows.indexOf(this.selectedzaehler);
  //   this.tblZaehler.rows = this.swapArray(this.tblZaehler.rows, index, index - 1);
  //   this.selectionFormulareChanged(this.selectedzaehler);
  // }

  // sortDown() {
  //   var index = this.tblZaehler.rows.indexOf(this.selectedzaehler);
  //   this.tblZaehler.rows = this.swapArray(this.tblZaehler.rows, index, index + 1);
  //   this.selectionFormulareChanged(this.selectedzaehler);
  // }

  // swapArray(Array: any, Swap1: number, Swap2: number): any {
  //   let sort1 = Array[Swap1].Sorting;
  //   let sort2 = Array[Swap2].Sorting;
  //   let id1 = Array[Swap1].id;
  //   let id2 = Array[Swap2].id;

  //   Array[Swap2].Sorting = sort1;
  //   Array[Swap1].Sorting = sort2;

  //   var temp = Array[Swap1];
  //   Array[Swap1] = Array[Swap2]
  //   Array[Swap2] = temp


  //   let resort = {
  //     id1: id1,
  //     id2: id2,
  //     sort1: sort2,
  //     sort2: sort1,
  //   }

  //   this.apiservice
  //     .editSortTabellen({ resort })
  //     .pipe(first())
  //     .subscribe((val) => {
  //       if (val) {
  //         this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
  //       }
  //     })

  //   return Array;
  // }


  fetchDataForTabelleName(szaehnID: number) {
    this.tblZaehler = undefined;
    this.apiservice.getTabellenDataBySZAEHNID(szaehnID).subscribe(
      data => {
        if (Array.isArray(data.rows)) {
          this.tblZaehler = data;
          this.image = {
            src:  this.selectedtabellen?.["BILD64"] ? this.selectedtabellen["BILD64"].toString() : null,
            file: undefined,
            date: dayjs().format("DD.MM.YYYY HH.mm.ss.SSS"),
          };
        }
      }
    );
  }



  // tabChange() {
    // this.selected = [];
  //   this.currentPage = 1;
  // }

  sendForTabellen() {
    let c = this.modalZEditGroup.value;
    let test = true;

    if (!c.code || c.code == '') {
      this.toastr.warning(this.mrTranslate.transform("Bitte den Zählercode angeben."));
      test = false;
    }
    if (!c.soll) {
      this.toastr.warning(this.mrTranslate.transform("Bitte den Soll-Wert angeben."));
      test = false;
    }
    if (!c.zArt) {
      this.toastr.warning(this.mrTranslate.transform("Bitte einer der Zählertypen auswählen!"));
      test = false;
    }


    if (test) {
      c.id = this.szaehwID;
      c.szaehnID = this.selectedTabelleID;
      c.zType = c.zType == true? 6 : 0;
      this.apiservice.editTabellen(c).pipe(first()).subscribe(val => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        this.selectTabelleID(this.selectedTabelleID);
        this.modalZEditGroup.reset();
        this.isZaehlerAddVisible = false;
      });
      this.locked = true;
    }
  }

  deleteForTabellen(id: number) {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Zeile löschen wollen?"))) {
      this.apiservice.deleteTabelle(id).pipe(first()).subscribe(val => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Zeile gelöscht"));
          this.tblZaehler = val;
          this.selectTabelleID(this.selectedTabelleID)

          setTimeout(() => {
            this.grid.resize();
          })

        }
      });
      this.locked = true;
    }
  }


  deleteTitelTabelle() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diesen Zähler löschen wollen?"))) {
      this.apiservice.deleteTabelleName(this.selectedtabellen.ID).pipe(first()).subscribe(val => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Zähler gelöscht"));
          this.tblZaehler = val;
          this.selectTabelleID(this.selectedTabelleID+1)
          this.reloadTabellen();
          setTimeout(() => {
            this.grid.resize();
          })

        }
      });
      this.locked = true;
    }
  }

  // addFormulare(){
  //   this.modaltitel=this.mrTranslate.transform("Neue Vorlage erstellen");
  //   this.isVorlageAddVisible = true;
  // }

  addZaehlerRow(){
    this.modaltitel=this.mrTranslate.transform("Neuen Zählerpunkt erstellen");
    this.szaehwID = "INSERT";
    this.modalZEditGroup.reset();
    if(this.tblEinheiten.length > 0) this.modalZEditGroup.controls.zEinheit.setValue(this.tblEinheiten[0]["ID"] | 0);
    this.modalZEditGroup.controls.SGenau.setValue(0);
    this.modalZEditGroup.controls.TGenau.setValue(0);
    this.modalZEditGroup.controls.zArt.setValue(1);
    this.isZaehlerAddVisible = true;
  }
  editZaehlerRow(row:any, index:number){
    this.modaltitel=this.mrTranslate.transform("Zählerpunkt bearbeiten");
    this.szaehwID = row["ID"];
    this.lastZaehlerRowIndex = index;
    this.modalZEditGroup.controls.bezeichnung.setValue(row["Bezeichnung"]);
    this.modalZEditGroup.controls.code.setValue(row["Code"]);
    this.modalZEditGroup.controls.soll.setValue(row["Soll"] | 0);
    this.modalZEditGroup.controls.zArt.setValue(row["ZArt"] | 0);
    this.modalZEditGroup.controls.zType.setValue(row["ZType"] == 6? true : false);
    this.modalZEditGroup.controls.zEinheit.setValue(row["ZEinheit"] | 0);
    this.modalZEditGroup.controls.SR100plus.setValue(row["SR 100+"] | 0);
    this.modalZEditGroup.controls.SR100minus.setValue(row["SR 100-"] | 0);
    this.modalZEditGroup.controls.aktivSR100.setValue(row["SR 100 aktiv"] | 0);
    this.modalZEditGroup.controls.SRLimplus.setValue(row["SR lim+"] | 0);
    this.modalZEditGroup.controls.SRLimminus.setValue(row["SR lim-"] | 0);
    this.modalZEditGroup.controls.aktivSRLimm.setValue(row["SR lim aktiv"] | 0);
    this.modalZEditGroup.controls.SRGplus.setValue(row["SRG+"] | 0);
    this.modalZEditGroup.controls.SRGminus.setValue(row["SRG-"] | 0);
    this.modalZEditGroup.controls.aktivSRG.setValue(row["SRG aktiv"] | 0);
    this.modalZEditGroup.controls.SGenau.setValue(row["SGenau"] | 0);
    this.modalZEditGroup.controls.TGenau.setValue(row["TGenau"] | 0);

    this.isZaehlerAddVisible = true;
  }

  addTabelle(){
    this.dorename = false;
    this.copyvorlageID = 0;
    this.copytabelle = 0;
    this.modaltitel = this.mrTranslate.transform("Neue Zählertabelle erstellen");
    this.modalNewTitleGroup.reset();
    this.isZTitelAddVisible = true;
  }

  renameTabelle(){
    this.dorename = true;
    this.copytabelle = this.selectedTabelleID;
    // this.copyvorlageID = this.selectionvorlageID;
    // this.getVorlageIDByName(this.selectedVorlageName);

    const tab = this.tblTitel.rows.find((tabelle) => tabelle.ID === parseInt(this.copytabelle.toString()));

    this.modalNewTitleGroup.controls.code.setValue(tab['Code']);
    this.modalNewTitleGroup.controls.bezeichnung.setValue(tab['Bezeichnung']);
    this.modalNewTitleGroup.controls.optionsSRG.setValue(tab['isSRG']);
    this.modalNewTitleGroup.controls.optionsSR100.setValue(tab['isSR100']);
    this.modaltitel = this.mrTranslate.transform("Titel bearbeiten");
    this.renameInitName = tab['Code'];
    this.isZTitelAddVisible = true;
  }


  addForTabellen() {
    let blank = JSON.parse(JSON.stringify(this.tblZaehler['rows'][0]));
    for (let key in blank) {
      blank[key] = undefined;
    }
    blank['ID'] = 'INSERT';
    blank['I_SORT'] = this.tblZaehler.rows.length + 1;
    this.tblZaehler['rows'].push(blank);
    setTimeout(() => {
      this.grid.resize();
      document.getElementById('saveButton').scrollIntoView(true);
    });
  }

  // triggerLock() {
  //   this.locked = !this.locked;
  // }

  // handleAuswahlOptionChange(row: any, value: string) {
  //   row['Tabelle'] = value;
  // }

  // handleModalReload() {
  //   this.reloadTabellen();
  // }

  ngOnInit() {
    // this.copyformular = 0;
    this.reloadTabellen();
    if(this.formType == 1) {
      this.titles = {
        topTitle: "Stellkraft konfigurieren",
        titleLeft: "Stellkrafttyp",
        titleRight: "Messwerte ",
      }
    } else {
      this.titles = {
        topTitle: "Zähler konfigurieren",
        titleLeft: "Zählerprogramm",
        titleRight: "Zählerwerte SA ",
      }
    }
  }
}
