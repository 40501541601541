//#region imports
import { Component, inject, signal, viewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { ZaehlerStore } from './zaehler.store.service';
import {
  combineLatest,
  defer,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  MessungGroup,
  MesswertEintrag,
  MesswertErfassung,
  Toleranzen,
  ToleranzenBezeichnungen,
  ZählerName,
  ZählerPrüfung,
  ZählerWert,
} from '../../_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.types';
import { cached } from 'src/app/misc/operators';
import { Table } from 'src/app/models/ui/table';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ZType, ZArtOptions, ZArtLongNames, MessungDropdownList, ZArt, AusfallStatus, VerknüpfungsTyp } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.enums';
import { FilterTolByNameOptionsPipe } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/filter-tol-by-options.pipe';
import { TransformColNamesPipe } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/transform-col-names.pipe';
import { WerteRowValuesPipe } from '../../_overlays/zaehlerdialog/zaehlerverwaltung/werte-row-values.pipe';
import { PreviewThumbnailsComponent } from '../../_shared/preview-thumbnails/preview-thumbnails.component';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { HilfeBeschreibungModalComponent } from '../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { AusfallColorPipe } from './ausfall-color.pipe';
import { AddToleranzenPipe } from './add-toleranzen.pipe';
import { CommonModule } from '@angular/common';
import { AtIndexArrayPipe } from 'src/app/pipes/array-methods.pipe';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
//#endregion

// const DELAY_TIME = 500;

@Component({
  selector: 'app-zaehlertab',
  templateUrl: './zaehlertab.component.html',
  styleUrls: ['./zaehlertab.component.scss'],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    PreviewThumbnailsComponent,
    FilterTolByNameOptionsPipe,
    TransformColNamesPipe,
    WerteRowValuesPipe,
    HilfeBeschreibungModalComponent,
    AusfallColorPipe,
    AddToleranzenPipe,
    MrTranslatePipe,
    AtIndexArrayPipe,
    BackendLocaleDatePipe,
    HideIDColumnsPipe,
    TablePrettyPrintPipe
  ],
  providers: [ BackendLocaleDatePipe ]
})
export class ZaehlertabComponent {
  //#region services
  private readonly apiService = inject(APIService);
  private readonly toastr = inject(ToastrService);
  private readonly mrTranslate = inject(MrTranslatePipe);
  protected readonly localeDate = inject(BackendLocaleDatePipe);
  protected readonly store = inject(ZaehlerStore);
  //#endregion
  //#region observables
  private readonly dataRefresh$ = new Subject<void>();

  protected readonly tol$: Observable<ToleranzenBezeichnungen> = this.apiService
    .getToleranzenBezeichnungen().pipe(cached());

  protected readonly zaehler_name$: Observable<ZählerName> = defer(() => {
    switch (this.store.verknüpfung()) {
      case VerknüpfungsTyp.Wartungszähler:
        // return this.apiService.getZaehlerByMaskeID(this.objekt().mID);
      case VerknüpfungsTyp.Hauptzähler:
        return this.apiService.getZaehlerByObjektID(this.objekt().OSTAMMID);
    }
  }).pipe(
    tap(name => {
      if (!name?.ID) this.store.isZaehlerAssigned.set(false);
      else this.store.onSave = this.saveIfValid.bind(this);
    }),
    cached()
  );

  protected readonly werte_checklist$: Observable<Table<ZählerWert>> = 
    this.zaehler_name$.pipe(
      switchMap((name) => this.apiService.getZaehlerWerte(name.ID)));
      
  private readonly pruefungen$: Observable<ZählerPrüfung[]> = this.dataRefresh$.pipe(
    startWith(null),
    switchMap(() => this.apiService.getPruefungenByObjektID(this.objekt().OSTAMMID))
  );
  
  protected readonly messungen$: Observable<MessungGroup[]> = combineLatest([
    this.pruefungen$,
    this.zaehler_name$
  ]).pipe(
    switchMap(([pruefungen, name]) => {
      const groups$: Observable<MessungGroup[]> = pruefungen.length 
        ? forkJoin(pruefungen.map((pruefung) =>
          this.apiService.getZaehlerWerteByPruefungID(pruefung.OPRUEFID, name.ID)
            .pipe(map(messwerte => ({ pruefung, messwerte })))
        ))
        : of([]);
      return groups$;
    }),
    withLatestFrom(this.werte_checklist$),
    map(([ groups, checklist ]) => {
      let lastInd: number, lastDatum: string;
      const groups_mapped: MessungGroup[] = groups.flatMap((group, index) => {
        const { pruefung, messwerte } = group;
        if (!messwerte.length) return [];

        const local_date = this.localeDate.transform(pruefung.P_ZDatum);
        let Column_Datum = local_date;

        if (pruefung.P_ZDatum == lastDatum) 
          Column_Datum = `${local_date} (${index - lastInd})`
        else {
          lastInd = index;
          lastDatum = pruefung.P_ZDatum;
        }
    
        const formatted_pruefung: ZählerPrüfung = { ...pruefung, Column_Datum };
        const matched_messwerte = checklist.rows.map(
          row => messwerte.find(wert => wert.Bezeichnung == row.Code)
        );
        return [{ pruefung: formatted_pruefung, messwerte: matched_messwerte }];
      });
      
      return groups_mapped;
    }),
    tap(groups_mapped => {
      // * edit_messung setzten:
      const defaultMessung = !this.store.isReadOnly() 
        ? createMessungShape() : null;
      const editMessung: MessungGroup = !this.store.isNew() ? groups_mapped.find(
        group => group.pruefung.OPRUEFID == this.objekt().OPRUEFID) : null;
      this.edit_messung.set(editMessung ?? defaultMessung);
    }),
    cached(),
  );

  private readonly istValueChanges$ = defer(() => 
    this.erfassungNgForm().form.get('Ist')?.valueChanges
  ).pipe(takeUntilDestroyed());

  //#endregion
  //#region signals
  protected readonly edit_messung = signal<MessungGroup>(undefined);
  protected readonly erfassungModel = signal<MesswertErfassung>(undefined);
  protected readonly index = signal<number>(0);
  protected readonly messenderList = toSignal<string[]>(
    this.apiService.getZaehlerDropdownList(MessungDropdownList.Messender)
  );
  protected readonly messgeraeteList = toSignal<string[]>(
    this.apiService.getZaehlerDropdownList(MessungDropdownList.Messgerät)
  );
  protected readonly isOpenModal = signal(false);
  protected readonly objekt = this.store.objekt;

  //#region ViewChild
  private pruefungNgForm = viewChild('pruefungForm', { read: NgForm });
  private erfassungNgForm = viewChild('erfassungForm', { read: NgForm });

  private hilfeModal = viewChild.required(HilfeBeschreibungModalComponent);
  //#endregion
  //#endregion
  //#region properties
  private sub: Subscription;
  private isChanged: boolean;
  protected readonly ZType_Header = ZType.Header;
  protected readonly ZArt_Options = ZArtOptions;
  protected readonly ZArt_Map = ZArtLongNames;
  protected readonly trackByID = (_: number, row: ZählerWert) => row.ID;
  //#endregion

  protected async saveIfValid(opruefid: number) {
    if (this.pruefungNgForm().pristine && !this.isChanged)
      return;

    if (this.pruefungNgForm().invalid) {
      this.toastr.warning(
        this.mrTranslate.transform('Datum oder Messender fehlen!'),
        this.mrTranslate.transform('Zähler')
      );
      return;
    }

    try {
      const szaehnid = (await firstValueFrom(this.zaehler_name$)).ID;
      const pack = this.isChanged || this.edit_messung().unsaved
        || this.pruefungNgForm().form.get('P_ZUser').dirty;
      const sendObj: MessungGroup = {
        pruefung: this.edit_messung().pruefung,
        messwerte: pack ? await this.packEintraege() : []
      };

      const { success, error: _ } = await firstValueFrom(
        this.apiService.setZaehlerMessung(opruefid, szaehnid, sendObj)
      );
      if (success) {
        this.toastr.success(
          this.mrTranslate.transform('Erfolgreich gespeichert!'),
          this.mrTranslate.transform('Zähler')
        );
        this.isChanged = false;
        this.pruefungNgForm().form.markAsPristine();
        this.dataRefresh$.next();
      } else this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Zähler')
      );
    } catch (error) {
      if (error == 'Abbrechen')
        return;
      else console.log(error);
    }
    
  }

  private async packEintraege() {
    let setNG: boolean;
    const checklist = (await firstValueFrom(this.werte_checklist$)).rows;
    const messwerte = [ ...this.edit_messung().messwerte ];
    for (let i = 0; i < checklist.length; i++) {
      const eintrag = createEintrag(messwerte[i], checklist[i]);
      if (!eintrag.isHeader && eintrag.Ist == null) {
        setNG ??= confirm(`
          ${this.mrTranslate.transform('Es wurden nicht alle Zählerwerte erfasst.')}
          ${this.mrTranslate.transform('Möchten Sie die Eingaben trotzdem speichen?')}
        `);
        if (setNG) eintrag.Ist = 'n.g.';
        else throw 'Abbrechen';
      }
      messwerte[i] = eintrag;
    }

    return messwerte;
  }

  /* protected async resetMesswerteToDefault() {
    // * sofort API zum Löschen: P_ZDatum; P_ZUser,; omess with OPRUEFID
    const defaultMessung = createMessungShape();
    this.edit_messung.set(defaultMessung);
  } */

  protected async saveRow() {
    const model = this.erfassungModel();
    if (model.Ausfall == this.mrTranslate.transform('i.O.'))
      model.Ausfall = null;

    this.edit_messung.update(messung => {
      const wert = messung.messwerte[this.index()];
      messung.messwerte[this.index()] = { ...wert, ...model };
      return { ...messung };
    });

    this.isChanged = true;

    if (await this.editNextRow() == 'END') {
      this.isOpenModal.set(false);
      this.index.set(0);
    }
  }

  protected editPrevRow() {
    this.editNextRow(null, -1);
  }

  protected async editNextRow(index?: number, direction: 1 | -1 = 1): Promise<'END'> {
    const checklist = (await firstValueFrom(this.werte_checklist$)).rows;
    index ??= this.index() + direction;
    while(checklist[index]?.ZType == ZType.Header) {
      index += direction;
    }
    if (!checklist[index]) return 'END';

    this.erfassungNgForm()?.reset();
    this.index.set(index);
    const wert = checklist[index];
    let ist = this.edit_messung().messwerte[index]?.Ist;
    if (typeof ist == 'string' && !isNaN(+ist))
      ist = +ist;

    this.erfassungModel.set({
      ...this.edit_messung().messwerte[index],
      Ist: ist ?? this.defaultIst(wert)
    });

    setTimeout(() => {
      this.sub?.unsubscribe();
      this.sub = this.istValueChanges$.pipe(
        startWith(this.erfassungModel().Ist)
      ).subscribe(async (ist) => {
        const Ausfall = await this.calcAusfall(ist, wert);
        this.erfassungModel.update(eintrag => ({ ...eintrag, Ausfall}));
      });
      this.isOpenModal.set(true);  
    });
  }

  protected openHelper(name: ZählerName) {
    this.hilfeModal().item = { ...name };
  }

  private async calcAusfall(ist: number | 'Ja' | 'Nein' | 'n.g.', wert: ZählerWert) {
    if (ist == null || ist == 'n.g.')
      return null;

    let ausfall: AusfallStatus | 'max';
    let tol: Toleranzen = { ...wert };

    if (wert.ZArt == ZArt.JaNein) 
      ausfall = ist == 'Ja' ? AusfallStatus.iO : 'max';
    else {
      ist = +ist;
      wert.Soll = +wert.Soll;
      switch(wert.ZArt) {
        case ZArt.Numerisch: {
          tol.SRGminus = wert.Soll - wert.SRGminus;
          tol.SRGplus = wert.Soll + wert.SRGplus;
          tol.SRlimminus = wert.Soll - wert.SRlimminus;
          tol.SRlimplus = wert.Soll + wert.SRlimplus;
          tol.SR100minus = wert.Soll - wert.SR100minus;
          tol.SR100plus = wert.Soll + wert.SR100plus;
          //* weiter in ZwischenMinMax
        }
        case ZArt.ZwischenMinMax: {
          if (ist > tol.SRGplus || ist < tol.SRGminus)
            ausfall = AusfallStatus.XXX;
          else if (ist > tol.SRlimplus || ist < tol.SRlimminus)
            ausfall = AusfallStatus.XX;
          else if (ist > tol.SR100plus || ist < tol.SR100minus)
            ausfall = AusfallStatus.X;
          else
            ausfall = AusfallStatus.iO;
          break; 
        }
        case ZArt.Mindestwert: {
          if (ist < wert.SRGminus)
            ausfall = AusfallStatus.XXX;
          else if (ist < wert.SRlimminus)
            ausfall = AusfallStatus.XX;
          else if (ist < wert.SR100minus)
            ausfall = AusfallStatus.X;
          else
            ausfall = AusfallStatus.iO;
          break;
        }
        case ZArt.Maximalwert: {
          if (ist > wert.SRGplus)
            ausfall = AusfallStatus.XXX;
          else if (ist > wert.SRlimplus)
            ausfall = AusfallStatus.XX;
          else if (ist > wert.SR100plus)
            ausfall = AusfallStatus.X;
          else
            ausfall = AusfallStatus.iO;
          break;
        }
        case ZArt.SollGreat: {
          if (ist > wert.Soll) 
            ausfall = AusfallStatus.iO;
          else 
            ausfall = 'max';
          break;
        }
        case ZArt.SollLess: {
          if (ist < wert.Soll) 
            ausfall = AusfallStatus.iO;
          else 
            ausfall = 'max';
          break;
        }
        case ZArt.SollEqGreat: {
          if (ist >= wert.Soll) 
            ausfall = AusfallStatus.iO;
          else 
            ausfall = 'max';
          break;
        }
        case ZArt.SollEqLess: {
          if (ist <= wert.Soll) 
            ausfall = AusfallStatus.iO;
          else 
            ausfall = 'max';
          break;
        }
      }
    }

    if (ausfall == 'max') {
      ausfall = wert.aktivSRG ? AusfallStatus.XXX 
        : wert.aktivSRlimm ? AusfallStatus.XX
        : wert.aktivSR100 ? AusfallStatus.X 
        : null;
    }
    return this.transformAusfall(ausfall);
  }
  
  private async transformAusfall(ausfall: AusfallStatus) {
    const tol = await firstValueFrom(this.tol$);
    switch (ausfall) {
      case AusfallStatus.X: 
        return tol.TEXT_100_A;
      case AusfallStatus.XX: 
        return tol.TEXT_L_A;
      case AusfallStatus.XXX:
        return tol.TEXT_G_A;
      case AusfallStatus.iO:
        return this.mrTranslate.transform('i.O.');
      default:
        return null;
    }
  }

  private defaultIst(wert: ZählerWert) {
    switch(wert?.ZArt) {
      case ZArt.Mindestwert:
        return wert?.SR100minus ?? wert?.SRlimminus ?? wert?.SRGminus ?? 'n.g.';
      case ZArt.Maximalwert:
        return wert?.SR100plus ?? wert?.SRlimplus ?? wert?.SRGplus ?? 'n.g.';
      case ZArt.SollGreat:
      case ZArt.SollLess:
      case ZArt.ZwischenMinMax:
        return 'n.g.';
      case ZArt.JaNein:
        return 'Ja';
      case ZArt.Numerisch:
      case ZArt.SollEqGreat:
      case ZArt.SollEqLess:
      default:
        return wert.Soll ?? 'n.g.';
    }
  }
}

const createMessungShape = (): MessungGroup => ({
  pruefung: {
    P_ZDatum: BackendLocaleDatePipe.now,
    P_Bemerkung: null,
    P_ZUser: null,
    M_Device: null
  },
  messwerte: [],
  unsaved: true
});

const createEintrag = (model: MesswertErfassung, wert: ZählerWert): MesswertEintrag => ({
  ...model,
  Soll: wert.Soll,
  SR100minus: wert.SR100minus,
  SR100plus: wert.SR100plus,
  SRlimminus: wert.SRlimminus,
  SRlimplus: wert.SR100plus,
  SRGminus: wert.SRGminus,
  SRGplus: wert.SRGplus,
  Bezeichnung: wert.Code,
  isHeader: wert.ZType == ZType.Header,
  INFO: wert.Bezeichnung ? `${wert.Code}: ${wert.Bezeichnung}` : '',
  ZTYP: wert.ZType == ZType.Header? 0 : wert.ZArt == ZArt.JaNein ? 6 : 1,
});