<clr-modal [(clrModalOpen)]="modalOpen" [clrModalSize]="'xl'">
  <h3 class="modal-title d-f">
    <span> {{ currentThumb?.title}} - {{'Medien' | mrTranslate}} ({{ currentIndex + 1 }} / {{ previewThumbnails.length }})</span>
  </h3>
  <div class="modal-body">
    <div class="text-center d-f" style="height: min(50svh, 50vw);">
      <ng-container *ngTemplateOutlet="currentThumb?.plays ? video : image"></ng-container>
      <ng-template #image>
        <pinch-zoom [limit-zoom]="10" class="h-100 w-100-p" style="background-color: transparent;">
          <img [src]="currentThumb?.src" alt="" class="centred" [class.invertWhenDark]="isSkizze">
        </pinch-zoom>

      </ng-template>
      <ng-template #video>
        <clr-spinner *ngIf="loadingFile" class="centred">
        </clr-spinner>
        <video *ngIf="!loadingFile"
          class="mw-100p h-100 centred" controls autoplay [muted]="true"
          [src]="currentThumb?.url" type="video/mp4">
          Your Browser does not support HTML5 Video Playback
        </video>
      </ng-template>
    </div>
    <div class="buttonRow" *ngIf="previewThumbnails.length > 1">
      <button type="button" class="btn btn-icon" aria-label="back" (click)="moveGallery('left')">
        <clr-icon shape="angle left"></clr-icon>
      </button>
      <button type="button" class="btn btn-icon" aria-label="forward" (click)="moveGallery('right')">
        <clr-icon shape="angle right"></clr-icon>
      </button>
    </div>
    <div class="thumbnailRow mw-100p scroll-y" *ngIf="previewThumbnails.length">
      <img class="cursor-pointer" *ngFor="let thumb of previewThumbnails; let i = index"
        [src]="thumb.src" (click)="changeIndexTo(i)"
        [ngStyle]="{'border': currentThumb == thumb ? '1px solid black' : 'none'}" style="max-height:50px" [class.invertWhenDark]="isSkizze">
    </div>
  </div>
</clr-modal>
