import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'check',
  standalone: true
})
export class CheckByCallbackFunctionPipe implements PipeTransform {

  transform<T, R, A>(value: T, cb: (value: T, ...args: A[]) => R, ...args: A[]): R {
    return cb(value, ...args);
  }

}
