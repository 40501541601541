<div class="clr-row h-100">
  <div class="clr-col h-100" style="min-width: 50%">
    <clr-datagrid #datagrid *ngIf="kostenTable.columns !== null"
      class="datagrid-compact auftragDetailKostenTable h-100">
      <!--       id="kostenTable" -->
      <clr-dg-column *ngFor="let column of kostenTable['columns']">
        <ng-container *clrDgHideableColumn="{
            hidden: column.id | hideIDColumns : ['kostentyp', 'kostenid']
          }">{{ column.id | titlecase | mrTranslate }}
        </ng-container>
      </clr-dg-column>

      <!-- PERSONAL ROWS -->
      <ng-container *ngIf="kostenTable.rows?.personal?.length">
        <clr-dg-row>
          <clr-dg-cell class="headlineRow">
            {{ "Personal" | mrTranslate | uppercase }}
            <span class="badge" style="margin-left: 0.3em">{{
              kostenTable.rows.personal.length
              }}</span>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-row *ngFor="let row of kostenTable.rows.personal">
          <clr-dg-cell *ngFor="let cell of kostenTable['columns']"
            [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint" [title]="row[cell.id] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
      </ng-container>

      <!-- MATERIAL ROWS -->
      <ng-container *ngIf="kostenTable.rows?.material?.length">
        <clr-dg-row>
          <clr-dg-cell class="headlineRow">
            {{ "Material" | mrTranslate | uppercase }}
            <span class="badge" style="margin-left: 0.3em">{{
              kostenTable.rows.material.length
              }}</span>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-row *ngFor="let row of kostenTable.rows.material">
          <clr-dg-cell *ngFor="let cell of kostenTable['columns']"
            [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint" [title]="row[cell.id] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
      </ng-container>

      <!-- MASCHINE ROWS -->
      <ng-container *ngIf="kostenTable.rows?.maschine?.length">
        <clr-dg-row>
          <clr-dg-cell class="headlineRow">
            {{ "Maschinen" | mrTranslate | uppercase }}
            <span class="badge" style="margin-left: 0.3em">{{
              kostenTable.rows.maschine.length
              }}</span>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-row *ngFor="let row of kostenTable.rows.maschine">
          <clr-dg-cell *ngFor="let cell of kostenTable['columns']"
            [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint" [title]="row[cell.id] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
      </ng-container>

      <!-- FREMDLEISTUNG ROWS -->
      <ng-container *ngIf="kostenTable.rows?.fremdleistung?.length">
        <clr-dg-row>
          <clr-dg-cell class="headlineRow">
            {{ "Fremdleistungen" | mrTranslate | uppercase }}
            <span class="badge" style="margin-left: 0.3em">{{
              kostenTable.rows.fremdleistung.length
              }}</span>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-row *ngFor="let row of kostenTable.rows.fremdleistung">
          <clr-dg-cell *ngFor="let cell of kostenTable['columns']"
            [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint" [title]="row[cell.id] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
      </ng-container>

      <clr-dg-footer>
        <div style="display: flex; justify-content: space-evenly;">
          <strong>{{ "Kosten" | mrTranslate }}:
            {{ kostenTable.summary.kosten }}&nbsp;€</strong>
          <strong>{{ "Plankosten" | mrTranslate }}:
            {{ kostenTable.summary.plankosten }}&nbsp;€</strong>
        </div>
        <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="15">
          {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
          {{ "von" | mrTranslate }} {{ pagination.totalItems }}
          {{ "Zeilen" | mrTranslate }}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="clr-col h-100" style="min-width: 50%">
    <clr-tabs>
      <clr-tab>
        <button clrTabLink (click)="tabChange()">
          {{ "Personal" | mrTranslate }}
        </button>
        <clr-tab-content *clrIfActive>
          <app-kosten-edit-modal mode="personal" [data]="modalData.personal" [open]="modals.personal"
            [mengeneinheiten]="mengeneinheiten" (close)="closeModal('personal', $event)"></app-kosten-edit-modal>

          <!-- Personal Kosten Form -->
          <div class="clr-row">
            <div class="clr-col" style="min-width: 70%">
              <form clrForm [formGroup]="personalForm" [clrLabelSize]="4" class="width100">
                <clr-date-container style="margin-top: 0.6em">
                  <label>{{ "Datum" | mrTranslate }}(*)</label>
                  <input type="date" clrDate name="datum"
                    [ngModel]="personalForm.get('datum').value | localeDate"
                    (ngModelChange)="personalForm.get('datum').setValue(localeDate.tosql($event))"
                    [ngModelOptions]="{standalone: true, updateOn: 'blur'}"
                    [disabled]="personalForm.disabled" required/>
                </clr-date-container>
                <clr-input-container class="inputLine">
                  <label>{{ "Arbeitszeit" | mrTranslate }}(*)</label>
                  <input clrInput type="time" formControlName="arbeitszeitStart" />
                  <input clrInput style="
                      margin-left: 1em;
                      margin-right: 0.5em;
                    " type="time" formControlName="arbeitszeitEnde" />
                  <input clrInput style="max-width: 4em; text-align: right" [value]="(zeiten.personal || 0) + ' h'"
                    readonly />
                </clr-input-container>
                <clr-select-container>
                  <label>{{ "Tätigkeit" | mrTranslate }}(*)</label>
                  <select clrSelect formControlName="taetigkeit">
                    <option *ngFor="let taetigkeit of taetigkeiten.personal" [value]="taetigkeit">
                      {{ taetigkeit }}
                    </option>
                  </select>
                </clr-select-container>
                <clr-textarea-container>
                  <label>{{ "Bemerkung" | mrTranslate }}</label>
                  <textarea clrTextarea formControlName="bemerkung" name="bemerkung"></textarea>
                </clr-textarea-container>
              </form>
            </div>
            <div class="clr-col" style="
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                max-width: 30%;
              ">
              <button type="button" class="btn btn-primary" style="margin-right: 0px" (click)="saveKosten('personal')"
                [disabled]="trial || auftrag?.Auftragsstatus != 0">
                {{ ("Kosten" | mrTranslate) + ' ' + ("speichern" | mrTranslate) }}
              </button>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col">
              <clr-button-group class="btn-primary btn-sm" style="margin-top: 0.5em;">
                <clr-button (click)="openModal('personal', null)" [disabled]="!selected">{{"Hinzufügen" |
                  mrTranslate}}</clr-button>
                <clr-button (click)="deleteFremdleistung(selected.personal,'personal')"
                  [disabled]="trial || !selected.personal">{{"Löschen" | mrTranslate}}</clr-button>
              </clr-button-group>
            </div>
          </div>

          <!-- Personal Datagrid -->

          <clr-datagrid #datagrid *ngIf="personalTable.rows !== null" [(clrDgSingleSelected)]="selected.personal"
            class="datagrid-compact auftragDetailPersonalTable" id="personalTable">
            <clr-dg-column *ngFor="let column of personalTable['columns']">
              <ng-container *clrDgHideableColumn="{
                  hidden:
                    column.id
                    | hideIDColumns
                      : ['kauftmid', 'bezirk', 'stellwerk', 'bild']
                }">{{ column.id | titlecase | mrTranslate }}
              </ng-container>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let row of personalTable['rows']" [clrDgItem]="row">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="openModal('personal', row)">
                  {{ "Personal bearbeiten" | mrTranslate }}
                </button>
              </clr-dg-action-overflow>
              <clr-dg-cell *ngFor="let cell of personalTable['columns']"
                [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="15">
                {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
                {{ "von" | mrTranslate }} {{ pagination.totalItems }}
                {{ "Zeilen" | mrTranslate }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange()">
          {{ "Material" | mrTranslate }}
        </button>
        <clr-tab-content *clrIfActive>
          <app-kosten-edit-modal mode="material" [data]="modalData.material" [open]="modals.material"
            [mengeneinheiten]="mengeneinheiten" (close)="closeModal('material', $event)"
            [data]="modalData.material"></app-kosten-edit-modal>

          <!-- Material Kosten Form -->

          <div class="clr-row">
            <div class="clr-col" style="min-width: 50%">
              <form clrForm [formGroup]="materialForm" [clrLabelSize]="4" class="width100">
                <clr-date-container style="margin-top: 0.6em">
                  <label>{{ "Datum" | mrTranslate }}(*)</label>
                  <input type="date" clrDate name="datum"
                    [ngModel]="materialForm.get('datum').value | localeDate"
                    (ngModelChange)="materialForm.get('datum').setValue(localeDate.tosql($event))"
                    [ngModelOptions]="{standalone: true, updateOn: 'blur'}"
                    [disabled]="materialForm.disabled" required/>
                </clr-date-container>
                <clr-input-container>
                  <label>{{ "Anzahl" | mrTranslate }}(*)</label>
                  <input clrInput type="number" formControlName="anzahl" />
                </clr-input-container>
                <clr-textarea-container>
                  <label>{{ "Bemerkung" | mrTranslate }}</label>
                  <textarea clrTextarea formControlName="bemerkung" name="bemerkung"></textarea>
                </clr-textarea-container>
              </form>
            </div>
            <div class="clr-col" style="
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                max-width: 30%;
              ">
              <button type="button" class="btn btn-primary" style="margin-right: 0px" (click)="saveKosten('material')"
                [disabled]="trial || auftrag?.Auftragsstatus != 0">
                {{ ("Kosten" | mrTranslate) + ' ' + ("speichern" | mrTranslate) }}
              </button>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col">
              <clr-button-group class="btn-primary btn-sm" style="margin-top: 0.5em;">
                <clr-button (click)="openModal('material', null)" [disabled]="!selected">{{"Hinzufügen" |
                  mrTranslate}}</clr-button>
                <clr-button (click)="deleteFremdleistung(selected.material,'material')"
                  [disabled]="trial || !selected.material">{{"Löschen" | mrTranslate}}</clr-button>
              </clr-button-group>
            </div>
          </div>

          <!-- Material Datagrid -->

          <clr-datagrid #datagrid *ngIf="materialTable.rows !== null" [(clrDgSingleSelected)]="selected.material"
            class="datagrid-compact auftragDetailMaterialTable" id="materialTable">
            <clr-dg-column *ngFor="let column of materialTable['columns']">
              <ng-container *clrDgHideableColumn="{
                  hidden:
                    column.id
                    | hideIDColumns
                      : ['kauftmid', 'bezirk', 'stellwerk', 'bild']
                }">{{ column.id | titlecase | mrTranslate }}
              </ng-container>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let row of materialTable['rows']" [clrDgItem]="row">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="openModal('material', row)">
                  {{ "Material bearbeiten" | mrTranslate }}
                </button>
              </clr-dg-action-overflow>
              <clr-dg-cell *ngFor="let cell of materialTable['columns']"
                [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="15">
                {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
                {{ "von" | mrTranslate }} {{ pagination.totalItems }}
                {{ "Zeilen" | mrTranslate }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange()">
          {{ "Maschinen" | mrTranslate }}
        </button>
        <clr-tab-content *clrIfActive>
          <app-kosten-edit-modal mode="maschine" [data]="modalData.maschine" [open]="modals.maschine"
            (close)="closeModal('maschine', $event)" [data]="modalData.maschine"></app-kosten-edit-modal>

          <!-- Maschinen Kosten Form -->
          <div class="clr-row">
            <div class="clr-col" style="min-width: 50%">
              <form clrForm [formGroup]="maschinenForm" [clrLabelSize]="4" class="width100">
                <clr-date-container style="margin-top: 0.6em">
                  <label>{{ "Datum" | mrTranslate }}(*)</label>
                  <input type="date" clrDate name="datum"
                    [ngModel]="maschinenForm.get('datum').value | localeDate"
                    (ngModelChange)="maschinenForm.get('datum').setValue(localeDate.tosql($event))"
                    [ngModelOptions]="{standalone: true, updateOn: 'blur'}"
                    [disabled]="maschinenForm.disabled" required/>
                </clr-date-container>
                <clr-input-container class="inputLine">
                  <label>{{ "Arbeitszeit" | mrTranslate }}(*)</label>
                  <input clrInput type="time" formControlName="arbeitszeitStart" />
                  <input clrInput style="
                      margin-left: 1em;
                      margin-right: 0.5em;
                    " type="time" formControlName="arbeitszeitEnde" />
                  <input clrInput style="max-width: 4em; text-align: right" [value]="(zeiten.maschine || 0) + ' h'"
                    readonly />
                </clr-input-container>
                <clr-select-container>
                  <label>{{ "Tätigkeit" | mrTranslate }}(*)</label>
                  <select clrSelect formControlName="taetigkeit">
                    <option *ngFor="let taetigkeit of taetigkeiten.personal" [value]="taetigkeit">
                      {{ taetigkeit }}
                    </option>
                  </select>
                </clr-select-container>
                <clr-textarea-container>
                  <label>{{ "Bemerkung" | mrTranslate }}</label>
                  <textarea clrTextarea formControlName="bemerkung" name="bemerkung"></textarea>
                </clr-textarea-container>
              </form>
            </div>
            <div class="clr-col" style="
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                max-width: 30%;
              ">
              <button type="button" class="btn btn-primary" style="margin-right: 0px" (click)="saveKosten('maschine')"
                [disabled]="trial || auftrag?.Auftragsstatus != 0">
                {{ ("Kosten" | mrTranslate) + ' ' + ("speichern" | mrTranslate) }}
              </button>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col">
              <clr-button-group class="btn-primary btn-sm" style="margin-top: 0.5em;">
                <clr-button (click)="openModal('maschine', null)" [disabled]="!selected">{{"Hinzufügen" |
                  mrTranslate}}</clr-button>
                <clr-button (click)="deleteFremdleistung(selected.maschine,'maschine')"
                  [disabled]="trial || !selected.maschine">{{"Löschen" | mrTranslate}}</clr-button>
              </clr-button-group>
            </div>
          </div>

          <!-- Maschinen Datagrid -->

          <clr-datagrid #datagrid *ngIf="maschinenTable.rows !== null" [(clrDgSingleSelected)]="selected.maschine"
            class="datagrid-compact auftragDetailMaschinenTable" id="maschinenTable">
            <clr-dg-column *ngFor="let column of maschinenTable['columns']">
              <ng-container *clrDgHideableColumn="{
                  hidden:
                    column.id
                    | hideIDColumns
                      : ['kauftmid', 'bezirk', 'stellwerk', 'bild']
                }">{{ column.id | titlecase | mrTranslate }}
              </ng-container>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let row of maschinenTable['rows']" [clrDgItem]="row">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="openModal('maschine', row)">
                  {{ "Maschine bearbeiten" | mrTranslate }}
                </button>
              </clr-dg-action-overflow>
              <clr-dg-cell *ngFor="let cell of maschinenTable['columns']"
                [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="15">
                {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
                {{ "von" | mrTranslate }} {{ pagination.totalItems }}
                {{ "Zeilen" | mrTranslate }}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange()">
          {{ "Fremdleistungen" | mrTranslate }}
        </button>
        <clr-tab-content *clrIfActive>
          <app-kosten-fremdleistung-modal [fremdleistung]="selected.fremdleistung" [open]="modals.fremdleistung"
            [mengeneinheiten]="mengeneinheiten"
            (close)="closeModal('fremdleistung', $event)"></app-kosten-fremdleistung-modal>

          <div class="clr-row">
            <div class="clr-col" style="min-width: 69%">
              <form clrForm [formGroup]="fremdleistungForm" id="fremdleistungForm" [clrLabelSize]="4" class="width100">
                <clr-date-container style="margin-top: 0.6em">
                  <label>{{ "Datum" | mrTranslate }}(*)</label>
                  <input type="date" clrDate name="datum"
                    [ngModel]="fremdleistungForm.get('datum').value | localeDate"
                    (ngModelChange)="fremdleistungForm.get('datum').setValue(localeDate.tosql($event))"
                    [ngModelOptions]="{standalone: true, updateOn: 'blur'}"
                    [disabled]="fremdleistungForm.disabled" required/>
                </clr-date-container>
                <clr-input-container id="kurztextInput">
                  <label>{{ "Kurztext" | mrTranslate }}</label>
                  <input clrInput formControlName="kurztext" />
                </clr-input-container>
                <div id="anzahlEinheitInput" class="clr-row">
                  <div class="clr-col-10">
                    <clr-input-container id="anzahlInput">
                      <label class="clr-col-12 clr-col-md-5">{{ "Anzahl / Einheit" | mrTranslate }}</label>
                      <input class="clr-col-12 clr-col-md-7 padding-10-l" clrInput placeholder="Anzahl(*)" type="number"
                        formControlName="anzahl" />
                    </clr-input-container>
                  </div>
                  <div class="clr-col-2">
                    <clr-select-container class="clr-form-control">
                      <label style="display: none"></label>
                      <select class="clr-col-12 clr-col-md-12" clrSelect formControlName="einheit">
                        <option *ngFor="let val of mengeneinheiten" [value]="val">
                          {{ val }}
                        </option>
                      </select>
                    </clr-select-container>
                  </div>
                </div>

                <clr-textarea-container style="margin-top: 1.3em">
                  <label>{{ "Bemerkung" | mrTranslate }}</label>
                  <textarea clrTextarea formControlName="bemerkung" name="bemerkung"></textarea>
                </clr-textarea-container>
              </form>
            </div>
            <div class="clr-col" style="
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                max-width: 30%;
              ">
              <button type="button" class="btn btn-primary" style="margin-right: 0px"
                (click)="saveKosten('fremdleistung')" [disabled]="
                  trial ||
                  auftrag?.Auftragsstatus != 0 ||
                  !fremdleistungForm.valid ||
                  !selected.fremdleistung
                ">
                {{ ("Kosten" | mrTranslate) + ' ' + ("speichern" | mrTranslate) }}
              </button>
            </div>
          </div>
          <div class="clr-row" *ngIf="fremdleistungen?.length > 0">
            <div class="clr-col">
              <app-fremdleistung-stack-view [root]="fremdleistungen" [deleteSelection]="$deleteSelection"
                (selectionChange)="fremdleistungSelected($event)" (action)="fremdleistungAction($event)">
              </app-fremdleistung-stack-view>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </div>
</div>