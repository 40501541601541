import { HilfeBeschreibung } from "../../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component";
import { ZArt, ZType } from './zaehler.enums';

export type ToleranzenAktivOptions = {
  SR100: boolean;
  SRG: boolean;
};

export type ZählerSkizze = {
  Bild64?: string;
  /** Name */
  Bild?: string;
}

export type ZählerName = {
  ID: number;
  /** @required @unique */
  Code: string;
  Bezeichnung: string;
} & ZählerSkizze & ToleranzenAktivOptions & HilfeBeschreibung;

export const TOL_FIELDS = {
  'aktivSRlimm': false,
  'SRlimplus': 0,
  'SRlimminus': 0,
  'aktivSR100': false,
  'SR100plus': 0,
  'SR100minus': 0,
  'aktivSRG': false,
  'SRGplus': 0,
  'SRGminus': 0,
};

export type Toleranzen = {[key in keyof typeof TOL_FIELDS]: typeof TOL_FIELDS[key] };

export type ZählerWert = {
  ID: number;
  SZAEHNID?: number;
  I_SORT: number;
  /** @required @unique within SZAEHNID*/
  Code: string;
  Soll: number | 'Ja';
  Bezeichnung: string;
  ZArt: ZArt; // ENUM
  ZType?: ZType; // ENUM
  isHeader?: boolean;
  ZEinheit: number; // id from szaeeh
  Einheit?: string;
  SGenau: Genauigkeit;
  TGenau: Genauigkeit;
} & Toleranzen;

export type Genauigkeit = 0 | 1 | 2 | 3; // Zahl der Dezimalwerte

export type ToleranzenBezeichnungen = {
  //#region Tol SR100
  TEXT_100_m: string,
  TEXT_100_p: string,
  TEXT_100_A: string,
  TEXT_100_S: string,
  COLOR_100: string,
  //#endregion
  //#region Tol SRLim
  TEXT_L_m: string,
  TEXT_L_p: string,
  TEXT_L_A: string,
  TEXT_L_S: string,
  COLOR_L: string,
  //#endregion
  //#region Tol SRG
  TEXT_G_m: string,
  TEXT_G_p: string,
  TEXT_G_A: string,
  TEXT_G_S: string,
  COLOR_G: string
  //#endregion
};

export type ZählerEinheit = {
  ID: number;
  Code: string;
  Bezeichnung: string;
  OTYPID: number;
};

export type ZählerPrüfung = {
  OPRUEFID?: number;
  P_Datum?: string;
  Column_Datum?: string;
  P_Bemerkung: string;
  /** Messungsdatum */
  P_ZDatum: string;
  /** Messender */
  P_ZUser: string;
  /** Messgerät */
  M_Device: string;
};

export type MesswertErfassung = {
  ID?: number;
  /** @see ZählerWert.Code */
  Bezeichnung?: string;
  Ist: number | 'Ja' | 'Nein' | 'n.g.';
  Bemerkung: string;
  Ausfall: string;
};

export type MesswertEintrag = {
  /** @see ZählerWert */
  Soll: number | 'Ja';  
  SRlimplus: number,
  SRlimminus: number,
  SR100plus: number,
  SR100minus: number,
  SRGplus: number,
  SRGminus: number,
  isHeader: boolean;
 
  /** @see ZählerWert.ZArt */
  ZTYP: 0 | 1 | 6;
  
  /** @see ZählerWert.Code + @see ZählerWert.Bezeichnung */
  INFO: string;
} & MesswertErfassung;

export type MessungGroup = {
  pruefung: ZählerPrüfung;
  messwerte: MesswertErfassung[];
  unsaved?: boolean;
};