<!-- <link rel="stylesheet" type="text/css" href="/src/assets/styles/clr-ui-dark.min.css" /> -->
<header class="header-2">
  <div class="branding">
    <span class="title">{{titles.topTitle | mrTranslate}}</span>
  </div>
  <div class="header-actions">
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon shape="times" (click)="close()"></clr-icon>
    </a>
  </div>
</header>




<div class="overlay-inner-wrapper">
  <div id="tabellenerstellungsWrapper">

    <div class="clr-row" *ngIf="tblObjektTypen?.rows?.length">
      <clr-select-container>
        <label>Objekttyp</label>
        <select clrSelect name="options" value={{selectedObjektTyp}} #mySelect (change)='objTypChanged(mySelect.value)'>
          <option *ngFor="let objlist of tblObjektTypen.rows" value={{objlist.ID}}>{{objlist.Bezeichnung}}</option>
        </select>
      </clr-select-container>
    </div>
    <div class="clr-row">
      <div class="clr-col-4">
        <div  style="margin-top:36px;">
          <label style="margin-top:36px; font-size: large;">{{titles.titleLeft}}  </label>
          <div class="btn-group btn-primary btn-icon">
            <button class="btn btn-success" (click)="addTabelle()">
              <clr-icon shape="file"></clr-icon>
              <span class="clr-icon-title">Add</span>
            </button>
            <button class="btn" [disabled]="selectedTabelleID == null"  (click)="renameTabelle()">
              <clr-icon shape="edit"></clr-icon>
              <span class="clr-icon-title">Sort</span>
            </button>
            <button class="btn btn-danger" [disabled]="selectedTabelleID == null" (click)="deleteTitelTabelle()">
              <clr-icon shape="times"></clr-icon>
              <span class="clr-icon-title">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <div class="clr-col-8">
        <label style="font-size: large;">{{titles.titleRight}}   </label>
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-success" [disabled]="selectedTabelleID == null"  (click)="addZaehlerRow()" style="margin-top:36px;">
            <clr-icon shape="file"></clr-icon>
          </button>
        </div>

      </div>
    </div>

    <div class="clr-row">
      <div class="clr-col-12">

        <button class="btn btn-sm" (click)="addForTabellen()" *ngIf="!locked"> {{ 'Zeile hinzufügen' |
          mrTranslate}}</button>

        <div *ngIf="selectedtabellen" class="inline-block float-right">
          <clr-icon *ngIf="cansortuptabelle" size="24" shape="arrow" (click)="sortUptable()"
            class="cursor-pointer"></clr-icon>
          <clr-icon *ngIf="cansortdowntabelle" size="24" shape="arrow" (click)="sortDowntable()" class="cursor-pointer"
            style="transform: rotate(180deg);"></clr-icon>
        </div>


        <div class="limit-height-500">

          <div class="clr-row nxm">

            <!-- Tabelle Links -->
            <div class="clr-col-4" style="padding-left:0px; padding-right:20px; max-height: 300px;">
              <clr-datagrid *ngIf="tblTitel?.columns?.length" style="padding-top: 0px;" class="datagrid-compact"
                [(clrDgSingleSelected)]="selectedtabellen"
                (clrDgSingleSelectedChange)="selectionTabelleTitelChanged($event)">
                <clr-dg-column *ngFor="let column of tblTitel['columns']" [clrDgField]="column.id"
                  [style.min-width.px]="(column.id.length * 7.5) + 45 "
                  [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid', 'zart']">
                  {{ column.id }}
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of tblTitel['rows']" [clrDgItem]="row">
                  <ng-container *ngFor="let cell of tblTitel['columns']">
                  <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint" [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } " 
                  [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid', 'zart']" style="overflow: hidden; text-overflow: ellipsis;">
                    <input clrInput type="text" [(ngModel)]="row[cell.id]" [readonly]="locked" />
                  </clr-dg-cell>
                </ng-container>
                </clr-dg-row>
                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination
                  [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
              </clr-datagrid>
            </div>

            <!-- Tabelle rechts -->
            <div class="clr-col-8" style="padding-left:0px; padding-right:0px; min-height: 500px;">
              <clr-datagrid style="padding-top: 0px;" class="datagrid-compact" *ngIf="tblZaehler">
                <clr-dg-column clrDgField="delete" [hidden]="locked">{{ 'Löschen' | mrTranslate }}</clr-dg-column>
                <clr-dg-column *ngFor="let column of tblZaehler['columns']" [clrDgField]="column.id"
                  [style.width.px]="(column.id.length * 7.5) + 45 "
                  [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid', 'zart']">
                  {{ column.id }}
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of tblZaehler['rows']; let i = index" [clrDgItem]="row">
                  <clr-dg-cell [hidden]="locked">
                  </clr-dg-cell>
                  <clr-dg-action-overflow>
                    <button class="action-item" (click)="editZaehlerRow(row,i)">{{'Bearbeiten' |
                      mrTranslate}}</button>
                    <button class="action-item" (click)="deleteForTabellen(row['ID'])">{{'Löschen' |
                      mrTranslate}}</button>
                  </clr-dg-action-overflow>
                  <ng-container *ngFor="let cell of tblZaehler['columns']">
                    <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                      [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                      [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid', 'zart']"
                      style="overflow: hidden; text-overflow: ellipsis;">
                      <input clrInput type="text" [(ngModel)]="row[cell.id]" [readonly]="locked" />
                    </clr-dg-cell>
                  </ng-container>
                </clr-dg-row>
                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination
                  [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
              </clr-datagrid>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bereich: Bild -->
    <div class="clr-row">
      <div class="clr-col-12">
        <div style="border-style: solid;">
          <div class="clr-form-control clr-row fileUpload">
            <label class="clr-control-label" style="
                font-size: 0.65rem;
                font-weight: var(--clr-forms-label-font-weight, 600);
              ">{{ "Bild hochladen" | mrTranslate }}</label>
            <div style="display: flex;justify-content: flex-end;align-items: center;">
              <button class="btn btn-sm btn-primary" type="button" [disabled]="selectedTabelleID == null"  (click)="fileInput.click()">
                <clr-icon shape="upload-cloud"></clr-icon>
                {{ "upload" | mrTranslate }}
              </button>
              <input type="file" #fileInput id="fileInput" name="fileInput" accept=".jpg" style="display: none; max-height: 200px;" (change)="onFileChange($event)" />
            </div>
          </div>
          <div class="clr-row">
            <div style="border-style: solid; min-height: 200px; max-height: 200px;">
              <img [src]="image.src" alt="" style="max-height: 196px" class="uploadImagePreview" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




<!-- MODUL ZÄHLEREINHEITEN (EVENTUELL NICHT VERWENDET) -->
  <clr-modal [(clrModalOpen)]="isZaehlerEinheitenEditVisible" [clrModalSize]="'m'" [clrModalClosable]="true">
    <clr-select-container *ngIf="tblObjektTypen?.rows?.length">
      <label>Objekttyp</label>
      <select clrSelect name="options" #mySelect (change)='objTypChangedInEinheit(mySelect.value)'>
        <option *ngFor="let objlist of tblObjektTypen.rows" value="{{objlist.ID}}">{{objlist.Bezeichnung}}</option>
      </select>
    </clr-select-container>
  </clr-modal>




<!-- MODUL ZÄHLERWERTE -->
    <clr-modal [(clrModalOpen)]="isZaehlerAddVisible" [clrModalSize]="'xl'" [clrModalClosable]="true">
      <h3 class="modal-title">{{modaltitel}}</h3>
      <div class="modal-body">
        <form clrForm clrLayout="horizontal" [formGroup]="modalZEditGroup">
          <div class="clr-row">
            <div class="clr-col-4">
              <div class="clr-row">
                <button type="button" class="btn btn-primary" [disabled] ="lastZaehlerRowIndex == 0" (click)="changeZaehler(-1)"><clr-icon
                    shape="arrow left"></clr-icon></button>
                <button type="button" class="btn btn-primary" [disabled] ="tblZaehler && lastZaehlerRowIndex == tblZaehler.rows.length-1" (click)="changeZaehler(1)"><clr-icon
                    shape="arrow right"></clr-icon></button>
              </div>
            </div>
            <div class="clr-col-3">
              <div class="clr-row">
                <clr-select-container>
                  <label>Einheit</label>
                  <select clrSelect style="margin-top: 0px;" name="options" formControlName="zEinheit">
                    <option *ngFor="let list of tblEinheiten" value="{{list.ID}}">{{list.Code}}</option>
                  </select>
                </clr-select-container>
              </div>
              </div>
              <div class="clr-col-5">
                <div class="clr-row" style="margin-top: 0px;">
                  <div class="container">
                    <label>Toleranzwerte Genauigkeit </label>
                    <input type="number" formControlName="TGenau" style="left:100px; width: 100px;" max="4" min="0" value="0"
                      class="clr-input combobox" />
                    <label>Soll-Werte Genauigkeit </label>
                    <input type="number" formControlName="SGenau" style="left:100px;  width: 100px" max="4" min="0" value="0"
                      class="clr-input combobox" />
                  </div>
                  <!-- <div>
                        <button>
                          <clr-icon shape="cog" size="24" class="btn" (click)="isZaehlerEinheitenEditVisible = true"></clr-icon>
                        </button>
                      </div> -->

              </div>
            </div>
          </div>
          <br>
          <br>
          <div class="clr-row">
            <div class="clr-col-5">
              <div class="container">
                <label>Code </label>
                <input formControlName="code" style="width: 100px" class="clr-input combobox"/>
                <label>{{'Soll-Wert' | mrTranslate }} </label>
                <input type="number"formControlName="soll" style="width: 100px" class="clr-input combobox" />
              </div>
            </div>
            <div class="clr-col-7">
              <div style="display: flex; align-items: top;">
                <label style="margin-right: 20px;">{{'Bezeichnung' | mrTranslate }} </label>
                <textarea style="width: 650px; height:70px" formControlName="bezeichnung" name="bezeichnung"></textarea>
              </div>

            </div>
          </div>
          <br>


          <div class="clr-row">
            <div class="clr-col-12">
              <div style="border-style: solid;">
                <div class="clr-row">
                  <div class="clr-col-6">
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A1" style="left: 28px;" clrRadio [value]="2" formControlName="zArt" />
                      <label class="clr-col-md-6">{{'Ist-Wert &lt;= Soll, dann Ausfall' | mrTranslate }}</label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A2" style="left: 28px;" clrRadio [value]="3" formControlName="zArt" />
                      <label for="A2" class="clr-col-md-12">{{'Ist-Wert =&gt; Soll, dann Ausfall' | mrTranslate }}</label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A3" style="left: 28px;" clrRadio [value]="21" formControlName="zArt" />
                      <label for="A3" class="clr-col-md-12">{{'Ist-Wert &lt; Soll, dann Ausfall' | mrTranslate }}</label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A4" style="left: 28px;" clrRadio [value]="31" formControlName="zArt" />
                      <label for="A4" class="clr-col-md-12">{{'Ist-Wert &gt; Soll, dann Ausfall' | mrTranslate }}</label>
                    </div>

                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A5" style="left: 28px;" clrRadio [value]="9" formControlName="zArt" />
                      <label for="A5" class="clr-col-md-12">{{'Ist-Wert liegt zwischen Min und Max' | mrTranslate }}</label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A6" style="left: 28px;" clrRadio [value]="1" formControlName="zArt" />
                      <label for="A6" class="clr-col-md-12">{{'Numerische Toleranzen' | mrTranslate }}</label>
                    </div>
                  </div>
                  <div class="clr-col-6">
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A7" style="left: 28px;" clrRadio [value]="4" formControlName="zArt" />
                      <label for="A7" class="clr-col-md-12">{{'Ist-Wert &lt; Mindestwert, dann Ausfall' | mrTranslate }} </label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="A8" style="left: 28px;" clrRadio [value]="5" formControlName="zArt" />
                      <label for="A8" class="clr-col-md-12">{{'Ist-Wert &lt; Maximalwert, dann Ausfall' | mrTranslate }} </label>
                    </div>
                    <br>
                    <br>
                    <br>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="9" style="left: 28px;" clrRadio [value]="6" formControlName="zArt" />
                      <label for="A9" class="clr-col-md-12">{{'Ja/Nein-Prüfung' | mrTranslate }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="clr-row">
            <div class="clr-col-12">
              <clr-checkbox-container>
                <clr-checkbox-wrapper>
                  <input type="checkbox" clrCheckbox formControlName="zType" />
                  <label>{{'Überschrift' | mrTranslate}}</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <!-- <clr-select-container>
                <label>Zählerart</label>
                <select clrSelect name="options" formControlName="zType" #mySelect
                  (change)='zaehlerArtSelected(mySelect.value)'>
                  <option value=0>{{'Handmessung' | mrTranslate }}</option>
                  <option value=1>{{'Überhöhung' | mrTranslate }}</option>
                  <option value=2>{{'Rillenweiten' | mrTranslate }}</option>
                  <option value=3>{{'Leitweiten' | mrTranslate }}</option>
                  <option value=4>{{'Leitkantenabstand' | mrTranslate }}</option>
                  <option value=5>{{'Spurweite' | mrTranslate }}</option>
                  <option value=6>{{'Überschrift' | mrTranslate }}</option>
                </select>
              </clr-select-container> -->
            </div>
          </div>

          <div class="clr-row" style="margin-top: 8px">

            <div class="clr-col-4">
              <div class="container">
                <label>SR 100+ </label>
                <input type="number" formControlName="SR100plus" style="left:100px; width: 100px" class="clr-input combobox" />
                <label>SR Lim+ </label>
                <input type="number" formControlName="SRLimplus" style="left:100px; width: 100px" class="clr-input combobox" />
                <label>SRG + </label>
                <input type="number" formControlName="SRGplus" style="left:100px; width: 100px" class="clr-input combobox" />
              </div>
            </div>
            <div class="clr-col-4">
              <div class="container">
                <label>SR100- </label>
                <input type="number" formControlName="SR100minus" style="left:100px; width: 100px" class="clr-input combobox" />
                <label>SR Lim- </label>
                <input type="number" formControlName="SRLimminus" style="left:100px;  width: 100px" class="clr-input combobox" />
                <label>SRG - </label>
                <input type="number" formControlName="SRGminus" style="left:100px; width: 100px" class="clr-input combobox" />
              </div>
            </div>
            <div class="clr-col-4">
              <clr-checkbox-container style="margin-top: 0px">
                <clr-checkbox-wrapper style="margin-top: 0px">
                  <label>{{'aktiv SR100 Toleranz' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="aktivSR100" />
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper style="margin-top: 5px">
                  <label>{{'aktiv SR lim Toleranz' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="aktivSRLimm" />
                </clr-checkbox-wrapper>
                <clr-checkbox-wrapper style="margin-top: 5px">
                  <label>{{'aktiv SRG Toleranz' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="aktivSRG" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>
          </div>
        </form>
        <br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="sendForTabellen()">{{'OK' | mrTranslate}}</button>
        <button type="button" class="btn" (click)="isVorlageAddVisible = false">{{'Abbrechen' | mrTranslate}}</button>
      </div>
    </clr-modal>




<!-- MODUL ZÄHLERTITEL -->
    <clr-modal [(clrModalOpen)]="isZTitelAddVisible" [clrModalClosable]="true">
      <h3 class="modal-title">{{modaltitel | mrTranslate}}</h3>
      <div class="modal-body">
        <form clrForm class="width100 grid " clrLayout="horizontal" [formGroup]="modalNewTitleGroup">
          <div class="modal-body grid" id="grid-container">
              <clr-input-container class="span-2" app-area="code">
                <label>{{ "Code" | mrTranslate }}</label>
                <input clrInput type="text" formControlName="code"/>
                <clr-control-error *clrIfError="'required'">
                  {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                </clr-control-error>
                <clr-control-error *clrIfError="'in-Table'; error as err">
                  {{ err | mrTranslate }}
                </clr-control-error>
              </clr-input-container>
              <clr-input-container class="span-2" app-area="bezeichnung">
                <label>{{ "Bezeichnung" | mrTranslate }}</label>
                <input clrInput type="text" formControlName="bezeichnung" />
              </clr-input-container>

              
              <clr-toggle-container clrInline>
                <label>{{ "Toleranzen" | mrTranslate }}</label>
                <clr-toggle-wrapper>
                  <input clrToggle formControlName="optionsSR100" type="checkbox" />
                  <label>{{ "SR 100 aktiv" | mrTranslate }}</label>
                </clr-toggle-wrapper>
                <clr-toggle-wrapper>
                  <input clrToggle formControlName="optionsSRG" type="checkbox" />
                  <label>{{ "SRG aktiv" | mrTranslate }}</label>
                </clr-toggle-wrapper>
              </clr-toggle-container>

              <br>
            </div>
        </form>
        <br>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addZaehler()">{{'OK' | mrTranslate}}</button>
        <button type="button" class="btn" (click)="isZTitelAddVisible = false">{{'Abbrechen' | mrTranslate}}</button>
      </div>
    </clr-modal>
