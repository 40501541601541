import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, TitleCasePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ClrButtonGroupModule, ClrIconModule, ClrPopoverHostDirective, ClrStopEscapePropagationDirective, ClrTreeViewModule } from "@clr/angular";
import { TranslateService } from "@ngx-translate/core";
import { CalendarCommonModule, CalendarEvent, CalendarMonthModule, DAYS_OF_WEEK } from "angular-calendar";
import dayjs from 'dayjs';

import "dayjs/locale/de";
import { Observable, ObservableInput, forkJoin, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { MrTranslatePipe } from "../../pipes/mr-translate.pipe";
import { WartungsPlanNode as Wartungen } from "../_components/_overlays/wartungsverwaltung/wartung.types";
import { DetailviewComponent } from "../_components/detailview/detailview.component";
import { ProgressbarComponent } from "../_components/progressbar/progressbar.component";
import { ToDateDayjsPipe } from "src/app/pipes/to-date-dayjs.pipe";


type Prüfungen = {
  OSTAMMID: number;
  icon: number;
  intervallbeginn: string; // DATE
  intervall: number;
  strBez: string;
} & { einheit: undefined };

type Begehungen = {
  OSTAMMID: number;
  icon: number;
  intervallbeginn: string; // DATE
  intervall: number;
  strBez: string;
  weekDay: DAYS_OF_WEEK;
} & { einheit: 'w' };

type ForkJoinResponce = {
  [key in RevisionMode]: ObservableInput<RevisionArt[]>;
};

type RevisionMode = 'Wartungen' | 'Prüfungen' | 'Begehungen'
type RevisionArt = Wartungen | Prüfungen | Begehungen;
type RevisionTermin = { eventDatum: string } & RevisionArt;
type GroupedEvents = CalendarEvent<RevisionTermin>[];

@Component({
    selector: "app-kalender",
    templateUrl: "./kalender.component.html",
    styleUrls: ["./kalender.component.scss"],
    providers: [BackendLocaleDatePipe],
    imports: [
        NgIf,
        NgStyle,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrButtonGroupModule,
        ClrIconModule,
        NgFor,
        CalendarCommonModule,
        NgSwitch,
        NgSwitchCase,
        ClrTreeViewModule,
        CalendarMonthModule,
        NgClass,
        DetailviewComponent,
        ProgressbarComponent,
        AsyncPipe,
        TitleCasePipe,
        DatePipe,
        MrTranslatePipe,
        ToDateDayjsPipe
    ],
    standalone: true
})
export class KalenderComponent implements OnInit, OnDestroy {
  currentDate: Date;
  activeDayIsOpen: boolean = false;
  _ostammid: number = undefined;

  showprogressbar: boolean = false;
  showdetailview: boolean = false;
  hidedetailview: boolean = false;
  split50 = false;
  sidenavenlarged = false;
  progressbarenlarged = false;
  progressbars: any;
  indexScrub: number = -1;

  protected events$: Observable<CalendarEvent[]>;

  constructor(
    private apiService: APIService,
    private basicStore: BasicStoreService,
    protected spinner: SpinnerService,
    protected translateService: TranslateService
  ) {
    dayjs.locale("de");
  }

  protected s_view: dayjs.Dayjs;
  protected e_view: dayjs.Dayjs;

  async ngOnInit() {
    this.currentDate = new Date();
    this.s_view = dayjs().startOf("month").startOf("day");
    this.e_view = dayjs(this.s_view).add(1, "year");

    const { ostammid = null, showdetailview = null, events = null } = this.basicStore.getComponentStore("kalender") ?? {};
    if (showdetailview) this.selectOstammid(ostammid);

    this.events$ = events ? of(events)
      : forkJoin<ForkJoinResponce>({
        'Prüfungen': this.apiService.getKalender(),
        'Wartungen': this.apiService.getWartungsPlan('list'),
        'Begehungen': this.apiService.getBegehungsCalendarList(),
      }).pipe(
        map(revisionObjekt => Object.entries(revisionObjekt).flatMap(
          ([art, objekte]) => this.groupEvents(this.findTermine(objekte), art as RevisionMode)
        )),
        tap(events => this.basicStore.setComponentStore('kalender', { events }, false)),
      );
  }

  private findTermine(objekte: RevisionArt[]): RevisionTermin[] {
    return objekte.flatMap(o => {
      const events: RevisionTermin[] = [];
      if (o.intervall <= 0) {
        o.intervall = 12;
        o.einheit = 'M';
      }
      for (
        let event_date = dayjs(o.intervallbeginn).startOf("day");
        event_date < this.e_view;
        event_date = dayjs(event_date).add(o.intervall, o.einheit ?? 'months')
      ) {
        if (event_date < this.s_view) continue;
        if ('weekDay' in o) {
          if (o.weekDay > event_date.day())
            event_date = event_date.day(o.weekDay);
          else if (o.weekDay < event_date.day())
            event_date = event_date.add(1, 'w').day(o.weekDay);
        }
        else if (event_date.day() == DAYS_OF_WEEK.SUNDAY)
          event_date = event_date.add(1, 'd');
        else if (event_date.day() == DAYS_OF_WEEK.SATURDAY)
          event_date = event_date.add(2, 'd');
        events.push({
          ...o,
          eventDatum: event_date.format(BackendLocaleDatePipe.format)
        });
      }
      return events;
    });
  }

  private groupEvents(events: RevisionTermin[], title: RevisionMode): CalendarEvent<GroupedEvents>[] {
    const groupedEvents = events.reduce((groupedObj, event) => {
      const date = event.eventDatum;
      groupedObj[date] ??= [];
      groupedObj[date].push({
        start: new Date(date),
        title: event.strBez,
        allDay: true,
        meta: event
      });
      return groupedObj;
    }, {} as { [datum: string]: GroupedEvents });

    return Object.entries(groupedEvents).map(([datum, groupedEvents]) => ({
      start: new Date(datum),
      title,
      allDay: true,
      meta: groupedEvents,
    }));
  }

  ngOnDestroy() {
    this.basicStore.resetComponentStore("kalender", {
      ostammid: this._ostammid,
      currentDate: this.currentDate,
      // * detailview Ansicht wird nicht wiedergestellt. Warum gespeichert?
      split50: this.split50,
      showdetailview: this.showdetailview,
      hidedetailview: this.hidedetailview,
      sidenavenlarged: this.sidenavenlarged,
      progressbarenlarged: this.progressbarenlarged,
    }, false);
  }

  switchNextPrev(amount: any) {
    const newDate = dayjs(this.currentDate).add(amount, "month");

    if (
      dayjs(newDate).isBefore(this.e_view, "month") &&
      !dayjs(newDate).isBefore(this.s_view, "month")
    ) {
      this.currentDate = newDate.toDate();
    } else {
      this.currentDate = dayjs(this.s_view).toDate();
    }
    this.activeDayIsOpen = false;
  }

  today() {
    this.currentDate = new Date();
  }

  handleEvent(event: CalendarEvent<RevisionTermin> | CalendarEvent<GroupedEvents>) {
    if (!Array.isArray(event.meta)) {
      this.selectOstammid(event.meta.OSTAMMID);
    } else {
      event.id = 'open';
      this.currentDate = event.start;
      this.activeDayIsOpen = true;
    }
  }

  selectOstammid(id: number) {
    if (id) {
      this._ostammid = id;
      this.showdetailview ||= true;
    }
  }

  reloadDetailview() {
    const reloadID = this._ostammid;
    this._ostammid = null;
    setTimeout(() => {
      this._ostammid = reloadID;
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (dayjs(this.currentDate).isSame(date, "month")) {
      if (dayjs(this.currentDate).isSame(date, "day")) {
        this.activeDayIsOpen = !this.activeDayIsOpen;
      } else {
        this.currentDate = date;
        this.activeDayIsOpen = true;
      }
      events.forEach(e => e.id = 'close');
    }
    if (events.length == 0) this.activeDayIsOpen = false;
  }

  enlargeSidenav(event) {
    if (event.mode == "full") {
      this.sidenavenlarged = event.val;
    } else if (event.mode == "half") {
      this.split50 = event.val;
    } else if (event.mode == "progress") {
      this.showprogressbar = event.val;
      this.progressbars = {};
    } else if (event.mode == "hidden") {
      this.hidedetailview = event.val;
    } else if (event.mode == "close") {
      this.showdetailview = false;
      this.showprogressbar = false;
    }
  }

  handleScrub(event) {
    this.indexScrub = event;
  }

  showProgressData(data) {
    if (data) {
      this.progressbars = data;
      this.showprogressbar = true;
    }
  }

  enlargeProgressbar(val?) {
    if (val) {
      this.progressbarenlarged = true;
    } else {
      this.progressbarenlarged = !this.progressbarenlarged;
    }
  }
}