<div class="card item-table wartungsItemWrapper">
  <div class="card-header np clr-row nxm">
    <div class="clr-col-auto margin-12-t margin-12-l nxp">
      {{ "Wartungs" +
        (mode == 'personal' ? "personal" : mode == 'art' ? "arten" : "arbeiten")
      | mrTranslate }}
    </div>
    <div class="clr-col">
      <form clrForm (ngSubmit)="saveIfValid()" #f="ngForm" clrLabelSize="12"
        clrLayout="horizontal" class="w-100-p np clr-row nxm">
        <clr-input-container class="clr-col margin-0-t">
          <input clrInput type="text" name="Bezeichnung" class="mw-100-p w-100-p"
            [autofocus]="!!insertItem" ngModel required [disabled]="!(insertItem || selectedItem)"/>
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
        </clr-input-container>
        <ng-container *ngIf="mode == 'art'">
          <clr-select-container class="clr-col-auto margin-4-t widthAuto">
            <label class="clr-col-auto">{{"Typ" | mrTranslate}}</label>
              <select class="clr-col-auto" clrSelect name="Typ" ngModel [disabled]="!(insertItem || selectedItem)">
                <option [value]="0">0 - {{"Header" | mrTranslate}}</option>
                <option [value]="1">1 - {{"Prüfung" | mrTranslate}}</option>
                <option [value]="2">2 - {{"Messwert" | mrTranslate}}</option>
              </select>
          </clr-select-container>
        </ng-container>
      </form>
    </div>
  </div>
  <div class="card-block nyp d-f" #gridContainer>
    <clr-datagrid #wItemGrid *ngIf="table?.columns; else loading"
      class="datagrid-compact h-100 w-100-p"
      [(clrDgSingleSelected)]="selectedItem" [clrDgRowSelection]="true">
      <clr-dg-column *ngFor="let column of table.columns" [clrDgField]="column.id" style="max-width: 50%;">
        <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">
          {{ column.id | titlecase | mrTranslate }}</ng-container>
      </clr-dg-column>
      <clr-dg-placeholder [class.hidePlaceholder]="isLoading">
        <clr-spinner *ngIf="isLoading" class="centred"></clr-spinner>
      </clr-dg-placeholder>
      <clr-dg-row *clrDgItems="let row of table.rows" [clrDgItem]="row"
        id="i-{{row.ID}}" [class.headerRow]="row.Typ == 0">
        <clr-dg-cell class="name-cell" *ngFor="let cell of table.columns"
          style="max-width: 50%;" [innerHTML]="row[cell.id] | tablePrettyPrint">
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>{{table.rows.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
    </clr-datagrid>
    <ng-template #loading>
      <clr-spinner class="centred"></clr-spinner>
    </ng-template>
  </div>
  <div class="card-footer">
    <div class="btn-group btn-primary btn-icon margin-0-t">
      <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
        (click)="addItem()" [disabled]="insertItem">
        <clr-icon shape="file"></clr-icon>
      </button>
      <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
        (click)="deleteItem()" [disabled]="!selectedItem">
        <clr-icon shape="times"></clr-icon>
      </button>
      <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate"
        (click)="ngForm.ngSubmit.emit()" [disabled]="!(insertItem || selectedItem)">
        <clr-icon shape="floppy"></clr-icon>
      </button>
    </div>
    <button *ngIf="withHelper" class="btn btn-icon" style="margin-left: auto;" [title]="'Beschreibung' | mrTranslate"
      (click)="openHelper()" [disabled]="!selectedItem">
      <clr-icon shape="help"></clr-icon>
    </button>
  </div>
</div>

<app-hilfe-beschreibung-modal *ngIf="withHelper"
  (onSave)="saveHelper($event)">
</app-hilfe-beschreibung-modal>