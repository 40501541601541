<clr-modal [(clrModalOpen)]="modalOpen" [clrModalSize]="'xl'" [clrModalClosable]="false" id="messungsmodal">
  <h3 class="modal-title" style="display:flex">{{'Weichenmessdaten importieren' | mrTranslate}}</h3>
  <div class="modal-body" id="settingsModalBody">
    <form clrForm>

      <div class="clr-row">


        <div class="clr-col-9" style="padding-left: 5px;">


          <div class="clr-row">
            <div class="clr-col-3">
              <span>{{'Auswahl Messgerät' | mrTranslate}}:</span>
            </div>
            <div class="clr-col-7">
              <span>{{'Toleranzenauswahl' | mrTranslate}}:</span>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col-3">
              <clr-select-container style="margin-left: -60px;">
                <!-- <label>I've got some options</label> -->
                <select clrSelect name="options" [(ngModel)]="selectedMessgeraet" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                  <option value="Messprofil V3.0">Messprofil V3.0</option>
                  <option value="Cemafer SGMT5">Cemafer SGMT5</option>
                  <option value="Diamont S2">Diamont S2</option>
                </select>
              </clr-select-container>

              <!-- <clr-combobox  class="messgeraet" [(ngModel)]="selectedMessgeraet" name="selectMessgeraet" #mySelect (change)='onOptionsSelected(mySelect.value)'>
                <clr-options>
                  <clr-option clrValue="Messprofil V3.0">Messprofil V3.0</clr-option>
                  <clr-option clrValue="Cemafer SGMT5">Cemafer SGMT5</clr-option>
                  <clr-option clrValue="Diamont S2">Diamont S2</clr-option>
                </clr-options>
              </clr-combobox> -->
            </div>
            <div class="clr-col-9">
              <clr-tabs>
                <clr-tab>
                  <button clrTabLink>{{'Regelinspektion' | mrTranslate}}</button>
                  <ng-template [(clrIfActive)]="regelActive">
                  </ng-template>
                </clr-tab>
                <clr-tab>
                  <button clrTabLink>{{'Neu' | mrTranslate}}</button>
                  <ng-template [(clrIfActive)]="neuActive">
                  </ng-template>
                </clr-tab>
              </clr-tabs>
            </div>
          </div>
        <div>
          <br/>
        </div>

        <div class="clr-row clr-align-items-center" [ngStyle]="{'background-color': backgroundcolor}" Style="height:45px;">
            <div class="clr-col-3">
              <span>{{'Optionen' | mrTranslate}}:</span>
            </div>
            <div class="clr-col-6">
              <clr-checkbox-container>
                <clr-checkbox-wrapper>
                  <label>{{'Bezeichnung als Großbuchstaben' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox #gross/>
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>
            <div class="clr-col">
              <clr-checkbox-container>
                <clr-checkbox-wrapper>
                  <label>{{'IIS Daten' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox  #iis />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>
          </div>

          <div>
            <br/>
          </div>

          <div *ngIf="regelActive" class="clr-row clr-align-items-center"  [ngStyle]="{'background-color': backgroundcolor}" style="height:45px;">
            <div class="clr-col-3">
              <span>{{'Auswertung' | mrTranslate}}:</span>
            </div>
            <div class="clr-col" *ngIf="messtol.value.usesr100 == true">
              <div class="round2" [ngStyle]="{'border-color': messtol.value.colsr100}"><clr-icon class="icons" shape="check"></clr-icon>{{messtol.value.txt100s}}</div>
            </div>
            <div class="clr-col" *ngIf="messtol.value.usesrlim == true">
              <div class="round2" [ngStyle]="{'border-color': messtol.value.colsrlim}"><clr-icon class="icons" shape="check"></clr-icon>{{messtol.value.txtls}}</div>
            </div>
            <div class="clr-col" *ngIf="messtol.value.usesrg == true">
              <div class="round2" [ngStyle]="{'border-color': messtol.value.colsrg}"><clr-icon class="icons" shape="check"></clr-icon>{{messtol.value.txtgs}}</div>
            </div>
          </div>

          <div *ngIf="neuActive" class="clr-row clr-align-items-center" [ngStyle]="{'background-color': backgroundcolor}" style="height:45px;">
            <div class="clr-col-3">
              <span>{{'Toleranzen' | mrTranslate}}:</span>
            </div>
            <div class="clr-col">
              <div class="round2" [ngStyle]="{'border-color': messtol.value.colsro}"><clr-icon class="icons" shape="check"></clr-icon>{{messtol.value.txt0s}}</div>
            </div>
            <div class="clr-col-5">
                <clr-checkbox-wrapper>
                  <label>{{'Vorabnahme (ohne Überhöhung)' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox #ouebh/>
                </clr-checkbox-wrapper>
            </div>
          </div>
          <div>
            <br/>
          </div>

        <div class="grid-container">
          <div class="grid-item">
            <div class="grid-container-3">
              <div class="grid-item" [ngStyle]="{'background-color': backgroundcolor}">
                <span style="position: relative; top: 12px;">{{'Durchfahrrillen' | mrTranslate}}:</span>
              </div>
              <div class="grid-item" [ngStyle]="{'background-color': backgroundcolor}">
                <div class="clr-form-control">
                  <div class="clr-control-container clr-control-inline">
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="vertical-radio3" name="radio-full1" value="0" class="clr-radio" [(ngModel)]="optionsD" />
                      <label for="vertical-radio3" class="clr-control-label">Messreg</label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="vertical-radio4" name="radio-full1" value="1" class="clr-radio" [(ngModel)]="optionsD"/>
                      <label for="vertical-radio4" class="clr-control-label">&ge;</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item">
            <div class="grid-container-3">
              <div class="grid-item" [ngStyle]="{'background-color': backgroundcolor}">
                <span style="position: relative; top: 12px;">{{'Leitkantenabstand' | mrTranslate}}:</span>
              </div>
              <div class="grid-item" [ngStyle]="{'background-color': backgroundcolor}">
                <div class="clr-form-control">
                  <div class="clr-control-container clr-control-inline">
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="vertical-radio5" name="radio-full2" value="0" class="clr-radio" [(ngModel)]="optionsLK" />
                      <label for="vertical-radio5" class="clr-control-label">Messreg</label>
                    </div>
                    <div class="clr-radio-wrapper">
                      <input type="radio" id="vertical-radio6" name="radio-full2" value="1" class="clr-radio" [(ngModel)]="optionsLK"/>
                      <label for="vertical-radio6" class="clr-control-label">&le;</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
            <br/>
        </div>

        <div class="clr-row clr-align-items-center" [ngStyle]="{'background-color': backgroundcolor}" style="height:45px;">
          <div class="clr-col-3">
            <span class="sp1">{{'Toleranz-Text' | mrTranslate}}:</span>
          </div>
          <div class="clr-col-5">
            <form class="clr-form" [formGroup]="messini">
              <div class="clr-form-control" style="height:45px;">
                <div class="clr-control-container clr-control-inline">
                  <div class="clr-radio-wrapper" style="margin-top: -5px;">
                    <input type="radio" id="vertical-radio1" name="optMessTol" value="0" class="clr-radio" formControlName="optMessTol"/>
                    <label for="vertical-radio1" class="clr-control-label">{{'Messreg'}}</label>
                  </div>
                  <div class="clr-radio-wrapper" style="margin-top: -5px;">
                    <input type="radio" id="vertical-radio2" name="optMessTol" value="1" class="clr-radio" formControlName="optMessTol"/>
                    <label for="vertical-radio2" class="clr-control-label">{{'andere' | mrTranslate}}</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="clr-col">
            <form class="clr-form" [formGroup]="messini">
              <input *ngIf="messini.controls.optMessTol?.value == 1" class="tolname" clrInput type="text" formControlName="optMessTolBez"/>
            </form>
          </div>
        </div>
        <div>
          <br/>
        </div>
        <button type="button" class="btn btn-icon" aria-label="settings" (click)="toggle()">
          <clr-icon shape="cog"></clr-icon>
        </button>
        </div>
        <div class="clr-col-3">
          <div class="clr-row" style="height:350px;">

            <div class="upload-container"
                  [ngStyle]="{'background-color': backgroundcolor1, 'border-color': bordercolor }"
                  #uploadzone
                  dropzone
                  [class.hovering]="isHovering"
                  (dragleave)="onDragLeave()"
                  (hovered)="onDragOver()"
                  (dropped)="onDrop($event)"
                  (click)="onClick()">
              <p class="title" style="font-size: 20px;">{{'Hochladen' | mrTranslate}}</p>
              <p class="icon"><clr-icon shape="cloud" style="width: 72px; height: 72px;"></clr-icon></p>
              <p class="title" style="font-size: 13px;">{{'Klicken oder ziehen Sie die Datei in diesen Bereich' | mrTranslate}}</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="show == true" class="clr-row" Style="margin-top: -5px;"  [ngStyle]="{'background-color': backgroundcolor}">
        <form clrForm [formGroup]="messtol">
          <div class="d-f w-100-p margin-10-l">
            <h5>{{'Einstellungen' | mrTranslate}}</h5>
            <button type="button" class="btn btn-icon btn-link" (click)="resetToleranzenBezeichnungen()">
              <clr-icon shape="backup-restore"></clr-icon>
            </button>
          </div>
          <div class="clr-row">
            <div class="clr-col-9">
              <div class="clr-row">
                  <div class="clr-col-2">
                    <span>{{'Feld' | mrTranslate}}:</span>
                  </div>
                  <div class="clr-col-2">
                    <span>{{'Bezeichnung' | mrTranslate}}:</span>
                  </div>
                  <div class="clr-col-2">
                    <span>{{'Farbe' | mrTranslate}}:</span>
                  </div>
                  <div class="clr-col-2">
                    <span>{{'Auswerten' | mrTranslate}}:</span>
                  </div>
                  <div class="clr-col-1">
                    <span>{{'Überschrift' | mrTranslate}}:</span>
                  </div>
                  <div class="clr-col-1">

                  </div>
                  <div class="clr-col-1" >
                    <span>{{'Ausfall' | mrTranslate}}:</span>
                  </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-2">
                  <span>GW5</span>
                </div>
                <div class="clr-col-2" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 80px;" formControlName="txt0s" (change)="onChange($event.target.value, 'txt0s')"/>
                </div>
                <div class="clr-col-2">
                  <input type="color" [ngStyle]="{'background-color': messtol.value.colsro}" formControlName="colsro" (change)="onChange($event.target.value, 'colsro')">
                </div>
                <div class="clr-col-2">
                  <!-- <input type="checkbox" clrCheckbox/> -->
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsr0m"/>
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsr0p"/>
                </div>
                <div class="clr-col-1" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="tolsr0" (change)="onChange($event.target.value, 'tolsr0')"/>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-2">
                  <span>GW1</span>
                </div>
                <div class="clr-col-2" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 80px;" formControlName="txt100s" (change)="onChange($event.target.value, 'txt100s')"/>
                </div>
                <div class="clr-col-2">
                  <input id="sr100" type="color" [ngStyle]="{'background-color': messtol.value.colsr100}" formControlName="colsr100" (change)="onChange($event.target.value, 'colsr100')">
                </div>
                <div class="clr-col-2">
                  <input class="inputcheckbox" type="checkbox" clrCheckbox formControlName="usesr100" (change)="onChange($event.target.checked, 'usesr100')"/>
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsr100m"/>
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsr100p"/>
                </div>
                <div class="clr-col-1" >
                  <input clrInput type="text"style="position: relative; top: -25px; width: 65px;" formControlName="tolsr100" (change)="onChange($event.target.value, 'tolsr100')"/>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-2">
                  <span>GW2</span>
                </div>
                <div class="clr-col-2" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 80px;" formControlName="txtls" (change)="onChange($event.target.value, 'txtls')"/>
                </div>
                <div class="clr-col-2">
                  <input id="srlim" type="color" [ngStyle]="{'background-color': messtol.value.colsrlim}" formControlName="colsrlim" (change)="onChange($event.target.value, 'colsrlim')">
                </div>
                <div class="clr-col-2">
                  <input class="inputcheckbox" type="checkbox" clrCheckbox formControlName="usesrlim" (change)="onChange($event.target.checked, 'usesrlim')" />
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsrlimm"/>
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsrlimp"/>
                </div>
                <div class="clr-col-1" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="tolsrlim" (change)="onChange($event.target.value, 'tolsrlim')"/>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-2">
                  <span>GW3</span>
                </div>
                <div class="clr-col-2" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 80px;" formControlName="txtgs" (change)="onChange($event.target.value, 'txtgs')"/>
                </div>
                <div class="clr-col-2">
                  <input id="srg" type="color" [ngStyle]="{'background-color': messtol.value.colsrg}" formControlName="colsrg" (change)="onChange($event.target.value, 'colsrg')">
                </div>
                <div class="clr-col-2">
                  <input class="inputcheckbox" type="checkbox" clrCheckbox formControlName="usesrg" (change)="onChange($event.target.checked, 'usesrg')"/>
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsrgm"/>
                </div>
                <div class="clr-col-1">
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="txtsrgp"/>
                </div>
                <div class="clr-col-1" >
                  <input clrInput type="text" style="position: relative; top: -25px; width: 65px;" formControlName="tolsrg"(change)="onChange($event.target.value, 'tolsrg')"/>
                </div>
              </div>
            </div>
            <div class="clr-col-3" >
              <form class="clr-form" [formGroup]="messtol">
                <span>{{'Bezeichnung Toleranzen' | mrTranslate}}:</span>
                <br>
                <label>{{'Regelinspektion' | mrTranslate}}</label>
                <div class="clr-form-control">
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="Regelinspektion" placeholder="{{'Toleranz Regelinspektion' | mrTranslate}}" class="clr-input" formControlName="tolnamer"/>
                    </div>
                  </div>
                </div>
                <br>
                <label>{{'Abnahmeinspektion' | mrTranslate}}</label>
                <div class="clr-form-control">
                  <div class="clr-control-container">
                    <div class="clr-input-wrapper">
                      <input type="text" id="Abnahme" placeholder="{{'Toleranz Abnahme' | mrTranslate}}" class="clr-input" formControlName="tolnamea"/>
                    </div>
                  </div>
                </div>
              </form>

            </div>

          </div>


          <div class="clr-row">
            <div class="clr-col">
              <button class="btn btn-primary btn-sm margin-20-t" type="submit" [disabled]="trial" (click)="saveSettings()">{{'Speichern' | mrTranslate}}</button>
            </div>
          </div>


        </form>
      </div>
      <div class="clr-col-4" style="padding-left: 5px;">

        <div *ngIf="filelistcount > 0" class="d-f w-100-p margin-10-l margin-7-b">
          <h5>{{'Dateien' | mrTranslate}}{{': ' + filelistcount}}</h5>
        </div>

        <ul>
          <li *ngFor="let mess of daten">
              <span class="file-label" [ngStyle]="{'border-color': mess.error? 'red' : '#8f9ba3'}">
                {{mess.filename}}
                <a [title]="'Cancel' | mrTranslate" (click)="removeFileFromArray(mess)"><clr-icon shape="times-circle" solid="true"></clr-icon></a>

                <a *ngIf="mess.error" href="..." role="tooltip" aria-haspopup="true" class="tooltip tooltip-lg">
                  <clr-icon shape="info-circle" size="22"></clr-icon>
                  <span class="tooltip-content">{{mess.errortext}}</span>
                </a>
              </span>
          </li>
        </ul>
      </div>
      <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}" style="display:none;"/>
    </form>

  </div>
  <div class="modal-footer">
    <div *ngIf="downloadingFile">
      <clr-spinner clrInline>Downloading</clr-spinner>
      <span>uploading</span>
    </div>
    <button [disabled]="filelistcount == 0" type="button" class="btn btn-primary" (click)="runUpload()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="doexit()">{{'Beenden' | mrTranslate}}</button>
  </div>
</clr-modal>

