import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewEncapsulation, signal,} from "@angular/core";
import { ClrAccordionModule, ClrButtonGroupModule, ClrCheckboxModule, ClrCommonFormsModule, ClrConditionalModule, ClrDatagrid, ClrDatagridModule, ClrIconModule,
  ClrModalModule, ClrPopoverHostDirective, ClrStopEscapePropagationDirective, ClrTabsModule, ClrTextareaModule, ClrTooltipModule, ClrTreeViewModule } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { APIService } from "../../../services/APIService/api.service";
import { previewMap } from "./previewMap";

import { FormsModule, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { AnlagenMediaApiFuncService } from "src/app/services/_abstract/anlagen-media-api-func.service - Copy";
import { MangelMediaApiFuncService } from "src/app/services/_abstract/mangel-media-api-func.service";
import { MediaViewModalComponent, TitleAndFileNames } from "../_shared/mediaviewmodal/mediaviewmodal.component";
import { AsyncPipe, KeyValue, KeyValuePipe, LowerCasePipe, NgFor, NgIf, NgStyle, NgTemplateOutlet, TitleCasePipe } from "@angular/common";
import { RouterLinkActive } from "@angular/router";
import { SharedService } from "src/app/services/Shared/shared.service";
import { WebSocketService } from "src/app/services/Websocket/websocket.service";
import { DynamicStyleDirective } from "../../../directives/dynamic-style.directive";
import { ElementMediaQueryDirective } from "../../../directives/element-media-query.directive";
import { TabsInOverflowDirective } from "../../../directives/tabs-in-overflow.directive";
import { WindowMediaQueryDirective } from "../../../directives/window-media-query.directive";
import { ArrayMethodsCallbackPipe } from "../../../pipes/array-methods-callback.pipe";
import { HideIDColumnsPipe } from "../../../pipes/hide-idcolumns.pipe";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../../pipes/mr-translate.pipe";
import { MrUnitTextPipe } from "../../../pipes/mrUnitText.pipe";
import { RoundToPipe } from "../../../pipes/round-to.pipe";
import { TablePrettyPrintPipe } from "../../../pipes/tablePrettyPrint.pipe";
import { SonderinspektionmodalComponent } from "../_modals/sonderinspektionmodal/sonderinspektionmodal.component";
import { ImageSlideShowComponent } from "../_shared/media-slide-show/image-slide-show.component";
import { PaintingComponent } from "../_shared/paint/painting.component";
import { PlotlyPlotComponent } from "../plotly-plot/plotly-plot.component";
import { HerstellDatenModalComponent } from "./herstell-daten-modal/herstell-daten-modal.component";
import { HistorieTreeComponent } from "./historie-tree/historie-tree.component";
import { MangelLogModalComponent } from "./mangel-log-modal/mangel-log-modal.component";
import { Table } from "src/app/models/ui/table";

interface TreeDataNode {
  id: number,
  typ: number,
  name: string,
  selected: boolean,
  expanded: boolean,
  rootpath: string | null,
  parent: TreeDataNode | null,
  children: TreeDataNode[]
}


export type DetailViewAnsicht = {
  mode: 'close' | 'half' | 'full' | 'progress' | 'hidden';
  val: boolean;
};

@Component({
    selector: "app-detailview",
    templateUrl: "./detailview.component.html",
    styleUrls: ["./detailview.component.scss"],
    encapsulation: ViewEncapsulation.None,
    imports: [
        ClrModalModule,
        NgIf,
        PlotlyPlotComponent,
        ClrIconModule,
        NgStyle,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrButtonGroupModule,
        WindowMediaQueryDirective,
        ElementMediaQueryDirective,
        ClrTabsModule,
        TabsInOverflowDirective,
        ClrConditionalModule,
        ImageSlideShowComponent,
        ClrCheckboxModule,
        ClrCommonFormsModule,
        NgFor,
        ClrTextareaModule,
        FormsModule,
        ClrDatagridModule,
        ClrTreeViewModule,
        RouterLinkActive,
        ClrTooltipModule,
        ClrAccordionModule,
        NgTemplateOutlet,
        DynamicStyleDirective,
        HistorieTreeComponent,
        PaintingComponent,
        MangelLogModalComponent,
        SonderinspektionmodalComponent,
        HerstellDatenModalComponent,
        MediaViewModalComponent,
        AsyncPipe,
        LowerCasePipe,
        TitleCasePipe,
        KeyValuePipe,
        TablePrettyPrintPipe,
        MrUnitTextPipe,
        HideIDColumnsPipe,
        RoundToPipe,
        MrTranslatePipe_1,
        ArrayMethodsCallbackPipe,
        ObjTypePipe
    ],
    standalone: true
})
export class DetailviewComponent
  implements AfterViewInit, OnDestroy {
  //@Input('sidebar') sidebar :MatSidenav;
  //@Input('PBenlarged') progressbarenlarged:Boolean;
  @Input("progressbarenlarged") progressbarenlarged: boolean;
  @ViewChild('kategorie') kategorie: ElementRef;
  @ViewChild('dgspez') dgspez: ClrDatagrid;
  @ViewChild(SonderinspektionmodalComponent) modal: SonderinspektionmodalComponent;

  constructor(
    private apiService: APIService,
    protected authService: AuthService,
    private basicStore: BasicStoreService,
    private overlayService: OverlayService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private changeDet: ChangeDetectorRef,
    private mrTranslate: MrTranslatePipe,
    private objtypePipe: ObjTypePipe,
    protected media: MangelMediaApiFuncService, // * in Template
    protected anlMedia: AnlagenMediaApiFuncService,
    protected websocket: WebSocketService,
    protected shared : SharedService,
    //protected localeDate: BackendLocaleDatePipe,
  ) {
    dayjs.locale("de");
    dayjs.extend(customParseFormat);
    dayjs.extend(isSameOrAfter);
    let token = this.authService.getToken();
    if (token && token.rights) {
      this.showProtected = {
        assignment: true,
        feedback: true,
        history: getTokenRight(token.rights.view, "AllowHIST"),
        interference: getTokenRight(token.rights.view, "AllowSTOE"),
        speedRestrictions: getTokenRight(token.rights.view, "AllowLAFS"),
        blockings: getTokenRight(token.rights.view, "AllowSPER"),
        kav: getTokenRight(token.rights.view, "AllowHIST"),
      };
      this.database = token.db;
      this.progVersion = parseInt(token.rights.progVersion);
      this.userstatus = token.rights.status;
    }
  }
  production :boolean;

  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isRep: boolean = localStorage.getItem("repID") != null && localStorage.getItem("repDB") == localStorage.getItem("DB");;

  tables: Record<string, any> = {};
  bezeichnung: string;
  protected files$: Observable<any>;
  protected skizzeData: string;

  progVersion: number = 50;

  docheader: any[]=[];
  dokheaderAnzahlItems: any[]=[];
  docsorginal: any[]=[];
  docs: any[]=[];
  objtyp: "point" | "line" | boolean;
  objOpt: any;
  seldoctyp: number = 0;
  selAnzDok: number = 0;
  katStatus: string ='';
  showKAV: boolean = false;
  showGIS: boolean = false;
  map:any;
  userstatus: number = 1;

  // showMessungVgl = false;
  messungVglModal = {
    open: false,
    title: "",
    graph: {},
  };

  database: string;
  protected mangelLog: number;
  mangelMedia: TitleAndFileNames[];
  showMangelMedia: boolean = false;
  anlagenMedia: TitleAndFileNames[];

  isHerstellDatenVisible = false;
  protected selectedSpezID: number;

  hasOpenMangel: boolean = false;
  hasMangel: boolean = false;
  deactivateAll: boolean = false;
  deactivateRep: boolean = false;
  excelPath = undefined;

  PRDEL: boolean = false;
  STDEL: boolean = false;
  STEDIT: boolean = false;
  STCOPY: boolean = false;
  SPEDIT: boolean = false;
  BWEDIT: boolean = false;
  KOMEDIT: boolean = false;
  TRAEDIT: boolean = false;

  @Output() enlarge = new EventEmitter<DetailViewAnsicht>();
  @Output() enlargePB = new EventEmitter<Boolean>();
  @Output() progressData = new EventEmitter<any>();
  @Output() indexScrub = new EventEmitter<number>();
  @Output() reload = new EventEmitter();
  @Output() reloadTabsIsReady = new EventEmitter();

  GroupBemerkung = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(""),
    skizzenname: new UntypedFormControl(""),
  });

  //expanded: boolean;
  @Input("split50") split50: boolean = false;
  @Input("expanded") expanded: boolean = false;
  @Input("hidden") set hidden(value: boolean) {
    this.isShownDetailView.set(!value);
  }
  @Input("viewMode") viewMode: number = 0; //Ansichtsmodus, ändert je nach Typ --- 0 = Standard, 1 = Alle Buttons deaktivieren
  dataRefresh = new EventEmitter();
  refreshSubscription: Subscription;
  trackByOmazsID = (_: number, row: any) => row["OMAZSID"];
  protected readonly isShownDetailView = signal(true);
  protected toggleDetailViewIsShown = () => {
    this.isShownDetailView.update(shown => !shown);
    this.enlarge.emit({ mode: 'hidden', val: !this.isShownDetailView() });
  }


  protected getRestTabellen = ({key}: KeyValue<string, any>) => ![
    'stamm', 'messungen', 'zaehler', 'historie', 'herst',
    'mangel', 'komp', 'spez', 'komponenten', 'dokheader',
    'doks', 'dokheaderAnzahlItems'
  ].includes(key);

  isKatVisible: boolean = false;
  TitelKat: string = "Neue Kategorie";
  _ostammid: number;
  geometry: any;
  typ_Line_Point: number;
  otypid: number;
  pointtype: number = undefined;
  bemerkung: any;

  selectedSpez: any;

  protected activeTab: { [key: string]: boolean } = {};
  protected initTab: { [key: string]: boolean } = {};

  bogenLayout: any = {
    autosize: true,
    showlegend: false,
    height: 600,
    margin: {
      l: 35,
      r: 20,
      b: 25,
      t: 20,
      pad: 0,
    },
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    yaxis: {
      //mirror: true
    },
  };

  graph: any = {
    layout: {
      autosize: true,
      showlegend: true,
      legend: { orientation: "h" },
      height: 730,
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
    },
    m: [],
    show: {
      bogen: true,
      spur: true,
      tiefpunkt: true,
      ueberhoehung: true,
      verwindung: true,
      rille_l: true,
      rille_r: true,
      dh_l: false,
      ds1_l: false,
      ds2_l: false,
      dh_r: false,
      ds1_r: false,
      ds2_r: false,
    },
    y: [],
    yData: {
      bogen: [],
      sollspur: [],
      spur: [],
      tiefpunkt: [],
      ueberhoehung: [],
      verwindung1: [],
      verwindung2: [],
      rille_l: [],
      rille_r: [],
      dh_l: [],
      ds1_l: [],
      ds2_l: [],
      dh_r: [],
      ds1_r: [],
      ds2_r: [],
    },
  };

  showProtected: any;

  kav: any = {
    value: undefined,
    color: undefined,
  };

  hasStellkraftmessung = false;
  rights: any;

  opruefid: number;
  _kavaktiv: boolean = false;

  komponentenObjekt: any;
  komponentenObjektSpez: any;
  komponentenObjektBilder: any;
  rootObjekt: TreeDataNode;
  rootname: string;
  spezData: any = [];
  selOKOMPID: number = 0;
  selnode: any;
  selnodename: string = '';
  selnodetyp: number = 0;
  bilder: any[] = [];
  allSubNodes: any = [];

  showbemerkung: boolean = false;
  mangelbemerkung: string = "";
  curID: number = 0;

  set kavaktiv(newVal: any) {
    let val: any =
      newVal === true ? true : newVal === false ? false : newVal.target.checked;
    if (newVal?.target) {
      this.apiService
        .sendObjektKAV(this._ostammid, val)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status == "OK") {
            this.toastr.success(
              this.mrTranslate.transform("Daten gespeichert")
            );
          }
        });
    }

    this._kavaktiv = val;
  }
  get kavaktiv() {
    return this._kavaktiv;
  }

  @ViewChild("chart", { static: true }) chart: TemplateRef<any>;
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;

  @Input() set ostammid(id: number) {
    if (id) {
      this.loading.next(true);
      this.kav.value = undefined;
      this.kav.color = undefined;
      this.selectedSpez = undefined;
      this.showKAV = false;
      this.hideColumnsArr = ['bild'];
      this.spinner.enable();

      this.loadAnlageById(id);
      this._ostammid = id;
    }
  }


  loadAnlageById(id: number) {
    this.rights = JSON.parse(this.authService.decrypt(localStorage.getItem('rights')));
    this.deactivateAll = this.authService?.token?.rights?.status == 1 || this.viewMode == 1;
    this.initTab = {};
      this.apiService
      .getAnlage(id)
      .pipe(first())
      .subscribe((result: any) => {
        this.getAnlageByID(result);
      });
  }

  getAnlageByID(result: any) {
    this.showKAV = result.showkav;
        this.hasStellkraftmessung = result.hasStellkraftmessung ?? false;
        this.hasOpenMangel = false;
        this.hasMangel = false;
        this.opruefid = result.opruefid;
        this.excelPath = undefined;
        this.tables = {};
        this.pointtype = undefined;
        this.bemerkung = result.bemerkung;
        this.deactivateRep = result.isRepLocked;

        this.GroupBemerkung.controls.bemerkung.setValue(result.bemerkung);

        if (result.otypid) {
          this.typ_Line_Point = result.typ_Line_Point;
          this.otypid = result.otypid;
        }

        Object.keys(this.rights.type).forEach((typid) => {
          if(typid == this.otypid.toString()){
            this.PRDEL=  getTokenRight(this.rights.type[typid], "PRDEL");
            this.STDEL=  getTokenRight(this.rights.type[typid], "STDEL");
            this.STEDIT = getTokenRight(this.rights.type[typid], "STEDIT");
            this.STCOPY = getTokenRight(this.rights.type[typid], "STCOPY");
            this.SPEDIT = getTokenRight(this.rights.type[typid], "SPEDIT");
            this.BWEDIT = getTokenRight(this.rights.type[typid], "BWEDIT");
            this.KOMEDIT = getTokenRight(this.rights.type[typid], "KOMEDIT");
            this.TRAEDIT = getTokenRight(this.rights.type[typid], "TRAEDIT");
          }
        });

        this.pointtype = result.pointtype;
        if(result.objOpt && result.objOpt.rows.length > 0){
          this.objOpt = result.objOpt.rows[0];
        }
        this.objtyp = this.objtypePipe.transform(this.typ_Line_Point);
        if (result.tabs) {
          if (this.objtyp == "point") this.selectedSpez = result.tabs.spez;

          Object.keys(result.tabs).forEach((index) => {
            this.tables[index] = {};
            let elem = result.tabs[index];
            switch (index) {
              case "dokheader":
                this.docheader = elem;
                this.tables["dokheader"] = { calcLength: true };
                break;
              case "dokheaderAnzahlItems":
                this.dokheaderAnzahlItems = elem;
                break;
              case "doks":
                this.docsorginal = elem;
                this.docs['columns'] = elem['columns'];
                this.docs['rows'] = elem['rows'].filter(item => item.Kategorie == 0);

                this.hideColumnsArr.push("OTypid");
                this.hideColumnsArr.push("Kategorie");
                this.selAnzDok = this.dokheaderAnzahlItems[0];

                break;

              case "komponenten":
                this.komponentenObjekt = elem.objektkomponenten;
                this.komponentenObjektSpez = elem.spez;
                this.komponentenObjektBilder = elem.bilder;

                break;
              case "stamm":
                let stamm = elem["rows"].filter((elem: any) => {
                  let obj = elem.Objekt;
                  if (obj == "Typ") {
                    this.bezeichnung = elem.Bezeichnung;
                  }
                  if (obj == "Bezeichnung") {
                    this.bezeichnung += " " + elem.Bezeichnung;
                    this.rootname = elem.Bezeichnung;
                  }

                  if (obj == "Bezeichnung_Format") {
                    if (elem.Bezeichnung !== null)
                    this.bezeichnung = elem.Bezeichnung;
                  } else if (obj == "OSTAMMID") {
                    elem.Objekt = "StammID";
                    return true;
                  }
                  else if (obj == "ExcelPath") {
                    this.excelPath = elem.Bezeichnung;
                  } else if (obj == "KAV") {
                    if (
                      typeof elem.Bezeichnung == "string" &&
                      elem.Bezeichnung.indexOf("%") != -1
                    )
                      this.kav.value = elem.Bezeichnung;
                    else if (elem.Bezeichnung === null)
                      this.kav.value = "NULL";
                    else this.kav.value = elem.Bezeichnung;
                  } else if (obj == "KAVFarbe") {
                    this.kav.color = elem.Bezeichnung;
                  } else if (obj == "KAVAktiv") {
                    this.kavaktiv = elem.Bezeichnung
                      ? elem.Bezeichnung == 1 || elem.Bezeichnung == true
                        ? true
                        : false
                      : false;
                  } else if (obj == "Bild") {
                    this.files$ = this.apiService.getPassBilder(elem.Bezeichnung);
                    return false;
                  }
                  else if( obj == "Skizze") return false;
                  else return true;
                  return false;
                });
                this.tables["stamm"]["rows"] = [];
                setTimeout(() => {
                  this.tables["stamm"]["rows"] = stamm;
                });
                this.tables["stamm"]["columns"] = elem.columns;
                break;
              case "messungen":
                let datecols = elem.columns.filter((col: any) =>
                  dayjs(col.id, "DD.MM.YYYY").isValid()
                );
                if (datecols.length / 2 > 15) {
                  datecols = datecols.filter(
                    (col: any) =>
                      !dayjs(col.id, "DD.MM.YYYY").isSameOrAfter(
                      dayjs().subtract(3, "year")
                    )
                  );
                }

                datecols.forEach((col) => {
                  let hide = col.id;
                  if (col.id.indexOf("Tol") != -1) hide = hide.toLowerCase();
                  if (this.hideColumnsArr.includes(hide) === undefined)
                    this.hideColumnsArr.push(hide);
                });
                this.tables["messungen"]["rows"] = elem.rows;
                this.tables["messungen"]["columns"] = elem.columns;
                break;
              case "mangel":
                this.tables["mangel"]["rows"] = elem.rows;
                this.tables["mangel"]["columns"] = elem.columns;
                this.hasMangel = elem.rows.length > 0;

                this.tables["mangel"]["rows"].map((val) => {
                  if (val["auftrag"] === false && val["erledigtStatus"] === false) this.hasOpenMangel = true;
                });
                break;
              default:
                if (elem && elem["type"] == "table") {
                  this.tables[index]["rows"] = [];
                  setTimeout(() => {
                    this.tables[index]["rows"] = elem.rows;
                  });
                  this.tables[index]["columns"] = elem.columns;
                } else {
                  this.tables[index] = elem;
                }
                break;
            }
          });

          let activeTabExists = false;
          Object.keys(this.tables).forEach((tableKey) => {
            const tab = tableKey == "zaehler" ? 'messungen' : tableKey;
            if (this.activeTab[tab] && this.tables[tableKey]) {
              activeTabExists = true;
            }
          });
          if (!activeTabExists) {
            Object.keys(this.activeTab).forEach((tableKey) => {
              if (tableKey == "stamm") {
                this.activeTab[tableKey] = true;
              } else {
                this.activeTab[tableKey] = false;
              }
            });
          }
        }

        if (result["geometry"]) {
          this.geometry = result["geometry"];
        }

        if (result["fehlerzeile"]) {
          this.progressData.emit({
            zeile: result["fehlerzeile"],
            stammid: this._ostammid,
            once: false,
          });
        } else {
          this.enlarge.emit({
            mode: "progress",
            val: false,
          });
        }

        this.skizzeData = result["skizze"];

        if (this.tables["teilerneuerung"]) {
        }

        if (this.tables["komponenten"]) {
          let root = {
            id: 0,
            name: this.rootname,
            typ: null,
            selected: true,
            expanded: true,
            parent: null,
            rootpath: null,
            children: []
          }

          for (let i = 0; i < this.komponentenObjekt.rows.length; i++) {
            let komp = this.komponentenObjekt.rows[i];

            if (komp.ParentID == 0){

              let node: TreeDataNode = {
                id: komp.OKOMPID,
                name: komp.TypBezeichnung,
                typ: komp.KTYPID,
                selected: false,
                expanded: false,
                rootpath: null,
                parent: this.rootObjekt,
                children: []
              };

              root.children.push(node);

              this.fillChildrenObjekt(node, komp.OKOMPID);
            }

          }

          this.rootObjekt = root;
        }





        this.spinner.disable();
        this.loading.next(false);
  }

  fillChildrenObjekt(parentNode:TreeDataNode, iid:number){
    const filtered = this.komponentenObjekt.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.OKOMPID,
        name: komp.TypBezeichnung,
        typ: komp.KTYPID,
        selected: false,
        expanded: false,
        rootpath: null,
        parent: parentNode,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildrenObjekt(node,komp.OKOMPID);
    }
  }
  public getChildren = (node) => node.children;

  treeClick(value){
    this.bilder = this.komponentenObjektBilder[this.selnodetyp] || [];
    this.selOKOMPID = value.id;
    this.selnode = value;
    this.selnodename = value.name;
    this.selnodetyp = value.typ;
    this.spezData = [];

    Object.keys(this.komponentenObjektSpez).forEach((typid) => {
      if(typid == this.selnodetyp.toString()){
        this.spezData = this.komponentenObjektSpez[typid];
      }
    });
  }


  hideColumnsArr = ['bild'];
  get ostammid() {
    return this._ostammid;
  }

  previewMap(row: any) {
    this.showGIS = true;
    setTimeout(() => {
      this.map = previewMap(row, this.geometry,this.map);  
    }, 300);
  }

  openStamm(table){

  }


  deleteStamm(){

  }

  deletePruef(){

  }

  protected openHerstellModal() {
    if (this.selectedSpez) {
      this.selectedSpezID = this.objtyp == "line"
        ? this.selectedSpez.OSPEZID.value
        : this.selectedSpez.find((row: any) => row.objekt == 'OSPEZID')?.bezeichnung;
      this.isHerstellDatenVisible = true;
    }
    else {
      this.toastr.warning(this.mrTranslate.transform("Bitte zuerst eine Spezifikation auswählen"));
      if (!this.activeTab.spez) {
        for (const tab in this.activeTab) {
          this.activeTab[tab] = tab == 'spez';
        }
      }
    }
  }

  protected closeHerstellModal(toReload: boolean) {
    setTimeout(() => {
      this.isHerstellDatenVisible = false;
    });
    if (toReload) this.loadAnlageById(this.ostammid);
  }

  getBauwerke() {
    this.overlayService.setOverlay({
      overlay: "bauwerke",
      isTopOverlay: true,
      table: this.tables['bauwerke'],
      type: this.typ_Line_Point,
      otypid: this.otypid,
      ostamm: this.ostammid,
      stammData: this.linieValues,
      dataRefresh: this.dataRefresh,
    })
  }

  getBahnuebergaenge() {
    this.overlayService.setOverlay({
      overlay: "bahnuebergang",
      isTopOverlay: true,
      type: this.typ_Line_Point,
      opruefid: this.opruefid,
      dataRefresh: this.dataRefresh,
    })
  }

  openMessungVgl(row) {
    let measure = this.mrTranslate.transform("Messung");
    this.messungVglModal.title = row.bezeichnung
      ? measure + ": " + row.bezeichnung
      : this.mrTranslate.transform("Messung");
    let graph = this.createVglMessung(row);
    this.messungVglModal.graph = graph;
    this.messungVglModal.open = true;
    this.changeDet.detectChanges();
  }

  createVglMessung(row) {
    const formatNumber = (num: any) => {
      return typeof num == "string" ? parseFloat(num.replace(",", ".")) : num;
    };

    let names = [
      row.bezeichnung,
      this.mrTranslate.transform("Soll-Maß"),
      this.mrTranslate.transform("SR 100 +"),
      this.mrTranslate.transform("SR 100 -"),
      this.mrTranslate.transform("SR lim +"),
      this.mrTranslate.transform("SR lim -"),
    ];
    let dates = Object.keys(row).filter((key) => {
      let valid = dayjs(key, "DD.MM.YYYY").isValid();
      if (!valid) return false;
      valid = key.indexOf("Tol") === -1;

      return valid;
    });

    dates = dates.filter((x, i, a) => a.indexOf(x) == i);
    let measurements = dates.map((date) => {
      return formatNumber(row[date]);
    });
    dates = dates.map((date) => dayjs(date, "DD.MM.YYYY").format("YYYY-MM-DD"));

    let values = {
      soll: Array(measurements.length).fill(formatNumber(row.soll)),
      sr100plus: Array(measurements.length).fill(
        formatNumber(row.soll) + formatNumber(row.sr100plus)
      ),
      sr100minus: Array(measurements.length).fill(
        formatNumber(row.soll) - formatNumber(row.sr100minus)
      ),
      srlimplus: Array(measurements.length).fill(
        formatNumber(row.soll) + formatNumber(row.srlimplus)
      ),
      srlimminus: Array(measurements.length).fill(
        formatNumber(row.soll) - formatNumber(row.srlimminus)
      ),
    };

    return {
      layout: {
        autosize: true,
        showlegend: true,
        legend: { orientation: "h" },
        height: 730,
        margin: {
          l: 35,
          r: 20,
          b: 25,
          t: 20,
          pad: 0,
        },
        plot_bgcolor: "transparent",
        paper_bgcolor: "transparent",
        yaxis: {
          autorange: false,
          range: [
            Math.min(...measurements) - 10,
            Math.max(...measurements) + 10,
          ],
          type: "linear",
        },
      },
      config: {
        responsive: true,
        displaylogo: false,
        locale: "de",
        modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
      },
      data: [
        {
          type: "scatter",
          mode: "lines",
          x: dates,
          y: measurements,
          name: names[0],
        },
        {
          type: "scatter",
          mode: "lines",
          x: dates,
          y: values.soll,
          name: names[1],
        },
        {
          type: "scatter",
          mode: "lines",
          x: dates,
          y: values.sr100plus,
          name: names[2],
        },
        {
          type: "scatter",
          mode: "lines",
          x: dates,
          y: values.sr100minus,
          name: names[3],
        },
        {
          type: "scatter",
          mode: "lines",
          x: dates,
          y: values.srlimplus,
          name: names[4],
        },
        {
          type: "scatter",
          mode: "lines",
          x: dates,
          y: values.srlimminus,
          name: names[5],
        },
      ],
    };
  }

  // ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
  //   if (changes["activeTab"]) {
  //   }
  // }

  ngOnDestroy() {
    this.basicStore.setDetailViewStore({
      activeTab: this.activeTab,
    });
    this.refreshSubscription.unsubscribe();
  }

  graphDetail(graph_slug) {
    this.overlayService.setOverlay({
      overlay: "messungDetail",
      id: this._ostammid,
      graph: {
        layout: this.graph.layout,
        config: this.graph.config,
        m: this.graph.m,
        y: this.graph.yData[graph_slug],
      },
    });
  }

  resizeGrid() {
    setTimeout(() => {
      this.grid.resize();
    });
  }

  graphXY(x, y) {
    let obj = {
      x: x,
      y: y,
    };
    let arr = [obj];
    return arr;
  }

  ngAfterViewInit(): void {
    this.activeTab = {
      stamm: false,
      spez: false,
      bauwerke: false,
      mangel: false,
      messungen: false,
      historie: false,
    };
    let detailviewStore = this.basicStore.getDetailViewStore();
    if (detailviewStore) {
      if (detailviewStore["activeTab"]) {
        this.activeTab = detailviewStore["activeTab"];
      }
    }
  }

  ngOnInit() {
    this.isRep = this.apiService.isRep
    this.production = this.shared.production;
    this.refreshSubscription = this.dataRefresh?.pipe().subscribe(() => {
      this.spinner.enable();

      // Refresh Tabs (Anlagen, Mängel u. Aufträge) im ComponentStore
      this.basicStore.reloadTabs({ emitter: this.reloadTabsIsReady });

      // Refresh Aktuelle Tab vom ComponentStore gleich nach Fertigkeit
      this.reload.emit(this.reloadTabsIsReady);
    });
  }

  addKategorie(){
    this.TitelKat = this.mrTranslate.transform("Neue Kategorie");
    this.kategorie.nativeElement.value ='';
    this.isKatVisible = true;
    this.katStatus="add";
  }

  editKategorie(){
    this.TitelKat = this.mrTranslate.transform("Kategorie bearbeiten");
    this.kategorie.nativeElement.value = this.docheader[this.seldoctyp];
    this.isKatVisible = true;
    this.katStatus="edit";
  }

  delKategorie(){
    if (confirm("Sind Sie sicher, dass Sie diese Kategorie löschen möchten?")) {
      this.katStatus="del";
      this.docheader.splice(this.seldoctyp, 1);
      this.dokheaderAnzahlItems.splice(this.seldoctyp, 1);
      this.sendKategorie();
    }
  }


  saveKategorie(){
    let valKat = this.kategorie.nativeElement.value;
    if (valKat == '') return;

    if (this.katStatus == "edit" && valKat != this.docheader[this.seldoctyp]){
      this.docheader[this.seldoctyp] = valKat;
      this.sendKategorie();
    }

    if (this.katStatus == "add"){
      this.docheader.push(valKat);
      this.dokheaderAnzahlItems.push(0);
      this.sendKategorie();
    }
  }

  sendKategorie(){
    let upfile = {
      ostammid: this._ostammid,
      kategorie: this.docheader.join(";"),
    };

    this.apiService
    .editAnlagenDokumentKategorie(JSON.parse(JSON.stringify(upfile)))
    .pipe(first())
    .subscribe((res: any) => {
      if (res) {

        this.isKatVisible = false;
        setTimeout(() => {
          this.doctabChange(0)
        }, 75);

      }
      else
      {
        this.toastr.error(this.mrTranslate.transform("Error: " + res.error));
      }
    });
  }

  doctabChange(event) {
    this.docs['rows'] =[];
    this.seldoctyp = event;
    this.docs['rows'] =  this.docsorginal['rows'].filter(item => item.Kategorie == this.seldoctyp);
    this.selAnzDok = this.dokheaderAnzahlItems[this.seldoctyp];
  }

  @Input() param = "file";
  @Input() accept = "image/png, image/jpeg, application/pdf";
  onDrop: any;
  uploadfiles: any[]=[];
  selecteddocrow:any;

  rowDocChanged(value: any) {
    this.selecteddocrow = value;
  }

  openDokument(row: any){
    this.selecteddocrow = row;

    if (this.selecteddocrow.data){
      let dataIn = {
        data: this.selecteddocrow.data,
        titel: this.mrTranslate.transform('Datei') +': '+ this.selecteddocrow.Name,
        file: this.selecteddocrow.Name,
      }

      this.overlayService.setOverlay({
        overlay: "viewAuftrag",
        data: dataIn,
        zIndex: 5,
      });

    }
    else
    {
      this.apiService
      .getDocumentFile(this.selecteddocrow.ID)
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          let data = this.apiService.convertFileAsBlob(res, this.selecteddocrow.Typ, 512);

          if (this.hideColumnsArr.includes('data') === undefined)
            this.hideColumnsArr.push('data');

          this.selecteddocrow.data = data;

          let dataIn = {
            data: data,
            titel: this.mrTranslate.transform('Datei') +': '+ this.selecteddocrow.Name,
            file: this.selecteddocrow.Name,
          }

          this.overlayService.setOverlay({
            overlay: "viewAuftrag",
            data: dataIn,
            zIndex: 5,
          });
        }
      });
    }
  }

  deleteDokument(row: any){
    const text = this.mrTranslate.transform("Sind Sie sicher, dass Sie dieses Dokument löschen möchten?");
    if (confirm(text)) {
      this.selecteddocrow = row;
      this.uploadfiles = [];

      let upfile = {
        status: 'delete',
        id: this.selecteddocrow.ID,
        ostammid: this._ostammid,
        kategorie: this.seldoctyp,
        filename: '',
        size: 0,
        typ: '',
        base64:  '',
        extension: '',
      };

      this.uploadfiles.push(upfile);

      this.apiService
      .addAnlageDocument(JSON.parse(JSON.stringify(this.uploadfiles)))
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.doks) {
          this.docsorginal = res.doks;
          this.docs['columns'] = res.doks['columns'];
          this.docs['rows'] = res.doks['rows'].filter(item => item.Kategorie == this.seldoctyp);
          this.dokheaderAnzahlItems = res.dokheaderAnzahlItems;

          setTimeout(() => {
            this.doctabChange(this.seldoctyp)
          }, 75);

        }
        else
        {
          this.toastr.error(this.mrTranslate.transform("Error: " + res.error));
        }
      });
    }
  }

  addDok()
  {
    const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;

    this.uploadfiles =[];

    fileUpload.onchange = () => {

      for (let i = 0; i < fileUpload.files.length; i++) {

        this.getBase64FromFile(fileUpload.files[i]).subscribe(base64 => {
          let file : File = fileUpload.files[i];
          let upfile = {
            status: 'add',
            id: -1,
            ostammid: this._ostammid,
            kategorie: this.seldoctyp,
            filename: file.name,
            size: file.size,
            typ: file.type,
            base64:  base64,
            extension: this.getFileExtension(file.name),
          };

          this.uploadfiles.push(upfile);

          if (fileUpload.files.length == this.uploadfiles.length){
            this.apiService
            .addAnlageDocument(JSON.parse(JSON.stringify(this.uploadfiles)))
            .pipe(first())
            .subscribe((res: any) => {
              if (res?.doks) {
                this.docsorginal = res.doks;
                this.docs['columns'] = res.doks['columns'];
                this.docs['rows'] = res.doks['rows'].filter(item => item.Kategorie == this.seldoctyp);
                this.dokheaderAnzahlItems = res.dokheaderAnzahlItems;

                setTimeout(() => {
                  this.doctabChange(this.seldoctyp)
                }, 75);

              }
              else
              {
                this.toastr.error(this.mrTranslate.transform("Error: " + res.error));
              }
            });
          }

        });

      }
    };
    fileUpload.click();
  }

  getFileExtension(filename) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  getBase64FromFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  tabChange(tab: string) {
    this.initTab[tab] = true;
    setTimeout(() => {
      if(tab == 'paint') this.expand('full', true);
      if (this.grid) this.grid.resize();
    });
  }

  expand(mode: string, val?: boolean) {
    if (mode == "full") {
      if (val != this.expanded) {
        this.expanded = !this.expanded;
        this.enlarge.emit({
          mode: "full",
          val: this.expanded,
        });
      }
    }
    if (mode == "half") {
      this.split50 = !this.split50;
      this.enlarge.emit({
        mode: "half",
        val: this.split50,
      });
    }
    let save = [this.graph.config, this.graph.layout];
    this.graph.config = [];
    this.graph.layout = [];
    this.graph.config = save[0];
    this.graph.layout = save[1];
  }

  getMessungen() {
    this.spinner.enable();
    this.overlayService.setOverlay({
      overlay: "openMeasurements",
      id: this._ostammid,
    });
  }


  getMessungenLWM() {
    this.spinner.enable();
    this.overlayService.setOverlay({
      overlay: "openMessungLWM",
      id: this._ostammid,
    });
  }

  getKomponenten(){
    this.overlayService.setOverlay({
      overlay: "komponenteneditdialog",
      id: this.ostammid,
      otypid: this.otypid,
      stamm: this.tables['stamm'],
      type: this.typ_Line_Point,
      zIndex: 8,
    });
  }

  saveMangelBemerkung(){
    this.showbemerkung = false;
    var req = {
      bemerkung: this.mangelbemerkung,
    };
    this.apiService
      .saveMangelBemerkung(req, this.curID)
      .pipe(first()).subscribe(() => {
        setTimeout(() => {
          this.dataRefresh?.emit(true);
        });
      });
    this.curID = 0;
    this.mangelbemerkung = "";

  }

  openBemerkung(bemerkung: string, id: number) {
    this.mangelbemerkung = bemerkung;
    this.curID = id;
    this.showbemerkung = true;
  }

  openSonderinspektion(row: any) {
    let inData = {
      Status: 1,
      OSTAMMID: row.ostammid,
      OMAZSID: row.OMAZSID,
      OTYPID: this.otypid,
      OSISPKID: 0,
      Mangel: row.mangel,
      aktive: true,
      intervall: 10
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true){
        this.modal.close();
        this.dataRefresh?.emit(true);
      }
    });
  }

  getMangelverteilung(){
    this.overlayService.setOverlay({
      overlay: "mangelverteilung",
      id: this.ostammid,
      stamm: this.tables['stamm'],
      mangel: this.tables['mangel'],
      spez: this.tables['spez'],
      bauwerk: this.tables['bauwerke'],
      zIndex: 8,
    });
  }


  setTrassierung() {
    this.overlayService.setOverlay({
      overlay: "trassierungseditor",
      id: this.ostammid,
      zIndex: 8,
    });

  }



  getProfile() {
    this.overlayService.setOverlay({
      overlay: "profileviewer",
      id: this._ostammid,
      start: 0,
    });
    //this.enlargePB.emit(true);
  }

  downloadExcelFile() {
    if (this.excelPath) {
      this.apiService
        .downloadExcelFile(this.excelPath)
        .pipe(first())
        .subscribe((res) => {
          if (res) {
            let filename = this.excelPath.split("/");
            filename = filename[filename.length - 1] + ".xlsx";
            this.apiService.downloadBlobAsFile(res, filename);
          }
        });
    }
  }

  getPDFFile() {
    if (this.opruefid != 0){
      this.spinner.enable();
      this.apiService
      .getPDFFile(this.opruefid)
      .pipe(first())
      .subscribe((res) => {
        if (res) {
          this.overlayService.setOverlay({
            overlay: "viewAuftrag",
            titel: this.mrTranslate.transform('Inspektionsbericht'),
            data: res,
            zIndex: 5,
          });
        } else {
          this.toastr.warning(this.mrTranslate.transform("Kein PDF erstellt, Fehler im Log."));
        }
        this.spinner.disable();
      });
    }
    else
    this.toastr.warning(this.mrTranslate.transform("Für diese Objekt sind keine Inspektionsdaten vorhanden"));
  }

  getRailmonitor() {
    this.overlayService.setOverlay({
      overlay: "railmonitor",
      id: this._ostammid,
      start: 0,
    });
  }

  openStellkraftmessung() {
    this.overlayService.setOverlay({
      overlay: "stellkraftmessung",
      id: this._ostammid,
    });
  }

  createAssignment(event) {
    if (this.tables?.mangel?.rows?.length) {
      this.overlayService.setOverlay({
        overlay: "createassignment",
        typ_Line_Point: this.typ_Line_Point,
        id: this._ostammid,
        Opt_F_Drawing: this.objOpt?.Opt_F_Drawing,
        latlng:
          this.tables?.stamm?.rows?.find((row: any) => row.DBField === "GPS_Lat")?.Bezeichnung +
          "," +
          this.tables?.stamm?.rows?.find((row: any) => row.DBField === "GPS_Lon")?.Bezeichnung,
          // this.tables["stamm"]["rows"][7]["Bezeichnung"],
        zIndex: 5,
        dataRefresh: this.dataRefresh,
      });
    }
  }

  createDefect(event) {
    const { startmeter, endmeter } = this.linieValues;

    this.overlayService.setOverlay({
      overlay: "createDefect",
      typ_Line_Point: this.typ_Line_Point,
      id: this._ostammid,
      startmeter,
      endmeter,
      zIndex: 5,
      dataRefresh: this.dataRefresh,
    });
  }

  createBlocking(event) {
    const { startmeter, endmeter } = this.linieValues;

    this.overlayService.setOverlay({
      overlay: "createBlocking",
      id: this._ostammid,
      typ_Line_Point: this.typ_Line_Point,
      pointtype: this.pointtype ? this.pointtype : undefined,
      startmeter,
      endmeter,
      zIndex: 5,
      dataRefresh: this.dataRefresh,
    });
  }

  createLAStelle(event) {
    const { startmeter, endmeter } = this.linieValues;

    this.overlayService.setOverlay({
      overlay: "createLaStelle",
      id: this._ostammid,
      typ_Line_Point: this.typ_Line_Point,
      pointtype: this.pointtype,
      startmeter,
      endmeter,
      zIndex: 5,
      dataRefresh: this.dataRefresh,
    });
  }

  createSpezifikationen() {
    this.overlayService.setOverlay({
      overlay: "spezifikation",
      tableSpez: this.objtyp == 'line' ? this.tables['spez'] : [],
      spezData: this.objtyp == 'point' ? this.selectedSpez : [],
      ostamm: this.ostammid,
      otypid: this.otypid,
      type: this.typ_Line_Point,
      stammData: this.linieValues,
      dataRefresh: this.dataRefresh,
    });
  }

  private get linieValues() {
    const startmeter = this.tables.stamm?.rows.find(
      (row: any) => row.DBField == "StartMeter"
    )?.Bezeichnung;
    const endmeter = this.tables.stamm?.rows.find(
      (row: any) => row.DBField == "EndMeter"
    )?.Bezeichnung;
    return { startmeter, endmeter };
  }

  createInspektion(){

    this.websocket._connection.on('inspLocked', data => {
      this.toastr.info(
        this.mrTranslate.transform("Inspektion '" + data[0] +"' wird erstellt von " +data[1] + ". Stammdokument ist für diesen Zeitraum gesperrt."),
        this.mrTranslate.transform("Inspektion")
      );
    });

    // this.apiService
    // .sendAnlagenMessage(this.ostammid,"Start")
    // .pipe(first())
    // .subscribe((val: any) => {
    //   if(val == "Blocked") {
    //     this.toastr.info(
    //       this.mrTranslate.transform("Diese Inspektion ist aktuell in Bearbeitung und kann nicht geöffnet werden."),
    //       this.mrTranslate.transform("Inspektion")
    //     );
    //   } else  {

    //   }
    // });
    this.overlayService.setOverlay({
      overlay: "openInspektionsDialog",
      isTopOverlay: true,
      stammData: this.linieValues,
      otypid: this.otypid,
      type: this.typ_Line_Point,
      pruefid: this.opruefid,
      ostamm: this.ostammid,
      dataRefresh: this.dataRefresh,
    })


  }

  createFeedback(event) {
    if (this.tables?.mangel?.rows?.length) {
      this.overlayService.setOverlay({
        overlay: "createfeedback",
        id: this._ostammid,
        stamm: this.tables['stamm'],
        mangel: this.tables['mangel'],
        typ_Line_Point: this.typ_Line_Point,
        otypod: this.otypid,
        zIndex: 5,
        Opt_F_Drawing: this.objOpt?.Opt_F_Drawing,
        dataRefresh: this.dataRefresh,
      });
    }
  }

  editStammdaten(event) {
    this.overlayService.setOverlay({
      overlay: "openStammdatenDialog",
      id: this._ostammid,
      otypid: this.otypid,
      type: this.typ_Line_Point,
      zIndex: 5,
      dataRefresh: this.dataRefresh,
    });

  }

  formatter(value) {
    var d = new Date(Date.parse(value));
    return d.getDate() + "." + d.getMonth() + "." + d.getFullYear();
  }

  /* MR 2024-08-07 jetzt unter Rückmeldung
  createMangel(event) {
    const { startmeter, endmeter } = this.linieValues;

    this.overlayService.setOverlay({
      overlay: "mangelerfassung",
      id: {
        ostammid: this._ostammid,
        typ_Line_Point: this.typ_Line_Point,
        otypid: this.otypid,
        mangel: this.tables?.mangel?.rows?.length ? this.tables["mangel"] : undefined,
        startmeter,
        endmeter,
      },
      zIndex: 5,
      dataRefresh: this.dataRefresh,
    });
  }
  */

  closeHandler() {
    this.split50 = false;
    this.expanded = false;
    this.progressbarenlarged = false;
    this.enlarge.emit({
      mode: "close",
      val: false,
    });
  }
}

export function getTokenRight(rights: any[], key: string) {
  let res = undefined;
  for (const right of rights) {
    if (right.key == key) {
      res = right.value;
      break;
    }
  }
  return res;
}
