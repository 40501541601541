<div class="overlay-inner-wrapper">
  <div id="sammelWrapper">
    <header class="header-2">
      <div class="branding">
          <span class="title">{{"Anlagenliste" | mrTranslate}}</span>
      </div>
      <div class="header-actions">

        <div class="btn-group btn-icon" style="margin-top: 10px;">
          <button class="btn" (click)="createSammel($event)"  [disabled]="selected.length == 0">
            <clr-icon [attr.title]="'Sammelexport' | mrTranslate" size="20" shape="pdffile"></clr-icon>
          </button>

          <ng-container *ngIf="progVersion == 45 && authService?.token?.rights?.status > 2">

            <ng-container *ngIf="STDEL == true">
              <button class="btn" (click)="stammdel()"  [disabled]="selected.length == 0">
                <clr-icon [attr.title]="'Stammdaten löschen' | mrTranslate" size="20" shape="deletestamm"></clr-icon>
              </button>
            </ng-container>


            <button class="btn" (click)="stammarchiv()"  [disabled]="selected.length == 0">
              <clr-icon [attr.title]="'Stammdaten archivieren' | mrTranslate" size="20" shape="archive"></clr-icon>
            </button>

            <ng-container *ngIf="PRDEL == true">
              <button class="btn" (click)="delpruef()"  [disabled]="selected.length == 0">
                <clr-icon [attr.title]="'Prüfung löschen' | mrTranslate" size="20" shape="deletepruef"></clr-icon>
              </button>
            </ng-container>

            <button class="btn" [disabled]="hasRepID" (click)="replicate()"  [disabled]="selected.length == 0">
              <clr-icon [attr.title]="'Replikat erstellen' | mrTranslate" size="20" shape="rep_download"></clr-icon>
            </button>
            <button class="btn" [disabled]="!hasRepID" (click)="stopReplicate()">
              <clr-icon [attr.title]="'Replikat in Masterdatenbank zurückspielen' | mrTranslate" size="20" shape="rep_upload"></clr-icon>
            </button>
            <button *ngIf="promptEvent" (click)="installPwa()">
              <clr-icon [attr.title]="'Installiere PWA' | mrTranslate" size="20" shape="times"></clr-icon>
            </button>

          </ng-container>

          <button class="btn" (click)="close()">
            <clr-icon [attr.title]="'Beenden' | mrTranslate" size="20" shape="times"></clr-icon>
          </button>

        </div>

        <!--
        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon  shape="pdffile" (click)="createSammel($event)"></clr-icon >
        </a>


        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon  shape="times" (click)="close()"></clr-icon >
        </a>
        -->

      </div>
    </header>

    <div class="limit-height">
      <clr-datagrid *ngIf="table && table['rows'].length > 0" [(clrDgSelected)]="selected" [clrDgRowSelection]="true" class="datagrid-compact" (clrDgSelectedChange)="selectionChanged($event)">
        <clr-dg-column *ngFor="let column of table['columns']" [clrDgField]="column.id"
          [style.min-width.px]="(column.id == 'KAV' || column.id == 'status') ? 100
          : (column.id.length * 7.5) + 50">
          <clr-dg-filter [attr.data-test-id]="'datagrid-anlagen-col-filter-' + column.id">
            <app-column-filter tableName="anlagen" [column]="column" [uniqueColumnValues]="getUniqueValuesForColumn(tableUniqueColumnValues, column.id)" (changes)="onFilterChange($event)"/>
          </clr-dg-filter>

          <ng-container *clrDgHideableColumn="{
              hidden: column.id | hideIDColumns : ['SPID', 'bild']
            }">
            {{
            column.id == "KAV"
            ? table["rows"][0]?.KAV &&
            table["rows"][0].KAV.toString().indexOf("%") != -1
            ? "SAX"
            : column.id
            : (column.id | titlecase | mrTranslate)
            }}
          </ng-container>
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let row of table['rows']" [clrDgItem]="row">
          <ng-container *ngFor="let cell of table['columns']">
            <ng-container *ngIf="
                iconColumns.indexOf(cell.id.toLowerCase()) == -1;
                else iconcell
              ">
              <ng-container *ngIf="cell.id == 'KAV'; else elseTemplate">
                <clr-dg-cell [style.min-width.px]="100"
                  [innerHTML]="" [title]="row[cell.id] | tablePrettyPrint">
                  <span>
                    <clr-icon *ngIf="row[cell.id] != null" shape="circle" class="is-solid" [ngStyle]="{ color: row['kavcolor'] }"
                      size="16"></clr-icon>&nbsp;
                    {{ row[cell.id] | tablePrettyPrint | dotDotDot : 30 }}
                  </span>
                </clr-dg-cell>
              </ng-container>
              <ng-template #elseTemplate>
                <clr-dg-cell [style.min-width.px]="cell.id.length * 7.5 + 50" class="ellipsis"
                  [innerHTML]="row[cell.id] | tablePrettyPrint"
                  [title]="row[cell.id] | tablePrettyPrint | dotDotDot : 30">
                </clr-dg-cell>
              </ng-template>
            </ng-container>

            <ng-template #iconcell>
              <clr-dg-cell style="color: #000;" [style.min-width.px]="85">
                <ng-container [ngSwitch]="cell.id.toLowerCase()">
                  <clr-icon *ngSwitchCase="'typ'" class="customIcon" [attr.shape]="
                      row['typ_icon']
                        ? 'objekttyp' + row['typ_icon']
                        : 'unknown-status'
                    " size="28">
                  </clr-icon>
                  <clr-icon *ngSwitchCase="'rep'" class="customIcon"
                    [ngStyle]="{ color: row['Rep'] == 0 ? 'green' : 'red' }"
                    [attr.shape]="row['Rep'] == 0 ? 'shield-check' : 'shield-x'" size="25"></clr-icon>
                  <clr-icon *ngSwitchCase="'sp'" class="customIcon" [attr.shape]="
                      row['SP'] && row['SP'] > 0
                        ? row['Richtung'] > 0 ? 'richtung' + row['Richtung'].toString()
                        : 'gesperrt'
                        : 'blank'
                    " size="28">
                  </clr-icon>
                  <clr-icon *ngSwitchCase="'lf'" class="customIcon" [attr.shape]="
                      row['LF'] && row['LF'] > 0
                        ? 'lastelle' +
                          (row['LF'] / 10).toString().replace('.', '_')
                        : 'blank'
                    " size="28">
                  </clr-icon>
                  <clr-icon *ngSwitchCase="'st'" class="customIcon iconcolor" [attr.shape]="
                      row['ST'] == 1 ? 'exclamation-triangle' : 'blank'
                    " size="28">
                  </clr-icon>
                </ng-container>
              </clr-dg-cell>
            </ng-template>
          </ng-container>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="rownr">
            <clr-dg-page-size [clrPageSizeOptions]="[20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
            </clr-dg-page-size>
            {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
            {{ "von" | mrTranslate }} {{ pagination.totalItems }}
            {{ "Zeilen" | mrTranslate }}
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
    <clr-button (click)="createSammel($event)">
      <clr-icon size="20" shape="plus-circle" class="cursor-pointer" [attr.title]="'Sammeldruck erstellen' | mrTranslate"></clr-icon>
    </clr-button>
  </div>
</div>
