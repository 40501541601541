<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
        <span class="title">{{'Begehungen verwalten' | mrTranslate}}</span>
    </div>
    <div class="header-actions">
      <a href="javascript://" class="nav-link nav-icon">
        <clr-icon  shape="times" (click)="close()"></clr-icon >
      </a>
    </div>
  </header>
  <div id="spezifikationsWrapper" class="w-100-p padding-10-lr padding-12-b scroll-y h-60">
<!--
    <div class="clr-row nxm" style="align-items: center;">
      <div class="clr-col-auto margin-12-tb">
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
            (click)="addBegehung()">
            <clr-icon shape="file"></clr-icon>
          </button>
          <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
            (click)="deleteBegehung()">
            <clr-icon shape="times"></clr-icon>
          </button>
          <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate">

            <clr-icon shape="floppy"></clr-icon>
          </button>
        </div>
      </div>
    </div>

     -->
      <div class="clr-row" style="margin-left: 5px;">
        <div class="clr-col-md-4 h-100">
          <h4 style="margin-top: 12px;">{{ "Begehungsart" | mrTranslate }}</h4>
          <ng-container *ngIf="begehungTable">
            <div class="limit-height">
              <clr-datagrid #objekteGrid class="datagrid-compact"
                [(clrDgSingleSelected)]="selectedBegehung" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionGridChanged($event)">
                  <clr-dg-column *ngFor="let column of begehungTable['columns']" [clrDgField]="column.id">
                    <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['tag']}">
                      {{ column.id | titlecase | mrUnitText }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of begehungTable['rows']" [clrDgItem]="row">
                    <clr-dg-cell class="name-cell" *ngFor="let cell of begehungTable['columns']"
                      [innerHTML]="row[cell.id] | tablePrettyPrint">
                    </clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>{{begehungTable['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
              </clr-datagrid>
            </div>
          </ng-container>
        </div>
        <div class="clr-col-md-8">
          <h4 style="margin-top: 12px;">{{ "Einstellungen" | mrTranslate }}</h4>
          <clr-tabs>
            <clr-tab>
              <button #auftragTabButton clrTabLink (click)="tabChange('stamm')">
                {{ "Begehung" | mrTranslate }}
              </button>
              <clr-tab-content>
                <div class="card item-table mt-0 d-f col clr-justify-content-between0">
                <div class="card-block h-60 np">
                  <form clrForm (ngSubmit)="saveIfValid()" #f="ngForm" [formGroup]="formGroup">

                    <clr-input-container>
                      <label class="clr-required-mark">{{ "Begehungsart" | mrTranslate }}</label>
                      <input clrInput placeholder="Bezeichnung eingeben" name="name" formControlName="bezeichnung"/>
                      <clr-control-error *clrIfError="'required'">
                        {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                      </clr-control-error>
                    </clr-input-container>

                    <clr-date-container>
                      <label class="clr-required-mark" for="intervallbeginn"> {{ "Beginn" | mrTranslate }} </label>
                      <input #dateModel clrDate name="intervallbeginn" autocomplete="off" type="date"
                        [ngModel]="dateControl | localeDate"
                        (ngModelChange)="dateControl = localeDate.tosql($event)"
                        [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                        [disabled]="formGroup.disabled" required/>
                      <clr-control-error *clrIfError="'required'">
                        {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                      </clr-control-error>
                    </clr-date-container>

                    <clr-input-container>
                      <label class="clr-required-mark" for="intervall">{{ "Intervall in Wochen" | mrTranslate }}</label>
                      <input clrInput type="number" min="1" name="intervall" formControlName="intervall" />
                      <clr-control-error *clrIfError="'required'">
                        {{ "Erforderlich" | mrTranslate }}
                      </clr-control-error>
                      <clr-control-error *clrIfError="'min'">
                        {{ "Mindestens 1" | mrTranslate }}
                      </clr-control-error>
                    </clr-input-container>


                    <clr-radio-container>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="1" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Montag" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="2" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Dienstag" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="3" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Mittwoch" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="4" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Donnerstag" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="5" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Freitag" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="6" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Samstag" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper style="margin-left: 205px;">
                        <input type="radio" clrRadio value="7" class="clr-radio" formControlName="tag"/>
                        <label>{{ "Sonntag" | mrTranslate }}</label>
                      </clr-radio-wrapper>
                    </clr-radio-container>
                    <clr-textarea-container>
                      <label>{{ "Beschreibung" | mrTranslate }}</label>
                      <textarea clrTextarea name="description" formControlName="bemerkung"></textarea>
                    </clr-textarea-container>
                  </form>
                </div>

                <div class="card-footer">
                  <div class="btn-group btn-primary btn-icon margin-0-t clr-flex-shrink-0">
                    <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                      (click)="addBegehung()">
                      <clr-icon shape="file"></clr-icon>
                    </button>
                    <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
                      (click)="deleteBegehung()" [disabled]="obegeid <= 0">
                      <clr-icon shape="times"></clr-icon>
                    </button>
                    <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate"
                      (click)="f.ngSubmit.emit()" [disabled]="!(insertItem || selectedBegehung)">
                      <clr-icon shape="floppy"></clr-icon>
                    </button>
                  </div>
                  <span class="name-cell">
                    {{ (insertItem
                      ? "Neue Begehungsart erstellen"
                      : selectedBegehung
                      ? "ausgewählte Begehungsart bearbeiten"
                      : ""
                    ) | mrTranslate }}
                  </span>
                </div>
              </div>
              </clr-tab-content>
            </clr-tab>

            <clr-tab>
              <button #auftragTabButton clrTabLink (click)="tabChange('stamm')" [disabled]="obegeid <= 0">
                {{ "Objekte" | mrTranslate }}{{ ' (' + anzahlItems +  ')'}}
              </button>
              <clr-tab-content>

                <div class="clr-row nxm" style="align-items: center;">
                  <div class="clr-col-auto margin-12-tb">
                    <div class="btn-group btn-primary btn-icon">
                      <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                        (click)="addObjekt()">
                        <clr-icon shape="file"></clr-icon>
                      </button>
                      <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate" [disabled]="selectedZuObjekte.length == 0"
                        (click)="deleteObjekt()">
                        <clr-icon shape="times"></clr-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <clr-datagrid #objekteDatagrid *ngIf="selectedZuordnungTable?.rows?.length > 0" class="datagrid-compact" style="height: calc(100% - 60px);"
                  [(clrDgSelected)]="selectedZuObjekte" [clrDgRowSelection]="true">
                  <clr-dg-column *ngFor="let column of selectedZuordnungTable['columns']" [clrDgField]="column.id"
                    [style.min-width.px]="(column.id.length * 7.5) + 26">
                    <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sp', 'lf','st','rep']}">
                      {{ column.id | titlecase | mrTranslate }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of selectedZuordnungTable['rows']" [clrDgItem]="row">
                    <ng-container *ngFor="let cell of selectedZuordnungTable['columns']">
                      <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                        <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                          [title]="row[cell.id] | tablePrettyPrint">
                        </clr-dg-cell>
                      </ng-container>
                      <ng-template #iconcell>
                        <clr-dg-cell style="color:#000">
                          <ng-container [ngSwitch]="cell.id.toLowerCase()">
                            <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                              [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="20">
                            </clr-icon>
                          </ng-container>
                        </clr-dg-cell>
                      </ng-template>
                    </ng-container>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="100">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </clr-tab>
          </clr-tabs>
        </div>
      </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="objekteModal" [clrModalClosable]="true" [clrModalSize]="'xl'">
  <h3 class="modal-title">{{"Objekte hinzufügen" | mrTranslate}}</h3>
<div class="modal-body">
  <!-- <form clrForm> -->
    <clr-datagrid *ngIf="objekte?.rows?.length > 0 && objekteModal" class="datagrid-compact" style="height: 850px;"
      [(clrDgSelected)]="selectedObjekte" [clrDgRowSelection]="true">
      <clr-dg-column *ngFor="let column of objekte['columns']" [clrDgField]="column.id"
        [style.min-width.px]="(column.id.length * 7.5) + 26">
        <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sp', 'lf','st','rep']}">
          {{ column.id | titlecase | mrTranslate }}
        </ng-container>
      </clr-dg-column>
      <clr-dg-row *clrDgItems="let row of objekte['rows']" [clrDgItem]="row">
        <ng-container *ngFor="let cell of objekte['columns']">
          <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
            <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
              [title]="row[cell.id] | tablePrettyPrint">
            </clr-dg-cell>
          </ng-container>
          <ng-template #iconcell>
            <clr-dg-cell style="color:#000">
              <ng-container [ngSwitch]="cell.id.toLowerCase()">
                <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                  [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="22">
                </clr-icon>
              </ng-container>
            </clr-dg-cell>
          </ng-template>
        </ng-container>
      </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="100">
          <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
          </clr-dg-page-size>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
          {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  <!-- </form> -->
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="setobjekteModal()">{{ 'Hinzufügen' | mrTranslate }}</button>
    <button type="button" class="btn" (click)="objekteModal = false">{{ 'Beenden' | mrTranslate }}</button>
  </div>
</clr-modal>
